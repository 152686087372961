//groupService.js
import { db } from './firebase';
import { 
  collection, 
  addDoc, 
  doc, 
  updateDoc, 
  getDocs, 
  query, 
  where,
  getDoc,
  serverTimestamp, arrayUnion, arrayRemove, increment 
} from 'firebase/firestore';

export const sendGroupConnectionInvitation = async (
  initiatingGroupId, 
  targetGroupId, 
  initiatorId, 
  activityType
) => {
  try {
    // Fetch members from both groups
    const initiatingGroupRef = doc(db, 'groups', initiatingGroupId);
    const targetGroupRef = doc(db, 'groups', targetGroupId);

    const initiatingGroupSnapshot = await getDoc(initiatingGroupRef);
    const targetGroupSnapshot = await getDoc(targetGroupRef);

    const initiatingGroupData = initiatingGroupSnapshot.data();
    const targetGroupData = targetGroupSnapshot.data();

    // Create a group connection invitation
    const invitationDoc = {
      initiatingGroupId,
      targetGroupId,
      initiatorId,
      activityType,
      initiatingGroupMembers: initiatingGroupData.members,
      targetGroupMembers: targetGroupData.members,
      status: 'pending',
      createdAt: serverTimestamp()
    };

    // Add invitation to Firestore
    const invitationRef = await addDoc(collection(db, 'groupConnectionInvitations'), invitationDoc);

    return invitationRef.id;
  } catch (error) {
    console.error('Error sending group connection invitation:', error);
    throw error;
  }
};


export const createGroup = async (groupData) => {
  try {
    const groupRef = await addDoc(collection(db, 'groups'), {
      ...groupData,
      createdAt: new Date(),
      members: [groupData.creatorId],
      memberCount: 1
    });
    return groupRef.id;
  } catch (error) {
    console.error('Error creating group:', error);
    throw error;
  }
};

export const fetchAllUsers = async () => {
  try {
    const usersRef = collection(db, 'profiles');
    const snapshot = await getDocs(usersRef);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const addGroupMembers = async (groupId, memberIds) => {
  try {
    const groupRef = doc(db, 'groups', groupId);
    await updateDoc(groupRef, {
      members: memberIds,
      memberCount: memberIds.length
    });
  } catch (error) {
    console.error('Error adding group members:', error);
    throw error;
  }
};

export const fetchUserGroups = async (userId) => {
  try {
    const groupsRef = collection(db, 'groups');
    const q = query(groupsRef, where('members', 'array-contains', userId));
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error fetching user groups:', error);
    throw error;
  }
};

// export const fetchGroupMembers = async (groupId) => {
//   try {
//     const groupRef = doc(db, 'groups', groupId);
//     const groupSnapshot = await getDoc(groupRef);
//     const groupData = groupSnapshot.data();
    
//     if (!groupData || !groupData.members) {
//       return [];
//     }

//     const membersRef = collection(db, 'profiles');
//     const q = query(membersRef, where('__name__', 'in', groupData.members));
//     const snapshot = await getDocs(q);
    
//     return snapshot.docs.map(doc => ({ 
//       id: doc.id, 
//       ...doc.data() 
//     }));
//   } catch (error) {
//     console.error('Error fetching group members:', error);
//     return [];
//   }
// };

export const fetchGroupMembers = async (groupId) => {
  try {
    // First get the group document to get member IDs
    const groupRef = doc(db, 'groups', groupId);
    const groupSnapshot = await getDoc(groupRef);
    const groupData = groupSnapshot.data();
    
    if (!groupData || !groupData.members || groupData.members.length === 0) {
      console.log('No members found in group data:', groupData); // Debug log
      return [];
    }

    // Fetch each member's profile individually to ensure we get all data
    const memberPromises = groupData.members.map(async (memberId) => {
      const profileRef = doc(db, 'profiles', memberId);
      const profileSnapshot = await getDoc(profileRef);
      
      if (profileSnapshot.exists()) {
        const profileData = profileSnapshot.data();
        return {
          id: profileSnapshot.id,
          name: profileData.basicInfo?.name || 'Anonymous',
          photos: profileData.photos || [],
          ...profileData
        };
      }
      return null;
    });

    const members = (await Promise.all(memberPromises)).filter(Boolean);
    // console.log('Fetched members:', members); // Debug log
    return members;
  } catch (error) {
    console.error('Error fetching group members:', error);
    return [];
  }
};

export const recommendProfileToGroup = async (groupId, recommendedProfileId, recommendedById) => {
  try {
    const recommendationsRef = collection(db, 'groups', groupId, 'recommendations');
    await addDoc(recommendationsRef, {
      recommendedProfileId,
      recommendedById,
      createdAt: new Date(),
      status: 'pending',
      profileDetails: await fetchProfileDetails(recommendedProfileId)
    });
  } catch (error) {
    console.error('Error recommending profile:', error);
    throw error;
  }
};

const fetchProfileDetails = async (profileId) => {
  const profileDoc = await getDoc(doc(db, 'profiles', profileId));
  return profileDoc.exists() ? profileDoc.data() : null;
};

export const voteOnGroupRecommendation = async (groupId, profileId, voterId, voteType) => {
  try {
    const voteRef = doc(db, 'groupRecommendations', `${groupId}_${profileId}`);
    
    await updateDoc(voteRef, {
      [`votes.${voterId}`]: voteType,
      lastVotedAt: serverTimestamp()
    }, { merge: true });

    // Optional: Check if voting threshold is met
    const voteDoc = await getDoc(voteRef);
    const voteData = voteDoc.data();
    const totalVotes = Object.keys(voteData.votes || {}).length;
    const positiveVotes = Object.values(voteData.votes || {})
      .filter(vote => vote === 'up').length;

    return {
      totalVotes,
      positiveVotes,
      completed: totalVotes === voteData.memberCount
    };
  } catch (error) {
    console.error('Error voting on recommendation:', error);
    throw error;
  }
};

export const updateRecommendationVote = async (groupId, profileId, voterId, voteType) => {
  try {
    const voteRef = doc(db, 'groupRecommendations', `${groupId}_${profileId}`);
    
    await updateDoc(voteRef, {
      [`votes.${voterId}`]: voteType,
      lastVotedAt: serverTimestamp()
    }, { merge: true });

    const voteDoc = await getDoc(voteRef);
    const voteData = voteDoc.data();
    const totalVotes = Object.keys(voteData.votes || {}).length;
    const positiveVotes = Object.values(voteData.votes || {})
      .filter(vote => vote === 'up').length;

    return {
      totalVotes,
      positiveVotes,
      completed: totalVotes === voteData.memberCount
    };
  } catch (error) {
    console.error('Error voting on recommendation:', error);
    throw error;
  }
};

export const connectGroupsForActivity = async (
  initiatingGroupId,
  targetGroupId,
  activityType
) => {
  const activityDoc = {
    type: activityType,
    groups: [initiatingGroupId, targetGroupId],
    status: 'pending',
    createdAt: serverTimestamp(),
  };

  const docRef = await addDoc(collection(db, 'groupActivities'), activityDoc);
  return docRef.id;
};


/////////// Academics
export const joinGroup = async (groupId, userId) => {
  try {
    const groupRef = doc(db, 'groups', groupId);
    await updateDoc(groupRef, {
      members: arrayUnion(userId),
      memberCount: increment(1)
    });
  } catch (error) {
    console.error('Error joining group:', error);
    throw error;
  }
};

export const leaveGroup = async (groupId, userId) => {
  try {
    const groupRef = doc(db, 'groups', groupId);
    await updateDoc(groupRef, {
      members: arrayRemove(userId),
      memberCount: increment(-1)
    });
  } catch (error) {
    console.error('Error leaving group:', error);
    throw error;
  }
};

export const getRecommendedGroups = async (userId) => {
  try {
    // Basic recommendation: groups user is not a member of
    const userGroups = await fetchUserGroups(userId);
    const userGroupIds = userGroups.map(group => group.id);

    const groupsRef = collection(db, 'groups');
    const q = query(
      groupsRef, 
      where('type', '==', 'academic'),
      where('__name__', 'not-in', userGroupIds)
    );

    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error fetching recommended groups:', error);
    return [];
  }
};