// src/components/Home/ImageCarousel.js
import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Circle, CircleDot } from 'lucide-react';

export const ImageCarousel = ({ images, onAllImagesLoaded }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [preloadedImages, setPreloadedImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const preloadImages = async () => {
      setLoading(true);
      try {
        const imagePromises = images.map(src => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = src;
            img.onload = () => resolve(src);
            img.onerror = reject;
          });
        });
        
        const loadedImages = await Promise.all(imagePromises);
        setPreloadedImages(loadedImages);
        setLoading(false);
        onAllImagesLoaded(); // Notify parent when all images are loaded
      } catch (error) {
        console.error('Error preloading images:', error);
        setLoading(false);
      }
    };

    preloadImages();
  }, [images, onAllImagesLoaded]);

  const nextImage = () => {
    setCurrentImageIndex((prev) => (prev + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  if (loading || preloadedImages.length === 0) {
    return (
      <div className="relative pb-[100%] bg-gray-100 animate-pulse" />
    );
  }

  return (
    <div className="relative pb-[100%]">
      <img 
        src={preloadedImages[currentImageIndex]}
        alt={`Profile ${currentImageIndex + 1}`}
        className="absolute h-full w-full object-cover transition-opacity duration-300"
      />
      
      <button 
        onClick={prevImage}
        className="absolute left-2 top-1/2 -translate-y-1/2 p-2 rounded-full 
                   bg-black/30 text-white hover:bg-black/50 transition-colors z-10"
      >
        <ChevronLeft className="w-6 h-6" />
      </button>
      <button 
        onClick={nextImage}
        className="absolute right-2 top-1/2 -translate-y-1/2 p-2 rounded-full 
                   bg-black/30 text-white hover:bg-black/50 transition-colors z-10"
      >
        <ChevronRight className="w-6 h-6" />
      </button>

      <div className="absolute bottom-20 left-1/2 -translate-x-1/2 flex space-x-2 z-10">
        {images.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentImageIndex(index)}
            className="focus:outline-none"
          >
            {index === currentImageIndex ? (
              <CircleDot className="w-4 h-4 text-white" />
            ) : (
              <Circle className="w-4 h-4 text-white/70" />
            )}
          </button>
        ))}
      </div>
    </div>
  );
};