import { doc, getDoc, setDoc, collection, addDoc } from 'firebase/firestore';
import { db } from './firebase';

export const NotificationType = {
  NEW_MESSAGE: 'newMessage',
  NEW_MATCH: 'newMatch',
  MEETUP_REQUEST: 'meetupRequest',
  STUDY_REQUEST: 'studyRequest',
  NEW_EVENT: 'newEvent'
};

const notificationTemplates = {
  [NotificationType.NEW_MESSAGE]: {
    title: "New Message",
    getBody: (data) => `${data.senderName}: ${data.message}`,
    getClickAction: (data) => `/messages/${data.chatId}`
  },
  [NotificationType.NEW_MATCH]: {
    title: "New Match! 🎉",
    getBody: (data) => `You matched with ${data.matchName}!`,
    getClickAction: (data) => `/messages/${data.chatId}`
  },
  [NotificationType.MEETUP_REQUEST]: {
    title: "New Meetup Request",
    getBody: (data) => `${data.senderName} wants to meet up!`,
    getClickAction: () => '/meetups'
  },
  [NotificationType.STUDY_REQUEST]: {
    title: "New Study Partner Request",
    getBody: (data) => `${data.senderName} wants to study with you!`,
    getClickAction: () => '/academics/study-partners'
  },
  [NotificationType.NEW_EVENT]: {
    title: "New Event Added",
    getBody: (data) => `${data.eventName} has been added!`,
    getClickAction: () => '/academics/events'
  }
};

// Store notification in Firestore for in-app retrieval
const storeNotification = async (userId, notification) => {
  try {
    const notificationsRef = collection(db, 'users', userId, 'notifications');
    await addDoc(notificationsRef, {
      ...notification,
      read: false,
      createdAt: new Date(),
    });
    console.log('Notification stored in Firestore');
  } catch (error) {
    console.error('Error storing notification:', error);
    // Don't throw - this is a fallback mechanism
  }
};

export const saveFCMToken = async (userId, token) => {
  if (!userId || !token) {
    console.error('Missing required parameters for saveFCMToken:', { userId, token });
    return;
  }

  try {
    const tokenDoc = doc(db, 'userTokens', userId);
    await setDoc(tokenDoc, {
      fcmToken: token,
      updatedAt: new Date(),
      lastSeen: new Date()
    }, { merge: true });
    console.log('FCM token saved successfully for user:', userId);
  } catch (error) {
    console.error('Error saving FCM token:', error);
    throw new Error(`Failed to save FCM token: ${error.message}`);
  }
};

export const sendNotification = async (userId, notification) => {
  try {
    // Input validation
    if (!userId || !notification) {
      throw new Error('Missing required parameters for sendNotification');
    }

    // Always store notification in Firestore for in-app access
    await storeNotification(userId, notification);

    // Try to get user's FCM token
    const profileDoc = await getDoc(doc(db, 'profiles', userId));
    if (!profileDoc.exists()) {
      console.log(`No profile found for user ${userId}, notification stored in-app only`);
      return { status: 'stored', message: 'Notification stored for in-app delivery' };
    }

    const fcmToken = profileDoc.data().fcmToken;
    if (!fcmToken) {
      console.log(`No FCM token found for user ${userId}, notification stored in-app only`);
      return { status: 'stored', message: 'Notification stored for in-app delivery' };
    }

    // Prepare notification payload
    const payload = {
      token: fcmToken,
      notification: {
        title: notification.title,
        body: notification.body,
        icon: '/logo192.png'
      },
      webpush: {
        headers: {
          Urgency: 'high'
        },
        notification: {
          icon: '/logo192.png',
          badge: '/logo192.png',
          requireInteraction: true
        },
        fcm_options: {
          link: notification.clickAction || '/'
        }
      },
      data: Object.fromEntries(
        Object.entries(notification.data || {}).map(([key, value]) => [key, String(value)])
      )
    };

    console.log('Sending notification payload:', JSON.stringify(payload, null, 2));

    // Try to send FCM notification
    const response = await fetch('https://us-central1-wingmancoza.cloudfunctions.net/sendPushNotification', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload)
    });

    const responseData = await response.json();
    
    if (!response.ok) {
      console.warn(`FCM delivery failed, but notification is stored in-app: ${JSON.stringify(responseData)}`);
      return { status: 'stored', message: 'Notification stored for in-app delivery' };
    }

    console.log('Notification sent successfully via FCM and stored in-app:', responseData);
    return { status: 'sent', fcmResponse: responseData };

  } catch (error) {
    console.error('Error in sendNotification:', error);
    // Don't throw - we've already stored the notification in Firestore
    return { status: 'stored', error: error.message };
  }
};

export const createNotification = async (userId, type, data) => {
  try {
    // Validate inputs
    if (!userId || !type || !data) {
      throw new Error('Missing required parameters for createNotification');
    }

    const template = notificationTemplates[type];
    if (!template) {
      throw new Error(`Invalid notification type: ${type}`);
    }

    // Create notification content from template
    const notificationContent = {
      title: typeof template.title === 'function' ? template.title(data) : template.title,
      body: template.getBody(data),
      clickAction: template.getClickAction(data),
      data: {
        type,
        timestamp: new Date().toISOString(),
        ...data
      }
    };

    console.log('Creating notification:', {
      userId,
      type,
      content: notificationContent
    });

    // Send notification with fallback handling
    const result = await sendNotification(userId, notificationContent);
    
    console.log('Notification processing complete:', {
      userId,
      type,
      result
    });

    return result;

  } catch (error) {
    console.error('Error in createNotification:', error);
    throw error;
  }
};