import React, { useState, useEffect, useRef } from 'react';
import {
    BookOpenCheck,
    Plus,
    Search,
    Filter,
    AlertCircle,
    Tag,
    MapPin,
    ChevronDown,
    Calendar,
    CalendarX,
    ChevronLeft,
    SlidersHorizontal,
    RefreshCw
} from 'lucide-react';
import AssignmentCard from '../../components/assignment/AssignmentCard'; // Import the new card component
import UploadAssignmentModal from './UploadAssignmentModal';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import {
    fetchAssignments,
    uploadAssignment,
    uploadFile
} from '../../firebase/assignmentService';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase/firebase';

const SkeletonLoader = () => {
    return (
        <div className="bg-white rounded-xl shadow-md mb-4 overflow-hidden border border-gray-100 animate-pulse">
            <div className="p-5 space-y-4">
                {/* Status Badge Skeleton */}
                <div className="flex justify-between">
                    <div className="h-6 bg-gray-200 rounded-full w-16"></div>
                    <div className="h-6 bg-gray-200 rounded-full w-8"></div>
                </div>
                
                {/* Header Skeleton */}
                <div className="flex items-start space-x-3">
                    <div className="h-10 w-10 bg-gray-200 rounded-lg"></div>
                    <div className="flex-1 space-y-2">
                        <div className="h-6 bg-gray-200 rounded w-3/4"></div>
                        <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                    </div>
                </div>

                {/* Details Skeleton */}
                <div className="grid grid-cols-2 gap-3">
                    <div className="h-16 bg-gray-200 rounded-lg"></div>
                    <div className="h-16 bg-gray-200 rounded-lg"></div>
                </div>

                {/* University Skeleton */}
                <div className="h-10 bg-gray-200 rounded-lg w-full"></div>

                {/* Description Skeleton */}
                <div className="space-y-2">
                    <div className="h-4 bg-gray-200 rounded w-1/4"></div>
                    <div className="h-4 bg-gray-200 rounded w-full"></div>
                    <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                </div>

                {/* PDF Link Skeleton */}
                <div className="h-10 bg-gray-200 rounded-lg w-full"></div>
            </div>
        </div>
    );
};

const AssignmentHub = () => {
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const [assignments, setAssignments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedAssignment, setSelectedAssignment] = useState(null);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredAssignments, setFilteredAssignments] = useState([]);
    const [assignmentData, setAssignmentData] = useState({
        courseName: '',
        courseCode: '',
        university: '',
        phoneNumber: '',
        description: '',
        dueDate: '',
        priceOffer: ''
    });
    const [attachedPdf, setAttachedPdf] = useState(null);
    const fileInputRef = useRef(null);
    const [universityFilter, setUniversityFilter] = useState('');
    const [userPreferences, setUserPreferences] = useState(null);
    const [statusFilter, setStatusFilter] = useState('active'); // 'active', 'expired', 'all'
    const [sortBy, setSortBy] = useState('dueDate'); // 'dueDate', 'priceOffer'
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [universities, setUniversities] = useState([]);
    const [refreshing, setRefreshing] = useState(false);

    // Function to check if assignment is expired
    const isExpired = (dueDate) => {
        if (!dueDate) return false;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        // Parse the due date (assuming format YYYY-MM-DD)
        const assignmentDueDate = new Date(dueDate);
        return assignmentDueDate < today;
    };

    // Extract unique universities from assignments
    useEffect(() => {
        if (assignments.length > 0) {
            const uniqueUniversities = [...new Set(assignments.map(a => a.university))].filter(Boolean);
            setUniversities(uniqueUniversities);
        }
    }, [assignments]);

    // Update filtering with status and sorting
    useEffect(() => {
        if (!assignments.length) {
            setFilteredAssignments([]);
            return;
        }

        let filtered = [...assignments];
        
        // Apply status filter
        if (statusFilter !== 'all') {
            filtered = filtered.filter(assignment => 
                statusFilter === 'active' 
                    ? !isExpired(assignment.dueDate) 
                    : isExpired(assignment.dueDate)
            );
        }
        
        // Apply search filter
        if (searchQuery.trim() !== '') {
            const query = searchQuery.toLowerCase();
            filtered = filtered.filter(assignment => 
                assignment.courseName?.toLowerCase().includes(query) ||
                assignment.courseCode?.toLowerCase().includes(query) ||
                assignment.description?.toLowerCase().includes(query)
            );
        }
        
        // Apply university filter
        if (universityFilter && universityFilter !== 'All Universities') {
            filtered = filtered.filter(assignment => 
                assignment.university === universityFilter
            );
        }
        
        // Apply sorting
        filtered.sort((a, b) => {
            if (sortBy === 'dueDate') {
                const dateA = new Date(a.dueDate || '2099-01-01');
                const dateB = new Date(b.dueDate || '2099-01-01');
                return dateA - dateB;
            } else if (sortBy === 'priceOffer') {
                return (parseFloat(b.priceOffer) || 0) - (parseFloat(a.priceOffer) || 0);
            }
            return 0;
        });
        
        setFilteredAssignments(filtered);
    }, [searchQuery, assignments, universityFilter, statusFilter, sortBy]);

    // Add this useEffect to fetch user preferences
    useEffect(() => {
        const fetchUserPreferences = async () => {
            if (currentUser) {
                try {
                    const userDoc = await getDocs(
                        query(
                            collection(db, 'users'),
                            where('userId', '==', currentUser.uid)
                        )
                    );
                    
                    if (!userDoc.empty) {
                        const userData = userDoc.docs[0].data();
                        setUserPreferences({
                            academic: {
                                university: userData.university || ''
                            }
                        });
                        
                        // Set the university filter to the user's university by default
                        if (userData.university) {
                            setUniversityFilter(userData.university);
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user preferences:', error);
                }
            }
        };

        fetchUserPreferences();
    }, [currentUser]);

    const loadAssignments = async () => {
        try {
            setLoading(true);
            const fetchedAssignments = await fetchAssignments();
            setAssignments(fetchedAssignments);
        } catch (error) {
            console.error('Error loading assignments:', error);
        } finally {
            setLoading(false);
            setRefreshing(false);
        }
    };

    useEffect(() => {
        if (currentUser) {
            loadAssignments();
        }
    }, [currentUser]);

    const handleRefresh = () => {
        setRefreshing(true);
        loadAssignments();
    };

    const handleSubmitAssignment = async (e) => {
        e.preventDefault();
        if (!currentUser) {
            alert('Please log in to submit an assignment');
            return;
        }

        // Validate compulsory fields
        if (
            !assignmentData.courseCode ||
            !assignmentData.university ||
            !assignmentData.phoneNumber ||
            !assignmentData.dueDate ||
            !attachedPdf
        ) {
            alert('Please fill out all compulsory fields: Course Code, University, Phone Number, Due Date, and PDF.');
            return;
        }

        // Validate phone number
        const phoneRegex = /^[0-9]{10}$/;
        if (!phoneRegex.test(assignmentData.phoneNumber)) {
            alert('Please enter a valid 10-digit phone number');
            return;
        }

        try {
            const pdfUrl = await uploadFile(attachedPdf);
            await uploadAssignment(assignmentData, currentUser.uid, pdfUrl);

            // Reset form and close modal
            setAssignmentData({
                courseName: '',
                courseCode: '',
                university: '',
                phoneNumber: '',
                description: '',
                dueDate: '',
                priceOffer: ''
            });
            setAttachedPdf(null);
            setIsUploadModalOpen(false);

            // Refresh assignments
            loadAssignments();
        } catch (error) {
            console.error('Error submitting assignment:', error);
            alert('Failed to submit assignment');
        }
    };

    const handleEditAssignment = (assignment) => {
        setSelectedAssignment(assignment);
        // Populate form with assignment data
        setAssignmentData({
            courseName: assignment.courseName || '',
            courseCode: assignment.courseCode || '',
            university: assignment.university || '',
            phoneNumber: assignment.phoneNumber || '',
            description: assignment.description || '',
            dueDate: assignment.dueDate || '',
            priceOffer: assignment.priceOffer || ''
        });
        setIsUploadModalOpen(true);
    };

    const renderUploadModal = () => (
        <UploadAssignmentModal
            isOpen={isUploadModalOpen}
            onClose={() => {
                setIsUploadModalOpen(false);
                setSelectedAssignment(null);
                setAssignmentData({
                    courseName: '',
                    courseCode: '',
                    university: '',
                    phoneNumber: '',
                    description: '',
                    dueDate: '',
                    priceOffer: ''
                });
            }}
            assignmentData={assignmentData}
            setAssignmentData={setAssignmentData}
            onSubmit={handleSubmitAssignment}
            selectedAssignment={selectedAssignment}
            fileInputRef={fileInputRef}
            setAttachedPdf={setAttachedPdf}
        />
    );

    const renderFloatingUploadButton = () => (
        <button
            onClick={() => setIsUploadModalOpen(true)}
            className="fixed bottom-6 right-6 bg-blue-600 text-white rounded-full p-3.5 shadow-lg hover:bg-blue-700 transition-colors z-40
                     transform hover:scale-110 active:scale-95 duration-150"
            aria-label="Add new assignment"
        >
            <Plus size={26} />
        </button>
    );

    const renderHeader = () => (
        <div className="bg-white border-b border-gray-200 sticky top-0 z-10 shadow-sm">
            <div className="max-w-7xl mx-auto">
                <div className="flex items-center justify-between p-4">
                    <div className="flex items-center">
                        <button 
                            onClick={() => navigate('/academic')} 
                            className="text-gray-600 hover:text-gray-800 mr-3 hover:bg-gray-100 p-2 rounded-full transition-colors"
                            aria-label="Go back"
                        >
                            <ChevronLeft className="w-5 h-5" />
                        </button>
                        <h1 className="text-xl font-bold text-gray-800 flex items-center">
                            {currentUser?.photoURL && (
                                <div className="w-8 h-8 rounded-full bg-green-500 flex items-center justify-center text-white mr-2">
                                    <span className="font-bold text-sm">V</span>
                                </div>
                            )}
                            Assignment Hub
                        </h1>
                    </div>
                    <div className="flex items-center space-x-2">
                        <button 
                            onClick={handleRefresh}
                            className={`p-2 rounded-full text-gray-600 hover:text-gray-800 hover:bg-gray-100 transition-colors ${refreshing ? 'animate-spin' : ''}`}
                            aria-label="Refresh assignments"
                        >
                            <RefreshCw className="w-5 h-5" />
                        </button>
                        <button 
                            onClick={() => setFiltersOpen(!filtersOpen)}
                            className={`p-2 rounded-full ${filtersOpen ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:text-gray-800 hover:bg-gray-100'} transition-colors`}
                            aria-label="Toggle filters"
                        >
                            <SlidersHorizontal className="w-5 h-5" />
                        </button>
                    </div>
                </div>
                
                {/* Search Input */}
                <div className="px-4 pb-3">
                    <div className="relative">
                        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                        <input
                            type="text"
                            placeholder="Search for assignments..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="w-full pl-10 pr-4 py-2.5 rounded-xl border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    const renderFilters = () => (
        <div className={`bg-gray-50 border-b border-gray-200 transition-all duration-300 overflow-hidden ${filtersOpen ? 'max-h-96' : 'max-h-0'}`}>
            <div className="max-w-7xl mx-auto px-4 py-3 space-y-4">
                {/* University Filter */}
                <div className="space-y-1.5">
                    <label className="text-xs font-medium text-gray-700">Filter by University</label>
                    <select
                        value={universityFilter}
                        onChange={(e) => setUniversityFilter(e.target.value)}
                        className="w-full p-2.5 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-800 text-sm appearance-none bg-white"
                        aria-label="Filter by university"
                    >
                        <option value="">All Universities</option>
                        {universities.map((uni) => (
                            <option key={uni} value={uni}>
                                {uni}
                            </option>
                        ))}
                    </select>
                </div>
                
                {/* Status Filter */}
                <div className="space-y-1.5">
                    <label className="text-xs font-medium text-gray-700">Status</label>
                    <div className="flex space-x-2">
                        {['active', 'expired', 'all'].map(status => (
                            <button
                                key={status}
                                onClick={() => setStatusFilter(status)}
                                className={`py-2 px-4 rounded-lg text-sm flex items-center space-x-2 ${
                                    statusFilter === status
                                        ? 'bg-blue-100 text-blue-700 font-medium'
                                        : 'bg-white text-gray-600 hover:bg-gray-100 border border-gray-200'
                                } transition-colors`}
                            >
                                {status === 'active' && <Calendar className="w-4 h-4" />}
                                {status === 'expired' && <CalendarX className="w-4 h-4" />}
                                {status === 'all' && <Tag className="w-4 h-4" />}
                                <span>{status.charAt(0).toUpperCase() + status.slice(1)}</span>
                            </button>
                        ))}
                    </div>
                </div>

                {/* Sort By */}
                <div className="space-y-1.5">
                    <label className="text-xs font-medium text-gray-700">Sort By</label>
                    <div className="grid grid-cols-2 gap-2">
                        <button
                            onClick={() => setSortBy('dueDate')}
                            className={`py-2 px-4 rounded-lg text-sm flex items-center justify-center space-x-2 ${
                                sortBy === 'dueDate'
                                    ? 'bg-blue-100 text-blue-700 font-medium'
                                    : 'bg-white text-gray-600 hover:bg-gray-100 border border-gray-200'
                            } transition-colors`}
                        >
                            <Calendar className="w-4 h-4 mr-1.5" />
                            <span>Due Date</span>
                        </button>
                        <button
                            onClick={() => setSortBy('priceOffer')}
                            className={`py-2 px-4 rounded-lg text-sm flex items-center justify-center space-x-2 ${
                                sortBy === 'priceOffer'
                                    ? 'bg-blue-100 text-blue-700 font-medium'
                                    : 'bg-white text-gray-600 hover:bg-gray-100 border border-gray-200'
                            } transition-colors`}
                        >
                            <span>Price (High to Low)</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

    const renderResultsSummary = () => {
        if (!filteredAssignments.length || loading) return null;
        
        return (
            <div className="max-w-7xl mx-auto px-4 py-3">
                <div className="flex items-center justify-between">
                    <p className="text-sm text-gray-600">
                        <span className="font-medium">{filteredAssignments.length}</span> assignments found
                        {universityFilter && universityFilter !== 'All Universities' && 
                            <span> at <span className="font-medium">{universityFilter}</span></span>}
                        {statusFilter !== 'all' && 
                            <span> with status <span className="font-medium">{statusFilter}</span></span>}
                    </p>
                </div>
            </div>
        );
    };

    return (
        <div className="min-h-screen bg-gray-50 flex flex-col">
            {renderHeader()}
            {renderFilters()}
            {renderResultsSummary()}

            <main className="flex-1 mt-2 mb-20 px-4 py-2 overflow-y-auto max-w-7xl mx-auto w-full">
                {!currentUser ? (
                    <div className="text-center py-10 bg-white rounded-xl shadow-sm mt-4">
                        <AlertCircle className="w-12 h-12 text-blue-400 mx-auto mb-4" />
                        <p className="text-lg text-gray-600 font-medium">Please log in to view assignments</p>
                        <p className="text-sm text-gray-500 mt-1">You'll need to authenticate to access this feature</p>
                    </div>
                ) : loading ? (
                    <div className="space-y-4 mt-4">
                        <SkeletonLoader />
                        <SkeletonLoader />
                        <SkeletonLoader />
                    </div>
                ) : (
                    <>
                        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                            {filteredAssignments.length > 0 ? (
                                filteredAssignments.map(assignment => (
                                    <AssignmentCard 
                                        key={assignment.id}
                                        assignment={assignment}
                                        currentUser={currentUser}
                                        onEdit={handleEditAssignment}
                                    />
                                ))
                            ) : (
                                <div className="text-center py-8 bg-white rounded-xl shadow-sm mt-4 col-span-full">
                                    <AlertCircle className="w-12 h-12 text-gray-300 mx-auto mb-4" />
                                    <p className="text-gray-500 font-medium">No assignments found</p>
                                    <p className="text-sm text-gray-400 mt-1">Try changing your search or filters</p>
                                    <button
                                        onClick={() => {
                                            setSearchQuery('');
                                            setUniversityFilter('');
                                            setStatusFilter('active');
                                        }}
                                        className="mt-4 px-4 py-2 bg-blue-50 text-blue-600 rounded-lg text-sm hover:bg-blue-100 transition-colors"
                                    >
                                        Reset filters
                                    </button>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </main>

            {currentUser && renderFloatingUploadButton()}
            {isUploadModalOpen && renderUploadModal()}
        </div>
    );
};

export default AssignmentHub;