import { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { auth, db } from '../firebase/firebase';
import NavBar from '../components/NavBar';
import { Plus, Search } from 'lucide-react';
import { CreateGroupModal } from '../components/groups/CreateGroupModal';
import { GroupList } from '../components/groups/GroupList';
import { connectGroupsForActivity } from '../firebase/groupService';
import { BellRing } from 'lucide-react';
import { useNotifications } from '../contexts/NotificationsContext';




import GroupConnectionNotificationsModal from '../components/groups/GroupConnectionNotificationsModal';

const GroupsPage = () => {
  const { 
    groupConnectionCount, 
    groupConnectionNotifications,
    acceptGroupConnectionInvitation,
    rejectGroupConnectionInvitation 
  } = useNotifications();
  const [showGroupConnectionModal, setShowGroupConnectionModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeSection, setActiveSection] = useState('your');
  const [userGroups, setUserGroups] = useState([]);
  const [discoverGroups, setDiscoverGroups] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState({ show: false, message: '' });

  useEffect(() => {
    const user = auth.currentUser;
    setCurrentUser(user);

    const fetchGroups = async () => {
      if (user) {
        setLoading(true);
        try {
          const allGroupsSnapshot = await getDocs(collection(db, 'groups'));
          const allGroups = allGroupsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));

          // Get user's primary group (the one they're a member of)
          const userGroup = allGroups.filter(group => 
            group.members?.includes(user.uid)
          );
          setUserGroups(userGroup);
          
          // Get all other groups for discovery (excluding user's own group)
          const groupsForDiscovery = allGroups.filter(group => 
            !group.members?.includes(user.uid)
          );
          setDiscoverGroups(groupsForDiscovery);
        } catch (error) {
          console.error('Error fetching groups:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchGroups();
  }, []);

  const showNotification = (message) => {
    setNotification({ show: true, message });
    setTimeout(() => {
      setNotification({ show: false, message: '' });
    }, 3000);
  };

  const handleCreateGroupClick = () => {
    if (userGroups.length > 0) {
      showNotification('You can only be in one group at a time');
    } else {
      setShowCreateModal(true);
    }
  };

  const handleInviteToGames = async (targetGroupId, selectedGroupId, activityType) => {
    // Verify user has a group before allowing connection
    if (userGroups.length === 0) {
      showNotification('You need to be in a group to connect with other groups');
      return;
    }

    try {
      const activityId = await connectGroupsForActivity(
        selectedGroupId,
        targetGroupId,
        activityType
      );
      console.log('Activity created with ID:', activityId);
      showNotification('Successfully connected with group for activity!');
    } catch (error) {
      console.error('Error creating group activity:', error);
      showNotification('Failed to connect with group');
    }
  };

  const filteredUserGroups = userGroups.filter(group =>
    group.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  
  const filteredDiscoverGroups = discoverGroups.filter(group =>
    group.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const TabButton = ({ section, label }) => (
    <button
      onClick={() => setActiveSection(section)}
      className={`py-3 px-4 relative ${
        activeSection === section
          ? 'text-green-600 font-semibold'
          : 'text-gray-500'
      }`}
    >
      {label}
      {activeSection === section && (
        <div className="absolute bottom-0 left-0 w-full h-0.5 bg-green-500" />
      )}
    </button>
  );

  return (
    <div className="h-screen bg-gray-50 flex flex-col">
      {notification.show && (
        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-50 bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded shadow-lg">
          {notification.message}
        </div>
      )}

      <header className="fixed top-0 w-full bg-white shadow-sm z-40 px-4 py-3">
  <div className="flex items-center justify-between mb-4">
    <span className="text-2xl font-bold bg-gradient-to-r from-green-500 to-blue-500 bg-clip-text text-transparent">
      Groups
    </span>
    <div className="flex items-center space-x-2">
      <div className="relative">
        <BellRing 
          onClick={() => setShowGroupConnectionModal(true)} 
          className="w-6 h-6 text-gray-600 cursor-pointer"
        />
        {groupConnectionCount > 0 && (
          <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center">
            {groupConnectionCount}
          </span>
        )}
      </div>
      <button
        onClick={handleCreateGroupClick}
        className="p-2 rounded-full bg-green-500 text-white hover:bg-green-600 disabled:bg-gray-300"
        disabled={userGroups.length > 0}
      >
        <Plus className="w-5 h-5" />
      </button>
    </div>
  </div>
  <div className="relative">
    <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
    <input
      type="text"
      placeholder="Search groups"
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      className="w-full pl-10 pr-4 py-2 rounded-full bg-gray-100 focus:outline-none focus:ring-2 focus:ring-green-500"
    />
  </div>
</header>
      {showGroupConnectionModal && (
        <GroupConnectionNotificationsModal 
          notifications={groupConnectionNotifications}
          onClose={() => setShowGroupConnectionModal(false)}
          onAccept={acceptGroupConnectionInvitation}
          onReject={rejectGroupConnectionInvitation}
        />
      )}
      <div className="fixed top-28 w-full bg-white border-b z-30">
        <div className="flex justify-center space-x-8 px-4">
          <TabButton section="your" label="Your Group" />
          <TabButton section="other" label="Discover Groups" />
        </div>
      </div>

      <main className="flex-1 mt-44 mb-20 px-4 py-6 overflow-y-auto">
        <div className={activeSection === 'your' ? 'block' : 'hidden'}>
          <GroupList
            groups={filteredUserGroups}
            loading={loading}
            isUserMember={true}
            userGroups={filteredUserGroups}
            emptyStateTitle="No group found"
            emptyStateMessage="Create or join a group to get started"
          />
        </div>

        <div className={activeSection === 'other' ? 'block' : 'hidden'}>
          <GroupList
            groups={filteredDiscoverGroups}
            loading={loading}
            isUserMember={false}
            userGroups={filteredUserGroups}
            onInviteToGames={handleInviteToGames}
            emptyStateTitle="No groups to discover"
            emptyStateMessage="Try adjusting your search"
          />
        </div>
      </main>

      <NavBar />

      {showCreateModal && currentUser && (
        <CreateGroupModal
          onClose={() => setShowCreateModal(false)}
          currentUser={currentUser}
        />
      )}
    </div>
  );
};

export default GroupsPage;