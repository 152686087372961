import React from 'react';

const ProfileSkeleton = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header Skeleton */}
      <div className="bg-white border-b">
        <div className="max-w-2xl mx-auto px-4 py-4 flex justify-between items-center">
          <div className="h-8 w-32 bg-gray-200 animate-pulse rounded-lg"></div>
          <div className="h-8 w-20 bg-gray-200 animate-pulse rounded-lg"></div>
        </div>
      </div>

      <main className="max-w-2xl mx-auto px-4 py-6">
        {/* Photo Gallery Skeleton */}
        <div className="grid grid-cols-3 gap-2 mb-8">
          {[...Array(6)].map((_, i) => (
            <div 
              key={i} 
              className="aspect-square bg-gray-200 animate-pulse rounded-xl"
            />
          ))}
        </div>

        {/* Basic Info Skeleton */}
        <div className="bg-white p-6 rounded-2xl mb-6">
          <div className="h-6 w-32 bg-gray-200 animate-pulse rounded mb-4"></div>
          <div className="space-y-3">
            {[...Array(4)].map((_, i) => (
              <div key={i} className="h-4 bg-gray-200 animate-pulse rounded w-3/4"></div>
            ))}
          </div>
        </div>

        {/* Academic Info Skeleton */}
        <div className="bg-white p-6 rounded-2xl mb-6">
          <div className="h-6 w-40 bg-gray-200 animate-pulse rounded mb-4"></div>
          <div className="space-y-3">
            {[...Array(3)].map((_, i) => (
              <div key={i} className="h-4 bg-gray-200 animate-pulse rounded w-2/3"></div>
            ))}
          </div>
        </div>

        {/* About Me Skeleton */}
        <div className="bg-white p-6 rounded-2xl mb-6">
          <div className="h-6 w-24 bg-gray-200 animate-pulse rounded mb-4"></div>
          <div className="space-y-3">
            <div className="h-20 bg-gray-200 animate-pulse rounded"></div>
            <div className="h-4 bg-gray-200 animate-pulse rounded w-1/2"></div>
          </div>
        </div>

        {/* Additional Info Skeleton */}
        <div className="bg-white p-6 rounded-2xl mb-6">
          <div className="h-6 w-36 bg-gray-200 animate-pulse rounded mb-4"></div>
          <div className="space-y-3">
            {[...Array(2)].map((_, i) => (
              <div key={i} className="h-4 bg-gray-200 animate-pulse rounded w-5/6"></div>
            ))}
          </div>
        </div>

        {/* Settings Skeleton */}
        <div className="bg-white p-6 rounded-2xl">
          <div className="h-6 w-28 bg-gray-200 animate-pulse rounded mb-4"></div>
          <div className="space-y-3">
            {[...Array(3)].map((_, i) => (
              <div key={i} className="h-4 bg-gray-200 animate-pulse rounded w-4/5"></div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default ProfileSkeleton;