import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { 
  GraduationCap, Book, User, 
  Clock, Languages, 
  Calendar, DollarSign, ArrowLeft, Search,
  X, MapPin, ChevronRight
} from 'lucide-react';
import { format } from 'date-fns';
import AcademicHeader from './AcademicHeader';
import TutorBooking from './TutorBooking';

const FindTutors = () => {
  const [tutors, setTutors] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUniversity, setSelectedUniversity] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedTutor, setSelectedTutor] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (!currentUser) {
      setLoading(false);
      return;
    }

    const fetchTutors = async () => {
      setError(null);
      try {
        const snapshot = await getDocs(collection(db, 'tutorApplications'));
        const approvedTutors = await Promise.all(
          snapshot.docs
            .filter(doc => doc.data().status === 'approved')
            .map(async (docSnapshot) => {
              const appData = {
                id: docSnapshot.id,
                ...docSnapshot.data(),
                submittedAt: docSnapshot.data().submittedAt?.toDate() || new Date(),
              };

              try {
                // Fetch profile details
                const profileRef = doc(db, 'profiles', appData.userId);
                const profileSnap = await getDoc(profileRef);
                
                if (profileSnap.exists()) {
                  const profileData = profileSnap.data();
                  appData.languages = profileData.languages || [];
                  appData.yearOfStudy = profileData.academic?.yearOfStudy || '';
                  appData.photoURL = profileData.photos?.[0] || profileData.photoURL || '/api/placeholder/80/80';
                }

                // Fetch availability and pricing
                const availabilityRef = doc(db, 'tutorAvailability', appData.userId);
                const availabilitySnap = await getDoc(availabilityRef);
                
                if (availabilitySnap.exists()) {
                  const availabilityData = availabilitySnap.data();
                  appData.availability = availabilityData.slots || [];
                  appData.hourlyRate = availabilityData.hourlyRate || 0;
                  appData.lastUpdated = availabilityData.updatedAt?.toDate() || null;
                }
              } catch (fetchError) {
                console.error(`Error fetching data for tutor ${appData.userId}:`, fetchError);
              }

              return appData;
            })
        );

        // Sort by last updated or submitted date
        const sortedTutors = approvedTutors.sort((a, b) => 
          (b.lastUpdated || b.submittedAt || 0) - (a.lastUpdated || a.submittedAt || 0)
        );

        setTutors(sortedTutors);
      } catch (error) {
        console.error('Failed to fetch tutors:', error);
        setError('Failed to load tutors. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchTutors();
  }, [currentUser]);

  const filterTutors = () => {
    return tutors.filter(tutor => {
      const matchesSearch = 
        tutor.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        tutor.university?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        tutor.courses?.some(course => 
          course.courseCode.toLowerCase().includes(searchTerm.toLowerCase())
        );
      
      const matchesUniversity = !selectedUniversity || 
        tutor.university?.toLowerCase().includes(selectedUniversity.toLowerCase());

      return matchesSearch && matchesUniversity;
    });
  };

  const handleFilterChange = (university) => {
    setSelectedUniversity(university);
  };

  const handleGoBack = () => {
    window.history.back();
  };

  const handleTutorClick = (tutor) => {
    setSelectedTutor(tutor);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTutor(null);
  };

  const renderDayBadge = (day) => {
    const colors = {
      Monday: "bg-blue-100 text-blue-800",
      Tuesday: "bg-purple-100 text-purple-800",
      Wednesday: "bg-green-100 text-green-800",
      Thursday: "bg-yellow-100 text-yellow-800",
      Friday: "bg-pink-100 text-pink-800",
      Saturday: "bg-orange-100 text-orange-800",
      Sunday: "bg-red-100 text-red-800"
    };
    
    return (
      <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${colors[day] || "bg-gray-100 text-gray-800"}`}>
        {day.substring(0, 3)}
      </span>
    );
  };

  const TutorCard = ({ tutor }) => {
    return (
      <div 
        className="relative bg-white rounded-xl shadow-md hover:shadow-lg transition-all duration-300 overflow-hidden cursor-pointer flex items-center p-3"
        onClick={() => handleTutorClick(tutor)}
      >
        <div className="relative mr-3 flex-shrink-0">
          <div className="w-14 h-14 rounded-full overflow-hidden bg-gray-100 border-2 border-gray-100">
            <img 
              src={tutor.photoURL || '/api/placeholder/80/80'} 
              alt={tutor.name}
              className="h-full w-full object-cover"
              onError={(e) => { e.target.src = '/api/placeholder/80/80'; }}
            />
          </div>
        </div>
        
        <div className="flex-1 min-w-0">
          <div className="flex justify-between items-start">
            <h3 className="text-base font-semibold text-gray-900 truncate">{tutor.name}</h3>
            <span className="text-sm font-medium text-green-600">
              R{tutor.hourlyRate?.toFixed(0) || '0'}/hr
            </span>
          </div>
          
          <p className="text-xs text-gray-500 flex items-center mt-0.5 truncate">
            <GraduationCap className="flex-shrink-0 w-3 h-3 mr-1" />
            <span>{tutor.university || 'University not specified'}</span>
          </p>
          
          <div className="mt-1.5 flex flex-wrap items-center text-xs text-gray-600">
            <span className="mr-1.5 flex items-center">
              <Book className="w-3 h-3 mr-1 text-green-500" />
            </span>
            {tutor.courses?.length > 0 ? (
              <span className="truncate">
                {tutor.courses.slice(0, 2).map(c => c.courseCode).join(', ')}
                {tutor.courses.length > 2 && ` +${tutor.courses.length - 2}`}
              </span>
            ) : (
              <span className="text-gray-400 italic">No courses listed</span>
            )}
          </div>
          
          <div className="mt-1.5 flex flex-wrap gap-1">
            {tutor.availability?.length > 0 ? (
              [...new Set(tutor.availability.map(slot => slot.day))].slice(0, 3).map(day => (
                <span key={day}>{renderDayBadge(day)}</span>
              ))
            ) : (
              <span className="text-xs text-gray-400 italic">No availability</span>
            )}
            {tutor.availability?.length > 3 && (
              <span className="text-xs text-gray-500">+more</span>
            )}
          </div>
        </div>
        
        <ChevronRight className="w-4 h-4 text-gray-400 ml-2 flex-shrink-0" />
      </div>
    );
  };

  const TutorModal = () => {
    if (!showModal || !selectedTutor) return null;

    const isSelfView = currentUser && currentUser.uid === selectedTutor.userId;
    const formattedCourses = selectedTutor.courses?.sort((a, b) => b.finalMark - a.finalMark) || [];
    const availabilityByDay = {};
    selectedTutor.availability?.forEach(slot => {
      availabilityByDay[slot.day] = availabilityByDay[slot.day] || [];
      availabilityByDay[slot.day].push(slot);
    });
    
    return (
      <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50 p-4">
        <div className="relative bg-white rounded-xl w-full max-w-md max-h-[90vh] overflow-hidden flex flex-col">
          <div className="bg-green-50 p-4 border-b border-gray-200 flex items-center space-x-4">
            <div className="w-16 h-16 rounded-full overflow-hidden bg-white border-4 border-white shadow-sm">
              <img 
                src={selectedTutor.photoURL || '/api/placeholder/80/80'} 
                alt={selectedTutor.name}
                className="h-full w-full object-cover"
                onError={(e) => { e.target.src = '/api/placeholder/80/80'; }}
              />
            </div>
            <div className="flex-1">
              <h2 className="text-xl font-bold text-gray-900">{selectedTutor.name}</h2>
              <p className="text-sm text-gray-600 flex items-center">
                <MapPin className="w-3 h-3 mr-1" />
                {selectedTutor.university || 'University not specified'}
              </p>
            </div>
            <button
              onClick={handleCloseModal}
              className="text-gray-500 hover:text-gray-700 transition-colors p-2 rounded-full hover:bg-gray-100"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
          
          <div className="flex-1 overflow-y-auto p-5 space-y-6">
            <div className="space-y-3">
              <h3 className="text-lg font-semibold flex items-center">
                <User className="w-5 h-5 mr-2 text-green-500" />
                About
              </h3>
              <div className="grid grid-cols-2 gap-3 text-sm">
                <div>
                  <p className="text-gray-500">Year of Study</p>
                  <p className="font-medium">{selectedTutor.yearOfStudy || 'Not specified'}</p>
                </div>
                <div>
                  <p className="text-gray-500">Hourly Rate</p>
                  <p className="font-medium text-green-600">
                    R{selectedTutor.hourlyRate?.toFixed(2) || '0.00'}
                  </p>
                </div>
                <div>
                  <p className="text-gray-500">Languages</p>
                  <p className="font-medium">
                    {selectedTutor.languages?.length > 0 
                      ? selectedTutor.languages.join(', ') 
                      : 'Not specified'}
                  </p>
                </div>
              </div>
            </div>
            
            <div className="space-y-3">
              <h3 className="text-lg font-semibold flex items-center">
                <Book className="w-5 h-5 mr-2 text-green-500" />
                Courses ({formattedCourses.length})
              </h3>
              {formattedCourses.length > 0 ? (
                <div className="space-y-2">
                  {formattedCourses.map((course, index) => (
                    <div 
                      key={index}
                      className="flex items-center justify-between p-3 rounded-lg bg-gray-50 border border-gray-100"
                    >
                      <div className="flex-1">
                        <p className="font-medium text-gray-800">{course.courseCode}</p>
                        <p className="text-xs text-gray-500">{course.courseName || ''}</p>
                      </div>
                      <div className={`px-2 py-1 rounded-md font-medium text-sm ${
                        course.finalMark >= 80 ? 'bg-green-100 text-green-800' :
                        course.finalMark >= 70 ? 'bg-blue-100 text-blue-800' :
                        course.finalMark >= 60 ? 'bg-yellow-100 text-yellow-800' :
                        'bg-gray-100 text-gray-800'
                      }`}>
                        {course.finalMark}%
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-6 bg-gray-50 rounded-lg">
                  <p className="text-gray-500">No courses listed</p>
                </div>
              )}
            </div>
            
            <div className="space-y-3">
              <h3 className="text-lg font-semibold flex items-center">
                <Calendar className="w-5 h-5 mr-2 text-green-500" />
                Availability
              </h3>
              {Object.keys(availabilityByDay).length > 0 ? (
                <div className="space-y-3">
                  {Object.entries(availabilityByDay).map(([day, slots]) => (
                    <div key={day} className="bg-gray-50 p-3 rounded-lg border border-gray-100">
                      <p className="font-medium text-gray-900 mb-2 flex items-center">
                        {renderDayBadge(day)}
                        <span className="ml-2">{day}</span>
                      </p>
                      <div className="grid grid-cols-2 gap-2">
                        {slots.map((slot, idx) => (
                          <div key={idx} className="bg-white px-3 py-2 rounded-md text-sm border border-gray-100">
                            {`${slot.startTime} - ${slot.endTime}`}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-6 bg-gray-50 rounded-lg">
                  <p className="text-gray-500">No availability set</p>
                </div>
              )}
            </div>

            {!isSelfView && (
              <div className="border-t border-gray-200 pt-4">
                <TutorBooking tutorId={selectedTutor.userId} onClose={handleCloseModal} />
              </div>
            )}
            
            {isSelfView && (
              <div className="border-t border-gray-200 pt-4 text-center">
                <p className="mb-2 text-gray-600">
                  You're viewing your own tutor profile.
                </p>
                <p className="text-sm text-gray-500">
                  You can update your availability in manage tutor in the homescreen.
                </p>
              </div>
            )}
          </div>

          {!isSelfView && (
            <div className="border-t border-gray-200 p-4 bg-gray-50">
              <button
                onClick={handleCloseModal}
                className="w-full bg-gray-200 text-gray-800 font-medium p-3 rounded-lg hover:bg-gray-300 transition-colors"
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="flex flex-col items-center space-y-4">
          <div className="w-12 h-12 border-4 border-green-200 border-t-green-500 rounded-full animate-spin" />
          <p className="text-base text-gray-500 animate-pulse">Loading tutors...</p>
        </div>
      </div>
    );
  }

  if (!currentUser) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center space-y-4 p-6 max-w-md">
          <GraduationCap className="w-16 h-16 text-green-500 mx-auto" />
          <h2 className="text-2xl font-bold text-gray-900">Welcome to Tutor Finder</h2>
          <p className="text-base text-gray-500">Please log in to view available tutors</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center space-y-4 p-6 max-w-md bg-white rounded-xl shadow-md">
          <X className="w-16 h-16 text-red-500 mx-auto" />
          <h2 className="text-xl font-bold text-gray-900">Something went wrong</h2>
          <p className="text-base text-gray-500">{error}</p>
          <button 
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  const filteredTutors = filterTutors();

  return (
    <div className="min-h-screen bg-gray-50">
      <AcademicHeader 
        title="Find Tutors" 
        onBack={handleGoBack}
        onFilterChange={handleFilterChange}
        userPreferences={{}}
      />
      
      <div className="pt-16 px-4 pb-20 max-w-3xl mx-auto">
        <div className="relative my-4">
          <input
            type="text"
            placeholder="Search tutors or courses..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-12 pr-4 py-3 rounded-full border-none shadow-md bg-white focus:ring-2 focus:ring-green-500 transition-all text-base"
          />
          <div className="absolute left-4 top-1/2 transform -translate-y-1/2">
            <Search className="w-5 h-5 text-gray-400" />
          </div>
        </div>
        
        {filteredTutors.length > 0 ? (
          <div className="grid grid-cols-1 gap-3 mt-4">
            {filteredTutors.map(tutor => (
              <TutorCard key={tutor.id} tutor={tutor} />
            ))}
          </div>
        ) : (
          <div className="text-center py-16">
            <div className="mx-auto w-20 h-20 rounded-full bg-green-50 flex items-center justify-center mb-4">
              <GraduationCap className="w-10 h-10 text-green-500" />
            </div>
            <h3 className="text-xl font-medium text-gray-900 mb-2">No tutors found</h3>
            <p className="text-base text-gray-500">
              {searchTerm || selectedUniversity
                ? 'Try adjusting your search or filters'
                : 'Check back later for new tutors'}
            </p>
          </div>
        )}
      </div>

      <TutorModal />
    </div>
  );
};

export default FindTutors;