import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Loader2, X, CheckCircle, AlertCircle } from 'lucide-react';
import { auth, db } from '../../firebase/firebase';
import { doc, updateDoc, query, collection, where, getDocs } from 'firebase/firestore';
import { updateProfile } from 'firebase/auth';
import { isProfileComplete, getIncompleteFields } from './profileValidation';

const ProfileSaveHandler = forwardRef(({ profile, isEditing, setIsEditing, onLoadingChange }, ref) => {
  const [loading, setLoading] = useState(false);
  const [showIncompleteDialog, setShowIncompleteDialog] = useState(false);
  const [error, setError] = useState(null);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [incompleteFields, setIncompleteFields] = useState([]);

  useEffect(() => {
    if (onLoadingChange) {
      onLoadingChange(loading);
    }
  }, [loading, onLoadingChange]);

  const checkUsernameAvailability = async (newUsername) => {
    const user = auth.currentUser;
    if (!user) throw new Error('No authenticated user found');

    // Normalize the new username to lowercase for comparison
    const normalizedNewUsername = newUsername.toLowerCase();

    // Get the current user's profile to check their current username
    const currentUserProfileRef = doc(db, 'profiles', user.uid);
    const currentUserProfileDoc = await getDocs(query(collection(db, 'profiles'), where('__name__', '==', user.uid)));
    const currentUsername = currentUserProfileDoc.docs[0]?.data()?.basicInfo?.username?.toLowerCase();

    // If the new username is the same as the current username (case-insensitive), it's available
    if (normalizedNewUsername === currentUsername) {
      return true;
    }

    // Query profiles collection for the new username (case-insensitive)
    const usernameQuery = query(
      collection(db, 'profiles'),
      where('basicInfo.username', '==', normalizedNewUsername)
    );

    const querySnapshot = await getDocs(usernameQuery);
    
    // Username is available if no documents are found
    return querySnapshot.empty;
  };

  const handleSaveProfile = async (forceSave = false) => {
    setLoading(true);
    setError(null);
    setSaveSuccess(false);

    try {
      const user = auth.currentUser;
      if (!user) throw new Error('No authenticated user found');

      // Check for incomplete fields but don't block saving
      const incomplete = getIncompleteFields(profile);
      if (incomplete.length > 0 && !forceSave) {
        setIncompleteFields(incomplete);
        setShowIncompleteDialog(true);
        setLoading(false);
        return;
      }

      // Normalize username to lowercase for availability check
      const normalizedUsername = profile.basicInfo.username.toLowerCase();
      
      // Check username availability before saving
      const isUsernameAvailable = await checkUsernameAvailability(normalizedUsername);
      if (!isUsernameAvailable) {
        throw new Error('Username is already taken. Please choose a different username.');
      }

      // Update display name if changed
      if (profile.basicInfo.name !== user.displayName) {
        await updateProfile(user, {
          displayName: profile.basicInfo.name
        });
      }

      // Save profile to Firestore with normalized username
      const profileRef = doc(db, 'profiles', user.uid);
      await updateDoc(profileRef, {
        ...profile,
        'basicInfo.username': normalizedUsername, // Always store username in lowercase
        lastUpdated: new Date(),
        isComplete: incomplete.length === 0
      });

      setSaveSuccess(true);
      setIsEditing(false);
      
      // Hide success message after 3 seconds
      setTimeout(() => {
        setSaveSuccess(false);
      }, 3000);

    } catch (err) {
      setError(err.message);
      console.error('Error saving profile:', err);
    } finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    handleSaveProfile
  }));

  return (
    <div className="fixed bottom-16 left-0 right-0 z-50">
      {/* Success Toast */}
      {saveSuccess && (
        <div className="mx-4 mb-4 bg-green-50 text-green-600 p-4 rounded-lg flex items-center justify-between shadow-lg animate-fade-in">
          <div className="flex items-center gap-2">
            <CheckCircle className="w-5 h-5" />
            <span>Profile saved successfully!</span>
          </div>
          <button onClick={() => setSaveSuccess(false)}>
            <X className="w-5 h-5" />
          </button>
        </div>
      )}

      {/* Error Toast */}
      {error && (
        <div className="mx-4 mb-4 bg-red-50 text-red-600 p-4 rounded-lg flex items-center justify-between shadow-lg animate-fade-in">
          <div className="flex items-center gap-2">
            <AlertCircle className="w-5 h-5" />
            <span>{error}</span>
          </div>
          <button onClick={() => setError(null)}>
            <X className="w-5 h-5" />
          </button>
        </div>
      )}

      {/* Incomplete Fields Dialog */}
      {showIncompleteDialog && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-xl p-6 w-full max-w-md">
            <div className="flex justify-between items-start mb-4">
              <div>
                <h3 className="text-lg font-semibold">Incomplete Profile</h3>
                <p className="text-gray-600 mt-1">The following sections are incomplete:</p>
              </div>
              <button onClick={() => setShowIncompleteDialog(false)}>
                <X className="w-5 h-5 text-gray-400" />
              </button>
            </div>
            
            <ul className="mb-6 space-y-2">
              {incompleteFields.map((field, index) => (
                <li key={index} className="flex items-center gap-2 text-gray-600">
                  <div className="w-2 h-2 bg-amber-400 rounded-full" />
                  {field}
                </li>
              ))}
            </ul>

            <div className="flex gap-3">
              <button
                onClick={() => {
                  setShowIncompleteDialog(false);
                  handleSaveProfile(true);
                }}
                className="flex-1 py-2 px-4 bg-gray-100 text-gray-600 rounded-lg hover:bg-gray-200 transition-colors"
              >
                Save Anyway
              </button>
              <button
                onClick={() => setShowIncompleteDialog(false)}
                className="flex-1 py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
              >
                Complete Profile
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

ProfileSaveHandler.displayName = 'ProfileSaveHandler';

export default ProfileSaveHandler;