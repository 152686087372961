import React from 'react';
import { X, UserCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const IncompleteProfileModal = ({ missingFields, onClose, open }) => {
  const navigate = useNavigate();

  if (!open) return null;

  const handleCompleteProfile = () => {
    onClose(); // Close the modal
    navigate('/profile'); // Navigate to the profile page
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-2xl max-w-md w-full p-6 relative">
        <button 
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
        >
          <X className="w-5 h-5" />
        </button>

        <div className="text-center space-y-2 mb-4">
          <h2 className="text-xl font-semibold text-gray-800">Complete Your Profile</h2>
          <p className="text-gray-600">Please complete your profile to interact with other users</p>
        </div>

        <div className="space-y-3 mb-6">
          <h3 className="text-sm font-medium text-gray-700">Missing information:</h3>
          <ul className="space-y-2">
            {missingFields.map((field, index) => (
              <li key={index} className="flex items-center text-gray-600">
                <span className="w-2 h-2 mr-2 bg-red-500 rounded-full" />
                {field}
              </li>
            ))}
          </ul>
        </div>

        <button
          onClick={handleCompleteProfile}
          className="w-full bg-green-500 hover:bg-green-600 text-white font-medium py-3 rounded-xl flex items-center justify-center space-x-2 transition-colors"
        >
          <UserCircle className="w-5 h-5" />
          <span>Complete Profile</span>
        </button>
      </div>
    </div>
  );
};

export default IncompleteProfileModal;