import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Handle service worker registration
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    // Unregister the main service worker if it exists
    navigator.serviceWorker.getRegistrations().then(registrations => {
      for (const registration of registrations) {
        if (registration.active && registration.active.scriptURL.includes('/service-worker.js')) {
          registration.unregister()
            .then(() => console.log('Service worker (/service-worker.js) unregistered'))
            .catch(error => console.error('Unregistration failed:', error));
        }
      }
    });

    // Ensure Firebase messaging service worker is registered (if initialized elsewhere)
    // This is typically handled by Firebase SDK in your app, not here directly
    console.log('Firebase messaging SW should be managed by Firebase SDK');
  });
}

reportWebVitals();