import React, { useState, useEffect, useCallback, Suspense } from 'react';
import { Users, UserPlus, User, ChevronRight, Bell, MessageCircle, Building, Briefcase } from 'lucide-react';
import { auth } from '../../firebase/firebase';
import { 
  query, 
  collection, 
  where, 
  onSnapshot, 
  doc as firestoreDoc,
  getDoc,
  doc,
  serverTimestamp,
  updateDoc, addDoc, getDocs, writeBatch
} from "firebase/firestore";
import { db } from '../../firebase/firebase';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import toast from 'react-hot-toast';
import AcademicNavBar from '../../components/AcademicNavBar';
import {
  getMeetupRequests,
  updateMeetupRequest,
  getUserChats,
  createChatRoom,
  subscribeToUnreadCounts
} from '../../firebase/messagingService';
import { markChatAsRead } from '../../firebase/messagingService';

// Reusing existing components
const Skeleton = ({ className }) => (
  <div className={`animate-pulse bg-gray-200 rounded ${className}`} />
);

const Badge = ({ children, variant = "default", className = "" }) => {
  const baseClasses = "px-2 py-0.5 rounded-full text-xs font-medium";
  const variants = {
    default: "bg-gray-100 text-gray-800",
    secondary: "bg-blue-100 text-blue-600",
    purple: "bg-purple-100 text-purple-600"
  };

  return (
    <span className={`${baseClasses} ${variants[variant]} ${className}`}>
      {children}
    </span>
  );
};

const ConnectionPreview = React.memo(({ connection, onConnectionClick }) => (
  <div
    onClick={() => onConnectionClick(connection.id)}
    className="mb-3 bg-white rounded-xl shadow-sm overflow-hidden cursor-pointer hover:bg-gray-50 transition-colors"
  >
    <div className="p-4 flex items-center justify-between">
      <div className="flex items-center space-x-4">
        <img
          src={connection.avatar}
          alt={connection.name}
          className="w-12 h-12 rounded-full object-cover"
          loading="lazy"
        />
        <div>
          <div className="flex items-center space-x-2">
            <h3 className="font-medium">{connection.name}</h3>
            {connection.unreadCount > 0 && (
              <div className="relative">
                <div className="absolute -top-1 -left-1 w-3 h-3 bg-blue-500 rounded-full animate-ping opacity-40"></div>
                <div className="relative w-2 h-2 bg-blue-500 rounded-full"></div>
              </div>
            )}
          </div>
          <p className="text-sm text-gray-600 line-clamp-1">{connection.title}</p>
          <p className="text-xs text-gray-400 mt-1">{connection.company}</p>
        </div>
      </div>
      <ChevronRight className="w-5 h-5 text-gray-400 flex-shrink-0" />
    </div>
  </div>
));

const RequestCard = React.memo(({ request, onAccept, onDecline }) => {
  const getRequestIcon = (type) => {
    const iconProps = { className: "w-6 h-6" };
    switch (type) {
      case 'work': return <Briefcase {...iconProps} className="text-blue-500" />;
      case 'academic': return <Building {...iconProps} className="text-green-500" />;
      default: return <User {...iconProps} className="text-gray-500" />;
    }
  };

  return (
    <div className="mb-3 bg-white rounded-xl shadow-sm overflow-hidden">
      <div className="p-4">
        <div className="flex items-center space-x-4">
          <div className="p-2 bg-gray-50 rounded-full flex-shrink-0">
            {getRequestIcon(request.type)}
          </div>
          <div className="flex-1 min-w-0">
            <div className="flex items-center space-x-2">
              <h3 className="font-medium truncate">{request.name}</h3>
              {request.isNew && (
                <Badge variant="purple" className="flex-shrink-0">
                  New
                </Badge>
              )}
            </div>
            <p className="text-sm text-gray-600 line-clamp-2">{request.message}</p>
            <p className="text-xs text-gray-400 mt-1">{request.timestamp}</p>
          </div>
        </div>

        <div className="mt-3 flex space-x-2">
          <button
            onClick={() => onAccept(request.id)}
            className="flex-1 py-2 px-4 bg-green-500 text-white rounded-lg font-medium hover:bg-green-600 transition-colors"
          >
            Accept
          </button>
          <button
            onClick={() => onDecline(request.id)}
            className="flex-1 py-2 px-4 bg-gray-200 text-gray-600 rounded-lg font-medium hover:bg-gray-300 transition-colors"
          >
            Decline
          </button>
        </div>
      </div>
    </div>
  );
});

const Network = () => {
  const [activeTab, setActiveTab] = useState('connections');
  const [connections, setConnections] = useState([]);
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chats, setChats] = useState([]);
  const [activities, setActivities] = useState([]); // Added this line
  const [unreadCounts, setUnreadCounts] = useState({
    connections: 0,
    requests: 0
  });
  const navigate = useNavigate();

  const handleChatClick = useCallback(
    debounce(async (chatId) => {
      const user = auth.currentUser;
      if (!user) return;
  
      try {
        // Mark the chat as read
        await markChatAsRead(chatId, user.uid);
        
        // Navigate to the chat
        navigate(`/chat/${chatId}`);
      } catch (error) {
        console.error('Error marking chat as read:', error);
        toast.error('Failed to open chat');
      }
    }, 300),
    [navigate]
  );

  const loadData = useCallback(async () => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) return;
  
      const [requests, userChats] = await Promise.all([
        getMeetupRequests(currentUser.uid),
        getUserChats(currentUser.uid)
      ]);
  
      const requestActivities = requests.map(req => ({
        id: req.id,
        type: 'Study',
        title: 'Study Request',
        content: `${req.senderName} wants to ${req.activity.toLowerCase()}`,
        timestamp: req.createdAt?.toDate().toLocaleString() || 'Just now',
        isNew: !req.seen,
        actionable: true,
        requestData: req
      }));
  
      const formattedChats = await Promise.all(
        userChats.map(async chat => {
          const otherUserId = chat.participants.find(id => id !== currentUser.uid);
          const otherUserDoc = await getDoc(doc(db, 'profiles', otherUserId));
          const otherUser = otherUserDoc.data();
      
          return {
            id: chat.id,
            name: otherUser?.basicInfo?.name || 'User',
            avatar: otherUser?.photos?.[0] || '/api/placeholder/80/80',
            lastMessage: chat.lastMessage || 'Start chatting!',
            timestamp: chat.lastMessageTimestamp?.toDate().toLocaleString() || 'Just now',
            unreadCount: chat.unreadCount?.[currentUser.uid] || 0 // Fix: Access the unread count for current user
          };
        })
      );
  
      formattedChats.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
  
      setActivities(requestActivities);
      setChats(formattedChats);
    } catch (error) {
      console.error('Error loading data:', error);
      toast.error('Failed to load messages and activities');
    } finally {
      setLoading(false);
    }
  }, []);

  // Subscribe to chat rooms and their unread counts
  useEffect(() => {
    const user = auth.currentUser;
    if (!user) return;

    // Query for chat rooms
    const chatQuery = query(
      collection(db, 'chatRooms'),
      where('participants', 'array-contains', user.uid)
    );

    const unsubscribe = onSnapshot(chatQuery, async (snapshot) => {
      const chatPromises = snapshot.docs.map(async (docSnapshot) => {
        const chatData = docSnapshot.data();
        const otherUserId = chatData.participants.find(id => id !== user.uid);
        // Fix: Use firestoreDoc instead of doc
        const otherUserDoc = await getDoc(firestoreDoc(db, 'profiles', otherUserId));
        const otherUser = otherUserDoc.data();

        return {
          id: docSnapshot.id,
          name: otherUser?.basicInfo?.name || 'User',
          avatar: otherUser?.photos?.[0] || '/api/placeholder/80/80',
          lastMessage: chatData.lastMessage || 'Start chatting!',
          timestamp: chatData.lastMessageTimestamp?.toDate().toLocaleString() || 'Just now',
          unreadCount: chatData.unreadCount?.[user.uid] || 0
        };
      });

      const formattedChats = await Promise.all(chatPromises);
      formattedChats.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      
      // Calculate total unread messages
      const totalUnread = formattedChats.reduce((sum, chat) => sum + (chat.unreadCount || 0), 0);
      setUnreadCounts(prev => ({
        ...prev,
        messages: totalUnread
      }));
      
      setChats(formattedChats);
    });

    return () => unsubscribe();
  }, []);

  const handleTabClick = useCallback(async (tab) => {
    setActiveTab(tab);
    if (tab === 'requests') {
      const user = auth.currentUser;
      if (user) {
        try {
          // Mark all requests as seen
          const requestsQuery = query(
            collection(db, 'studyRequests'),
            where('receiverId', '==', user.uid),
            where('status', '==', 'pending'),
            where('seen', '==', false)
          );
          
          const snapshot = await getDocs(requestsQuery);
          const batch = writeBatch(db);
          
          snapshot.docs.forEach(doc => {
            batch.update(doc.ref, { seen: true });
          });
          
          await batch.commit();
          
          // Update local unread counts
          setUnreadCounts(prev => ({
            ...prev,
            requests: 0
          }));
        } catch (error) {
          console.error('Error marking requests as seen:', error);
          // Don't show error toast to user as it's not critical
        }
      }
    }
  }, []);

  // Subscribe to connections
  useEffect(() => {
    const user = auth.currentUser;
    if (!user) return;

    const connectionsQuery = query(
      collection(db, 'connections'),
      where('participants', 'array-contains', user.uid)
    );

    const unsubscribe = onSnapshot(connectionsQuery, async (snapshot) => {
      const connectionPromises = snapshot.docs.map(async (docSnapshot) => {
        const connectionData = docSnapshot.data();
        const otherUserId = connectionData.participants.find(id => id !== user.uid);
        const otherUserDoc = await getDoc(doc(db, 'profiles', otherUserId));
        const otherUser = otherUserDoc.data();

        return {
          id: docSnapshot.id,
          name: otherUser?.basicInfo?.name || 'User',
          avatar: otherUser?.photos?.[0] || '/api/placeholder/80/80',
          title: otherUser?.professionalInfo?.title || 'Professional',
          company: otherUser?.professionalInfo?.company || 'Company',
          unreadCount: connectionData.unreadCount?.[user.uid] || 0,
          timestamp: connectionData.lastUpdateTimestamp?.toDate().toLocaleString() || 'Just now'
        };
      });

      const formattedConnections = await Promise.all(connectionPromises);
      formattedConnections.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      
      const totalUnread = formattedConnections.reduce((sum, conn) => sum + (conn.unreadCount || 0), 0);
      setUnreadCounts(prev => ({
        ...prev,
        connections: totalUnread
      }));
      
      setConnections(formattedConnections);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Subscribe to connection requests
  useEffect(() => {
    const user = auth.currentUser;
    if (!user) return;

    const requestsQuery = query(
      collection(db, 'studyRequests'),
      where('receiverId', '==', user.uid),
      where('status', '==', 'pending')
    );

    const unsubscribe = onSnapshot(requestsQuery, (snapshot) => {
      const unreadRequests = snapshot.docs.filter(doc => !doc.data().seen).length;
      setUnreadCounts(prev => ({
        ...prev,
        requests: unreadRequests
      }));

      const formattedRequests = snapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().senderName,
        message: doc.data().message,
        type: doc.data().type,
        timestamp: doc.data().createdAt?.toDate().toLocaleString() || 'Just now',
        isNew: !doc.data().seen
      }));

      setRequests(formattedRequests);
    });

    return () => unsubscribe();
  }, []);

  const handleConnectionClick = useCallback(
    debounce(async (connectionId) => {
      const user = auth.currentUser;
      if (!user) return;
  
      try {
        // Get the connection document to find the other user's ID
        const connectionDoc = await getDoc(doc(db, 'connections', connectionId));
        if (!connectionDoc.exists()) {
          throw new Error('Connection not found');
        }
  
        const connectionData = connectionDoc.data();
        const otherUserId = connectionData.participants.find(id => id !== user.uid);
        
        if (!otherUserId) {
          throw new Error('Other user not found in connection');
        }
  
        // Mark connection as read
        await updateDoc(doc(db, 'connections', connectionId), {
          [`unreadCount.${user.uid}`]: 0,
          lastViewedTimestamp: serverTimestamp()
        });
  
        // Find existing chatroom between these users
        const chatRoomsQuery = query(
          collection(db, 'chatRooms'),
          where('participants', 'array-contains', user.uid)
        );
  
        const chatRoomsSnapshot = await getDocs(chatRoomsQuery);
        let chatroomId = null;
  
        // Check each chatroom for the other participant
        chatRoomsSnapshot.forEach((doc) => {
          const chatData = doc.data();
          if (chatData.participants.includes(otherUserId)) {
            chatroomId = doc.id;
          }
        });
  
        // If no chatroom exists, create one
        if (!chatroomId) {
          const newChatRoomRef = await addDoc(collection(db, 'chatRooms'), {
            participants: [user.uid, otherUserId],
            createdAt: serverTimestamp(),
            lastMessage: 'Chat started',
            lastMessageTimestamp: serverTimestamp(),
            unreadCount: {
              [user.uid]: 0,
              [otherUserId]: 0
            }
          });
          chatroomId = newChatRoomRef.id;
        }
  
        // Navigate to the chatroom
        navigate(`/chat/${chatroomId}`);
      } catch (error) {
        console.error('Error handling connection click:', error);
        toast.error('Failed to open chatroom: ' + error.message);
        setLoading(false); // Make sure to stop loading on error
      }
    }, 300),
    [navigate]
  );


  const handleRequestResponse = useCallback(async (requestId, accept) => {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error('User not authenticated');

      // Get the request document
      const requestDoc = await getDoc(doc(db, 'studyRequests', requestId));
      if (!requestDoc.exists()) throw new Error('Request not found');

      const requestData = requestDoc.data();
      const batch = writeBatch(db);

      // Update request status
      batch.update(doc(db, 'studyRequests', requestId), {
        status: accept ? 'accepted' : 'declined',
        responseTimestamp: serverTimestamp()
      });

      if (accept) {
        // Create a connection document
        const connectionRef = doc(collection(db, 'connections'));
        batch.set(connectionRef, {
          participants: [user.uid, requestData.senderId],
          createdAt: serverTimestamp(),
          lastUpdateTimestamp: serverTimestamp(),
          unreadCount: {
            [user.uid]: 0,
            [requestData.senderId]: 1
          }
        });

        // Create a chat room
        const chatRoomRef = doc(collection(db, 'chatRooms'));
        batch.set(chatRoomRef, {
          participants: [user.uid, requestData.senderId],
          createdAt: serverTimestamp(),
          lastMessageTimestamp: serverTimestamp(),
          lastMessage: 'Chat started',
          unreadCount: {
            [user.uid]: 0,
            [requestData.senderId]: 1
          }
        });
      }

      // Commit all changes
      await batch.commit();

      // Update local state
      setRequests(prev => prev.filter(req => req.id !== requestId));
      
      // Show success message
      toast.success(accept ? 'Request accepted!' : 'Request declined');
      
      if (accept) {
        // Reload connections
        setActiveTab('connections');
      }
    } catch (error) {
      console.error('Error handling request:', error);
      throw error; // Propagate error to RequestCard component
    }
  }, [setRequests, setActiveTab]);
 
  const EmptyState = ({ icon: Icon, title, description }) => (
    <div className="text-center py-8">
      <Icon className="w-12 h-12 mx-auto text-gray-400 mb-3" />
      <p className="text-gray-500 font-medium">{title}</p>
      <p className="text-sm text-gray-400 mt-1">{description}</p>
    </div>
  );

  const renderTabContent = () => {
    if (loading) {
      return Array(3).fill(null).map((_, index) => (
        <div key={index} className="mb-3 bg-white rounded-xl shadow-sm overflow-hidden p-4">
          <div className="flex items-center space-x-4">
            <Skeleton className="w-12 h-12 rounded-full" />
            <div className="space-y-2 flex-1">
              <Skeleton className="h-4 w-1/4" />
              <Skeleton className="h-3 w-3/4" />
              <Skeleton className="h-2 w-1/6" />
            </div>
          </div>
        </div>
      ));
    }

    if (activeTab === 'connections') {
      return connections.length > 0 ? (
        connections.map((connection) => (
          <ConnectionPreview
            key={connection.id}
            connection={connection}
            onConnectionClick={handleConnectionClick}
          />
        ))
      ) : (
        <EmptyState
          icon={Users}
          title="No connections yet"
          description="Start connecting with professionals in your field!"
        />
      );
    }

    return requests.length > 0 ? (
      requests.map((request) => (
        <RequestCard
          key={request.id}
          request={request}
          onAccept={(id) => handleRequestResponse(id, true)}
          onDecline={(id) => handleRequestResponse(id, false)}
        />
      ))
    ) : (
      <EmptyState
        icon={UserPlus}
        title="No pending requests"
        description="Check back for new connection requests"
      />
    );
  };

  return (
    <div className="min-h-screen bg-gray-50 pb-20">
      <header className="fixed top-0 w-full bg-white shadow-sm z-50 px-4 py-3">
        <h1 className="text-xl font-semibold bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent">
          Network
        </h1>
      </header>

      <div className="fixed top-14 w-full bg-white border-b z-40">
        <div className="flex">
          <TabButton
            active={activeTab === 'connections'}
            onClick={() => handleTabClick('connections')}
            icon={Users}
            label="Connections"
            count={unreadCounts.connections}
            color="blue"
          />
          <TabButton
            active={activeTab === 'requests'}
            onClick={() => handleTabClick('requests')}
            icon={UserPlus}
            label="Requests"
            count={unreadCounts.requests}
            color="purple"
          />
        </div>
      </div>

      <main className="mt-32 px-4">
        <Suspense fallback={
          <div className="space-y-4">
            {Array(3).fill(null).map((_, i) => (
              <div key={i} className="mb-3 bg-white rounded-xl shadow-sm overflow-hidden p-4">
                <div className="flex items-center space-x-4">
                  <Skeleton className="w-12 h-12 rounded-full" />
                  <div className="space-y-2 flex-1">
                    <Skeleton className="h-4 w-1/4" />
                    <Skeleton className="h-3 w-3/4" />
                    <Skeleton className="h-2 w-1/6" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        }>
          {renderTabContent()}
        </Suspense>
      </main>

      <AcademicNavBar />
    </div>
  );
};

// Tab Button Component
const TabButton = ({ active, onClick, icon: Icon, label, count, color }) => (
  <button
    onClick={onClick}
    className={`flex-1 py-3 px-4 relative ${active ? `text-${color}-600 font-semibold` : 'text-gray-500'}`}
  >
    <div className="flex items-center justify-center space-x-2">
      <div className="relative">
        <Icon className="w-5 h-5" />
        {count > 0 && (
          <Badge
            variant={color === 'purple' ? 'purple' : 'secondary'}
            className="absolute -top-2 -right-2 min-w-[1.25rem] h-5 flex items-center justify-center"
          >
            {count}
          </Badge>
        )}
      </div>
      <span>{label}</span>
    </div>
    {active && (
      <div className={`absolute bottom-0 left-0 w-full h-0.5 bg-${color}-500`} />
    )}
  </button>
);

export default Network;