
// src/components/meetups/UserCardSkeleton.js
import React from 'react';

const Skeleton = ({ className }) => (
  <div className={`animate-pulse bg-gray-200 rounded ${className}`} />
);

const UserCardSkeleton = () => (
  <div className="bg-white rounded-xl shadow-sm overflow-hidden">
    <div className="flex p-4">
      <Skeleton className="w-20 h-20 rounded-xl" />
      <div className="ml-4 flex-1">
        <div className="flex justify-between items-start">
          <div className="space-y-2">
            <Skeleton className="h-6 w-32" />
            <Skeleton className="h-4 w-24" />
            <Skeleton className="h-4 w-40" />
          </div>
          <Skeleton className="w-10 h-10 rounded-full" />
        </div>
        
        <div className="flex items-center gap-4 mt-4">
          <Skeleton className="h-4 w-24" />
          <Skeleton className="h-4 w-24" />
        </div>
      </div>
    </div>
  </div>
);

export default UserCardSkeleton;