// src/pages/auth/ForgotPassword.jsx
import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { Mail, ArrowLeft, Loader2 } from 'lucide-react';
import { auth } from '../../firebase/firebase';

const ForgotPassword = ({ onBack }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    if (!email) {
      return 'University email is required';
    }
    if (!email.toLowerCase().endsWith('.ac.za')) {
      return 'Please use your university email address';
    }
    return '';
  };

  const getErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 'auth/user-not-found':
        return 'No account found with this email address';
      case 'auth/invalid-email':
        return 'Invalid email address';
      case 'auth/too-many-requests':
        return 'Too many requests. Please try again later';
      default:
        return 'Password reset failed. Please try again later';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailError = validateEmail(email);
    if (emailError) {
      setError(emailError);
      return;
    }

    setLoading(true);
    setError('');
    
    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess(true);
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setError(getErrorMessage(error.code));
    } finally {
      setLoading(false);
    }
  };

  if (success) {
    return (
      <div className="bg-white rounded-3xl shadow-lg p-6 space-y-4">
        <div className="text-center space-y-2">
          <h2 className="text-2xl font-semibold text-gray-800">Check Your Email</h2>
          <p className="text-gray-600">
            We've sent password reset instructions to {email}
          </p>
        </div>
        <button
          onClick={onBack}
          className="w-full bg-gray-50 hover:bg-gray-100 text-gray-700 font-medium py-3 rounded-xl flex items-center justify-center space-x-2"
        >
          <ArrowLeft className="w-5 h-5" />
          <span>Back to Login</span>
        </button>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-3xl shadow-lg p-6 space-y-6">
      <div className="text-center space-y-2">
        <h2 className="text-2xl font-semibold text-gray-800">Reset Password</h2>
        <p className="text-gray-600">
          Enter your university email address and we'll send you instructions to reset your password.
        </p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="space-y-2">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Mail className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="email"
              placeholder="University Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`pl-10 pr-16 py-3 w-full bg-gray-50 border ${
                error ? 'border-red-500' : 'border-gray-200'
              } rounded-xl focus:ring-2 focus:ring-green-500 focus:border-transparent`}
            />
            <span className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-gray-400">
              .ac.za
            </span>
          </div>
          {error && <p className="text-red-500 text-sm">{error}</p>}
        </div>

        <div className="space-y-4">
          <button
            type="submit"
            disabled={loading}
            className="w-full bg-green-500 hover:bg-green-600 text-white font-medium py-3 rounded-xl flex items-center justify-center space-x-2"
          >
            {loading ? (
              <>
                <Loader2 className="w-5 h-5 animate-spin" />
                <span>Sending Instructions...</span>
              </>
            ) : (
              <span>Send Instructions</span>
            )}
          </button>

          <button
            type="button"
            onClick={onBack}
            className="w-full bg-gray-50 hover:bg-gray-100 text-gray-700 font-medium py-3 rounded-xl flex items-center justify-center space-x-2"
          >
            <ArrowLeft className="w-5 h-5" />
            <span>Back to Login</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;