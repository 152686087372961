import React, { useState, useEffect } from 'react';
import { Heart } from 'lucide-react';

const MatchOverlay = ({ profile, onClose }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
    const timer = setTimeout(() => {
      setShow(false);
      setTimeout(onClose, 500); // Allow exit animation to complete
    }, 3000);
    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className={`fixed inset-0 flex items-center justify-center z-50 
                    transition-opacity duration-500 ${show ? 'opacity-100' : 'opacity-0'}`}>
      <div className="absolute inset-0 bg-gradient-to-br from-pink-500/90 to-purple-600/90 backdrop-blur-sm" />
      <div className="relative text-center space-y-6 p-6">
        <div className="flex justify-center space-x-2">
          <Heart className="w-16 h-16 text-white animate-bounce" fill="white" />
          <Heart className="w-16 h-16 text-white animate-bounce delay-100" fill="white" />
          <Heart className="w-16 h-16 text-white animate-bounce delay-200" fill="white" />
        </div>
        <div className="space-y-2">
          <h2 className="text-4xl font-bold text-white">It's a Match!</h2>
          <p className="text-xl text-white/90">
            You and {profile.basicInfo.name} liked each other
          </p>
        </div>
        <div className="pt-4">
          <button 
            onClick={onClose}
            className="px-8 py-3 bg-white text-purple-600 rounded-full font-semibold
                     shadow-lg hover:bg-gray-100 transition-colors"
          >
            Start Chatting
          </button>
        </div>
      </div>
    </div>
  );
};

export default MatchOverlay;