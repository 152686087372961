import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { deleteDoc } from "firebase/firestore";
import { getDoc, doc } from 'firebase/firestore';
import { collection, addDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyDTPRM8G4tT72HFdQwblHgBX8A5Q9kmnRk",
  authDomain: "wingmancoza.firebaseapp.com",
  projectId: "wingmancoza",
  storageBucket: "wingmancoza.firebasestorage.app",
  messagingSenderId: "778264646788",
  appId: "1:778264646788:web:7584248a55e1487e879ed1",
  measurementId: "G-WJR04RMFRF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase services
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
const messaging = getMessaging(app);

export const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
      console.log('Service Worker registered:', registration);
      return registration;
    } catch (error) {
      console.error('Service Worker registration failed:', error);
    }
  } else {
    console.warn('Service workers are not supported in this browser.');
  }
};


export const initializePushNotifications = async () => {
  try {
    if (!('Notification' in window)) {
      console.log('This browser does not support notifications');
      return;
    }

    const permission = await Notification.requestPermission();
    console.log('Notification permission:', permission);

    if (permission === 'granted') {
      const registration = await registerServiceWorker(); // Ensure SW is registered first
      
      try {
        const currentToken = await getToken(messaging, {
          vapidKey: 'BMCcm6qEbD-rmMyyhOUln2S_ALIl6Ro9T6jg4HR05u4CauVNCz3vFYVgB-oKxIgUyxgMzSmMTLiyXKT593UXqXc',
          serviceWorkerRegistration: registration, // Use the registered SW
        });

        if (currentToken) {
          console.log('FCM Token:', currentToken);
          
          const user = auth.currentUser;
          if (user) {
            await updateDoc(doc(db, 'profiles', user.uid), {
              fcmToken: currentToken,
              tokenUpdatedAt: serverTimestamp(),
            });
          }
        } else {
          console.log('No registration token available');
        }
      } catch (err) {
        console.error('Error getting token:', err);
      }
    }
  } catch (error) {
    console.error('Error initializing push notifications:', error);
  }
};
// Handle foreground messages
onMessage(messaging, (payload) => {
  console.log('Received foreground message:', payload);
  // You can show a notification even when the app is in the foreground
  new Notification(payload.notification.title, {
    body: payload.notification.body,
    icon: '/logo192.png'
  });
});

export { ref, uploadBytes, getDownloadURL };