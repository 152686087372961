import React, { useState } from 'react';
import { Mail } from 'lucide-react';

const EmailInput = ({ formData, handleChange, errors, universities }) => {
  const [isTyping, setIsTyping] = useState(false);
  
  const selectedUniversity = universities.find(uni => uni.name === formData.university);
  const domain = selectedUniversity?.domain || '';

  const handleInputFocus = () => {
    setIsTyping(true);
  };

  const handleInputBlur = () => {
    setIsTyping(false);
  };

  return (
    <div className="space-y-2">
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Mail className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="email"
          name="email"
          placeholder="University Email"
          value={formData.email}
          onChange={handleChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          className={`pl-10 pr-4 py-3 w-full bg-gray-50 border ${
            errors.email ? 'border-red-500' : 'border-gray-200'
          } rounded-xl focus:ring-2 focus:ring-green-500 focus:border-transparent`}
        />
        {formData.university && !isTyping && (
          <span className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-gray-400">
            {domain}
          </span>
        )}
      </div>
      {errors.email && (
        <p className="text-red-500 text-sm">{errors.email}</p>
      )}
      <p className="text-gray-500 text-xs">Use your university email address</p>
    </div>
  );
};

export default EmailInput;