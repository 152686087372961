import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  BookOpen, 
  Users, 
  Calendar, 
  MessageCircle, 
  UserCircle, 
  Briefcase, 
  Home 
} from 'lucide-react';
import { auth } from '../firebase/firebase';
import { subscribeToUnreadCounts } from '../firebase/messagingService';

const AcademicNavBar = () => {
  const location = useLocation();
  const [unreadCounts, setUnreadCounts] = useState({
    studyGroups: 0,
    messages: 0,
    network: 0  // This will be populated from the existing service
  });

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) return;
    
    const unsubscribe = subscribeToUnreadCounts(user.uid, (counts) => {
      setUnreadCounts({
        studyGroups: counts.studyGroups,
        messages: counts.messages,
        network: counts.network || 0  // Using the network count from the existing service
      });
    });
    
    return () => unsubscribe();
  }, []);

  const isActive = (path) => {
    return location.pathname === path;
  };

  const NotificationBadge = ({ count }) => {
    if (!count) return null;
    return (
      <div className="absolute -top-2 -right-1 flex items-center justify-center">
        <div className="animate-pulse bg-red-500 rounded-full w-5 h-5 flex items-center justify-center">
          <span className="text-white text-xs font-bold">
            {count > 9 ? '9+' : count}
          </span>
        </div>
      </div>
    );
  };

  return (
    <nav className="fixed bottom-0 w-full bg-white border-t border-gray-200 z-50">
      <div className="max-w-2xl mx-auto px-4 py-2 flex justify-around items-center">
        <Link to="/" className="relative">
          <NavButton 
            icon={<Home className="w-6 h-6" />} 
            label="Home" 
            active={isActive('/')} 
          />
        </Link>
        <Link to="/academic-resources" className="relative">
          <NavButton 
            icon={<Briefcase className="w-6 h-6" />} 
            label="Resources" 
            active={isActive('/academic-resources')} 
          />
        </Link>
        <Link to="/academic-events" className="relative">
          <NavButton 
            icon={<Calendar className="w-6 h-6" />} 
            label="Events" 
            active={isActive('/academic-events')} 
          />
        </Link>
       
        <Link to="/profile" className="relative">
          <NavButton 
            icon={<UserCircle className="w-6 h-6" />} 
            label="Profile" 
            active={isActive('/profile')} 
          />
        </Link>
      </div>
    </nav>
  );
};

const NavButton = ({ icon, label, active, disabled }) => (
  <button 
    className={`flex flex-col items-center space-y-1 px-2 ${disabled ? 'cursor-not-allowed' : ''}`}
    disabled={disabled}
  >
    <div className={
      disabled ? "text-gray-300" : 
      active ? "text-green-500" : 
      "text-gray-600"
    }>
      {icon}
    </div>
    <span className={`text-xs ${
      disabled ? "text-gray-300" : 
      active ? "text-green-500" : 
      "text-gray-600"
    }`}>
      {label}
    </span>
  </button>
);

export default AcademicNavBar;