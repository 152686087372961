import React from 'react';
import { Building } from 'lucide-react';

// South African Universities list - matching registration
const universities = [
  'Cape Peninsula University of Technology',
  'Central University of Technology',
  'Durban University of Technology',
  'Mangosuthu University of Technology',
  'Nelson Mandela University',
  'North-West University',
  'Rhodes University',
  'Sefako Makgatho Health Sciences University',
  'Sol Plaatje University',
  'Stellenbosch University',
  'Tshwane University of Technology',
  'University of Cape Town',
  'University of Fort Hare',
  'University of Johannesburg',
  'University of KwaZulu-Natal',
  'University of Limpopo',
  'University of Mpumalanga',
  'University of Pretoria',
  'University of South Africa',
  'University of the Free State',
  'University of the Western Cape',
  'University of the Witwatersrand',
  'Vaal University of Technology',
  'Walter Sisulu University'
].sort();

export const FilterModal = ({
  showFilters,
  selectedUniversity,
  setSelectedUniversity,
  userPreferences,
  setShowFilters,
  onApplyFilters
}) => {
  if (!showFilters) return null;

  const handleApplyFilters = () => {
    setShowFilters(false);
    if (onApplyFilters) {
      onApplyFilters(selectedUniversity);
    }
  };

  const handleResetFilters = () => {
    setSelectedUniversity('');
    setShowFilters(false);
    if (onApplyFilters) {
      onApplyFilters('');
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-2xl p-6 w-full max-w-md space-y-6">
        <div className="flex items-center justify-between">
          <h3 className="text-xl font-semibold text-gray-800">Filter Posts</h3>
          <button 
            onClick={() => setShowFilters(false)}
            className="text-gray-400 hover:text-gray-600"
          >
            ✕
          </button>
        </div>

        <div className="space-y-4">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              University
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Building className="h-5 w-5 text-gray-400" />
              </div>
              <select
                value={selectedUniversity}
                onChange={(e) => setSelectedUniversity(e.target.value)}
                className="pl-10 pr-4 py-3 w-full bg-gray-50 border border-gray-200 rounded-xl focus:ring-2 focus:ring-green-500 focus:border-transparent"
              >
                <option value="">All Universities</option>
                {userPreferences?.academic?.university && (
                  <option value={userPreferences.academic.university}>
                    {userPreferences.academic.university} (Your University)
                  </option>
                )}
                {universities
                  .filter(uni => userPreferences?.academic?.university !== uni)
                  .map(uni => (
                    <option key={uni} value={uni}>{uni}</option>
                  ))
                }
              </select>
            </div>
            <p className="text-sm text-gray-500">
              Show posts only from the selected university
            </p>
          </div>
        </div>

        <div className="flex gap-3">
          <button 
            className="flex-1 p-3 rounded-xl border border-gray-200 text-gray-600 font-medium hover:bg-gray-50"
            onClick={handleResetFilters}
          >
            Reset
          </button>
          <button 
            className="flex-1 p-3 rounded-xl bg-green-500 text-white font-medium hover:bg-green-600 transition-colors"
            onClick={handleApplyFilters}
          >
            Apply Filters
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;