// src/components/Home/GroupSelectModal.js
import React from 'react';

export const GroupSelectModal = ({
  showGroupSelect,
  userGroups,
  handleGroupSelect,
  setShowGroupSelect
}) => {
  if (!showGroupSelect) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-2xl p-4 w-full max-w-sm">
        <h3 className="text-lg font-semibold mb-4">Recommend to Group</h3>
        {userGroups.length > 0 ? (
          <div className="space-y-2">
            {userGroups.map((group) => (
              <button 
                key={group.id}
                className="w-full p-3 rounded-lg bg-gray-50 hover:bg-gray-100 text-left text-sm font-medium flex items-center justify-between"
                onClick={() => handleGroupSelect(group.id)}
              >
                <span>{group.name}</span>
                <span className="text-xs text-gray-500">{group.memberCount} members</span>
              </button>
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-500">You have no groups to recommend to</p>
        )}
        <button 
          className="mt-4 w-full p-3 rounded-lg bg-gray-100 text-gray-600 font-medium"
          onClick={() => setShowGroupSelect(false)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};