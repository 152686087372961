import React from 'react';
import { PERSONALITY_TRAITS, RELATIONSHIP_GOALS } from '../../constants/profileConstants';

const AboutMe = ({ 
  profile, 
  isEditing, 
  setProfile 
}) => {
  return (
    <section className="bg-white rounded-2xl p-6 shadow-sm">
      <h2 className="text-xl font-semibold mb-6">About Me</h2>
      <div className="space-y-6">
        {/* Short Bio */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Short Bio</label>
          {isEditing ? (
            <input
              type="text"
              value={profile.bio.shortBio}
              onChange={(e) => setProfile(prev => ({
                ...prev,
                bio: { ...prev.bio, shortBio: e.target.value }
              }))}
              className="w-full p-2 border rounded-lg"
              maxLength={50}
              placeholder="Brief introduction (50 characters max)"
            />
          ) : (
            <p className="text-gray-900">{profile.bio.shortBio}</p>
          )}
        </div>

        {/* Long Bio */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">About Me</label>
          {isEditing ? (
            <textarea
              value={profile.bio.longBio}
              onChange={(e) => setProfile(prev => ({
                ...prev,
                bio: { ...prev.bio, longBio: e.target.value }
              }))}
              className="w-full p-2 border rounded-lg h-32 resize-none"
              placeholder="Tell us more about yourself..."
            />
          ) : (
            <p className="text-gray-900 whitespace-pre-wrap">{profile.bio.longBio}</p>
          )}
        </div>

        {/* Personality Traits */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Personality Traits</label>
          <div className="flex flex-wrap gap-2">
            {PERSONALITY_TRAITS.map((trait) => (
              <button
                key={trait}
                onClick={() => {
                  if (isEditing) {
                    setProfile(prev => ({
                      ...prev,
                      bio: {
                        ...prev.bio,
                        personalities: prev.bio.personalities.includes(trait)
                          ? prev.bio.personalities.filter(t => t !== trait)
                          : [...prev.bio.personalities, trait]
                      }
                    }));
                  }
                }}
                className={`px-4 py-2 rounded-full text-sm ${
                  profile.bio.personalities.includes(trait)
                    ? 'bg-green-500 text-white'
                    : 'bg-gray-100 text-gray-600'
                } ${!isEditing && 'pointer-events-none'}`}
              >
                {trait}
              </button>
            ))}
          </div>
        </div>

        {/* Relationship Goals */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Looking For</label>
          <div className="flex flex-wrap gap-2">
            {RELATIONSHIP_GOALS.map((goal) => (
              <button
                key={goal}
                onClick={() => {
                  if (isEditing) {
                    setProfile(prev => ({
                      ...prev,
                      bio: { ...prev.bio, relationshipGoal: goal }
                    }));
                  }
                }}
                className={`px-4 py-2 rounded-full text-sm ${
                  profile.bio.relationshipGoal === goal
                    ? 'bg-green-500 text-white'
                    : 'bg-gray-100 text-gray-600'
                } ${!isEditing && 'pointer-events-none'}`}
              >
                {goal}
              </button>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;