import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase/firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import {
  recommendProfileToGroup,
  fetchUserGroups,
} from "../firebase/groupService";
import { addViewedProfile, getViewedProfiles } from "../firebase/socialService";
import { ProfileCard } from "../components/homepage/ProfileCard";
import { HomeHeader } from "../components/homepage/HomeHeader";
import { FilterModal } from "../components/homepage/FilterModal";
import { GroupSelectModal } from "../components/homepage/GroupSelectModal";
import IncompleteProfileModal from "../components/homepage/IncompleteProfileModal";
import ProfileCardSkeleton from "../components/homepage/ProfileCardSkeleton";
import NoProfilesState from "../components/homepage/NoProfilesState";
import NavBar from "../components/NavBar";
import { matchingService } from "../firebase/matchingService";
import toast from "react-hot-toast";
import MatchOverlay from "../components/homepage/MatchOverlay";
import {
  createChatRoom,
  createMatchChatRoom,
} from "../firebase/messagingService";
import { serverTimestamp } from "firebase/firestore";
const Home = () => {
  const navigate = useNavigate();
  const [showGroupSelect, setShowGroupSelect] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showIncompleteProfile, setShowIncompleteProfile] = useState(false);
  const [currentActivity, setCurrentActivity] = useState("dating");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userProfiles, setUserProfiles] = useState([]);
  const [currentProfileIndex, setCurrentProfileIndex] = useState(0);
  const [userPreferences, setUserPreferences] = useState(null);
  const [selectedUniversity, setSelectedUniversity] = useState("all");
  const [profileComplete, setProfileComplete] = useState(true);
  const [missingFields, setMissingFields] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [cardAction, setCardAction] = useState(null);
  const [viewedProfiles, setViewedProfiles] = useState(new Set());
  const [hasMoreProfiles, setHasMoreProfiles] = useState(true);

  const checkProfileCompleteness = (profile) => {
    const requiredFields = [];

    if (!profile.basicInfo?.gender) {
      requiredFields.push("Gender");
    }
    if (
      !profile.basicInfo?.interestedIn ||
      profile.basicInfo.interestedIn.length === 0
    ) {
      requiredFields.push("Interested In");
    }
    if (!profile.academic?.degree) {
      requiredFields.push("Degree Program");
    }
    if (!profile.academic?.yearOfStudy) {
      requiredFields.push("Year of Study");
    }
    if (!profile.bio?.shortBio) {
      requiredFields.push("Short Bio");
    }
    if (!profile.photos || profile.photos.length === 0) {
      requiredFields.push("Profile Photo");
    }

    return requiredFields;
  };

  const isProfileComplete = (profile) => {
    return checkProfileCompleteness(profile).length === 0;
  };

  const updateProfilesList = (profiles, currentIndex) => {
    setUserProfiles(profiles);
    if (profiles.length === 0) {
      setHasMoreProfiles(false);
    } else {
      setCurrentProfileIndex(currentIndex);
      setHasMoreProfiles(true);
    }
  };

  const [likedProfiles, setLikedProfiles] = useState(new Set());

  const [showMatchOverlay, setShowMatchOverlay] = useState(false);
  const [matchedProfile, setMatchedProfile] = useState(null);

  const handleLike = async (profileId) => {
    if (!profileComplete) {
      setShowIncompleteProfile(true);
      return;
    }

    try {
      const result = await matchingService.sendLike(
        auth.currentUser.uid,
        profileId
      );

      if (result.success) {
        setLikedProfiles((prev) => new Set([...prev, profileId]));

        if (result.isMatch) {
          // Create chat room with proper structure
          const chatRoomId = await createMatchChatRoom(
            auth.currentUser.uid,
            profileId
          );

          setMatchedProfile({
            ...userProfiles[currentProfileIndex],
            chatId: chatRoomId,
          });
          setShowMatchOverlay(true);
        }

        await markProfileAsViewed(profileId);
        setCardAction("right");

        setTimeout(() => {
          setCardAction(null);
        }, 300);
      }
    } catch (error) {
      console.error("Error liking profile:", error);
      toast.error("Failed to like profile. Please try again.");
    }
  };


  const filterProfilesByGenderPreference = (profiles, userPreferences) => {
    // Early return if user preferences aren't set
    if (!userPreferences?.basicInfo?.interestedIn || !userPreferences.basicInfo.gender) {
      console.log("Missing user preferences:", {
        gender: userPreferences?.basicInfo?.gender,
        interestedIn: userPreferences?.basicInfo?.interestedIn,
      });
      return [];
    }
  
    // Filter profiles based on gender preferences and dating visibility
    return profiles.filter((profile) => {
      // Skip profiles without basic info or those who opted out of dating
      if (!profile.basicInfo?.gender || 
          !profile.basicInfo?.interestedIn || 
          !profile.preferences?.showInDating) {
        return false;
      }
  
      // Check if the profile's gender matches what the user is interested in
      const userLikesProfileGender = userPreferences.basicInfo.interestedIn.includes(
        profile.basicInfo.gender
      );
  
      // Only return profiles where there's a match and they've enabled dating visibility
      return userLikesProfileGender;
    });
  };

  useEffect(() => {
    const loadUserGroups = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const groups = await fetchUserGroups(user.uid);
          setUserGroups(groups);
        }
      } catch (error) {
        console.error("Error loading user groups:", error);
      }
    };
    loadUserGroups();

    const fetchUserPreferencesAndProfiles = async () => {
      setLoading(true);
      try {
        const user = auth.currentUser;
        if (!user) throw new Error("No authenticated user");

        // Fetch previously viewed and liked profiles
        const [viewedProfileIds, likedProfiles] = await Promise.all([
          getViewedProfiles(user.uid),
          matchingService.getUserMatches(user.uid),
        ]);

        setViewedProfiles(new Set(viewedProfileIds));
        setLikedProfiles(
          new Set(
            likedProfiles.map((match) =>
              match.users.find((id) => id !== user.uid)
            )
          )
        );

        // Fetch user preferences and profile
        const userDoc = await getDoc(doc(db, "profiles", user.uid));
        if (!userDoc.exists()) throw new Error("User profile not found");

        const userProfile = userDoc.data();
        setUserPreferences(userProfile);

        // Check profile completeness
        const incomplete = checkProfileCompleteness(userProfile);
        setProfileComplete(incomplete.length === 0);
        setMissingFields(incomplete);

        // Set initial university filter to user's university if not already selected
        if (selectedUniversity === "all") {
          setSelectedUniversity(userProfile.academic.university);
        }

        // Build query with university filter only
        const baseQuery = query(
          collection(db, "profiles"),
          where(
            "academic.university",
            "==",
            selectedUniversity || userProfile.academic.university
          )
        );

        // const querySnapshot = await getDocs(baseQuery);
        // const profiles = querySnapshot.docs
        //   .map(doc => ({ id: doc.id, ...doc.data() }))
        //   .filter(profile =>
        //     profile.id !== user.uid && // Filter out current user
        //     isProfileComplete(profile) // Only include complete profiles
        //   );

        const querySnapshot = await getDocs(baseQuery);
        const profiles = querySnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter(
            (profile) =>
              profile.id !== user.uid && // Filter out current user
              isProfileComplete(profile) && // Only include complete profiles
              !viewedProfileIds.includes(profile.id)
          );

        // Filter profiles based on user preferences for gender
        const filteredProfiles = filterProfilesByGenderPreference(
          profiles,
          userProfile
        );

        updateProfilesList(filteredProfiles, 0);
      } catch (err) {
        console.error("Error fetching profiles:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserPreferencesAndProfiles();
  }, [selectedUniversity]);

  const markProfileAsViewed = async (profileId) => {
    try {
      const user = auth.currentUser;
      if (user) {
        await addViewedProfile(user.uid, profileId);
        setViewedProfiles((prev) => new Set([...prev, profileId]));

        // Update profiles list immediately
        const remainingProfiles = userProfiles.filter(
          (profile) => profile.id !== profileId
        );
        updateProfilesList(remainingProfiles, currentProfileIndex);
      }
    } catch (error) {
      console.error("Error marking profile as viewed:", error);
    }
  };

  const handleDislike = async () => {
    if (!profileComplete) {
      setShowIncompleteProfile(true);
      return;
    }

    const currentProfile = userProfiles[currentProfileIndex];
    if (currentProfile) {
      setCardAction("left");
      await markProfileAsViewed(currentProfile.id);

      setTimeout(() => {
        setCardAction(null);
      }, 300);
    }
  };

  const handleRecommend = () => {
    if (!profileComplete) {
      setShowIncompleteProfile(true);
      return;
    }

    setShowGroupSelect(true);
  };

  const handleGroupSelect = async (groupId) => {
    try {
      const currentProfile = userProfiles[currentProfileIndex];
      await recommendProfileToGroup(
        groupId,
        currentProfile.id,
        auth.currentUser.uid
      );

      setCardAction("up");
      setShowGroupSelect(false);

      await markProfileAsViewed(currentProfile.id);

      setTimeout(() => {
        setCardAction(null);
      }, 300);
    } catch (error) {
      console.error("Error recommending profile:", error);
      setShowGroupSelect(false);
    }
  };

  if (error) {
    return (
      <div className="h-screen flex items-center justify-center bg-gray-50">
        <div className="text-red-500">{error}</div>
      </div>
    );
  }

  return (
    <div className="h-screen bg-gray-50 flex flex-col">
      <HomeHeader
        currentActivity={currentActivity}
        setCurrentActivity={setCurrentActivity}
        setShowFilters={setShowFilters}
      />

      <main className="flex-1 mt-16 mb-20 px-4 py-6 overflow-y-auto">
        {loading ? (
          <ProfileCardSkeleton />
        ) : hasMoreProfiles && userProfiles[currentProfileIndex] ? (
          <ProfileCard
            profile={userProfiles[currentProfileIndex]}
            onDislike={handleDislike}
            onRecommend={handleRecommend}
            onLike={() => handleLike(userProfiles[currentProfileIndex].id)}
            cardAction={cardAction}
            isLiked={likedProfiles.has(userProfiles[currentProfileIndex].id)}
          />
        ) : (
          <NoProfilesState onOpenFilters={() => setShowFilters(true)} />
        )}

        {showMatchOverlay && matchedProfile && (
          <MatchOverlay
            profile={matchedProfile}
            onClose={() => {
              setShowMatchOverlay(false);
              setMatchedProfile(null);
              // Navigate to the specific chat with the matched user
              navigate(`/chat/${matchedProfile.chatId}`);
            }}
          />
        )}
      </main>

      <NavBar />

      <FilterModal
        showFilters={showFilters}
        selectedUniversity={selectedUniversity}
        setSelectedUniversity={setSelectedUniversity}
        userPreferences={userPreferences}
        setShowFilters={setShowFilters}
      />

      <GroupSelectModal
        showGroupSelect={showGroupSelect}
        userGroups={userGroups}
        handleGroupSelect={handleGroupSelect}
        setShowGroupSelect={setShowGroupSelect}
      />

      <IncompleteProfileModal
        missingFields={missingFields}
        onComplete={() => navigate("/edit-profile")}
        open={showIncompleteProfile}
        onClose={() => setShowIncompleteProfile(false)}
      />
    </div>
  );
};

export default Home;