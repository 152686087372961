import { db } from './firebase';
import { 
  collection, 
  query,
  where,
  orderBy,
  getDocs,
  limit,
  doc,
  getDoc
} from 'firebase/firestore';

export const searchService = {
  // Search across resources
  async searchResources(searchTerm) {
    try {
      // Basic implementation - you might want to use Firebase extensions or a more sophisticated approach
      const resourcesRef = collection(db, 'resources');
      const querySnapshot = await getDocs(resourcesRef);
      
      // Client-side filtering (not ideal for large collections)
      return querySnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(resource => 
          resource.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          resource.description?.toLowerCase().includes(searchTerm.toLowerCase())
        );
    } catch (error) {
      console.error('Error searching resources:', error);
      throw error;
    }
  },

  // Search across assignments
  async searchAssignments(searchTerm) {
    try {
      const assignmentsRef = collection(db, 'assignments');
      const querySnapshot = await getDocs(assignmentsRef);
      
      return querySnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(assignment => 
          assignment.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          assignment.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          assignment.courseName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          assignment.courseCode?.toLowerCase().includes(searchTerm.toLowerCase())
        );
    } catch (error) {
      console.error('Error searching assignments:', error);
      throw error;
    }
  },

  // Search for tutors - enhanced version that looks through tutorApplications
  async searchTutors(searchTerm, options = {}) {
    try {
      const results = [];
      
      // Search through tutor applications (approved ones)
      const tutorAppsRef = collection(db, 'tutorApplications');
      const appQuery = query(
        tutorAppsRef, 
        where('status', '==', 'approved')
      );
      
      const appSnapshot = await getDocs(appQuery);
      
      // Process tutor applications
      const tutorApps = await Promise.all(appSnapshot.docs.map(async (docSnapshot) => {
        const appData = {
          id: docSnapshot.id,
          ...docSnapshot.data(),
          createdAt: docSnapshot.data().createdAt?.toDate() || null,
        };

        // Match search term against name, university, or courses
        const nameMatch = appData.name?.toLowerCase().includes(searchTerm.toLowerCase());
        const universityMatch = appData.university?.toLowerCase().includes(searchTerm.toLowerCase());
        const courseMatch = appData.courses?.some(course => 
          course.courseCode.toLowerCase().includes(searchTerm.toLowerCase())
        );
        
        // Skip if no matches
        if (!nameMatch && !universityMatch && !courseMatch) {
          return null;
        }

        // Fetch additional profile data
        if (appData.userId) {
          const profileRef = doc(db, 'profiles', appData.userId);
          const profileSnap = await getDoc(profileRef);
          
          if (profileSnap.exists()) {
            const profileData = profileSnap.data();
            appData.languages = profileData.languages || [];
            appData.yearOfStudy = profileData.academic?.yearOfStudy || '';
            
            if (profileData.photos && profileData.photos.length > 0) {
              appData.photoURL = profileData.photos[0];
            } else if (profileData.photoURL) {
              appData.photoURL = profileData.photoURL;
            } else {
              appData.photoURL = '/api/placeholder/80/80';
            }
          }
        }

        return appData;
      }));
      
      // Filter out null results and add to results array
      results.push(...tutorApps.filter(app => app !== null));
      
      // Optionally filter by university
      if (options.university) {
        return results.filter(tutor => 
          tutor.university?.toLowerCase().includes(options.university.toLowerCase())
        );
      }
      
      return results;
    } catch (error) {
      console.error('Error searching tutors:', error);
      throw error;
    }
  },

  // Combined search across all entities
  async searchAll(searchTerm, options = {}) {
    try {
      const [resources, assignments, tutors] = await Promise.all([
        this.searchResources(searchTerm),
        this.searchAssignments(searchTerm),
        this.searchTutors(searchTerm, options)
      ]);
      
      return {
        resources,
        assignments,
        tutors
      };
    } catch (error) {
      console.error('Error performing search:', error);
      throw error;
    }
  }
};