import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Bell } from 'lucide-react';
import { auth, db } from '../firebase/firebase';
import { socialService } from '../firebase/socialService';
import { AnimatePresence, motion } from 'framer-motion';
import CreatePost from '../components/social/CreatePost';
import Post from '../components/social/Post';
import PostSkeleton from '../components/social/PostSkeleton';
import TagsModal from '../components/social/TagsModal';
import HomeHeader from '../components/homepage/HomeHeader';
import { formatTimestamp } from '../utils/formatTimestamp';
import { useNotifications } from '../contexts/NotificationsContext';
import { NotificationsPopover } from '../components/social/NotificationsPopover';
import IncompleteProfileModal from '../components/homepage/IncompleteProfileModal';
import { useNavigate, useLocation } from 'react-router-dom';
import SocialNavBar from '../components/SocialNav';
import { collection, query, getDocs, where, orderBy, limit } from 'firebase/firestore';
import MyPosts from '../components/social/MyPosts';
import FilterModal from '../components/homepage/FilterModal';

const checkProfileCompleteness = (profile) => {
  const requiredFields = [];
  
  if (!profile.basicInfo?.gender) requiredFields.push('Gender');
  if (!profile.basicInfo?.interestedIn || profile.basicInfo.interestedIn.length === 0) requiredFields.push('Interested In');
  if (!profile.academic?.degree) requiredFields.push('Degree Program');
  if (!profile.academic?.yearOfStudy) requiredFields.push('Year of Study');
  if (!profile.bio?.shortBio) requiredFields.push('Short Bio');
  if (!profile.photos || profile.photos.length === 0) requiredFields.push('Profile Photo');

  return requiredFields;
};

// Number of posts to load initially and per scroll
const POSTS_PER_PAGE = 5;
// Number of skeleton loaders to show
const SKELETON_COUNT = 3;
// Intersection observer threshold - trigger when 80% of the element is visible
const OBSERVER_THRESHOLD = 0.8;
// Buffer for early loading - start loading when 2 elements from the bottom
const LOAD_BUFFER = 2;

const SocialFeed = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);
  const [activeCommentId, setActiveCommentId] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [posting, setPosting] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showIncompleteProfile, setShowIncompleteProfile] = useState(false);
  const [profileComplete, setProfileComplete] = useState(true);
  const [missingFields, setMissingFields] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loadingFailed, setLoadingFailed] = useState(false);
  const [selectedUniversity, setSelectedUniversity] = useState('');
  
  const { socialCount } = useNotifications();
  const location = useLocation();
  const feedRef = useRef(null);
  const navigate = useNavigate();
  const unsubscribeRef = useRef(null);
  const observerRef = useRef(null);
  const lastPostRef = useRef(null);
  const loadingRef = useRef(false); // Use ref to track loading state across renders

  const isMyPosts = location.pathname === '/my-social-profile';

  const studyTags = [
    "AI", "Machine Learning", "Data Structures", "Algorithms", 
    "Web Dev", "Mobile Dev", "Database", "Networks"
  ];

  const checkProfileStatus = async (profileDoc) => {
    const incomplete = checkProfileCompleteness(profileDoc);
    setProfileComplete(incomplete.length === 0);
    setMissingFields(incomplete);
    return incomplete.length === 0;
  };

  // Setup realtime user profile subscription
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const user = auth.currentUser;
        if (!user) throw new Error('No authenticated user found');

        const profileDoc = await socialService.getUserProfile(user.uid);
        setUserProfile(profileDoc);
        await checkProfileStatus(profileDoc);
      } catch (err) {
        console.error('Error fetching user profile:', err);
        setError('Failed to load user profile');
      }
    };

    fetchUserProfile();
  }, []);

  // Create a memoized version of loadMorePosts to use as a callback
  const loadMorePosts = useCallback(async () => {
    // Check if already loading, no more posts, or lastVisible is not available
    if (loadingRef.current || !hasMore || !lastVisible) return;
    
    try {
      // Set loading state
      setLoadingMore(true);
      loadingRef.current = true;
      setLoadingFailed(false);
      
      const filters = {
        tags: selectedTags.length > 0 ? selectedTags : undefined,
        university: selectedUniversity || undefined,
        ...(isMyPosts && { authorId: auth.currentUser?.uid })
      };
      
      const result = await socialService.getMorePosts(lastVisible, filters, POSTS_PER_PAGE);
      
      if (result.posts && result.posts.length > 0) {
        setPosts(prevPosts => [
          ...prevPosts,
          ...result.posts.map(post => ({
            ...post,
            isLiked: post.likes?.includes(auth.currentUser?.uid)
          }))
        ]);
        setLastVisible(result.lastVisible);
        setHasMore(result.hasMore);
      } else {
        // No more posts to load
        setHasMore(false);
      }
    } catch (err) {
      console.error('Error loading more posts:', err);
      setLoadingFailed(true);
      showToast('Failed to load more posts', true);
    } finally {
      setLoadingMore(false);
      loadingRef.current = false;
    }
  }, [lastVisible, hasMore, selectedTags, selectedUniversity, isMyPosts]);

  // Setup intersection observer for infinite scrolling
  useEffect(() => {
    // Create an observer instance
    const observer = new IntersectionObserver(
      (entries) => {
        const lastEntry = entries[0];
        if (lastEntry && lastEntry.isIntersecting && hasMore && !loadingRef.current) {
          // Load more posts when the last post is visible
          loadMorePosts();
        }
      },
      { 
        threshold: OBSERVER_THRESHOLD,
        rootMargin: '100px' // Load posts before they come into view
      }
    );
    
    observerRef.current = observer;
    
    return () => {
      // Clean up observer when component unmounts
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [loadMorePosts, hasMore]);

  // Connect the observer to the last post element when posts change
  useEffect(() => {
    // When we have posts and a last post element, observe it
    if (lastPostRef.current && observerRef.current && posts.length > 0) {
      observerRef.current.disconnect();
      observerRef.current.observe(lastPostRef.current);
    }
  }, [posts]);

  // Function to refresh posts
  const refreshPosts = () => {
    // Reset pagination
    setLastVisible(null);
    setHasMore(true);
    
    // Re-setup subscription which will reload the posts
    if (unsubscribeRef.current) {
      unsubscribeRef.current();
      unsubscribeRef.current = null;
    }
    
    setupSubscription();
    scrollToTop();
  };

  // Setup realtime posts subscription for initial load
  const setupSubscription = async () => {
    try {
      setLoading(true);
      loadingRef.current = true;
      setPosts([]);
      setLastVisible(null);
      setHasMore(true);
      setLoadingFailed(false);
      
      const filters = {
        tags: selectedTags.length > 0 ? selectedTags : undefined,
        university: selectedUniversity || undefined,
        ...(isMyPosts && { authorId: auth.currentUser?.uid })
      };

      // Clean up any existing subscription
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
      }

      // Set up new subscription
      unsubscribeRef.current = socialService.subscribeToPosts(filters, (updatedPosts) => {
        if (updatedPosts.length > 0) {
          // Process posts and set liked state
          setPosts(updatedPosts.map(post => ({
            ...post,
            isLiked: post.likes?.includes(auth.currentUser?.uid)
          })));
          
          // Get the last document snapshot for pagination
          const postsQuery = query(
            collection(db, 'posts'),
            orderBy('createdAt', 'desc'),
            limit(POSTS_PER_PAGE)
          );
          
          getDocs(postsQuery).then(snapshot => {
            if (snapshot.docs.length > 0) {
              setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
            }
            setLoading(false);
            loadingRef.current = false;
          });
        } else {
          // No posts available
          setPosts([]);
          setHasMore(false);
          setLoading(false);
          loadingRef.current = false;
        }
      }, POSTS_PER_PAGE);
    } catch (err) {
      console.error('Error setting up posts subscription:', err);
      setError('Failed to load posts');
      setLoading(false);
      loadingRef.current = false;
    }
  };

  // Call setupSubscription when dependencies change
  useEffect(() => {
    setupSubscription();

    // Clean up subscription on unmount or when dependencies change
    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
      }
    };
  }, [selectedTags, selectedUniversity, isMyPosts]);

  // Add buffer loading function to detect when approaching end
  const setLastPostElement = (index) => {
    // Mark an element as the "last visible" when it's within LOAD_BUFFER of the end
    const isApproachingEnd = posts.length > 0 && index >= posts.length - LOAD_BUFFER;
    
    // Return the ref for the last post (for intersection observer)
    if (index === posts.length - 1) {
      return lastPostRef;
    }
    
    // When approaching end but not yet at last post, we can also trigger loading
    if (isApproachingEnd && hasMore && !loadingRef.current) {
      // Debounce multiple near-end elements triggering loads
      const timer = setTimeout(() => {
        loadMorePosts();
      }, 100);
      
      return { current: null }; // Dummy ref
    }
    
    return { current: null }; // Dummy ref
  };

  const handleCreatePost = async (postData) => {
    if (!profileComplete) {
      setShowIncompleteProfile(true);
      return;
    }

    setPosting(true);
    try {
      await socialService.createPost(auth.currentUser.uid, postData);
      scrollToTop();
    } catch (err) {
      console.error('Error creating post:', err);
      showToast('Failed to create post', true);
    } finally {
      setPosting(false);
    }
  };

  const handleDeletePost = async (postId) => {
    try {
      // Optimistically remove post from UI
      setPosts(prevPosts => prevPosts.filter(post => post.id !== postId));
      
      await socialService.deletePost(postId, auth.currentUser.uid);
      showToast('Post deleted successfully');
    } catch (err) {
      console.error('Error deleting post:', err);
      showToast('Failed to delete post', true);
      // Revert optimistic update on error
      const unsubscribe = socialService.subscribeToPosts({ 
        ...(isMyPosts && { authorId: auth.currentUser?.uid })
      }, setPosts);
      unsubscribe();
    }
  };

  const handleToggleLike = async (postId) => {
    if (!profileComplete) {
      setShowIncompleteProfile(true);
      return;
    }

    try {
      const userId = auth.currentUser.uid;
      // Optimistically update UI
      setPosts(prevPosts => 
        prevPosts.map(post => {
          if (post.id === postId) {
            const isCurrentlyLiked = post.likes.includes(userId);
            return {
              ...post,
              likes: isCurrentlyLiked 
                ? post.likes.filter(id => id !== userId)
                : [...post.likes, userId],
              likesCount: post.likesCount + (isCurrentlyLiked ? -1 : 1),
              isLiked: !isCurrentlyLiked
            };
          }
          return post;
        })
      );

      // Server update
      await socialService.toggleLike(postId, userId);
    } catch (err) {
      console.error('Error toggling like:', err);
      showToast('Failed to update like', true);
    }
  };

  const handleAddComment = async (postId, content, parentCommentId = null) => {
    if (!profileComplete) {
      setShowIncompleteProfile(true);
      return;
    }

    try {
      const newComment = await socialService.addComment(postId, auth.currentUser.uid, content, parentCommentId);
      
      // Optimistically update UI
      setPosts(prevPosts => 
        prevPosts.map(post => {
          if (post.id === postId) {
            if (!parentCommentId) {
              return {
                ...post,
                comments: [newComment, ...post.comments],
                commentsCount: post.commentsCount + 1
              };
            } else {
              return {
                ...post,
                comments: post.comments.map(comment => {
                  if (comment.id === parentCommentId) {
                    return {
                      ...comment,
                      replies: [...(comment.replies || []), newComment],
                      repliesCount: (comment.repliesCount || 0) + 1
                    };
                  }
                  return comment;
                })
              };
            }
          }
          return post;
        })
      );
    } catch (err) {
      console.error('Error adding comment:', err);
      showToast('Failed to add comment', true);
    }
  };

  const toggleTag = (tag) => {
    setSelectedTags(prev => 
      prev.includes(tag)
        ? prev.filter(t => t !== tag)
        : [...prev, tag]
    );
  };

  const scrollToTop = () => {
    feedRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Toast notification function
  const showToast = (message, isError = false) => {
    const toast = document.createElement('div');
    toast.className = `fixed bottom-20 left-1/2 transform -translate-x-1/2 px-4 py-2 rounded-lg text-white ${
      isError ? 'bg-red-500' : 'bg-green-500'
    } z-50 transition-opacity duration-300`;
    toast.textContent = message;
    document.body.appendChild(toast);
    setTimeout(() => {
      toast.style.opacity = '0';
      setTimeout(() => document.body.removeChild(toast), 300);
    }, 3000);
  };

  // Retry button for failed loads
  const handleRetryLoad = () => {
    setLoadingFailed(false);
    loadMorePosts();
  };

  return (
    <div className="min-h-screen bg-gray-50" ref={feedRef}>
      <HomeHeader 
        currentActivity={isMyPosts ? "my posts" : "social"}
        setCurrentActivity={() => {}}
        setShowFilters={setShowFilters}
        onRefresh={refreshPosts} 
      />

      <div className="max-w-2xl mx-auto pt-16 px-4 pb-24">
        {!isMyPosts && (
          <CreatePost
            userProfile={userProfile}
            onCreatePost={handleCreatePost}
            loading={posting}
          />
        )}

        {isMyPosts && posts.length === 0 && !loading && (
          <div className="text-center py-8">
            <p className="text-gray-600 mb-4">You haven't created any posts yet.</p>
            <button
              onClick={() => navigate('/')}
              className="text-blue-600 hover:text-blue-700 font-medium"
            >
              Go to main feed to start posting
            </button>
          </div>
        )}

        <AnimatePresence>
          {loading ? (
            // Display skeleton loaders while loading
            Array.from({ length: SKELETON_COUNT }).map((_, index) => (
              <PostSkeleton key={`skeleton-${index}`} />
            ))
          ) : error ? (
            <div className="text-center py-8">
              <p className="text-red-600">{error}</p>
              <button
                onClick={() => window.location.reload()}
                className="mt-4 text-blue-600 hover:text-blue-700 font-medium"
              >
                Try again
              </button>
            </div>
          ) : (
            posts.map((post, index) => {
              // Get ref for this post (last post or approaching last post)
              const postRef = setLastPostElement(index);
              
              return isMyPosts ? (
                <div ref={postRef} key={post.id}>
                  <MyPosts
                    post={post}
                    userProfile={userProfile}
                    activeCommentId={activeCommentId}
                    onToggleComment={setActiveCommentId}
                    onToggleLike={handleToggleLike}
                    onAddComment={handleAddComment}
                    onDeletePost={handleDeletePost}
                    formatTimestamp={formatTimestamp}
                  />
                </div>
              ) : (
                <div ref={postRef} key={post.id}>
                  <Post
                    post={post}
                    userProfile={userProfile}
                    activeCommentId={activeCommentId}
                    onToggleComment={setActiveCommentId}
                    onToggleLike={handleToggleLike}
                    onAddComment={handleAddComment}
                    onDeletePost={handleDeletePost}
                    formatTimestamp={formatTimestamp}
                    isOwnPost={post.authorId === auth.currentUser?.uid}
                  />
                </div>
              );
            })
          )}
        </AnimatePresence>

        {/* Loading more indicator */}
        {loadingMore && (
          <div className="py-4">
            <PostSkeleton />
          </div>
        )}

        {/* Load failed indicator with retry button */}
        {!loading && loadingFailed && (
          <div className="text-center py-4">
            <p className="text-red-600 mb-2">Failed to load more posts</p>
            <button
              onClick={handleRetryLoad}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              Retry
            </button>
          </div>
        )}

        {/* No more posts indicator */}
        {!loading && !loadingMore && posts.length > 0 && !hasMore && (
          <div className="text-center py-6">
            <p className="text-gray-500">No more posts to load</p>
          </div>
        )}
      </div>

      {/* Notifications Panel */}
      <AnimatePresence>
        {showNotifications && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => setShowNotifications(false)}
              className="fixed inset-0 bg-black/20 z-40"
            />
            <motion.div
              initial={{ opacity: 0, scale: 0.9, y: 20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.9, y: 20 }}
              className="fixed bottom-32 right-4 w-80 bg-white rounded-lg shadow-xl z-50 overflow-hidden"
            >
              <div className="p-4 border-b border-gray-200 flex justify-between items-center">
                <h3 className="font-semibold text-gray-900">Notifications</h3>
                <button 
                  onClick={() => setShowNotifications(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ✕
                </button>
              </div>
              <NotificationsPopover 
                show={true}
                onClose={() => setShowNotifications(false)}
                className="max-h-[60vh] overflow-y-auto"
              />
            </motion.div>
          </>
        )}
      </AnimatePresence>

      <TagsModal
        show={showTagsModal}
        onClose={() => setShowTagsModal(false)}
        selectedTags={selectedTags}
        onToggleTag={toggleTag}
        onClearTags={() => {
          setSelectedTags([]);
          setShowTagsModal(false);
        }}
        studyTags={studyTags}
      />

      <FilterModal
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        selectedUniversity={selectedUniversity}
        setSelectedUniversity={setSelectedUniversity}
        userPreferences={userProfile}
        onApplyFilters={() => refreshPosts()}
      />

      <IncompleteProfileModal
        missingFields={missingFields}
        onComplete={() => navigate('/edit-profile')}
        open={showIncompleteProfile}
        onClose={() => setShowIncompleteProfile(false)}
      />

      <SocialNavBar onHomeClick={scrollToTop} />
    </div>
  );
};

export default SocialFeed;