// components/groups/GroupCardSkeleton.js
import { Skeleton } from './Skeleton';

export const GroupCardSkeleton = () => (
  <div className="bg-white rounded-xl shadow-sm overflow-hidden">
    <div className="p-4">
      <div className="flex items-start justify-between">
        <div className="flex items-center space-x-3">
          <Skeleton className="w-12 h-12 rounded-xl" />
          <div>
            <Skeleton className="w-32 h-6 mb-2" />
            <Skeleton className="w-24 h-4" />
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <Skeleton className="w-24 h-8 rounded-full" />
        </div>
      </div>
      
      <div className="mt-3 flex flex-wrap gap-2">
        <Skeleton className="w-16 h-6 rounded-full" />
        <Skeleton className="w-20 h-6 rounded-full" />
        <Skeleton className="w-24 h-6 rounded-full" />
      </div>
      
      <div className="mt-4 flex items-center justify-between">
        <div className="flex -space-x-2">
          <Skeleton className="w-8 h-8 rounded-full" />
          <Skeleton className="w-8 h-8 rounded-full" />
          <Skeleton className="w-8 h-8 rounded-full" />
        </div>
        <Skeleton className="w-5 h-5 rounded" />
      </div>
    </div>
  </div>
);
