// src/components/homepage/NoProfilesState.js
import React from 'react';
import { Users, Filter, Sparkles, ChevronRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const NoProfilesState = ({ onOpenFilters }) => {
  const navigate = useNavigate();

  return (
    <div className="max-w-md mx-auto text-center px-6 py-8 bg-white rounded-3xl shadow-lg">
      <div className="flex justify-center mb-6">
        <div className="p-4 bg-green-50 rounded-full">
          <Users className="w-10 h-10 text-green-500" />
        </div>
      </div>
      
      <h2 className="text-2xl font-semibold text-gray-900 mb-3">
        No More Profiles
      </h2>
      
      <p className="text-gray-600 mb-8">
        You've seen all profiles matching your criteria. Try adjusting your filters or explore other features!
      </p>

      <div className="space-y-3">
        <button
          onClick={onOpenFilters}
          className="w-full flex items-center justify-between p-4 bg-green-50 rounded-xl hover:bg-green-100 transition-colors"
        >
          <div className="flex items-center gap-3">
            <Filter className="w-5 h-5 text-green-600" />
            <span className="font-medium text-gray-900">Adjust Filters</span>
          </div>
          <ChevronRight className="w-5 h-5 text-gray-400" />
        </button>

        <button
          onClick={() => navigate('/social-feed')}
          className="w-full flex items-center justify-between p-4 bg-purple-50 rounded-xl hover:bg-purple-100 transition-colors"
        >
          <div className="flex items-center gap-3">
            <Sparkles className="w-5 h-5 text-purple-600" />
            <span className="font-medium text-gray-900">Explore Social Feed</span>
          </div>
          <ChevronRight className="w-5 h-5 text-gray-400" />
        </button>
      </div>
    </div>
  );
};

export default NoProfilesState;