import React, { useState } from 'react';
import { X, Heart } from 'lucide-react';
import { voteOnRecommendation } from '../firebase/groupVotingService';
import toast from 'react-hot-toast';

const RecommendationCard = ({ recommendation, groupMembers, onVote, onClose, groupId }) => {
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [selectedMember, setSelectedMember] = useState(null);
  const [votingComplete, setVotingComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const profile = recommendation.profileDetails;
  const photos = profile?.photos || ['/default-avatar.png'];

  // Filter out both the recommended user and the recommender from group members
  const votingMembers = groupMembers.filter(member =>
    member.id !== recommendation.recommendedProfileId &&
    member.id !== recommendation.recommendedById
  );

  const nextPhoto = (e) => {
    e.stopPropagation();
    if (currentPhotoIndex < photos.length - 1) {
      setCurrentPhotoIndex(prev => prev + 1);
    }
  };

  const prevPhoto = (e) => {
    e.stopPropagation();
    if (currentPhotoIndex > 0) {
      setCurrentPhotoIndex(prev => prev - 1);
    }
  };

  const handleClose = (e) => {
    e.stopPropagation();
    onClose();
  };

  const handleVote = async (memberId) => {
    if (votingComplete || selectedMember || !groupId || !recommendation.id) {
      console.log('Vote blocked:', {
        votingComplete,
        selectedMember,
        groupId,
        recommendationId: recommendation.id
      });
      return;
    }
  
    try {
      setIsLoading(true);
      
      const votingData = {
        recommendedUserId: recommendation.recommendedProfileId,
        selectedMemberId: memberId,  // The member being voted for
        vote: 'up'
      };
  
      if (onVote) {
        await onVote(recommendation.id, votingData);
        setSelectedMember(memberId);
      }
    } catch (error) {
      console.error('Error voting:', error);
      toast.error(`Failed to submit vote: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/90 z-[100] flex items-center justify-center">
      <div className="w-full max-w-md bg-white rounded-2xl overflow-hidden relative min-h-[85vh]">
        {/* Top Navigation Bar */}
        <div className="absolute top-0 left-0 right-0 z-10 flex items-center justify-between p-4 bg-gradient-to-b from-black/50 to-transparent">
          <button onClick={() => window.history.back()} className="text-white">
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <button
            onClick={handleClose}
            className="text-white p-1"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        {/* Main Photo */}
        <div className="relative h-[60vh]">
          <img
            src={photos[currentPhotoIndex]}
            alt="Profile"
            className="w-full h-full object-cover"
          />

          {/* Photo Navigation */}
          <div className="absolute top-12 left-0 right-0 flex justify-center space-x-1 px-4">
            {photos.map((_, index) => (
              <div
                key={index}
                className={`h-1 rounded-full transition-all ${index === currentPhotoIndex ? 'w-8 bg-white' : 'w-8 bg-white/30'
                  }`}
              />
            ))}
          </div>

          <div className="absolute top-0 bottom-0 left-0 right-0 flex">
            <div className="w-1/2 cursor-pointer" onClick={prevPhoto} />
            <div className="w-1/2 cursor-pointer" onClick={nextPhoto} />
          </div>
        </div>

        {/* Profile Info */}
        <div className="p-4 bg-white">
          <h3 className="text-2xl font-semibold">{profile?.basicInfo?.name}</h3>
          <p className="text-gray-600 text-lg">
            {profile?.academic?.yearOfStudy} {profile?.academic?.degree}
          </p>
          <p className="text-gray-600 text-lg">
            Bio: {profile?.bio?.shortBio}
          </p>

          {/* Voting Section */}
          <div className="mt-6">
            <h4 className="text-lg font-medium mb-4">Match with:</h4>
            <div className="grid grid-cols-4 gap-4">
              {votingMembers.map((member) => {
                const isSelected = selectedMember === member.id;
                const isDisabled = votingComplete && !isSelected || isLoading;

                return (
                  <button
                    key={member.id}
                    onClick={() => handleVote(member.id)}
                    className={`group relative flex flex-col items-center ${isDisabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                      }`}
                    disabled={isDisabled}
                  >
                    <div className="relative">
                      <img
                        src={member.photos?.[0] || '/default-avatar.png'}
                        alt={member.name || 'Member'}
                        className="w-16 h-16 rounded-full object-cover"
                      />
                      <div className="absolute inset-0 flex items-center justify-center">
                        <Heart
                          className={`w-8 h-8 transition-all ${isSelected
                              ? 'text-red-500 fill-red-500 scale-110'
                              : 'text-white hover:text-red-500 hover:scale-105'
                            }`}
                          fill={isSelected ? 'currentColor' : 'none'}
                        />
                      </div>
                    </div>
                    <span className="mt-1 text-sm text-gray-600">
                      {member.name}
                    </span>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendationCard;