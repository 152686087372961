import React, { useEffect, useState } from 'react';
import { Filter, GraduationCap, Heart, Users, RefreshCw } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase/firebase';
import { subscribeToUnreadCounts } from '../../firebase/messagingService';
import { socialService } from '../../firebase/socialService'; // Import socialService

export const HomeHeader = ({
  currentActivity = 'academic',
  setCurrentActivity,
  setShowFilters,
  onRefresh
}) => {
  const navigate = useNavigate();
  const [unreadCounts, setUnreadCounts] = useState({
    meetupRequests: 0,
    messages: 0
  });
  const [socialUnreadCount, setSocialUnreadCount] = useState(0); // New state for social notifications

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) return;

    // Subscribe to messaging unread counts (Connect/Dating)
    const messagingUnsubscribe = subscribeToUnreadCounts(user.uid, (counts) => {
      setUnreadCounts(counts);
    });

    // Subscribe to social notifications
    const socialUnsubscribe = socialService.subscribeToNotifications(user.uid, (notifs) => {
      setSocialUnreadCount(notifs.length); // Update social unread count
    });

    return () => {
      messagingUnsubscribe();
      socialUnsubscribe();
    };
  }, []);

  const activities = [
    {
      id: 'academic',
      label: 'Academic',
      path: '/',
      icon: GraduationCap,
      color: 'text-green-500',
      hoverBg: 'hover:bg-green-100',
      activeBg: 'bg-green-500',
      inactiveBg: 'bg-green-50'
    },
    {
      id: 'dating',
      label: 'Connect',
      path: '/connect',
      icon: Heart,
      color: 'text-rose-500',
      hoverBg: 'hover:bg-rose-100',
      activeBg: 'bg-rose-500',
      inactiveBg: 'bg-rose-50',
      showBadge: true // Badge for messaging notifications
    },
    {
      id: 'social',
      label: 'Social',
      path: '/social-feed',
      icon: Users,
      color: 'text-blue-500',
      hoverBg: 'hover:bg-blue-100',
      activeBg: 'bg-blue-500',
      inactiveBg: 'bg-blue-50',
      showBadge: true // Add badge for social notifications
    }
  ];

  const handleActivityChange = (activity) => {
    setCurrentActivity(activity.id);
    navigate(activity.path);
  };

  const NotificationBadge = ({ count }) => {
    if (!count) return null;
    return (
      <div className="absolute -top-1 -right-1 flex items-center justify-center">
        <div className="animate-pulse bg-red-500 rounded-full w-5 h-5 flex items-center justify-center">
          <span className="text-white text-xs font-bold">
            {count > 9 ? '9+' : count}
          </span>
        </div>
      </div>
    );
  };

  const totalMessagingNotifications = unreadCounts.messages + unreadCounts.meetupRequests;

  const handleLogoClick = () => {
    if (onRefresh) {
      onRefresh();
    }
  };

  return (
    <header className="fixed top-0 w-full bg-white shadow-sm z-50">
      <div className="max-w-7xl mx-auto px-4 py-3">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-3">
            <div className="flex items-center space-x-2">
              <div
                className="relative cursor-pointer"
                onClick={handleLogoClick}
                aria-label="Refresh feed"
              >
                <div className="w-8 h-8 bg-gradient-to-br from-green-500 to-green-600 rounded-xl flex items-center justify-center transform rotate-12 shadow-lg hover:shadow-md transition-shadow">
                  <span className="text-white font-bold text-xl transform -rotate-12">V</span>
                </div>
                <div className="absolute -top-1 -right-1 w-2 h-2 bg-green-400 rounded-full animate-pulse" />
              </div>
            </div>
            <button
              onClick={() => setShowFilters?.(true)}
              className="p-2 rounded-full hover:bg-gray-100 transition-colors"
              aria-label="Show filters"
            >
              <Filter className="w-5 h-5 text-gray-500" />
            </button>
          </div>

          <nav className="flex space-x-1">
            {activities.map(activity => {
              const Icon = activity.icon;
              const isActive = currentActivity === activity.id;
              const badgeCount =
                activity.id === 'dating'
                  ? totalMessagingNotifications
                  : activity.id === 'social'
                  ? socialUnreadCount
                  : 0;

              return (
                <div key={activity.id} className="relative">
                  <button
                    className={`px-4 py-1.5 rounded-full text-sm font-medium transition-all 
                              flex items-center space-x-2 ${
                      isActive
                        ? `${activity.activeBg} text-white shadow-sm`
                        : `${activity.inactiveBg} ${activity.color} ${activity.hoverBg}`
                    }`}
                    onClick={() => handleActivityChange(activity)}
                  >
                    <Icon className="w-4 h-4" />
                    <span className="hidden md:inline">{activity.label}</span>
                  </button>
                  {activity.showBadge && badgeCount > 0 && (
                    <NotificationBadge count={badgeCount} />
                  )}
                </div>
              );
            })}
          </nav>
        </div>
      </div>
    </header>
  );
};

export default HomeHeader;