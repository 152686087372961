import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/firebase';
import { collection, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';

import { useCallback, useMemo, useRef } from 'react';
import { Transition } from '@headlessui/react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles

const EmailManagement = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [emailData, setEmailData] = useState({
    subject: '',
    message: '', // This will now store HTML content
    recipients: 'all',
    selectedUsers: [],
    filter: {
      university: '',
      studyYear: ''
    },
    attachments: []
  });
  const [universities, setUniversities] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [courses, setCourses] = useState([]);
  const [studyYears, setStudyYears] = useState(['1', '2', '3', '4', '5', 'Post-graduate']);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [emailHistory, setEmailHistory] = useState([]);
  const [emailSent, setEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [activeTab, setActiveTab] = useState('compose'); // 'compose' or 'history'
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedEmail, setSelectedEmail] = useState(null);

  const functions = getFunctions();
  const sendBulkEmail = httpsCallable(functions, 'sendBulkEmail');
  const auth = getAuth();

// Modified useEffect to fetch data from both collections
useEffect(() => {
  const fetchData = async () => {
    setLoading(true);
    try {
      // Fetch user profiles
      const profilesRef = collection(db, 'profiles');
      const usersSnapshot = await getDocs(profilesRef);
      const profilesList = usersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      // Fetch tutor applications
      const tutorApplicationsRef = collection(db, 'tutorApplications');
      const tutorSnapshot = await getDocs(tutorApplicationsRef);
      const tutorsList = tutorSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      // Combine and deduplicate users
      const usersList = [...profilesList, ...tutorsList].filter((user, index, self) => 
        index === self.findIndex((u) => (u.id === user.id || (user.email && u.email && user.email === u.email)))
      );
      
      setUsers(usersList);
      
      // Extract unique values for filters - updated for the correct structure
      const uniqueUniversities = [...new Set(usersList
        .map(user => {
          return user.university || 
                 (user.academic && user.academic.university) || 
                 '';
        })
        .filter(Boolean))];
      
      const uniqueStudyYears = [...new Set(profilesList
        .map(user => {
          return (user.academic && user.academic.yearOfStudy) || 
                 '';
        })
        .filter(Boolean))];
      
      setUniversities(uniqueUniversities);
      
      // If there are any study years found in the data, use those instead of the default
      if (uniqueStudyYears.length > 0) {
        setStudyYears(uniqueStudyYears);
      }
      
      // Fetch email history
      const emailHistoryRef = collection(db, 'emailHistory');
      const emailSnapshot = await getDocs(emailHistoryRef);
      const emailHistoryList = emailSnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate() || doc.data().sentAt?.toDate() || new Date()
        }))
        .sort((a, b) => b.timestamp - a.timestamp);
      
      setEmailHistory(emailHistoryList);
    } catch (error) {
      console.error('Error fetching data:', error);
      setErrorMessage('Failed to load data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  fetchData();
}, [emailSent]);

  
// Debounced search function
const debouncedSearch = useCallback(
  debounce((searchValue) => {
    setSearchTerm(searchValue);
  }, 300),
  []
);

// Handle search input change with debounce
const handleSearchChange = (e) => {
  debouncedSearch(e.target.value);
};

// For responsive handling of message textarea
const messageTextareaRef = useRef(null);
const autoResizeTextarea = () => {
  const textarea = messageTextareaRef.current;
  if (textarea) {
    textarea.style.height = 'auto';
    textarea.style.height = `${Math.min(textarea.scrollHeight, 400)}px`;
  }
};


// Utility function for debouncing
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

// Add to track scroll position for sticky headers
const [isScrolled, setIsScrolled] = useState(false);
const tableRef = useRef(null);

const handleTableScroll = useCallback(() => {
  if (tableRef.current) {
    setIsScrolled(tableRef.current.scrollTop > 0);
  }
}, []);

// Update existing handleChange function to include textarea resize
const handleChange = (e) => {
  const { name, value } = e.target;
  
  setEmailData(prev => {
    if (name.startsWith('filter.')) {
      const filterField = name.split('.')[1];
      return {
        ...prev,
        filter: {
          ...prev.filter,
          [filterField]: value
        }
      };
    }
    return {
      ...prev,
      [name]: value
    };
  });
  
  // Auto-resize textarea when it's the message field
  if (name === 'message') {
    setTimeout(autoResizeTextarea, 0);
  }
};

// Utility function for copy to clipboard
const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (err) {
    console.error('Failed to copy text: ', err);
    return false;
  }
};

// Handle copy email template
const handleCopyTemplate = async (email, e) => {
  e.stopPropagation();
  const templateText = `Subject: ${email.subject}\n\n${email.message || email.body || ''}`;
  const success = await copyToClipboard(templateText);
  
  if (success) {
    // Show temporary success message
    const button = e.currentTarget;
    const originalText = button.innerText;
    button.innerText = 'Copied!';
    setTimeout(() => {
      button.innerText = originalText;
    }, 2000);
  }
};
  // Handle checkbox selection for individual users
  const handleUserSelection = (userId) => {
    setEmailData(prev => {
      const isSelected = prev.selectedUsers.includes(userId);
      return {
        ...prev,
        selectedUsers: isSelected 
          ? prev.selectedUsers.filter(id => id !== userId)
          : [...prev.selectedUsers, userId]
      };
    });
  };
  
// Update getUserEmail function to handle both collection structures
const getUserEmail = (user) => {
  return user.email || 
         user.basicInfo?.email;
};

// Update getUserName function to handle both collection structures
const getUserName = (user) => {
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }
  if (user.name) {
    return user.name;
  }
  return user.basicInfo?.name || 'Unnamed User';
};
// Update the filteredUsers function to handle both tutor applications and profiles
const filteredUsers = users.filter(user => {
  if (emailData.recipients === 'all') return true;
  
  if (emailData.recipients === 'selected') {
    return emailData.selectedUsers.includes(user.id);
  }
  
  if (emailData.recipients === 'filtered') {
    const { university, studyYear } = emailData.filter;
    
    // Updated to handle both data structures
    const userUniversity = user.university || 
                          (user.academic && user.academic.university);
    
    const userStudyYear = (user.academic && user.academic.yearOfStudy);
    
    return (
      (!university || userUniversity === university) &&
      (!studyYear || userStudyYear === studyYear)
    );
  }
  
  return false;
});

// Calculate recipient stats for display
const recipientStats = useMemo(() => {
  const totalCount = users.length;
  const selectedCount = emailData.selectedUsers.length;
  const filteredCount = filteredUsers.length;
  
  return {
    totalCount,
    selectedCount,
    filteredCount,
    percentage: totalCount > 0 ? Math.round((filteredCount / totalCount) * 100) : 0
  };
}, [users.length, emailData.selectedUsers.length, filteredUsers.length]);

  // Get filtered and searched users
  const searchedUsers = users.filter(user => {
    if (!searchTerm) return true;
    
    const name = getUserName(user).toLowerCase();
    const email = getUserEmail(user)?.toLowerCase() || '';
    const university = (user.university || user.basicInfo?.university || '').toLowerCase();
    
    return name.includes(searchTerm.toLowerCase()) || 
           email.includes(searchTerm.toLowerCase()) ||
           university.includes(searchTerm.toLowerCase());
  });

  // Get recipient emails
  const getRecipientEmails = () => {
    return filteredUsers
      .map(user => getUserEmail(user))
      .filter(Boolean); // Remove any undefined/null emails
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Validate form
    if (!emailData.subject.trim()) {
      setErrorMessage('Email subject is required.');
      return;
    }
    
    if (!emailData.message.trim()) {
      setErrorMessage('Email message is required.');
      return;
    }
    
    const recipientEmails = getRecipientEmails();
    if (recipientEmails.length === 0) {
      setErrorMessage('No recipients selected. Please select at least one recipient.');
      return;
    }
    
    // Show confirmation
    setShowConfirmation(true);
  };
  const confirmSendEmail = async () => {
    try {
      setLoading(true);
      setErrorMessage('');
  
      // Get recipient emails
      const recipientEmails = getRecipientEmails();
  
      // Convert all attachments to base64
      const attachmentsBase64 = await Promise.all(
        emailData.attachments.map(async (file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve({
              filename: file.name,
              data: reader.result.split(',')[1] // Get base64 data
            });
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
          });
        })
      );
  
      // Prepare the payload for the Cloud Function
      const payload = {
        subject: emailData.subject,
        message: emailData.message,
        recipients: recipientEmails,
        attachments: attachmentsBase64 // Include the array of base64-encoded files
      };
  
      // Call the Cloud Function to send the email
      await sendBulkEmail(payload);
  
      // Log the email in history
      await addDoc(collection(db, 'emailHistory'), {
        subject: emailData.subject,
        message: emailData.message,
        body: emailData.message, // For compatibility
        recipientCount: recipientEmails.length,
        recipientType: emailData.recipients,
        sentBy: auth.currentUser?.uid,
        sentByEmail: auth.currentUser?.email,
        timestamp: serverTimestamp(),
        sentAt: serverTimestamp(), // For compatibility
        attachments: emailData.attachments.map(file => file.name) // Log attachment names
      });
  
      // Reset the form
      setEmailData({
        subject: '',
        message: '',
        recipients: 'all',
        selectedUsers: [],
        filter: {
          university: '',
          studyYear: '',
          course: '',
          department: ''
        },
        attachments: [] // Reset attachments
      });
  
      setShowConfirmation(false);
      setEmailSent(true);
  
      // Reset emailSent after 3 seconds
      setTimeout(() => {
        setEmailSent(false);
      }, 3000);
  
    } catch (error) {
      console.error('Error sending email:', error);
      setErrorMessage('Failed to send email. Please try again later.');
    } finally {
      setLoading(false);
    }
  };
  // Handle selection of an email from history for reuse
  const handleSelectEmailTemplate = (email) => {
    setEmailData(prev => ({
      ...prev,
      subject: email.subject,
      message: email.message || email.body
    }));
    setSelectedEmail(email.id);
    setActiveTab('compose');
  };

  // Format relative time for email history
  const getRelativeTime = (date) => {
    const now = new Date();
    const diffMs = now - date;
    const diffSec = Math.floor(diffMs / 1000);
    const diffMin = Math.floor(diffSec / 60);
    const diffHour = Math.floor(diffMin / 60);
    const diffDay = Math.floor(diffHour / 24);
    
    if (diffDay > 0) {
      return diffDay === 1 ? 'Yesterday' : `${diffDay} days ago`;
    }
    if (diffHour > 0) {
      return `${diffHour} ${diffHour === 1 ? 'hour' : 'hours'} ago`;
    }
    if (diffMin > 0) {
      return `${diffMin} ${diffMin === 1 ? 'minute' : 'minutes'} ago`;
    }
    return 'Just now';
  };

  return (
    <div className="container mx-auto p-4 max-w-6xl">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Email Management</h1>
        <div className="flex space-x-2">
          <button 
            onClick={() => setActiveTab('compose')}
            className={`px-4 py-2 rounded-lg ${activeTab === 'compose' 
              ? 'bg-blue-500 text-white' 
              : 'bg-gray-100 hover:bg-gray-200'}`}
          >
            Compose Email
          </button>
          <button 
            onClick={() => setActiveTab('history')}
            className={`px-4 py-2 rounded-lg ${activeTab === 'history' 
              ? 'bg-blue-500 text-white' 
              : 'bg-gray-100 hover:bg-gray-200'}`}
          >
            Email History
          </button>
        </div>
      </div>
      
      {/* Success Message */}
      {emailSent && (
        <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 rounded shadow-md mb-4 flex justify-between items-center">
          <div className="flex items-center">
            <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
            </svg>
            <span>Email sent successfully to {filteredUsers.length} recipients!</span>
          </div>
          <button 
            onClick={() => setEmailSent(false)}
            className="text-green-700 hover:text-green-900"
          >
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      )}
      
      {/* Error Message */}
      {errorMessage && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded shadow-md mb-4 flex justify-between items-center">
          <div className="flex items-center">
            <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
            </svg>
            <span>{errorMessage}</span>
          </div>
          <button 
            onClick={() => setErrorMessage('')}
            className="text-red-700 hover:text-red-900"
          >
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      )}

      {/* Compose Email Tab */}
      {activeTab === 'compose' && (
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="mb-6 flex items-center">
            <h2 className="text-xl font-semibold">Compose Email</h2>
            {selectedEmail && (
              <span className="ml-3 text-sm bg-blue-100 text-blue-800 px-2 py-1 rounded">
                Using template
                <button 
                  onClick={() => {
                    setSelectedEmail(null);
                    setEmailData(prev => ({...prev, subject: '', message: ''}));
                  }}
                  className="ml-2 text-blue-700 hover:text-blue-900"
                >
                  ✕
                </button>
              </span>
            )}
          </div>
          
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
              {/* Left Column - Email Content */}
              <div className="lg:col-span-2 space-y-4">
                {/* Subject */}
                <div>
                  <label className="block text-gray-700 mb-2 font-medium" htmlFor="subject">
                    Subject
                  </label>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    value={emailData.subject}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter email subject"
                  />
                </div>
                
                <div>
  <label className="block text-gray-700 mb-2 font-medium" htmlFor="message">
    Message
  </label>
  <ReactQuill
    id="message"
    value={emailData.message}
    onChange={(value) => setEmailData(prev => ({ ...prev, message: value }))}
    modules={{
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'], // Text formatting
        ['blockquote', 'code-block'], // Block formatting
        [{ 'header': 1 }, { 'header': 2 }], // Headers
        [{ 'list': 'ordered' }, { 'list': 'bullet' }], // Lists
        [{ 'script': 'sub' }, { 'script': 'super' }], // Subscript/superscript
        [{ 'indent': '-1' }, { 'indent': '+1' }], // Indentation
        [{ 'direction': 'rtl' }], // Text direction
        [{ 'size': ['small', false, 'large', 'huge'] }], // Font size
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }], // Headers
        [{ 'color': [] }, { 'background': [] }], // Text color/background
        [{ 'font': [] }], // Font family
        [{ 'align': [] }], // Text alignment
        ['clean'] // Remove formatting
      ]
    }}
    placeholder="Enter email message"
  />
</div>
                <div>
                <div>
  <label className="block text-gray-700 mb-2 font-medium" htmlFor="attachment">
    Attachments (Images)
  </label>
  <input
    type="file"
    id="attachment"
    name="attachment"
    accept="image/*"
    multiple // Allow multiple files
    onChange={(e) => {
      const newFiles = Array.from(e.target.files); // Convert FileList to an array
      setEmailData(prev => ({
        ...prev,
        attachments: [...prev.attachments, ...newFiles] // Append new files to existing files
      }));
    }}
    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
  />
</div>
<div className="mt-2">
  {emailData.attachments.map((file, index) => (
    <div key={index} className="flex items-center justify-between bg-gray-50 p-2 rounded-md mb-2">
      <span className="text-sm text-gray-700">{file.name}</span>
      <button
        type="button"
        onClick={() => {
          // Remove the file from the list
          setEmailData(prev => ({
            ...prev,
            attachments: prev.attachments.filter((_, i) => i !== index)
          }));
        }}
        className="text-red-500 hover:text-red-700"
      >
        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
  ))}
</div>
</div>
                {/* Available Variables */}
                <div className="bg-gray-50 p-3 rounded-md">
                  <h3 className="text-sm font-medium text-gray-700 mb-2">Available Variables</h3>
                  <div className="flex flex-wrap gap-2">
                    <button
                      type="button"
                      onClick={() => setEmailData(prev => ({
                        ...prev,
                        message: prev.message + "{{name}}"
                      }))}
                      className="px-2 py-1 bg-gray-200 text-gray-800 text-xs rounded hover:bg-gray-300"
                    >
                      {"{{name}}"}
                    </button>
                    <button
                      type="button"
                      onClick={() => setEmailData(prev => ({
                        ...prev,
                        message: prev.message + "{{university}}"
                      }))}
                      className="px-2 py-1 bg-gray-200 text-gray-800 text-xs rounded hover:bg-gray-300"
                    >
                      {"{{university}}"}
                    </button>
                    <button
                      type="button"
                      onClick={() => setEmailData(prev => ({
                        ...prev,
                        message: prev.message + "{{course}}"
                      }))}
                      className="px-2 py-1 bg-gray-200 text-gray-800 text-xs rounded hover:bg-gray-300"
                    >
                      {"{{course}}"}
                    </button>
                    <button
                      type="button"
                      onClick={() => setEmailData(prev => ({
                        ...prev,
                        message: prev.message + "{{department}}"
                      }))}
                      className="px-2 py-1 bg-gray-200 text-gray-800 text-xs rounded hover:bg-gray-300"
                    >
                      {"{{department}}"}
                    </button>
                  </div>
                  <p className="text-xs text-gray-500 mt-2">
                    These variables will be replaced with recipient data in the final email.
                  </p>
                </div>
              </div>
              
              {/* Right Column - Recipients */}
              <div className="space-y-4">
                <div className="bg-gray-50 p-4 rounded-md">
                  <h3 className="font-medium text-gray-800 mb-3">Recipients</h3>
                  
                  <div className="space-y-3">
                    <div className="flex items-center">
                      <input 
                        type="radio" 
                        id="all" 
                        name="recipients" 
                        value="all"
                        checked={emailData.recipients === 'all'}
                        onChange={handleChange}
                        className="mr-2"
                      />
                      <label htmlFor="all" className="flex items-center">
                        <span className="mr-2">All Users</span>
                        <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2 py-0.5 rounded">
                          {users.length}
                        </span>
                      </label>
                    </div>
                    
                    <div className="flex items-center">
                      <input 
                        type="radio" 
                        id="selected" 
                        name="recipients" 
                        value="selected"
                        checked={emailData.recipients === 'selected'}
                        onChange={handleChange}
                        className="mr-2"
                      />
                      <label htmlFor="selected" className="flex items-center">
                        <span className="mr-2">Selected Users</span>
                        <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2 py-0.5 rounded">
                          {emailData.selectedUsers.length}
                        </span>
                      </label>
                    </div>
                    
                    <div className="flex items-center">
                      <input 
                        type="radio" 
                        id="filtered" 
                        name="recipients" 
                        value="filtered"
                        checked={emailData.recipients === 'filtered'}
                        onChange={handleChange}
                        className="mr-2"
                      />
                      <label htmlFor="filtered" className="flex items-center">
                        <span className="mr-2">Filtered Users</span>
                        <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2 py-0.5 rounded">
                          {filteredUsers.length}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                
               

{/* Replace the filter section with this improved version */}
{emailData.recipients === 'filtered' && (
  <div className="p-4 border border-gray-200 rounded-md bg-gray-50">
    <h3 className="font-medium mb-3 text-gray-800">Filter Options</h3>
    
    <div className="space-y-3">
      {/* University Filter */}
      <div>
        <label className="block text-gray-700 mb-1 text-sm" htmlFor="university">
          University
        </label>
        <div className="relative">
          <select
            id="university"
            name="filter.university"
            value={emailData.filter.university}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm appearance-none pr-8"
          >
            <option value="">All Universities</option>
            {universities.map(univ => (
              <option key={univ} value={univ}>{univ}</option>
            ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
          </div>
        </div>
      </div>
      
      {/* Study Year Filter */}
      <div>
        <label className="block text-gray-700 mb-1 text-sm" htmlFor="studyYear">
          Study Year
        </label>
        <div className="relative">
          <select
            id="studyYear"
            name="filter.studyYear"
            value={emailData.filter.studyYear}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm appearance-none pr-8"
          >
            <option value="">All Years</option>
            {studyYears.map(year => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
          </div>
        </div>
      </div>
    </div>

    <div className="mt-3 p-2 bg-blue-50 rounded-md text-sm">
      <div className="font-medium mb-1 text-blue-800">Current Filter:</div>
      <div className="text-xs text-blue-800 space-y-1">
        <div className="flex items-center">
          <span className="w-24">University:</span>
          <span className="font-medium">{emailData.filter.university || 'All Universities'}</span>
        </div>
        <div className="flex items-center">
          <span className="w-24">Year:</span>
          <span className="font-medium">{emailData.filter.studyYear || 'All Years'}</span>
        </div>
      </div>
      
      <div className="mt-2 pt-2 border-t border-blue-100">
        <div className="flex justify-between items-center">
          <span className="text-xs text-blue-800">Matching recipients:</span>
          <span className="font-medium text-blue-800">{recipientStats.filteredCount} ({recipientStats.percentage}%)</span>
        </div>
        <div className="w-full bg-blue-200 rounded-full h-1.5 mt-1">
          <div 
            className="bg-blue-600 h-1.5 rounded-full transition-all duration-300 ease-in-out" 
            style={{ width: `${recipientStats.percentage}%` }}
          ></div>
        </div>
      </div>
    </div>
  </div>
)}
                
              {/* Replace the user selection table with this improved version */}
{emailData.recipients === 'selected' && (
  <div className="border border-gray-200 rounded-md">
    <div className="p-3 border-b">
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <svg className="h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
          </svg>
        </div>
        <input
          type="text"
          placeholder="Search users..."
          onChange={handleSearchChange}
          className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
    </div>
    <div 
      className="max-h-64 overflow-y-auto" 
      ref={tableRef}
      onScroll={handleTableScroll}
    >
      <table className="min-w-full divide-y divide-gray-200">
        <thead className={`bg-white transition-all duration-200 z-10 ${isScrolled ? 'shadow-sm' : ''}`}>
          <tr>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 bg-gray-50 z-10">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={emailData.selectedUsers.length > 0 && emailData.selectedUsers.length === searchedUsers.length}
                  onChange={() => {
                    if (emailData.selectedUsers.length === searchedUsers.length) {
                      // Deselect all visible users
                      setEmailData(prev => ({
                        ...prev,
                        selectedUsers: prev.selectedUsers.filter(id => 
                          !searchedUsers.some(user => user.id === id)
                        )
                      }));
                    } else {
                      // Select all visible users
                      const visibleUserIds = searchedUsers.map(user => user.id);
                      const newSelectedUsers = [...new Set([...emailData.selectedUsers, ...visibleUserIds])];
                      setEmailData(prev => ({
                        ...prev,
                        selectedUsers: newSelectedUsers
                      }));
                    }
                  }}
                  className="h-4 w-4 text-blue-600 rounded"
                />
                <span className="ml-2">Select</span>
              </div>
            </th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 bg-gray-50 z-10">
              Name
            </th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky top-0 bg-gray-50 z-10">
              Email
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {searchedUsers.map(user => (
            <tr key={user.id} className="hover:bg-gray-50 transition-colors duration-150">
              <td className="px-4 py-2">
                <input
                  type="checkbox"
                  checked={emailData.selectedUsers.includes(user.id)}
                  onChange={() => handleUserSelection(user.id)}
                  className="h-4 w-4 text-blue-600 rounded focus:ring-blue-500"
                />
              </td>
              <td className="px-4 py-2 text-sm text-gray-900 font-medium">
                {getUserName(user)}
              </td>
              <td className="px-4 py-2 text-sm text-gray-500">
                {getUserEmail(user)}
              </td>
            </tr>
          ))}
          {searchedUsers.length === 0 && (
            <tr>
              <td colSpan="3" className="px-4 py-8 text-center">
                <div className="text-gray-500 flex flex-col items-center">
                  <svg className="h-8 w-8 text-gray-400 mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M12 2a10 10 0 110 20 10 10 0 010-20z" />
                  </svg>
                  <span className="text-sm">No users found matching your search.</span>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
    <div className="p-3 bg-gray-50 border-t">
      <div className="flex justify-between items-center">
        <span className="text-sm text-gray-600">
          {emailData.selectedUsers.length} of {users.length} users selected
        </span>
        {emailData.selectedUsers.length > 0 && (
          <button
            type="button"
            onClick={() => setEmailData(prev => ({...prev, selectedUsers: []}))}
            className="text-blue-600 hover:text-blue-800 text-sm font-medium flex items-center"
          >
            <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
            Clear selection
          </button>
        )}
      </div>
    </div>
  </div>
)}       
            {/* Replace the send button with this improved version */}
<button
  type="submit"
  disabled={loading || filteredUsers.length === 0}
  className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50 transition-colors duration-200 flex items-center justify-center"
>
  {loading ? (
    <>
      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      <span>Processing...</span>
    </>
  ) : (
    <>
      <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
      </svg>
      {filteredUsers.length > 0 ? 
        `Send Email (${filteredUsers.length} ${filteredUsers.length === 1 ? 'Recipient' : 'Recipients'})` : 
        'No Recipients Selected'}
    </>
  )}
</button>
              </div>
            </div>
          </form>
        </div>
      )}
      
      {/* Replace the email history section with this improved version */}
{activeTab === 'history' && (
  <div className="bg-white rounded-lg shadow-md p-6">
    <div className="flex justify-between items-center mb-4">
      <h2 className="text-xl font-semibold">Email History</h2>
      <div className="text-sm text-gray-500">
        {emailHistory.length} {emailHistory.length === 1 ? 'email' : 'emails'} sent
      </div>
    </div>
    
    {emailHistory.length === 0 ? (
      <div className="bg-gray-50 rounded-md p-8 text-center">
        <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
        </svg>
        <p className="mt-2 text-gray-500">No email history found</p>
      </div>
    ) : (
      <div className="space-y-1">
        <div className="border border-gray-200 rounded-md overflow-hidden">
          {emailHistory.map((email, index) => (
            <Transition
              key={email.id}
              show={true}
              appear={true}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
              style={{ 
                transitionDelay: `${index * 50}ms` 
              }}
            >
              <div 
                className={`border-b border-gray-200 hover:bg-gray-50 transition-colors duration-150 cursor-pointer ${
                  selectedEmail === email.id ? 'bg-blue-50' : ''
                }`}
                onClick={() => handleSelectEmailTemplate(email)}
              >
                <div className="p-4">
                  <div className="flex justify-between items-start">
                    <div className="flex-grow pr-4">
                      <h3 className="font-medium text-gray-900 line-clamp-1">{email.subject}</h3>
                      <p className="text-sm text-gray-500 mt-1 line-clamp-2 h-10">
                        {email.message || email.body || 'No message content'}
                      </p>
                    </div>
                    <div className="text-right flex flex-col items-end">
                      <span className="text-xs text-gray-500 whitespace-nowrap">
                        {getRelativeTime(email.timestamp)}
                      </span>
                      <span className="text-xs bg-blue-100 text-blue-800 px-2 py-0.5 rounded-full mt-1 inline-block">
                        {email.recipientCount || '?'} {email.recipientCount === 1 ? 'recipient' : 'recipients'}
                      </span>
                    </div>
                  </div>
                  <div className="mt-2 flex items-center justify-between text-xs text-gray-500">
                    <span className="flex items-center">
                      <svg className="w-3 h-3 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                      </svg>
                      {email.sentByEmail || 'Unknown user'}
                    </span>
                    <span className="flex items-center">
                      <svg className="w-3 h-3 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      {email.timestamp?.toLocaleDateString()} at {email.timestamp?.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                    </span>
                  </div>
                  <div className="mt-2 text-right space-x-2">
                    <button 
                      className="text-gray-600 hover:text-gray-800 text-xs font-medium px-2 py-1 rounded hover:bg-gray-200 transition-colors duration-150"
                      onClick={(e) => handleCopyTemplate(email, e)}
                    >
                      <span className="flex items-center">
                        <svg className="w-3 h-3 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                        </svg>
                        Copy
                      </span>
                    </button>
                    <button 
                      className="text-blue-600 hover:text-blue-800 text-xs font-medium px-2 py-1 rounded hover:bg-blue-50 transition-colors duration-150"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectEmailTemplate(email);
                      }}
                    >
                      <span className="flex items-center">
                        <svg className="w-3 h-3 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2" />
                        </svg>
                        Use Template
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </Transition>
          ))}
        </div>
      </div>
    )}
  </div>
)}
{/* Replace the confirmation modal with this improved version */}
{showConfirmation && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
    <Transition
      show={showConfirmation}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <div className="bg-white rounded-lg shadow-xl p-6 max-w-md w-full">
        <div className="text-center mb-4">
          <svg className="mx-auto h-12 w-12 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <h3 className="text-xl font-bold mt-2">Confirm Email Sending</h3>
        </div>
        <div className="mb-6 text-center">
          <p className="text-gray-600">
            You are about to send an email with subject:
          </p>
          <p className="font-medium text-gray-800 mt-1">"{emailData.subject}"</p>
          <div className="mt-4 bg-blue-50 p-3 rounded-lg inline-block">
            <span className="text-sm text-blue-800">To {filteredUsers.length} {filteredUsers.length === 1 ? 'recipient' : 'recipients'}</span>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row justify-center space-y-2 sm:space-y-0 sm:space-x-3">
          <button 
            onClick={() => setShowConfirmation(false)}
            className="order-2 sm:order-1 px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50 transition-colors duration-150 flex-1 flex justify-center items-center"
          >
            Cancel
          </button>
          <button 
            onClick={confirmSendEmail}
            className="order-1 sm:order-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-150 flex-1 flex justify-center items-center"
          >
            <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
            </svg>
            Confirm & Send
          </button>
        </div>
      </div>
    </Transition>
  </div>
)}    </div>
  );
};

export default EmailManagement;