// src/components/SocialNav.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Home, BellRing, UserCircle } from 'lucide-react';
import { auth } from '../firebase/firebase';
import { socialService } from '../firebase/socialService';
import SocialNotificationsModal from './social/SocialNotificationsModal';

const SocialNavBar = ({ onHomeClick }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) return;

    const unsubscribe = socialService.subscribeToNotifications(user.uid, (notifs) => {
      console.log('Received notifications:', notifs); // Debug log
      setNotifications(notifs);
      setUnreadCount(notifs.length);
    });

    return () => unsubscribe();
  }, []);

  const handleNavigation = (path, e) => {
    e.preventDefault();
    if (location.pathname === path) {
      onHomeClick();
    } else {
      navigate(path);
    }
  };

  const getNavItemClass = (path) => `
    flex flex-col items-center space-y-1 px-4 py-2 rounded-full
    transition-all duration-200 relative
    ${location.pathname === path 
      ? 'text-blue-600 bg-blue-50' 
      : 'text-gray-600 hover:bg-gray-50'
    }
  `;

  const NotificationBadge = ({ count }) => {
    if (!count) return null;
    return (
      <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full min-w-[20px] h-5 flex items-center justify-center px-1">
        {count > 9 ? '9+' : count}
      </span>
    );
  };

  return (
    <>
      <nav className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 py-2 px-4 z-40">
        <div className="max-w-screen-xl mx-auto">
          <div className="flex justify-around items-center">
            <button
              onClick={(e) => handleNavigation('/', e)}
              className={getNavItemClass('/')}
            >
              <Home className="w-6 h-6" />
              <span className="text-xs font-medium">Home</span>
            </button>

            <button
              onClick={() => setIsNotificationsOpen(true)}
              className={getNavItemClass('/notifications')}
            >
              <div className="relative">
                <BellRing className="w-6 h-6" />
                <NotificationBadge count={unreadCount} />
              </div>
              <span className="text-xs font-medium">Alerts</span>
            </button>

            <button
              onClick={(e) => handleNavigation('/my-social-profile', e)}
              className={getNavItemClass('/my-social-profile')}
            >
              <UserCircle className="w-6 h-6" />
              <span className="text-xs font-medium">My Posts</span>
            </button>
          </div>
        </div>
      </nav>

      <SocialNotificationsModal
        isOpen={isNotificationsOpen}
        onClose={() => setIsNotificationsOpen(false)}
        notifications={notifications}
        markNotificationsAsRead={() => socialService.markNotificationsAsRead(notifications.map(n => n.id))}
      />
    </>
  );
};

export default SocialNavBar;