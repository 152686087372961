import React from 'react';
import { Menu, X, Home, Users, BarChart2, Mail, ChevronDown } from 'lucide-react';

const AdminNav = ({ activeSection, onSectionChange, showMobileMenu, toggleMobileMenu }) => {
  const navItems = [
    { id: 'dashboard', label: 'Dashboard', icon: Home },
    { id: 'tutors', label: 'Tutors', icon: Users },
    { id: 'users', label: 'Users', icon: Users },
    { id: 'email', label: 'Email', icon: Mail },
  ];

  return (
    <>
      {/* Desktop and tablet navigation */}
      <nav className="bg-white shadow-sm fixed w-full top-0 z-10">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0 flex items-center">
                <BarChart2 className="w-6 h-6 text-blue-500" />
                <span className="ml-2 text-lg font-bold text-gray-800 hidden sm:block">Admin</span>
              </div>
              
              <div className="hidden md:flex ml-8 space-x-4">
                {navItems.map((item) => {
                  const Icon = item.icon;
                  return (
                    <button
                      key={item.id}
                      onClick={() => onSectionChange(item.id)}
                      className={`flex items-center px-3 py-2 rounded-md text-sm font-medium transition-colors ${
                        activeSection === item.id
                          ? 'bg-blue-50 text-blue-700'
                          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                      }`}
                    >
                      <Icon className="w-4 h-4 mr-2" />
                      <span>{item.label}</span>
                    </button>
                  );
                })}
              </div>
            </div>
            
            <div className="flex items-center">
              <div className="hidden md:flex items-center space-x-2 bg-gray-50 p-2 rounded-lg">
                <span className="text-sm text-gray-700 font-medium">Admin Portal</span>
                <ChevronDown className="w-4 h-4 text-gray-500" />
              </div>
              
              {/* Mobile menu button */}
              <button
                onClick={toggleMobileMenu}
                className="md:hidden p-2 rounded-md text-gray-600 hover:text-gray-900 hover:bg-gray-100 focus:outline-none"
              >
                {showMobileMenu ? (
                  <X className="w-6 h-6" />
                ) : (
                  <Menu className="w-6 h-6" />
                )}
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile navigation menu */}
      {showMobileMenu && (
        <div className="md:hidden fixed inset-0 z-20 pt-16 bg-gray-900 bg-opacity-50">
          <div className="bg-white h-auto rounded-t-xl shadow-xl overflow-hidden">
            <div className="p-4 border-b border-gray-200">
              <h3 className="font-bold text-gray-800 flex items-center">
                <BarChart2 className="w-5 h-5 text-blue-500 mr-2" />
                Admin Navigation
              </h3>
            </div>
            <div className="p-2">
              {navItems.map((item) => {
                const Icon = item.icon;
                return (
                  <button
                    key={item.id}
                    onClick={() => {
                      onSectionChange(item.id);
                      toggleMobileMenu();
                    }}
                    className={`flex items-center w-full px-4 py-3 rounded-lg text-left ${
                      activeSection === item.id
                        ? 'bg-blue-50 text-blue-700'
                        : 'text-gray-700 hover:bg-gray-50'
                    }`}
                  >
                    <Icon className="w-5 h-5 mr-3" />
                    <span className="font-medium">{item.label}</span>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {/* Bottom navigation for mobile */}
      <div className="md:hidden fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 z-10">
        <div className="flex justify-around">
          {navItems.map((item) => {
            const Icon = item.icon;
            return (
              <button
                key={item.id}
                onClick={() => onSectionChange(item.id)}
                className={`flex flex-col items-center py-2 px-1 ${
                  activeSection === item.id
                    ? 'text-blue-600'
                    : 'text-gray-600'
                }`}
              >
                <Icon className="w-5 h-5" />
                <span className="text-xs mt-1">{item.label}</span>
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default AdminNav;