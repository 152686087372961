// src/components/messages/ChatPreview.js
import React from 'react';
import { ChevronRight, MessageCircle, Clock } from 'lucide-react';
import { motion } from 'framer-motion';

export const ChatPreview = React.memo(({ chat, onChatClick }) => {
  const { 
    id, 
    name, 
    photoURL = '/default-avatar.png', 
    lastMessage, 
    lastMessageTimestamp, 
    unreadCount = 0, 
    lastActive, 
    isTyping = false 
  } = chat;

  const formatTimestamp = (timestamp) => {
    const date = timestamp instanceof Date ? timestamp : new Date(timestamp);
    const now = new Date();
    const diff = now - date;
    if (diff < 60000) return 'Just now';
    if (diff < 3600000) return `${Math.floor(diff / 60000)}m ago`;
    if (diff < 86400000) return `${Math.floor(diff / 3600000)}h ago`;
    return date.toLocaleDateString();
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ scale: 1.01 }}
      className="bg-white rounded-xl shadow-md hover:shadow-lg transition-all duration-300 flex items-center p-3 cursor-pointer border border-gray-100"
      onClick={() => onChatClick(id)}
    >
      <div className="relative mr-3 flex-shrink-0">
        <div className="w-14 h-14 rounded-full overflow-hidden bg-gray-100 border-2 border-gray-100">
          <img
            src={photoURL}
            alt={name}
            className="h-full w-full object-cover"
            onError={(e) => (e.target.src = '/default-avatar.png')}
            loading="lazy"
          />
          {lastActive && (new Date() - new Date(lastActive)) < 300000 && (
            <span className="absolute bottom-0 right-0 w-3 h-3 bg-green-500 rounded-full border-2 border-white"></span>
          )}
        </div>
      </div>
      <div className="flex-1 min-w-0">
        <div className="flex justify-between items-start">
          <h3 className="text-base font-semibold text-gray-900 truncate">{name}</h3>
          <span className="text-xs text-gray-500 flex items-center">
            <Clock className="w-3 h-3 mr-1" />
            {formatTimestamp(lastMessageTimestamp)}
          </span>
        </div>
        <div className="mt-1 flex items-center text-sm text-gray-600">
          <MessageCircle className="w-4 h-4 mr-2 text-green-500 flex-shrink-0" />
          {isTyping ? (
            <span className="text-green-600 italic flex items-center">
              Typing
              <span className="inline-flex ml-1">
                <span className="animate-bounce w-1 h-1 bg-green-500 rounded-full mr-1"></span>
                <span className="animate-bounce delay-100 w-1 h-1 bg-green-500 rounded-full mr-1"></span>
                <span className="animate-bounce delay-200 w-1 h-1 bg-green-500 rounded-full"></span>
              </span>
            </span>
          ) : (
            <p className="truncate">{lastMessage || 'No messages yet'}</p>
          )}
        </div>
        {unreadCount > 0 && (
          <span className="mt-1 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
            {unreadCount} new
          </span>
        )}
      </div>
      <ChevronRight className="w-4 h-4 text-gray-400 ml-2 flex-shrink-0 transition-transform group-hover:translate-x-1" />
    </motion.div>
  );
});

ChatPreview.displayName = 'ChatPreview';