// meetupService.js
import { 
  addDoc, 
  collection, 
  query, 
  where, 
  getDocs, 
  serverTimestamp, 
  updateDoc,
  doc,
  getDoc,
  setDoc, 
  increment 
} from 'firebase/firestore';
import { db } from './firebase';
import { sendNotification } from './notificationService';

const checkExistingRequest = async (senderId, receiverId) => {
  const existingRequestsQuery = query(
    collection(db, 'meetupRequests'),
    where('senderId', '==', senderId),
    where('receiverId', '==', receiverId),
    where('status', '==', 'pending')
  );
  
  const reverseRequestsQuery = query(
    collection(db, 'meetupRequests'),
    where('senderId', '==', receiverId),
    where('receiverId', '==', senderId),
    where('status', '==', 'pending')
  );

  const [existingRequests, reverseRequests] = await Promise.all([
    getDocs(existingRequestsQuery),
    getDocs(reverseRequestsQuery)
  ]);

  if (!existingRequests.empty) {
    const request = existingRequests.docs[0].data();
    return {
      exists: true,
      direction: 'sent',
      createdAt: request.createdAt,
      requestId: existingRequests.docs[0].id
    };
  }

  if (!reverseRequests.empty) {
    const request = reverseRequests.docs[0].data();
    return {
      exists: true,
      direction: 'received',
      createdAt: request.createdAt,
      requestId: reverseRequests.docs[0].id
    };
  }

  return { exists: false };
};

export const sendMeetupRequest = async (senderId, receiverId, activity, senderName, receiverName) => {
  try {
    const existingRequest = await checkExistingRequest(senderId, receiverId);
    
    if (existingRequest.exists) {
      const timeAgo = existingRequest.createdAt ? 
        new Date(existingRequest.createdAt.toDate()).toLocaleDateString() : 
        'recently';
      
      if (existingRequest.direction === 'sent') {
        throw {
          code: 'DUPLICATE_REQUEST',
          message: `You already sent a request to this user on ${timeAgo}`,
          requestId: existingRequest.requestId
        };
      } else {
        throw {
          code: 'REVERSE_REQUEST_EXISTS',
          message: `This user sent you a request on ${timeAgo}. Check your pending requests.`,
          requestId: existingRequest.requestId
        };
      }
    }

    const requestData = {
      senderId,
      receiverId,
      activity,
      status: 'pending',
      createdAt: serverTimestamp(),
      seen: false,
      senderName,
      receiverName,
      lastUpdated: serverTimestamp(),
      unreadCount: 1
    };

    const docRef = await addDoc(collection(db, 'meetupRequests'), requestData);
    
    const unreadCountRef = doc(db, 'userUnreadCounts', receiverId);
    await setDoc(unreadCountRef, {
      meetupRequests: increment(1)
    }, { merge: true });

    // Send notification to receiver
    await sendNotification(receiverId, {
      title: 'New Meetup Request!',
      body: `${senderName} wants to ${activity.toLowerCase()} with you`,
      clickAction: '/messages',
      data: {
        type: 'meetup_request',
        requestId: docRef.id
      }
    });

    return {
      success: true,
      requestId: docRef.id,
      message: 'Meetup request sent successfully'
    };
  } catch (error) {
    if (error.code === 'DUPLICATE_REQUEST' || error.code === 'REVERSE_REQUEST_EXISTS') {
      throw error;
    }
    console.error('Error sending meetup request:', error);
    throw {
      code: 'REQUEST_FAILED',
      message: 'Failed to send meetup request. Please try again.',
      originalError: error
    };
  }
};

export const updateMeetupRequestStatus = async (requestId, newStatus, reason = '') => {
  try {
    const requestRef = doc(db, 'meetupRequests', requestId);
    const request = await getDoc(requestRef);
    
    if (!request.exists()) {
      throw {
        code: 'REQUEST_NOT_FOUND',
        message: 'Meetup request not found'
      };
    }

    const requestData = request.data();
    await updateDoc(requestRef, {
      status: newStatus,
      statusReason: reason,
      lastUpdated: serverTimestamp(),
      updatedAt: serverTimestamp()
    });

    if (newStatus === 'accepted') {
      await sendNotification(requestData.senderId, {
        title: 'Meetup Request Accepted!',
        body: `${requestData.receiverName} accepted your ${requestData.activity.toLowerCase()} request`,
        clickAction: '/messages',
        data: {
          type: 'meetup_accepted',
          requestId
        }
      });
    }

    return {
      success: true,
      message: `Request ${newStatus} successfully`
    };
  } catch (error) {
    console.error('Error updating meetup request:', error);
    throw {
      code: 'UPDATE_FAILED',
      message: 'Failed to update meetup request status',
      originalError: error
    };
  }
};

export const getUserMeetupRequests = async (userId) => {
  try {
    const sentQuery = query(
      collection(db, 'meetupRequests'),
      where('senderId', '==', userId)
    );
    
    const receivedQuery = query(
      collection(db, 'meetupRequests'),
      where('receiverId', '==', userId)
    );

    const [sentSnapshot, receivedSnapshot] = await Promise.all([
      getDocs(sentQuery),
      getDocs(receivedQuery)
    ]);

    const sent = sentSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      type: 'sent'
    }));

    const received = receivedSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      type: 'received'
    }));

    return {
      sent,
      received,
      stats: {
        totalSent: sent.length,
        totalReceived: received.length,
        pendingSent: sent.filter(r => r.status === 'pending').length,
        pendingReceived: received.filter(r => r.status === 'pending').length
      }
    };
  } catch (error) {
    console.error('Error fetching meetup requests:', error);
    throw {
      code: 'FETCH_FAILED',
      message: 'Failed to fetch meetup requests',
      originalError: error
    };
  }
};