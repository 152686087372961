import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { searchService } from '../firebase/searchService';
import { HomeHeader } from '../components/homepage/HomeHeader';
import AcademicNavBar from '../components/AcademicNavBar';
import { 
  Search, Book, FileText, User, ArrowLeft, 
  GraduationCap, Star, Clock, Languages
} from 'lucide-react';

export default function SearchResults() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('q') || '';
  
  const [results, setResults] = useState({ resources: [], assignments: [], tutors: [] });
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('all');
  const [filters, setFilters] = useState({
    university: '',
    degree: '',
    yearOfStudy: ''
  });
  
  useEffect(() => {
    const fetchResults = async () => {
      if (!query) {
        setLoading(false);
        return;
      }
      
      setLoading(true);
      try {
        const searchResults = await searchService.searchAll(query, filters);
        setResults(searchResults);
      } catch (error) {
        console.error('Error searching:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchResults();
  }, [query, filters]);

  const handleSearch = (e) => {
    e.preventDefault();
    const searchInput = e.target.elements.searchInput.value;
    if (searchInput.trim()) {
      navigate(`/search-results?q=${encodeURIComponent(searchInput)}`);
    }
  };
  
  return (
    <div className="min-h-screen bg-gray-50">
      <HomeHeader currentActivity="academic" />
      
      <main className="pt-20 pb-24 px-4 max-w-5xl mx-auto">
        <div className="flex items-center mb-6">
          <button 
            onClick={() => navigate(-1)} 
            className="p-2 rounded-full hover:bg-gray-100"
          >
            <ArrowLeft className="w-5 h-5 text-gray-600" />
          </button>
          <h1 className="text-2xl font-bold text-gray-800 ml-2">
            Search Results: "{query}"
          </h1>
        </div>

        {/* Search form */}
        <form onSubmit={handleSearch} className="mb-6">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              name="searchInput"
              defaultValue={query}
              placeholder="Search resources, assignments, tutors..."
              className="w-full pl-10 pr-4 py-3 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 focus:outline-none"
            />
          </div>
        </form>
        
        {/* Tabs */}
        <div className="flex overflow-x-auto space-x-1 border-b border-gray-200 mb-6">
          <button 
            onClick={() => setActiveTab('all')}
            className={`px-4 py-2 font-medium text-sm ${activeTab === 'all' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-600'}`}
          >
            All ({results.resources.length + results.assignments.length + results.tutors.length})
          </button>
          <button 
            onClick={() => setActiveTab('resources')}
            className={`px-4 py-2 font-medium text-sm ${activeTab === 'resources' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-600'}`}
          >
            Resources ({results.resources.length})
          </button>
          <button 
            onClick={() => setActiveTab('assignments')}
            className={`px-4 py-2 font-medium text-sm ${activeTab === 'assignments' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-600'}`}
          >
            Assignments ({results.assignments.length})
          </button>
          <button 
            onClick={() => setActiveTab('tutors')}
            className={`px-4 py-2 font-medium text-sm ${activeTab === 'tutors' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-600'}`}
          >
            Tutors ({results.tutors.length})
          </button>
        </div>

        {/* Results */}
        {loading ? (
          <div className="flex flex-col items-center justify-center py-20">
            <div className="w-12 h-12 border-4 border-blue-200 border-t-blue-500 rounded-full animate-spin mb-4"></div>
            <p className="text-lg text-gray-500">Searching...</p>
          </div>
        ) : (
          <div className="space-y-8">
            {/* Resources Section */}
            {(activeTab === 'all' || activeTab === 'resources') && (
              <section>
                <h2 className="text-xl font-semibold text-gray-800 mb-4">
                  Resources
                </h2>
                {results.resources.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {results.resources.map(resource => (
                      <div 
                        key={resource.id}
                        onClick={() => navigate(`/resources/${resource.id}`)}
                        className="bg-white rounded-lg shadow hover:shadow-md transition-shadow p-4 cursor-pointer"
                      >
                        <div className="flex items-start gap-3">
                          <div className="bg-blue-50 p-2 rounded-lg">
                            <Book className="w-5 h-5 text-blue-600" />
                          </div>
                          <div>
                            <h3 className="font-medium text-gray-800">{resource.title}</h3>
                            <p className="text-sm text-gray-500 line-clamp-2">{resource.description}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-500 py-4">No resources found matching "{query}"</p>
                )}
              </section>
            )}
            
            {/* Assignments Section */}
            {(activeTab === 'all' || activeTab === 'assignments') && (
              <section>
                <h2 className="text-xl font-semibold text-gray-800 mb-4">
                  Assignments
                </h2>
                {results.assignments.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {results.assignments.map(assignment => (
                      <div 
                        key={assignment.id}
                        onClick={() => navigate(`/assignments/${assignment.id}`)}
                        className="bg-white rounded-lg shadow hover:shadow-md transition-shadow p-4 cursor-pointer"
                      >
                        <div className="flex items-start gap-3">
                          <div className="bg-amber-50 p-2 rounded-lg">
                            <FileText className="w-5 h-5 text-amber-600" />
                          </div>
                          <div>
                            <h3 className="font-medium text-gray-800">{assignment.title}</h3>
                            <p className="text-sm text-gray-500 line-clamp-2">{assignment.description}</p>
                            <div className="flex items-center gap-2 mt-2">
                              <span className="text-xs font-medium bg-gray-100 text-gray-700 px-2 py-1 rounded">{assignment.courseCode}</span>
                              <span className="text-xs text-gray-500">{assignment.university}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-500 py-4">No assignments found matching "{query}"</p>
                )}
              </section>
            )}
            
            {/* Tutors Section */}
            {(activeTab === 'all' || activeTab === 'tutors') && (
              <section>
                <h2 className="text-xl font-semibold text-gray-800 mb-4">
                  Tutors
                </h2>
                {activeTab === 'tutors' && (
                  <div className="bg-white rounded-lg p-4 mb-4 shadow-sm">
                    <h3 className="font-medium text-gray-700 mb-3">Filter Tutors</h3>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                      <div>
                        <label className="block text-sm text-gray-600 mb-1">University</label>
                        <input
                          type="text"
                          value={filters.university}
                          onChange={(e) => setFilters({...filters, university: e.target.value})}
                          placeholder="Any university"
                          className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-200 focus:border-blue-500"
                        />
                      </div>
                      <div>
                        <label className="block text-sm text-gray-600 mb-1">Degree</label>
                        <input
                          type="text"
                          value={filters.degree}
                          onChange={(e) => setFilters({...filters, degree: e.target.value})}
                          placeholder="Any degree"
                          className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-200 focus:border-blue-500"
                        />
                      </div>
                      <div>
                        <label className="block text-sm text-gray-600 mb-1">Year of Study</label>
                        <select
                          value={filters.yearOfStudy}
                          onChange={(e) => setFilters({...filters, yearOfStudy: e.target.value})}
                          className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-200 focus:border-blue-500"
                        >
                          <option value="">Any year</option>
                          <option value="1">Year 1</option>
                          <option value="2">Year 2</option>
                          <option value="3">Year 3</option>
                          <option value="4">Year 4</option>
                          <option value="5+">Year 5+</option>
                          <option value="Graduate">Graduate</option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}
                
                {results.tutors.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {results.tutors.map(tutor => (
                      <div 
                        key={tutor.id}
                        onClick={() => navigate(`/tutors/${tutor.id}`)}
                        className="bg-white rounded-lg shadow hover:shadow-md transition-shadow p-4 cursor-pointer"
                      >
                        <div className="flex items-start space-x-4">
                          <div className="relative">
                            <div className="w-16 h-16 rounded-full overflow-hidden bg-gray-100">
                              <img 
                                src={tutor.photos?.[0] || '/api/placeholder/80/80'} 
                                alt={tutor.basicInfo?.name || 'Tutor'}
                                className="h-full w-full object-cover"
                                onError={(e) => {
                                  e.target.src = '/api/placeholder/80/80';
                                  e.target.onerror = null;
                                }}
                              />
                            </div>
                          </div>
                          
                          <div className="flex-1 min-w-0">
                            <h3 className="text-lg font-semibold text-gray-800 tracking-tight truncate">
                              {tutor.basicInfo?.name || 'Unnamed Tutor'}
                            </h3>
                            <p className="text-sm text-gray-500 flex items-center mt-1">
                              <GraduationCap className="flex-shrink-0 w-4 h-4 mr-1" />
                              <span className="truncate">{tutor.academic?.university || 'University not specified'}</span>
                            </p>
                            
                            <div className="grid grid-cols-1 gap-2 text-sm mt-2">
                              <div className="flex items-center text-gray-600">
                                <Clock className="flex-shrink-0 w-4 h-4 text-blue-500 mr-2" />
                                <span>{tutor.academic?.yearOfStudy || 'Year not specified'}</span>
                              </div>
                              {tutor.academic?.degree && (
                                <div className="flex items-center text-gray-600">
                                  <GraduationCap className="flex-shrink-0 w-4 h-4 text-blue-500 mr-2" />
                                  <span className="truncate">{tutor.academic.degree}</span>
                                </div>
                              )}
                            </div>
                            
                            {tutor.courses && tutor.courses.length > 0 && (
                              <div className="mt-3">
                                <div className="flex items-center flex-wrap gap-2">
                                  {tutor.courses.slice(0, 3).map((course, idx) => (
                                    <span 
                                      key={idx}
                                      className="text-xs bg-blue-50 text-blue-700 px-2 py-1 rounded-full"
                                    >
                                      {course.courseCode}
                                    </span>
                                  ))}
                                  {tutor.courses.length > 3 && (
                                    <span className="text-xs text-gray-500">
                                      +{tutor.courses.length - 3} more
                                    </span>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-500 py-4">No tutors found matching "{query}"</p>
                )}
              </section>
            )}
            
            {/* No results at all */}
            {!loading && 
             results.resources.length === 0 && 
             results.assignments.length === 0 && 
             results.tutors.length === 0 && (
              <div className="text-center py-16">
                <div className="bg-gray-100 p-4 rounded-full inline-block mb-4">
                  <Search className="w-10 h-10 text-gray-400" />
                </div>
                <h3 className="text-xl font-medium text-gray-800 mb-2">
                  No results found
                </h3>
                <p className="text-base text-gray-500">
                  Try different keywords or check for typos
                </p>
              </div>
            )}
          </div>
        )}
      </main>
      
      <AcademicNavBar />
    </div>
  );
}