// PhotoGallery.jsx
import React, { useRef, useState } from 'react';
import { Camera, X, PlusCircle, Loader2 } from 'lucide-react';

const PhotoGallery = ({ 
  profile, 
  isEditing, 
  handleImageUpload, 
  setProfile 
}) => {
  const [uploadingIndex, setUploadingIndex] = useState(null);
  const fileInputRef = useRef(null);

  const processImage = async (file) => {
    return new Promise((resolve, reject) => {
      // Check file size (max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        reject(new Error('Image must be less than 5MB'));
        return;
      }

      const img = new Image();
      const reader = new FileReader();

      reader.onload = () => {
        img.src = reader.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // Calculate new dimensions (max 1200px width/height)
          let width = img.width;
          let height = img.height;
          const maxSize = 1200;

          if (width > maxSize || height > maxSize) {
            if (width > height) {
              height = Math.round((height * maxSize) / width);
              width = maxSize;
            } else {
              width = Math.round((width * maxSize) / height);
              height = maxSize;
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          // Convert to WebP format with quality of 0.85
          canvas.toBlob(
            (blob) => {
              resolve(new File([blob], `${file.name.split('.')[0]}.webp`, {
                type: 'image/webp'
              }));
            },
            'image/webp',
            0.85
          );
        };
      };

      reader.onerror = () => reject(new Error('Failed to read file'));
      reader.readAsDataURL(file);
    });
  };

  const handleFileSelect = async (event, index) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setUploadingIndex(index);
      const processedFile = await processImage(file);
      await handleImageUpload(processedFile, index);
    } catch (error) {
      console.error('Error processing image:', error);
      // You might want to show this error to the user through a toast or alert
    } finally {
      setUploadingIndex(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <section className="bg-white rounded-2xl p-6 shadow-sm">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Photos</h2>
        {isEditing && (
          <label className="flex items-center gap-2 px-4 py-2 bg-green-500 text-white rounded-full cursor-pointer hover:bg-green-600 transition-colors">
            <Camera className="w-4 h-4" />
            <span>Add Photo</span>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/jpeg,image/png,image/webp"
              className="hidden"
              onChange={(e) => handleFileSelect(e, profile.photos.length)}
            />
          </label>
        )}
      </div>
      <div className="grid grid-cols-3 gap-4">
        {profile.photos.map((photo, index) => (
          <div key={index} className="relative aspect-square">
            <img
              src={photo}
              alt={`Profile ${index + 1}`}
              className="w-full h-full object-cover rounded-xl"
            />
            {isEditing && (
              <button
                onClick={() => {
                  setProfile(prev => ({
                    ...prev,
                    photos: prev.photos.filter((_, i) => i !== index)
                  }));
                }}
                className="absolute top-2 right-2 p-1.5 bg-red-500 rounded-full text-white hover:bg-red-600 transition-colors"
                disabled={uploadingIndex !== null}
              >
                <X className="w-4 h-4" />
              </button>
            )}
          </div>
        ))}
        {isEditing && profile.photos.length < 6 && (
          <label className={`aspect-square rounded-xl border-2 border-dashed border-gray-300 flex items-center justify-center cursor-pointer hover:border-gray-400 transition-colors ${uploadingIndex !== null ? 'opacity-50 cursor-not-allowed' : ''}`}>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/jpeg,image/png,image/webp"
              className="hidden"
              onChange={(e) => handleFileSelect(e, profile.photos.length)}
              disabled={uploadingIndex !== null}
            />
            {uploadingIndex !== null ? (
              <Loader2 className="w-8 h-8 text-gray-400 animate-spin" />
            ) : (
              <PlusCircle className="w-8 h-8 text-gray-400" />
            )}
          </label>
        )}
      </div>
    </section>
  );
};

export default PhotoGallery;