// CommentItem.js
import React from 'react';
import { Heart, Reply, MoreHorizontal } from 'lucide-react';

export const CommentItem = ({ comment, onReply, formatTimestamp }) => {
  return (
    <div className="group animate-fadeIn">
      <div className="flex gap-2">
        <img
          src={comment.author?.avatar || "/api/placeholder/32/32"}
          alt={comment.author?.name}
          className="w-8 h-8 rounded-full object-cover border-2 border-gray-100"
        />
        <div className="flex-1">
          <div className="bg-white rounded-xl p-3 shadow-sm group-hover:shadow-md transition-shadow">
            <div className="flex justify-between items-start">
              <h4 className="font-medium text-gray-900">{comment.author?.name}</h4>
              <div className="flex items-center gap-2">
                <span className="text-xs text-gray-500">
                  {formatTimestamp(comment.createdAt)}
                </span>
                <button className="opacity-0 group-hover:opacity-100 transition-opacity text-gray-400 hover:text-gray-600">
                  <MoreHorizontal className="w-4 h-4" />
                </button>
              </div>
            </div>
            <p className="text-gray-800 mt-1">{comment.content}</p>
          </div>
          
          <div className="flex items-center gap-4 mt-1 ml-2">
            <button 
              onClick={() => comment.onLike?.(comment.id)}
              className={`flex items-center gap-1 text-sm transition-colors ${
                comment.isLiked ? 'text-red-500' : 'text-gray-500 hover:text-red-500'
              }`}
            >
              <Heart className={`w-4 h-4 ${comment.isLiked ? 'fill-current' : ''}`} />
              <span>{comment.likes?.length || 0}</span>
            </button>
            <button 
              onClick={() => onReply(comment)}
              className="flex items-center gap-1 text-sm text-gray-500 hover:text-blue-500 transition-colors"
            >
              <Reply className="w-4 h-4" />
              Reply
            </button>
          </div>

          {comment.replies?.length > 0 && (
            <div className="mt-2 ml-4 space-y-3">
              {comment.replies.map(reply => (
                <CommentItem
                  key={reply.id}
                  comment={reply}
                  onReply={onReply}
                  formatTimestamp={formatTimestamp}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
