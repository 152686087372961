import React from 'react';
import { X, ThumbsUp, MessageCircle, Reply, ChevronRight, UserCircle } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { useNavigate } from 'react-router-dom';

const SocialNotificationsModal = ({ isOpen, onClose, notifications, markNotificationsAsRead }) => {
  const navigate = useNavigate();

  if (!isOpen) return null;

  const handleClose = () => {
    markNotificationsAsRead(); // Mark all as read when closing
    onClose();
  };

  const handleNotificationClick = (notification) => {
    if (notification.clickAction) {
      navigate(notification.clickAction);
      handleClose();
    }
  };

  const getNotificationIcon = (type) => {
    switch (type) {
      case 'new_like':
        return <ThumbsUp className="w-4 h-4 text-red-500" />;
      case 'new_comment':
        return <MessageCircle className="w-4 h-4 text-blue-500" />;
      case 'comment_reply':
        return <Reply className="w-4 h-4 text-green-500" />;
      case 'profile_view':
        return <UserCircle className="w-4 h-4 text-purple-500" />;
      case 'new_post':
        return <MessageCircle className="w-4 h-4 text-orange-500" />;
      default:
        return null;
    }
  };

  const getNotificationContent = (notification) => {
    const { body } = notification;
    return (
      <div className="flex flex-col space-y-1">
        <p className="text-sm text-gray-900">{body}</p>
      </div>
    );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-end sm:items-center justify-center">
      <div className="bg-white w-full sm:w-96 sm:rounded-lg max-h-[80vh] flex flex-col">
        {/* Header */}
        <div className="px-4 py-3 border-b border-gray-200 flex justify-between items-center sticky top-0 bg-white">
          <h2 className="text-lg font-semibold text-gray-900">Notifications</h2>
          <button 
            onClick={handleClose}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <X className="w-5 h-5 text-gray-500" />
          </button>
        </div>

        {/* Notifications List */}
        <div className="flex-1 overflow-y-auto">
          {notifications.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-48 text-gray-500">
              <MessageCircle className="w-12 h-12 text-gray-300 mb-2" />
              <p className="text-center">No new notifications</p>
            </div>
          ) : (
            <div className="divide-y divide-gray-200">
              {notifications.map((notification) => (
                <button
                  key={notification.id}
                  onClick={() => handleNotificationClick(notification)}
                  className={`w-full p-4 hover:bg-gray-50 transition-colors duration-150 flex items-start gap-3 
                    ${notification.read ? 'bg-white' : 'bg-blue-50'}`}
                >
                  <div className={`w-10 h-10 rounded-full flex items-center justify-center
                    ${notification.type === 'new_like' ? 'bg-red-100' :
                      notification.type === 'new_comment' ? 'bg-blue-100' :
                      notification.type === 'comment_reply' ? 'bg-green-100' :
                      notification.type === 'profile_view' ? 'bg-purple-100' :
                      'bg-orange-100'}`}
                  >
                    {getNotificationIcon(notification.type)}
                  </div>
                  
                  <div className="flex-1 min-w-0 text-left">
                    {getNotificationContent(notification)}
                    <p className="mt-1 text-xs text-gray-500">
                      {formatDistanceToNow(new Date(notification.createdAt), { addSuffix: true })}
                    </p>
                  </div>
                  
                  <ChevronRight className="w-5 h-5 text-gray-400 self-center flex-shrink-0" />
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SocialNotificationsModal;