// src/firebase/messagingService.js
import { 
  addDoc, 
  collection, 
  query, 
  where, 
  getDocs, 
  orderBy, 
  serverTimestamp,
  updateDoc,
  doc,
  onSnapshot,
  getDoc, 
  arrayUnion, 
  setDoc, 
  increment
} from 'firebase/firestore';
import { createNotification } from './notificationService';
import { db, storage } from './firebase';
import { ref, uploadBytes, getDownloadURL } from '../firebase/firebase';

// Helper to get or create a unique chat room between two users
export const getOrCreateChatRoom = async (user1Id, user2Id, activity = 'chat', customName = null) => {
  try {
    const participants = [user1Id, user2Id].sort();
    const chatQuery = query(
      collection(db, 'chatRooms'),
      where('participants', '==', participants)
    );

    const snapshot = await getDocs(chatQuery);
    if (!snapshot.empty) {
      return snapshot.docs[0].id;
    }

    const chatData = {
      participants,
      activity,
      createdAt: serverTimestamp(),
      lastMessage: null,
      lastMessageTimestamp: null,
      lastActive: serverTimestamp(),
      unreadCount: { [user1Id]: 0, [user2Id]: 0 },
      name: customName || null,
      readBy: {}
    };
    const chatRoomRef = await addDoc(collection(db, 'chatRooms'), chatData);
    return chatRoomRef.id;
  } catch (error) {
    console.error('Error getting/creating chat room:', error);
    throw error;
  }
};

// Create group connection chat room
export const createGroupConnectionChatRoom = async (
  initiatingGroupId, 
  targetGroupId, 
  activityType,
  customName = null
) => {
  try {
    const initiatingGroupRef = doc(db, 'groups', initiatingGroupId);
    const targetGroupRef = doc(db, 'groups', targetGroupId);

    const initiatingGroupSnapshot = await getDoc(initiatingGroupRef);
    const targetGroupSnapshot = await getDoc(targetGroupRef);

    const initiatingGroupData = initiatingGroupSnapshot.data();
    const targetGroupData = targetGroupSnapshot.data();

    const allMembers = [...initiatingGroupData.members, ...targetGroupData.members];

    const chatRoomData = {
      participants: allMembers,
      type: 'group_connection',
      activityType,
      groups: [initiatingGroupId, targetGroupId],
      createdAt: serverTimestamp(),
      lastMessage: null,
      lastMessageTimestamp: null,
      lastActive: serverTimestamp(),
      unreadCount: allMembers.reduce((acc, memberId) => ({ ...acc, [memberId]: 0 }), {}),
      name: customName || `${initiatingGroupData.name} + ${targetGroupData.name}`,
      readBy: {}
    };

    const chatRoomRef = await addDoc(collection(db, 'chatRooms'), chatRoomData);

    await updateDoc(initiatingGroupRef, { connectionChats: arrayUnion(chatRoomRef.id) });
    await updateDoc(targetGroupRef, { connectionChats: arrayUnion(chatRoomRef.id) });

    return chatRoomRef.id;
  } catch (error) {
    console.error('Error creating group connection chat room:', error);
    throw error;
  }
};

// Mark activities as read
export const markActivitiesAsRead = async (userId) => {
  if (!userId) {
    console.error('No userId provided to markActivitiesAsRead');
    return;
  }

  try {
    const unreadCountRef = doc(db, 'userUnreadCounts', userId);
    await setDoc(unreadCountRef, { activities: 0, meetupRequests: 0 }, { merge: true });

    const requestsQuery = query(
      collection(db, 'meetupRequests'),
      where('receiverId', '==', userId),
      where('status', '==', 'pending'),
      where('seen', '==', false)
    );

    const snapshot = await getDocs(requestsQuery);
    const updatePromises = snapshot.docs.map(doc => updateDoc(doc.ref, { seen: true }));
    await Promise.all(updatePromises);
  } catch (error) {
    console.error('Error marking activities as read:', error);
    throw error;
  }
};

// Send voice message
export const sendVoiceMessage = async (chatId, senderId, audioBlob) => {
  try {
    const storageRef = ref(storage, `voice-messages/${chatId}/${Date.now()}.webm`);
    await uploadBytes(storageRef, audioBlob);
    const url = await getDownloadURL(storageRef);

    const messageRef = await addDoc(collection(db, 'chatRooms', chatId, 'messages'), {
      type: 'voice',
      content: url,
      senderId,
      timestamp: serverTimestamp(),
      read: false,
      reactions: {}
    });

    const chatRoomRef = doc(db, 'chatRooms', chatId);
    const chatSnap = await getDoc(chatRoomRef);
    const chatData = chatSnap.data();
    const recipientId = chatData.participants.find(id => id !== senderId);

    await updateDoc(chatRoomRef, {
      lastMessage: 'Voice message',
      lastMessageTimestamp: serverTimestamp(),
      lastActive: serverTimestamp(),
      [`unreadCount.${recipientId}`]: increment(1)
    });

    return messageRef.id;
  } catch (error) {
    console.error('Error sending voice message:', error);
    throw error;
  }
};

// Get meetup requests
export const getMeetupRequests = async (userId) => {
  try {
    const q = query(
      collection(db, 'meetupRequests'),
      where('receiverId', '==', userId),
      where('status', '==', 'pending'),
      orderBy('createdAt', 'desc')
    );
    
    const snapshot = await getDocs(q);
    await resetMeetupRequestUnreadCount(userId);
    
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error getting meetup requests:', error);
    throw error;
  }
};

// Update meetup request status
export const updateMeetupRequest = async (requestId, status) => {
  try {
    const requestRef = doc(db, 'meetupRequests', requestId);
    await updateDoc(requestRef, { status, updatedAt: serverTimestamp() });

    if (status === 'accepted') {
      const request = (await getDoc(requestRef)).data();
      return getOrCreateChatRoom(request.senderId, request.receiverId, request.activity);
    }
  } catch (error) {
    console.error('Error updating meetup request:', error);
    throw error;
  }
};

// Create chat room
export const createChatRoom = async (user1Id, user2Id, activity, customName = null) => {
  return getOrCreateChatRoom(user1Id, user2Id, activity, customName);
};

// Create match chat room
export const createMatchChatRoom = async (user1Id, user2Id) => {
  try {
    const chatId = await getOrCreateChatRoom(user1Id, user2Id, 'Varsity');
    const chatRef = doc(db, 'chatRooms', chatId);
    const chatSnap = await getDoc(chatRef);

    if (!chatSnap.data().lastMessage) {
      await updateDoc(chatRef, {
        lastMessage: "You matched! Say hello 👋",
        lastMessageTimestamp: serverTimestamp(),
        lastActive: serverTimestamp(),
        unreadCount: { [user1Id]: 0, [user2Id]: 1 }
      });
    }
    return chatId;
  } catch (error) {
    console.error('Error creating match chat room:', error);
    throw error;
  }
};

// Send like
export const sendLike = async (fromUser, toUser) => {
  try {
    const likeDoc = {
      fromUserId: fromUser.uid,
      toUserId: toUser.id,
      senderName: fromUser.displayName || fromUser.basicInfo?.name || 'Someone',
      type: 'like',
      status: 'pending',
      createdAt: serverTimestamp()
    };
    
    await addDoc(collection(db, 'likes'), likeDoc);
  } catch (error) {
    console.error('Error sending like:', error);
    throw error;
  }
};

// Check for mutual likes (match)
export const checkMatch = async (userId1, userId2) => {
  try {
    const likes = await getDocs(
      query(
        collection(db, 'likes'),
        where('fromUserId', 'in', [userId1, userId2]),
        where('toUserId', 'in', [userId1, userId2])
      )
    );
    
    return likes.docs.length === 2;
  } catch (error) {
    console.error('Error checking match:', error);
    throw error;
  }
};

export const sendMessage = async (chatRoomId, senderId, content) => {
  try {
    const chatRoomRef = doc(db, 'chatRooms', chatRoomId);
    const chatRoomSnap = await getDoc(chatRoomRef);
    const chatData = chatRoomSnap.data();
    const recipientId = chatData.participants.find(id => id !== senderId);

    await addDoc(collection(db, `chatRooms/${chatRoomId}/messages`), {
      senderId,
      content,
      timestamp: serverTimestamp(),
      read: false,
      reactions: {}
    });

    await updateDoc(chatRoomRef, {
      lastMessage: content,
      lastMessageTimestamp: serverTimestamp(),
      lastActive: serverTimestamp(),
      [`unreadCount.${recipientId}`]: increment(1)
    });

    const userUnreadRef = doc(db, 'userUnreadCounts', recipientId);
    const userUnreadSnap = await getDoc(userUnreadRef);
    const currentMessagesCount = userUnreadSnap.exists() ? (userUnreadSnap.data().messages || 0) : 0;
    console.log(`[sendMessage] Before increment - messages count for ${recipientId}: ${currentMessagesCount}`); // Debug log
    await setDoc(userUnreadRef, { messages: increment(1) }, { merge: true });
    console.log(`[sendMessage] After increment - messages count for ${recipientId}: ${currentMessagesCount + 1}`); // Debug log

    const senderDoc = await getDoc(doc(db, 'profiles', senderId));
    const senderName = senderDoc.data()?.basicInfo?.name || 'Someone';

    await createNotification(recipientId, 'newMessage', {
      senderName,
      message: content.length > 50 ? content.substring(0, 47) + '...' : content,
      chatId: chatRoomId
    });
  } catch (error) {
    console.error('Error sending message:', error);
    throw error;
  }
};

export const markChatAsRead = async (chatRoomId, userId) => {
  try {
    const chatRoomRef = doc(db, 'chatRooms', chatRoomId);
    const chatSnap = await getDoc(chatRoomRef);
    const chatData = chatSnap.data();
    const currentUnreadCount = chatData.unreadCount?.[userId] || 0;
    console.log(`[markChatAsRead] Chat ${chatRoomId} - currentUnreadCount for ${userId}: ${currentUnreadCount}`); // Debug log

    if (currentUnreadCount > 0) {
      await updateDoc(chatRoomRef, { 
        [`unreadCount.${userId}`]: 0,
        [`readBy.${userId}`]: serverTimestamp()
      });

      const userUnreadRef = doc(db, 'userUnreadCounts', userId);
      const userUnreadSnap = await getDoc(userUnreadRef);
      const currentMessagesCount = userUnreadSnap.exists() ? (userUnreadSnap.data().messages || 0) : 0;
      console.log(`[markChatAsRead] Before decrement - messages count for ${userId}: ${currentMessagesCount}`); // Debug log
      const newMessagesCount = Math.max(0, currentMessagesCount - currentUnreadCount);
      await setDoc(userUnreadRef, { messages: newMessagesCount }, { merge: true });
      console.log(`[markChatAsRead] After decrement - messages count for ${userId}: ${newMessagesCount}`); // Debug log
    }

    const messagesQuery = query(
      collection(db, `chatRooms/${chatRoomId}/messages`),
      where('read', '==', false),
      where('senderId', '!=', userId)
    );
    const messagesSnapshot = await getDocs(messagesQuery);
    const updatePromises = messagesSnapshot.docs.map(msg => 
      updateDoc(doc(db, `chatRooms/${chatRoomId}/messages`, msg.id), { read: true })
    );
    await Promise.all(updatePromises);
  } catch (error) {
    console.error('Error marking chat as read:', error);
    throw error;
  }
};
export const resetMeetupRequestUnreadCount = async (userId) => {
  try {
    const unreadCountRef = doc(db, 'userUnreadCounts', userId);
    await setDoc(unreadCountRef, { meetupRequests: 0 }, { merge: true });
  } catch (error) {
    console.error('Error resetting meetup request unread count:', error);
    throw error;
  }
};

// Get user chats with deduplication
export const getUserChats = async (userId) => {
  try {
    const q = query(
      collection(db, 'chatRooms'),
      where('participants', 'array-contains', userId)
    );
    const snapshot = await getDocs(q);

    const chatMap = new Map();
    for (const chatDoc of snapshot.docs) {
      const chatData = chatDoc.data();
      const otherUserId = chatData.participants.find(id => id !== userId);
      if (!otherUserId) continue;

      const otherUserDoc = await getDoc(doc(db, 'profiles', otherUserId));
      const otherUser = otherUserDoc.data() || {};

      const chatEntry = {
        id: chatDoc.id,
        userId: otherUserId,
        name: chatData.name || otherUser.basicInfo?.name || 'User',
        photoURL: otherUser.photos?.[0] || '/default-avatar.png',
        lastMessage: chatData.lastMessage || 'Start chatting!',
        lastMessageTimestamp: chatData.lastMessageTimestamp?.toDate() || new Date(),
        unreadCount: chatData.unreadCount?.[userId] || 0,
        lastActive: chatData.lastActive?.toDate() || new Date()
      };

      if (chatMap.has(otherUserId)) {
        const existing = chatMap.get(otherUserId);
        if (chatEntry.lastMessageTimestamp > existing.lastMessageTimestamp) {
          chatMap.set(otherUserId, chatEntry);
        }
      } else {
        chatMap.set(otherUserId, chatEntry);
      }
    }

    return Array.from(chatMap.values()).sort((a, b) => b.lastMessageTimestamp - a.lastMessageTimestamp);
  } catch (error) {
    console.error('Error getting user chats:', error);
    throw error;
  }
};

// Subscribe to user chats with deduplication
export const subscribeToUserChats = (userId, callback) => {
  const q = query(
    collection(db, 'chatRooms'),
    where('participants', 'array-contains', userId)
  );

  return onSnapshot(q, async (snapshot) => {
    const chatMap = new Map();
    await Promise.all(snapshot.docs.map(async (docSnapshot) => {
      const chatData = docSnapshot.data();
      const otherUserId = chatData.participants.find(id => id !== userId);
      if (!otherUserId) return;

      const otherUserDoc = await getDoc(doc(db, 'profiles', otherUserId));
      const otherUser = otherUserDoc.data() || {};

      const chatEntry = {
        id: docSnapshot.id,
        userId: otherUserId,
        name: chatData.name || otherUser.basicInfo?.name || 'User',
        photoURL: otherUser.photos?.[0] || '/default-avatar.png',
        lastMessage: chatData.lastMessage || 'Start chatting!',
        lastMessageTimestamp: chatData.lastMessageTimestamp?.toDate() || new Date(),
        unreadCount: chatData.unreadCount?.[userId] || 0,
        lastActive: chatData.lastActive?.toDate() || new Date()
      };

      if (chatMap.has(otherUserId)) {
        const existing = chatMap.get(otherUserId);
        if (chatEntry.lastMessageTimestamp > existing.lastMessageTimestamp) {
          chatMap.set(otherUserId, chatEntry);
        }
      } else {
        chatMap.set(otherUserId, chatEntry);
      }
    }));

    const chats = Array.from(chatMap.values()).sort((a, b) => b.lastMessageTimestamp - a.lastMessageTimestamp);
    callback(chats);
  }, (error) => {
    console.error('Error in chat subscription:', error);
  });
};

export const subscribeToUnreadCounts = (userId, callback) => {
  const unreadCountRef = doc(db, 'userUnreadCounts', userId);
  return onSnapshot(unreadCountRef, (snapshot) => {
    if (snapshot.exists()) {
      const data = snapshot.data();
      console.log(`[subscribeToUnreadCounts] Updated counts for ${userId}:`, data); // Debug log
      callback({
        meetupRequests: data.meetupRequests || 0,
        messages: data.messages || 0
      });
    } else {
      console.log(`[subscribeToUnreadCounts] No unread counts document for ${userId}, defaulting to 0`); // Debug log
      callback({ meetupRequests: 0, messages: 0 });
    }
  }, (error) => {
    console.error('Error in unread counts subscription:', error);
  });
};

// // Reset meetup request unread count
// export const resetMeetupRequestUnreadCount = async (userId) => {
//   try {
//     const unreadCountRef = doc(db, 'userUnreadCounts', userId);
//     await setDoc(unreadCountRef, { meetupRequests: 0 }, { merge: true });
//   } catch (error) {
//     console.error('Error resetting meetup request unread count:', error);
//     throw error;
//   }
// };

// Subscribe to chat room messages
export const subscribeToChatRoom = (chatRoomId, callback) => {
  try {
    const messagesQuery = query(
      collection(db, `chatRooms/${chatRoomId}/messages`),
      orderBy('timestamp', 'desc')
    );

    return onSnapshot(messagesQuery, (snapshot) => {
      const messages = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      callback(messages);
    });
  } catch (error) {
    console.error('Error subscribing to chat room:', error);
    throw error;
  }
};

// Set typing status
export const setTypingStatus = async (chatRoomId, userId, isTyping) => {
  try {
    const chatRoomRef = doc(db, 'chatRooms', chatRoomId);
    await updateDoc(chatRoomRef, { 
      [`typing.${userId}`]: isTyping,
      lastActive: serverTimestamp()
    });
  } catch (error) {
    console.error('Error setting typing status:', error);
    throw error;
  }
};

// Subscribe to typing status
export const subscribeToTypingStatus = (chatRoomId, callback) => {
  const chatRoomRef = doc(db, 'chatRooms', chatRoomId);
  return onSnapshot(chatRoomRef, (snapshot) => {
    const data = snapshot.data();
    callback(data?.typing || {});
  });
};

// Add reaction to message
export const addMessageReaction = async (chatRoomId, messageId, userId, reaction) => {
  try {
    const messageRef = doc(db, `chatRooms/${chatRoomId}/messages`, messageId);
    await updateDoc(messageRef, {
      [`reactions.${userId}`]: reaction
    });
  } catch (error) {
    console.error('Error adding message reaction:', error);
    throw error;
  }
};

// Update chat room name
export const updateChatRoomName = async (chatRoomId, newName) => {
  try {
    const chatRoomRef = doc(db, 'chatRooms', chatRoomId);
    await updateDoc(chatRoomRef, { name: newName });
  } catch (error) {
    console.error('Error updating chat room name:', error);
    throw error;
  }
};

export default {
  getOrCreateChatRoom,
  createGroupConnectionChatRoom,
  markActivitiesAsRead,
  sendVoiceMessage,
  getMeetupRequests,
  updateMeetupRequest,
  createChatRoom,
  createMatchChatRoom,
  sendLike,
  checkMatch,
  sendMessage,
  markChatAsRead,
  getUserChats,
  subscribeToUserChats,
  subscribeToUnreadCounts,
  resetMeetupRequestUnreadCount,
  subscribeToChatRoom,
  setTypingStatus,
  subscribeToTypingStatus,
  addMessageReaction,
  updateChatRoomName
};