import {
    addDoc,
    collection,
    getDocs,
    serverTimestamp,
    updateDoc,
    doc,
    deleteDoc,
    query,
    where,
    getDoc
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from './firebase';

// Function to upload a new assignment
export const uploadAssignment = async (assignmentData, userId, pdfUrl) => {
    try {
        const newAssignment = {
            title: `${assignmentData.courseName} - ${assignmentData.courseCode}`,
            courseName: assignmentData.courseName,
            courseCode: assignmentData.courseCode,
            university: assignmentData.university,
            phoneNumber: assignmentData.phoneNumber,
            dueDate: assignmentData.dueDate,
            priceOffer: assignmentData.priceOffer,
            description: assignmentData.description,
            pdfUrl: pdfUrl,
            userId: userId,
            createdAt: serverTimestamp(),
        };
        const docRef = await addDoc(collection(db, 'assignments'), newAssignment);
        return docRef.id;
    } catch (error) {
        console.error('Error uploading assignment:', error);
        throw error;
    }
};

// Function to fetch all assignments
export const fetchAssignments = async (userRole) => {
    try {
        const assignmentsRef = collection(db, 'assignments');
        const assignmentSnapshot = await getDocs(assignmentsRef);
        const assignments = await Promise.all(
            assignmentSnapshot.docs.map(async (doc) => {
                const data = { id: doc.id, ...doc.data() };
                if (userRole === 'tutor') {
                    const solutionsRef = collection(db, `assignments/${doc.id}/solutions`);
                    const solutionsSnapshot = await getDocs(solutionsRef);
                    data.solutions = solutionsSnapshot.docs.map(sol => ({
                        id: sol.id,
                        ...sol.data()
                    }));
                }
                return data;
            })
        );
        return assignments;
    } catch (error) {
        console.error('Error fetching assignments:', error);
        throw error;
    }
};

// Function to update an existing assignment
export const updateAssignment = async (assignmentId, assignmentData, pdfUrl) => {
    try {
        const assignmentRef = doc(db, 'assignments', assignmentId);
        const updateData = {
            title: `${assignmentData.courseName} - ${assignmentData.courseCode}`,
            courseName: assignmentData.courseName,
            courseCode: assignmentData.courseCode,
            university: assignmentData.university,
            phoneNumber: assignmentData.phoneNumber,
            dueDate: assignmentData.dueDate,
            priceOffer: assignmentData.priceOffer,
            description: assignmentData.description,
        };
        
        if (pdfUrl) {
            updateData.pdfUrl = pdfUrl;
        }
        
        await updateDoc(assignmentRef, updateData);
    } catch (error) {
        console.error('Error updating assignment:', error);
        throw error;
    }
};

// Function to delete an assignment
export const deleteAssignment = async (assignmentId) => {
    try {
        const assignmentRef = doc(db, 'assignments', assignmentId);
        await deleteDoc(assignmentRef);
    } catch (error) {
        console.error('Error deleting assignment:', error);
        throw error;
    }
};

// Function to upload a solution
export const uploadSolution = async (assignmentId, tutorId, tutorName, solutionUrl) => {
    try {
        const solutionRef = collection(db, `assignments/${assignmentId}/solutions`);
        const docRef = await addDoc(solutionRef, {
            tutorId,
            tutorName,
            solutionUrl,
            submittedAt: serverTimestamp()
        });
        return docRef.id;
    } catch (error) {
        console.error('Error uploading solution:', error);
        throw error;
    }
};

// Function to fetch solutions for an assignment
export const fetchSolutions = async (assignmentId) => {
    try {
        const solutionsRef = collection(db, `assignments/${assignmentId}/solutions`);
        const solutionSnapshot = await getDocs(solutionsRef);
        return solutionSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
        console.error('Error fetching solutions:', error);
        throw error;
    }
};

// Function to upload a file to Firebase Storage
export const uploadFile = async (file, path = 'assignments') => {
    if (!file) return null;
    try {
        const storageRef = ref(storage, `${path}/${Date.now()}_${file.name}`);
        const snapshot = await uploadBytes(storageRef, file);
        return await getDownloadURL(snapshot.ref);
    } catch (error) {
        console.error('Error uploading file:', error);
        throw error;
    }
};