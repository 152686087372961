import React from 'react';
import { Edit, Save, Loader2, CheckCircle, AlertCircle } from 'lucide-react';
import { isProfileComplete } from '../profile/profileValidation';

const ProfileHeader = ({ 
  profile, 
  isEditing, 
  loading, 
  setIsEditing,
  onSave
}) => {
  return (
    <header className="sticky top-0 bg-white shadow-lg z-50 border-b border-gray-200">
      <div className="max-w-2xl mx-auto px-6 py-4 flex items-center justify-between">
        <div className="flex items-center gap-3">
          <h1 className="text-xl font-semibold text-gray-800">Profile</h1>
          
          {isProfileComplete(profile) ? (
            <div className="px-3 py-1 bg-green-50 border border-green-200 rounded-full flex items-center gap-1">
              <CheckCircle className="w-4 h-4 text-green-500" />
              <span className="text-sm font-medium text-green-700">Complete</span>
            </div>
          ) : (
            <div className="px-3 py-1 bg-amber-50 border border-amber-200 rounded-full flex items-center gap-1">
              <AlertCircle className="w-4 h-4 text-amber-500" />
              <span className="text-sm font-medium text-amber-700">Incomplete</span>
            </div>
          )}
        </div>
        
        <div>
          {!isEditing ? (
            <button
              onClick={() => setIsEditing(true)}
              className="px-3 py-1.5 bg-blue-100 hover:bg-blue-200 text-blue-700 font-medium rounded-md transition-colors flex items-center gap-1.5 w-24 justify-center"
              aria-label="Edit profile"
            >
              <Edit className="w-4 h-4" />
              <span>Edit</span>
            </button>
          ) : (
            <button
              onClick={onSave}
              disabled={loading}
              className="px-3 py-1.5 bg-green-600 hover:bg-green-700 text-white font-medium rounded-md transition-colors flex items-center gap-1.5 w-24 justify-center disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? (
                <Loader2 className="w-4 h-4 animate-spin" />
              ) : (
                <Save className="w-4 h-4" />
              )}
              <span>Save</span>
            </button>
          )}
        </div>
      </div>
    </header>
  );
};

export default ProfileHeader;