// Validation function to check if a profile is complete
const isProfileComplete = (profile) => {
    // Check if basic required fields are present
    const hasBasicInfo = Boolean(
      profile.basicInfo?.name?.trim() && 
      profile.basicInfo?.username?.trim()
    );
  
    // Check if at least one photo is uploaded
    const hasPhoto = Array.isArray(profile.photos) && profile.photos.length > 0;
  
    // Check if academic information is complete
    const hasAcademicInfo = Boolean(
      profile.academic?.university?.trim() &&
      profile.academic?.yearOfStudy?.trim() &&
      profile.academic?.degree?.trim() &&
      profile.academic?.residence?.trim()
    );
  
    // Check if bio information is complete
    const hasBioInfo = Boolean(
      profile.bio?.shortBio?.trim() &&
      profile.bio?.longBio?.trim() &&
      Array.isArray(profile.bio?.personalities) &&
      profile.bio.personalities.length > 0 &&
      profile.bio?.relationshipGoal?.trim()
    );
  
    // Check if basic preferences are set
    const hasBasicPreferences = Boolean(
      profile.basicInfo?.gender &&
      Array.isArray(profile.basicInfo?.interestedIn) &&
      profile.basicInfo.interestedIn.length > 0 &&
      profile.basicInfo?.starSign
    );
  
    // Check if languages are selected
    const hasLanguages = Array.isArray(profile.languages) && profile.languages.length > 0;
  
    // Return true only if all sections are complete
    return (
      hasBasicInfo &&
      hasPhoto &&
      hasAcademicInfo &&
      hasBioInfo &&
      hasBasicPreferences &&
      hasLanguages
    );
  };
  
  // Helper function to get incomplete sections
  const getIncompleteFields = (profile) => {
    const incomplete = [];
  
    // Check photos
    if (!Array.isArray(profile.photos) || profile.photos.length === 0) {
      incomplete.push('Profile photo');
    }
  
    // Check basic info
    if (!profile.basicInfo?.name?.trim() || !profile.basicInfo?.username?.trim()) {
      incomplete.push('Name and username');
    }
  
    // Check gender and interested in
    if (!profile.basicInfo?.gender || !profile.basicInfo?.interestedIn?.length) {
      incomplete.push('Gender and preferences');
    }
  
    // Check academic info
    if (!profile.academic?.university?.trim() || !profile.academic?.yearOfStudy?.trim() ||
        !profile.academic?.degree?.trim() || !profile.academic?.residence?.trim()) {
      incomplete.push('Academic information');
    }
  
    // Check bio
    if (!profile.bio?.shortBio?.trim() || !profile.bio?.longBio?.trim() ||
        !profile.bio?.personalities?.length || !profile.bio?.relationshipGoal?.trim()) {
      incomplete.push('Bio and personality');
    }
  
    // Check star sign
    if (!profile.basicInfo?.starSign) {
      incomplete.push('Star sign');
    }
  
    // Check languages
    if (!profile.languages?.length) {
      incomplete.push('Languages');
    }
  
    return incomplete;
  };
  
  export { isProfileComplete, getIncompleteFields };