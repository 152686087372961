import { 
  collection, 
  doc, 
  addDoc, 
  getDoc,
  getDocs,
  writeBatch,
  serverTimestamp,
  updateDoc,
  query,
  where
} from 'firebase/firestore';
import { db } from './firebase';
import { sendMeetupRequest } from './meetupService';
import { createChatRoom } from './messagingService';

// Create or update vote for a recommendation
export const voteOnRecommendation = async (
  groupId,
  recommendationId,
  votingData,
  voterId
) => {
  try {
    if (!groupId || !recommendationId || !votingData) {
      throw new Error('Missing required parameters');
    }

    // Reference to the specific recommendation in Firestore
    const recommendationRef = doc(db, 'groups', groupId, 'recommendations', recommendationId);
    const recommendationDoc = await getDoc(recommendationRef);

    // Check if the recommendation exists
    if (!recommendationDoc.exists()) {
      throw new Error('Recommendation not found');
    }

    const votesRef = collection(recommendationRef, 'votes');
    const existingVoteQuery = query(votesRef, where('votingMemberId', '==', voterId));
    const existingVoteSnapshot = await getDocs(existingVoteQuery);

    // Check if the user has already voted
    if (!existingVoteSnapshot.empty) {
      throw new Error('You have already voted on this recommendation.');
    }

    // Record the new vote
    await addDoc(votesRef, {
      ...votingData,
      votingMemberId: voterId,
      timestamp: serverTimestamp() // Ensures proper timestamp is added
    });

    const votesSnapshot = await getDocs(votesRef);
    const votes = votesSnapshot.docs.map(doc => doc.data());

    // Calculate vote distribution
    const voteCounts = votes.reduce((acc, vote) => {
      acc[vote.votingMemberId] = (acc[vote.votingMemberId] || 0) + 1;
      return acc;
    }, {});

    const totalVotes = Object.values(voteCounts).reduce((sum, count) => sum + count, 0);
    const groupSize = votes.length;

    // Handle the case when all members have voted
    if (totalVotes >= groupSize) {
      return await processVotingResults(groupId, recommendationId, votes, recommendationDoc.data()); // Pass the recommendation document data
    }

    return {
      success: true,
      votesReceived: totalVotes,
      totalExpectedVotes: groupSize,
      voteDistribution: voteCounts
    };
  } catch (error) {
    console.error('Error in voteOnRecommendation:', error);
    throw error;
  }
};

// Process voting results and handle subsequent logic
const processVotingResults = async (groupId, recommendationId, votes, recommendationData) => {
  const batch = writeBatch(db);
  const voteCounts = votes.reduce((acc, vote) => {
    acc[vote.votingMemberId] = (acc[vote.votingMemberId] || 0) + 1;
    return acc;
  }, {});

  const maxVotes = Math.max(...Object.values(voteCounts));
  const winners = Object.entries(voteCounts).filter(([_, count]) => count === maxVotes).map(([memberId]) => memberId);

  // Randomly select a winner if there's a tie
  const winningMemberId = winners[Math.floor(Math.random() * winners.length)];

  // Update the recommendation with the winning member
  const recommendationRef = doc(db, 'groups', groupId, 'recommendations', recommendationId);
  batch.update(recommendationRef, {
    status: 'pending_acceptance',
    winningMemberId,
    completedAt: serverTimestamp(),
    voteResults: voteCounts,
    totalVotes: votes.length,
    winners
  });

  await batch.commit();

  // Notify the recommended user of the match
  await createMatchNotification(winningMemberId, recommendationData.recommendedProfileId, groupId, recommendationId);
  return { success: true, winningMemberId };
};
// Create notification for the recommended user
const createMatchNotification = async (
  winnerId,
  recommendedUserId,
  groupId,
  recommendationId
) => {
  try {
      const [winnerDoc, recommendedDoc] = await Promise.all([
          getDoc(doc(db, 'profiles', winnerId)),
          getDoc(doc(db, 'profiles', recommendedUserId))
      ]);

      if (!winnerDoc.exists() || !recommendedDoc.exists()) {
          throw new Error('User profiles not found');
      }

      const winnerData = winnerDoc.data();
      const recommendedData = recommendedDoc.data();

      // Create notification
      const activityRef = collection(db, 'activities');
      await addDoc(activityRef, {
          type: 'group_match_request',
          status: 'pending',
          fromUserId: winnerId,
          toUserId: recommendedUserId,
          fromUserName: winnerData.basicInfo?.name,
          toUserName: recommendedData.basicInfo?.name,
          groupId,
          recommendationId,
          createdAt: serverTimestamp(),
          read: false,
          message: `${winnerData.basicInfo?.name} wants to connect with you!`,
          activityType: 'invite'  // New field to signify invitation
      });
  } catch (error) {
      console.error('Error creating match notification:', error);
      throw error;
  }
};

// Handle match acceptance
export const acceptGroupMatch = async (activityId) => {
  try {
    const activityRef = doc(db, 'activities', activityId);
    const activityDoc = await getDoc(activityRef);
    
    if (!activityDoc.exists()) {
      throw new Error('Activity not found');
    }

    const activity = activityDoc.data();
    
    // Create meetup request
    const requestId = await sendMeetupRequest(
      activity.fromUserId,
      activity.toUserId,
      'meet',
      activity.fromUserName,
      activity.toUserName
    );

    // Update activity status
    await updateDoc(activityRef, {
      status: 'accepted',
      meetupRequestId: requestId,
      updatedAt: serverTimestamp()
    });

    // Update recommendation status
    const recommendationRef = doc(db, 'groups', activity.groupId, 'recommendations', activity.recommendationId);
    await updateDoc(recommendationRef, {
      status: 'accepted',
      meetupRequestId: requestId,
      acceptedAt: serverTimestamp()
    });

    // Create chat room only after acceptance
    await createChatRoom({
      participants: [activity.fromUserId, activity.toUserId],
      type: 'meetup',
      groupId: activity.groupId,
      recommendationId: activity.recommendationId,
      meetupRequestId: requestId,
      createdAt: serverTimestamp()
    });

    return {
      success: true,
      meetupRequestId: requestId
    };

  } catch (error) {
    console.error('Error accepting group match:', error);
    throw error;
  }
};