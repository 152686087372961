import { 
    doc, 
    collection, 
    query, 
    where, 
    getDocs, 
    getDoc, 
    setDoc, 
    updateDoc, 
    arrayUnion, 
    serverTimestamp 
  } from 'firebase/firestore';
  import { db } from './firebase';
  
  export const matchingService = {
    /**
     * Sends a like and checks for a match
     * @param {string} senderId - ID of the user sending the like
     * @param {string} recipientId - ID of the user being liked
     * @returns {Promise<{ success: boolean, isMatch: boolean, matchData?: object }>}
     */
    async sendLike(senderId, recipientId) {
      try {
        // First, check if there's an existing like from the recipient
        const recipientLikeDoc = await getDoc(
          doc(db, 'likes', `${recipientId}_${senderId}`)
        );
  
        // Record the new like
        await setDoc(doc(db, 'likes', `${senderId}_${recipientId}`), {
          senderId,
          recipientId,
          timestamp: serverTimestamp(),
        });
  
        // If there's a mutual like, create a match
        if (recipientLikeDoc.exists()) {
          const matchData = await this.createMatch(senderId, recipientId);
          
          // Create a chat room for the matched users
          const chatRoom = await this.createMatchChat(senderId, recipientId);
          
          return {
            success: true,
            isMatch: true,
            matchData: {
              ...matchData,
              chatId: chatRoom.id
            }
          };
        }
  
        return {
          success: true,
          isMatch: false
        };
      } catch (error) {
        console.error('Error in sendLike:', error);
        throw error;
      }
    },
  
    /**
     * Creates a match between two users
     * @param {string} user1Id 
     * @param {string} user2Id 
     * @returns {Promise<object>} Match data
     */
    async createMatch(user1Id, user2Id) {
      try {
        const matchRef = doc(collection(db, 'matches'));
        const matchData = {
          users: [user1Id, user2Id],
          timestamp: serverTimestamp(),
          active: true
        };
  
        await setDoc(matchRef, matchData);
  
        // Update user documents with the match
        await Promise.all([
          updateDoc(doc(db, 'profiles', user1Id), {
            matches: arrayUnion(user2Id)
          }),
          updateDoc(doc(db, 'profiles', user2Id), {
            matches: arrayUnion(user1Id)
          })
        ]);
  
        return {
          id: matchRef.id,
          ...matchData
        };
      } catch (error) {
        console.error('Error creating match:', error);
        throw error;
      }
    },
  
    /**
     * Creates a chat room for matched users
     * @param {string} user1Id 
     * @param {string} user2Id 
     * @returns {Promise<object>} Chat room data
     */
    async createMatchChat(user1Id, user2Id) {
      try {
        const chatRef = doc(collection(db, 'chats'));
        const chatData = {
          participants: [user1Id, user2Id],
          createdAt: serverTimestamp(),
          lastMessage: "You've matched! Say hello! 👋",
          lastMessageTimestamp: serverTimestamp(),
          type: 'match',
          unreadCount: {
            [user1Id]: 1,
            [user2Id]: 1
          }
        };
  
        await setDoc(chatRef, chatData);
  
        return {
          id: chatRef.id,
          ...chatData
        };
      } catch (error) {
        console.error('Error creating match chat:', error);
        throw error;
      }
    },
  
    /**
     * Gets all matches for a user
     * @param {string} userId 
     * @returns {Promise<Array>} Array of match objects
     */
    async getUserMatches(userId) {
      try {
        const matchesQuery = query(
          collection(db, 'matches'),
          where('users', 'array-contains', userId),
          where('active', '==', true)
        );
  
        const querySnapshot = await getDocs(matchesQuery);
        return querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      } catch (error) {
        console.error('Error getting user matches:', error);
        throw error;
      }
    },
  
    /**
     * Gets basic profile info for matched users
     * @param {string} userId 
     * @returns {Promise<Array>} Array of basic profile data
     */
    async getMatchedProfiles(userId) {
      try {
        const matches = await this.getUserMatches(userId);
        
        const matchedProfiles = await Promise.all(
          matches.map(async match => {
            const otherUserId = match.users.find(id => id !== userId);
            const profileDoc = await getDoc(doc(db, 'profiles', otherUserId));
            
            return {
              id: otherUserId,
              matchId: match.id,
              ...profileDoc.data()
            };
          })
        );
  
        return matchedProfiles;
      } catch (error) {
        console.error('Error getting matched profiles:', error);
        throw error;
      }
    }
  };