import React, { useState, useRef, useEffect } from 'react';
import { 
  X, 
  Briefcase, 
  GraduationCap, 
  FileUp, 
  Check, 
  ArrowRight, 
  ArrowLeft, 
  Globe,
  Target,
  Lightbulb,
  Mic,
  Square,
  Trash2,
  Lock,
  Building,
  Shield
} from 'lucide-react';
import { storage, db, auth } from "../../firebase/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";
import { toast } from 'react-hot-toast';

const GraduateProgramApplicationFlow = ({ onClose, isOpen }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    faculty: '',
    careerInterests: [],
    industriesOfInterest: [],
    internationalExposureVoice: null,
    careerGoalVoice: null,
    innovationPotentialVoice: null,
    cvFile: null
  });

  // Voice recording state
  const [isRecording, setIsRecording] = useState(false);
  const [recordingField, setRecordingField] = useState(null);
  const [audioRecorder, setAudioRecorder] = useState(null);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const durationIntervalRef = useRef(null);
  const [audioPreviewUrls, setAudioPreviewUrls] = useState({
    internationalExposureVoice: null,
    careerGoalVoice: null,
    innovationPotentialVoice: null
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  // Existing arrays
  const faculties = [
    'Engineering', 
    'Computer Science', 
    'Business & Management', 
    'Medical Sciences', 
    'Social Sciences', 
    'Arts & Humanities', 
    'Natural Sciences', 
    'Environmental Studies'
  ];
  
  const careerOptions = [
    'Research & Development',
    'Corporate Leadership',
    'Entrepreneurship',
    'Consulting',
    'Public Service',
    'Academic Career',
    'Non-profit Work',
    'Creative Professional'
  ];
  
  const industries = [
    'Technology',
    'Healthcare',
    'Finance',
    'Education',
    'Energy & Sustainability',
    'Manufacturing',
    'Media & Entertainment',
    'Government & Policy'
  ];

  // Create audio preview URLs when voice recordings change
  useEffect(() => {
    // Clean up old URLs to prevent memory leaks
    Object.values(audioPreviewUrls).forEach(url => {
      if (url) URL.revokeObjectURL(url);
    });
    
    const newUrls = {};
    
    // Create new URLs for current voice recordings
    for (const field of ['internationalExposureVoice', 'careerGoalVoice', 'innovationPotentialVoice']) {
      if (formData[field]) {
        newUrls[field] = URL.createObjectURL(formData[field]);
      } else {
        newUrls[field] = null;
      }
    }
    
    setAudioPreviewUrls(newUrls);
    
    // Cleanup on component unmount
    return () => {
      Object.values(newUrls).forEach(url => {
        if (url) URL.revokeObjectURL(url);
      });
    };
  }, [formData.internationalExposureVoice, formData.careerGoalVoice, formData.innovationPotentialVoice]);

  const startVoiceRecording = async (field) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      const audioChunks = [];

      recorder.ondataavailable = (event) => {
        audioChunks.push(event.data);
      };

      recorder.onstop = async () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
        setFormData(prev => ({
          ...prev,
          [`${field}Voice`]: audioBlob
        }));
        setRecordingField(null);
      };

      recorder.start();
      setIsRecording(true);
      setRecordingField(field);
      setAudioRecorder(recorder);

      // Start duration tracking
      let duration = 0;
      durationIntervalRef.current = setInterval(() => {
        duration += 1;
        setRecordingDuration(duration);
        if (duration >= 60) {  // Maximum 1 minute recording
          stopVoiceRecording();
        }
      }, 1000);
      
      toast.success('Recording started. Maximum duration: 60 seconds');
    } catch (error) {
      toast.error('Could not access microphone');
      console.error(error);
    }
  };

  const stopVoiceRecording = () => {
    if (audioRecorder) {
      audioRecorder.stop();
      setIsRecording(false);
      clearInterval(durationIntervalRef.current);
      setRecordingDuration(0);
      toast.success('Voice recording saved');
    }
  };

  const deleteVoiceRecording = (field) => {
    setFormData(prev => ({
      ...prev,
      [`${field}Voice`]: null
    }));
    toast.success('Voice recording deleted');
  };
  
  const renderVoiceRecorder = (field, prompt) => {
    return (
      <div className="space-y-4">
        {/* Voice prompt */}
        <div className="bg-blue-50 rounded-xl p-4 text-blue-800">
          <p className="text-sm mb-2">{prompt}</p>
          <div className="text-xs text-blue-600">60-second maximum recording length</div>
        </div>
        
        {/* Voice recorder interface */}
        <div className="bg-gray-50 rounded-xl p-4">
          <p className="text-sm text-gray-600 mb-3 flex items-center">
            <Mic className="w-4 h-4 mr-1" /> Voice response (preferred by employers)
          </p>
          
          <div className="flex gap-2 items-center flex-wrap">
            {isRecording && recordingField === field ? (
              <button
                onClick={stopVoiceRecording}
                className="flex items-center gap-1 px-4 py-3 bg-red-100 text-red-600 rounded-lg hover:bg-red-200 transition-colors"
              >
                <Square className="w-4 h-4" />
                Stop Recording ({recordingDuration}s)
              </button>
            ) : (
              <button
                onClick={() => startVoiceRecording(field)}
                disabled={isRecording}
                className={`flex items-center gap-1 px-4 py-3 rounded-lg transition-colors ${
                  isRecording 
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed' 
                    : 'bg-blue-500 text-white hover:bg-blue-600'
                }`}
              >
                <Mic className="w-4 h-4" />
                Record Your Answer
              </button>
            )}
            
            {formData[`${field}Voice`] && (
              <>
                <audio 
                  src={audioPreviewUrls[`${field}Voice`]} 
                  controls 
                  className="h-10 flex-grow"
                />
                <button
                  onClick={() => deleteVoiceRecording(field)}
                  className="flex items-center gap-1 px-3 py-3 text-red-500 rounded-lg hover:bg-red-50"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const steps = [
    {
      id: 'introduction',
      icon: Shield,
      title: "Welcome to VarsityLink's Graduate Program Application",
      subtitle: "Your gateway to exclusive career opportunities",
      input: (
        <div className="space-y-6">
          <div className="bg-blue-50 rounded-xl p-4 text-blue-800">
            <div className="flex items-start mb-2">
              <Lock className="w-5 h-5 mr-2 flex-shrink-0 mt-1" />
              <div>
                <h3 className="font-medium text-blue-900">Your Information is Secure</h3>
                <p className="text-sm">All your data is encrypted and stored securely. We never share your personal information without your explicit consent.</p>
              </div>
            </div>
          </div>
          
          <div className="bg-green-50 rounded-xl p-4 text-green-800">
            <div className="flex items-start mb-2">
              <Building className="w-5 h-5 mr-2 flex-shrink-0 mt-1" />
              <div>
                <h3 className="font-medium text-green-900">Exclusive Corporate Partnerships</h3>
                <p className="text-sm">VarsityLink has established relationships with leading employers who trust our platform as the exclusive source for student talent. Your profile will be shared only with relevant potential employers.</p>
              </div>
            </div>
          </div>
          
          <div className="bg-purple-50 rounded-xl p-4 text-purple-800">
            <div className="flex items-start">
              <Mic className="w-5 h-5 mr-2 flex-shrink-0 mt-1" />
              <div>
                <h3 className="font-medium text-purple-900">Voice Responses Required</h3>
                <p className="text-sm">Employers value hearing your authentic voice. This application requires voice responses to help them better understand your communication style and personality.</p>
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
      id: 'faculty',
      icon: GraduationCap,
      title: "What's Your Academic Focus?",
      subtitle: "Select the faculty that best aligns with your academic passion",
      input: (
        <div className="grid grid-cols-2 gap-3">
          {faculties.map((faculty) => (
            <button
              key={faculty}
              onClick={() => setFormData({...formData, faculty})}
              className={`p-4 rounded-xl border-2 transition-all ${
                formData.faculty === faculty
                  ? 'border-blue-500 bg-blue-50 text-blue-700'
                  : 'border-gray-200 hover:border-blue-200 hover:bg-blue-50'
              }`}
            >
              {faculty}
            </button>
          ))}
        </div>
      )
    },
    {
      id: 'career-interests',
      icon: Briefcase,
      title: "What Drives Your Professional Passion?",
      subtitle: "Select up to 3 career aspirations that resonate with you",
      input: (
        <div className="grid grid-cols-2 gap-3">
          {careerOptions.map((option) => (
            <button
              key={option}
              onClick={() => {
                const currentInterests = formData.careerInterests;
                const newInterests = currentInterests.includes(option)
                  ? currentInterests.filter(item => item !== option)
                  : (currentInterests.length < 3 ? [...currentInterests, option] : currentInterests);
                
                setFormData({...formData, careerInterests: newInterests});
              }}
              className={`p-4 rounded-xl border-2 transition-all ${
                formData.careerInterests.includes(option)
                  ? 'border-green-500 bg-green-50 text-green-700'
                  : 'border-gray-200 hover:border-green-200 hover:bg-green-50'
              }`}
            >
              {option}
            </button>
          ))}
        </div>
      )
    },
    {
      id: 'industries',
      icon: Globe,
      title: "Which Industries Excite You?",
      subtitle: "Select up to 3 industries you're passionate about",
      input: (
        <div className="grid grid-cols-2 gap-3">
          {industries.map((industry) => (
            <button
              key={industry}
              onClick={() => {
                const currentIndustries = formData.industriesOfInterest;
                const newIndustries = currentIndustries.includes(industry)
                  ? currentIndustries.filter(item => item !== industry)
                  : (currentIndustries.length < 3 ? [...currentIndustries, industry] : currentIndustries);
                
                setFormData({...formData, industriesOfInterest: newIndustries});
              }}
              className={`p-4 rounded-xl border-2 transition-all ${
                formData.industriesOfInterest.includes(industry)
                  ? 'border-purple-500 bg-purple-50 text-purple-700'
                  : 'border-gray-200 hover:border-purple-200 hover:bg-purple-50'
              }`}
            >
              {industry}
            </button>
          ))}
        </div>
      )
    },
    {
      id: 'international-exposure',
      icon: Globe,
      title: "International Perspective",
      subtitle: "Tell us about your global mindset (voice required)",
      input: renderVoiceRecorder(
        'internationalExposure', 
        "Share a brief perspective on how you view global opportunities and cross-cultural experiences. How do international experiences shape your professional outlook?"
      )
    },
    {
      id: 'career-goal',
      icon: Target,
      title: "Your Realistic Career Goal",
      subtitle: "What professional milestone do you aim to achieve? (voice required)",
      input: renderVoiceRecorder(
        'careerGoal', 
        "Describe a specific, achievable career goal you're working towards in the next 5-7 years. What steps are you taking to reach this goal?"
      )
    },
    {
      id: 'innovation-potential',
      icon: Lightbulb,
      title: "Your Innovation Potential",
      subtitle: "How do you approach solving complex problems? (voice required)",
      input: renderVoiceRecorder(
        'innovationPotential', 
        "Share an example of how you've creatively approached a challenging problem. What was your thought process and how did you implement your solution?"
      )
    },
    {
      id: 'cv-upload',
      icon: FileUp,
      title: "Upload Your CV",
      subtitle: "Share your professional journey",
      input: (
        <div className="space-y-4">
          <div 
            className="border-2 border-dashed border-gray-200 rounded-xl p-8 text-center hover:border-green-200 transition-all cursor-pointer"
            onClick={() => document.getElementById('cv-upload').click()}
          >
            <input
              type="file"
              accept=".pdf,.doc,.docx"
              onChange={(e) => {
                const file = e.target.files[0];
                if (file && ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(file.type)) {
                  setFormData({...formData, cvFile: file});
                } else if (file) {
                  toast.error("Please upload PDF or DOC/DOCX files only");
                }
              }}
              className="hidden"
              id="cv-upload"
            />
            <div>
              <FileUp className="w-12 h-12 text-gray-400 mx-auto mb-3" />
              <p className="text-gray-600 font-medium">
                {formData.cvFile ? formData.cvFile.name : "Click to upload CV (PDF/DOC)"}
              </p>
            </div>
          </div>
          {formData.cvFile && (
            <p className="text-sm text-green-600 flex items-center gap-2">
              <Check className="w-4 h-4" />
              File selected: {formData.cvFile.name}
            </p>
          )}
        </div>
      )
    }
  ];

  const canProgress = () => {
    switch(currentStep) {
      case 0: return true; // Introduction step, always can progress
      case 1: return formData.faculty;
      case 2: return formData.careerInterests.length > 0;
      case 3: return formData.industriesOfInterest.length > 0;
      case 4: return formData.internationalExposureVoice !== null;
      case 5: return formData.careerGoalVoice !== null;
      case 6: return formData.innovationPotentialVoice !== null;
      case 7: return formData.cvFile !== null;
      default: return false;
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true); // Set loading state to true when submission starts
      
      let cvUrl = null;
      let voiceUrls = {};
  
      // Upload CV to Firebase Storage
      if (formData.cvFile) {
        const fileRef = ref(storage, `graduate_applications/${auth.currentUser.uid}/${formData.cvFile.name}`);
        await uploadBytes(fileRef, formData.cvFile);
        cvUrl = await getDownloadURL(fileRef);
      }
      
      // Upload voice notes
      for (const field of ['internationalExposureVoice', 'careerGoalVoice', 'innovationPotentialVoice']) {
        if (formData[field]) {
          const fileName = `${field}_${Date.now()}.wav`;
          const fileRef = ref(storage, `graduate_applications/${auth.currentUser.uid}/voice_notes/${fileName}`);
          await uploadBytes(fileRef, formData[field]);
          voiceUrls[field] = await getDownloadURL(fileRef);
        }
      }
  
      // Save application data in Firestore
      await addDoc(collection(db, "graduate_applications"), {
        faculty: formData.faculty,
        careerInterests: formData.careerInterests,
        industriesOfInterest: formData.industriesOfInterest,
        voiceUrls,
        cvUrl,
        userId: auth.currentUser.uid,
        submittedAt: new Date()
      });
      
      toast.success("Application submitted successfully!");
      onClose(); // Close the modal after submission
    } catch (error) {
      console.error("Error submitting graduate program application:", error);
      toast.error("There was an error submitting your application. Please try again.");
    } finally {
      setIsSubmitting(false); // Reset loading state regardless of success or failure
    }
  };
  if (!isOpen) return null;

  const CurrentIcon = steps[currentStep].icon;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[60] p-4">
      <div className="bg-white rounded-2xl w-full max-w-lg relative overflow-hidden max-h-[90vh] overflow-y-auto">
        <div className="absolute top-0 left-0 right-0 h-1 bg-gray-100">
          <div 
            className="h-full bg-blue-500 transition-all duration-300"
            style={{ width: `${((currentStep + 1) / steps.length) * 100}%` }}
          />
        </div>

        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 p-2 rounded-full hover:bg-gray-100 z-10"
          aria-label="Close modal"
        >
          <X className="w-5 h-5" />
        </button>

        <div className="p-6">
          <div className="text-center mb-6">
            <div className="bg-blue-50 p-3 rounded-xl inline-block mb-4">
              <CurrentIcon className="w-8 h-8 text-blue-500" />
            </div>
            <h2 className="text-xl font-bold text-gray-800">
              {steps[currentStep].title}
            </h2>
            <p className="text-sm text-gray-600 mt-1">
              {steps[currentStep].subtitle}
            </p>
          </div>

          <div className="mb-8">
            {steps[currentStep].input}
          </div>

          <div className="flex gap-3 justify-between">
            <button
              onClick={() => setCurrentStep(prev => prev - 1)}
              disabled={currentStep === 0}
              className={`px-4 py-3 rounded-xl flex items-center gap-2 ${
                currentStep === 0
                  ? 'opacity-0 pointer-events-none'
                  : 'text-gray-600 hover:bg-gray-50'
              }`}
            >
              <ArrowLeft className="w-4 h-4" />
              Back
            </button>
            
            {currentStep === steps.length - 1 ? (
  <button
    onClick={handleSubmit}
    disabled={!canProgress() || isSubmitting}
    className={`px-6 py-3 bg-blue-500 text-white rounded-xl hover:bg-blue-600 transition-colors flex items-center gap-2 ${
      !canProgress() || isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
    }`}
  >
    {isSubmitting ? (
      <>
        <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        Submitting...
      </>
    ) : (
      <>
        Submit Application
        <Check className="w-4 h-4" />
      </>
    )}
  </button>
) : (
  <button
    onClick={() => setCurrentStep(prev => prev + 1)}
    disabled={!canProgress()}
    className={`px-6 py-3 bg-blue-500 text-white rounded-xl hover:bg-blue-600 transition-colors flex items-center gap-2 ${
      !canProgress() ? 'opacity-50 cursor-not-allowed' : ''
    }`}
  >
    Next
    <ArrowRight className="w-4 h-4" />
  </button>
)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraduateProgramApplicationFlow;