import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MessageCircle, Settings, ChevronRight, UserPlus, Gamepad, Mountain, Beer, Volleyball, X, LogOut } from 'lucide-react';
import { fetchGroupMembers, sendGroupConnectionInvitation } from '../../firebase/groupService';
import { createGroupConnectionChatRoom } from '../../firebase/messagingService';
import { auth, db } from '../../firebase/firebase';
import { 
  arrayRemove, 
  arrayUnion, 
  doc, 
  updateDoc, 
  collection, 
  getDocs,
  addDoc,
  serverTimestamp 
} from 'firebase/firestore';
import { fetchUserGroups } from '../../firebase/groupService'; // Added import for fetchUserGroups
import { MemberSelectionModal } from './MemberSelectionModal';
import toast from 'react-hot-toast';

export const GroupCard = ({ 
  group, 
  isUserMember, 
  onGroupUpdate 
}) => {
 
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [memberProfiles, setMemberProfiles] = useState([]);
  const [showBubbleMenu, setShowBubbleMenu] = useState(false);
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [showExitConfirm, setShowExitConfirm] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const navigate = useNavigate();

  // Load potential members when the component mounts
  useEffect(() => {
    const loadMemberProfiles = async () => {
      const profiles = await fetchGroupMembers(group.id);
      setMemberProfiles(profiles);
    };

    const loadUsers = async () => {
      try {
        const usersRef = collection(db, 'profiles');
        const snapshot = await getDocs(usersRef);
        const allUsers = snapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(user => 
            user.id !== auth.currentUser.uid && 
            !group.members?.includes(user.id)
          );
        setUsers(allUsers);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    loadMemberProfiles();
    loadUsers();
  }, [group.id]);

  const handleToggleMember = (userId) => {
    setSelectedMembers(prev => 
      prev.includes(userId)
        ? prev.filter(id => id !== userId)
        : [...prev, userId]
    );
  };

  const handleAddMembers = async () => {
    setLoading(true);
    try {
      const groupRef = doc(db, 'groups', group.id);
      await updateDoc(groupRef, {
        members: arrayUnion(...selectedMembers),
        memberCount: (group.memberCount || 0) + selectedMembers.length
      });
      
      if (onGroupUpdate) {
        onGroupUpdate();
      }
      
      setShowMemberModal(false);
      setSelectedMembers([]);
    } catch (error) {
      console.error('Error adding members:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleExitGroup = async () => {
    setLoading(true);
    try {
      const userId = auth.currentUser.uid;
      const groupRef = doc(db, 'groups', group.id);
      
      // Remove user from the group's members array
      await updateDoc(groupRef, {
        members: arrayRemove(userId),
        memberCount: (group.memberCount || 1) - 1
      });
      
      // Optional: Update user's groups if you have such a collection
      const userRef = doc(db, 'profiles', userId);
      await updateDoc(userRef, {
        groups: arrayRemove(group.id)
      });
      
      // Trigger any necessary updates
      if (onGroupUpdate) {
        onGroupUpdate();
      }
      
      // Navigate away from the group
      navigate('/groups');
    } catch (error) {
      console.error('Error leaving group:', error);
      toast.error('Failed to exit group');
    } finally {
      setLoading(false);
      setShowExitConfirm(false);
    }
  };

  const handleGroupConnectionInvite = async (groupId, activityType) => {
    try {
      // Get the current user's first group (assuming they're part of at least one group)
      const userGroups = await fetchUserGroups(auth.currentUser.uid);
      const initiatingGroupId = userGroups[0].id;

      // Send invitation to all members of the group
      const invitationId = await sendGroupConnectionInvitation(
        initiatingGroupId, 
        groupId, 
        auth.currentUser.uid, 
        activityType
      );

      // Create a notification for group members
      await Promise.all(group.members.map(async (memberId) => {
        await addDoc(collection(db, 'notifications'), {
          userId: memberId,
          type: 'group_connection_invitation',
          groupId: groupId,
          invitationId: invitationId,
          activityType: activityType,
          createdAt: serverTimestamp(),
          read: false
        });
      }));

      toast.success(`Invitation sent for ${activityType}!`);
    } catch (error) {
      console.error('Error sending group invitation:', error);
      toast.error('Failed to send invitation');
    }
  };
  
  const handleCardClick = () => {
    if (isUserMember) {
      navigate(`/group-chat/${group.id}`);
    }
  };

  const handleButtonClick = (e, action) => {
    e.stopPropagation();
    action();
  };

  const activityOptions = [
    {
      icon: Gamepad,
      label: 'Games Night',
      action: 'games',
      color: 'bg-purple-500',
      description: 'Board games & video games'
    },
    {
      icon: Mountain,
      label: 'Hiking',
      action: 'hiking',
      color: 'bg-green-500',
      description: 'Trail exploration'
    },
    {
      icon: Volleyball,
      label: 'Sports',
      action: 'sports',
      color: 'bg-blue-500',
      description: 'Team challenges'
    },
    {
      icon: Beer,
      label: 'Chillers',
      action: 'chillers',
      color: 'bg-amber-500',
      description: 'Drinks & hangouts'
    },
  ];

  return (
    <>
      <div
        onClick={handleCardClick}
        className={`
          ${isUserMember ? 'cursor-pointer' : ''}
          bg-gray-50 hover:bg-gray-100 rounded-xl shadow-sm overflow-visible 
          transition-all duration-200 ease-in-out relative
          transform hover:translate-y-[-2px] hover:shadow-md
        `}
      >
        <div className="p-4">
          <div className="flex items-start justify-between">
            <div className="flex items-center space-x-3">
              <div className="p-3 rounded-xl bg-white text-xl shadow-sm">
                {group.emoji || '👥'}
              </div>
              <div>
                <h3 className="font-semibold text-lg">{group.name}</h3>
                <p className="text-sm text-gray-500">
                  {group.memberCount || 0}/5 members
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              {isUserMember ? (
                <>
                  <button
                    onClick={(e) => handleButtonClick(e, () => navigate(`/group-chat/${group.id}`))}
                    className="p-2 rounded-full hover:bg-white transition-colors"
                  >
                    <MessageCircle className="w-5 h-5 text-gray-600" />
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowMemberModal(true);
                    }}
                    className="p-2 rounded-full hover:bg-white transition-colors"
                  >
                    <UserPlus className="w-5 h-5 text-gray-600" />
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowExitConfirm(true);
                    }}
                    className="p-2 rounded-full hover:bg-white transition-colors"
                  >
                    <LogOut className="w-5 h-5 text-red-600" />
                  </button>
                </>
              ) : (
                <div className="relative">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowBubbleMenu(!showBubbleMenu);
                    }}
                    className={`
                      flex items-center space-x-2 px-4 py-2 rounded-full
                      ${showBubbleMenu ? 'bg-gray-200' : 'bg-white'}
                      border border-gray-200 text-gray-700 hover:bg-gray-50
                      transition-colors duration-200 shadow-sm
                    `}
                  >
                    {showBubbleMenu ? (
                      <>
                        <X className="w-4 h-4" />
                        <span className="text-sm font-medium">Close</span>
                      </>
                    ) : (
                      <>
                        <UserPlus className="w-4 h-4" />
                        <span className="text-sm font-medium">Connect</span>
                      </>
                    )}
                  </button>

                  {/* Activity Bubbles */}
                  <div className={`
                    absolute right-0 top-12 flex gap-3
                    transition-all duration-300 origin-top-right z-50
                    ${showBubbleMenu ? 'scale-100 opacity-100' : 'scale-0 opacity-0'}
                  `}>
                    {activityOptions.map((option, index) => (
                      <div
                        key={option.action}
                        className="relative group"
                      >
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleGroupConnectionInvite(group.id, option.action);
                            setShowBubbleMenu(false);
                          }}
                          className={`
                            flex flex-col items-center justify-center
                            w-16 h-16 rounded-full ${option.color} text-white
                            transform transition-all duration-300
                            hover:scale-110 shadow-lg relative
                            ${showBubbleMenu ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}
                          `}
                          style={{
                            transitionDelay: `${index * 50}ms`
                          }}
                        >
                          <option.icon className="w-5 h-5 mb-1" />
                          <span className="text-xs font-medium">{option.label}</span>
                        </button>
                        
                        {/* Tooltip */}
                        <div className={`
                          absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2
                          px-3 py-1.5 bg-gray-800 text-white text-xs rounded-lg
                          opacity-0 group-hover:opacity-100 transition-opacity duration-200
                          whitespace-nowrap pointer-events-none
                        `}>
                          {option.description}
                          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full
                            border-4 border-transparent border-t-gray-800"/>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="mt-3 flex flex-wrap gap-2">
            {group.tags?.map((tag, index) => (
              <span
                key={index}
                className="text-xs px-2 py-1 rounded-full bg-white text-gray-600"
              >
                {tag}
              </span>
            ))}
          </div>
          <div className="mt-4 flex items-center justify-between">
            <div className="flex -space-x-2">
              {memberProfiles.slice(0, 3).map((profile, i) => (
                <img
                  key={i}
                  src={profile.photos?.[0] || '/default-avatar.png'}
                  alt={`${profile.basicInfo?.name || 'Member'}`}
                  className="w-8 h-8 rounded-full border-2 border-white"
                />
              ))}
            </div>
            {isUserMember && (
              <ChevronRight className="w-5 h-5 text-gray-400 transition-transform group-hover:translate-x-1" />
            )}
          </div>
        </div>
      </div>

      {showMemberModal && (
        <MemberSelectionModal 
          users={users}
          selectedMembers={selectedMembers}
          onToggleMember={handleToggleMember}
          onClose={() => {
            setShowMemberModal(false);
            setSelectedMembers([]);
          }}
          onSave={handleAddMembers}
        />
      )}

      {showExitConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 max-w-sm w-full">
            <div className="text-center">
              <div className="flex justify-center mb-4">
                <LogOut className="w-12 h-12 text-red-600" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Exit Group?</h3>
              <p className="text-gray-600 mb-4">
                Are you sure you want to exit this group? This action cannot be undone.
              </p>
              <div className="flex space-x-3 justify-center">
                <button
                  onClick={() => setShowExitConfirm(false)}
                  className="px-4 py-2 border rounded-lg hover:bg-gray-50"
                  disabled={loading}
                >
                  Cancel
                </button>
                <button
                  onClick={handleExitGroup}
                  className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
                  disabled={loading}
                >
                  {loading ? 'Exiting...' : 'Exit Group'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};