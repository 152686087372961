import React, { useState, useEffect } from 'react';
import { 
  X, MapPin, Building2, GraduationCap, Users, 
  ScrollText, Heart, Camera, ChevronLeft, ChevronRight,
  Languages, User, Goal, Sparkles, Medal, Send, Handshake,
  Coffee, Utensils, Book, Lightbulb, ClipboardList, FlaskConical,
  Briefcase, Dumbbell, Activity, Music, PartyPopper, Bike,
  Palette, Film, Globe, IceCream, AtSign
} from 'lucide-react';
import MeetupOption from './MeetupOption';

const UserProfileModal = ({ isOpen, onClose, user, onSendRequest, hasRequestBeenSent }) => {
  const [step, setStep] = useState(0);
  const [expandedImage, setExpandedImage] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [showMeetupOptions, setShowMeetupOptions] = useState(false);
  const [showConnectEffect, setShowConnectEffect] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);

  // Define meetup options and categories
  const meetupOptions = [
    { icon: Coffee, label: 'Coffee Chat', category: 'social' },
    { icon: Utensils, label: 'Foodie Adventure', category: 'social' },
    { icon: Book, label: 'Study Session', category: 'academic' },
    { icon: Users, label: 'Group Tutoring', category: 'academic' },
    { icon: Lightbulb, label: 'Brainstorming Session', category: 'academic' },
    { icon: ClipboardList, label: 'Assignment Help', category: 'academic' },
    { icon: FlaskConical, label: 'Lab Partners', category: 'academic' },
    { icon: Briefcase, label: 'Career Workshop', category: 'professional' },
    { icon: Dumbbell, label: 'Gym Buddy', category: 'fitness' },
    { icon: Activity, label: 'Morning Run', category: 'fitness' },
    { icon: Music, label: 'Music & Vibes', category: 'entertainment' },
    { icon: PartyPopper, label: 'Party Time', category: 'social' },
    { icon: Camera, label: 'Photo Walk', category: 'hobby' },
    { icon: Bike, label: 'Cycling Group', category: 'fitness' },
    { icon: Palette, label: 'Art Gallery Visit', category: 'culture' },
    { icon: Film, label: 'Movie Night', category: 'entertainment' },
    { icon: Handshake, label: 'Networking Meetup', category: 'professional' },
    { icon: Globe, label: 'Language Exchange', category: 'academic' },
    { icon: IceCream, label: 'Ice Cream Date', category: 'social' },
  ];

  const categories = [
    { id: 'all', label: 'All', icon: Sparkles },
    { id: 'social', label: 'Social', icon: Users },
    { id: 'academic', label: 'Academic', icon: Book },
    { id: 'professional', label: 'Career', icon: Briefcase },
    { id: 'entertainment', label: 'Fun', icon: PartyPopper },
    { id: 'culture', label: 'Culture', icon: Palette },
    { id: 'fitness', label: 'Fitness', icon: Dumbbell },
    { id: 'hobby', label: 'Hobbies', icon: Camera }
  ];

  const filteredOptions = selectedCategory === 'all' 
    ? meetupOptions 
    : meetupOptions.filter(option => option.category === selectedCategory);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      setStep(0);
      setShowMeetupOptions(false);
    } else {
      document.body.style.overflow = '';
    }

    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscape);
    return () => {
      window.removeEventListener('keydown', handleEscape);
      document.body.style.overflow = '';
    };
  }, [isOpen, onClose]);

  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleSendRequest = async (userId, option) => {
    if (isConnecting) return;
    
    try {
      setIsConnecting(true);
      setShowConnectEffect(true);
      await onSendRequest(userId, option);
      
      // Reset connect effect after animation
      setTimeout(() => {
        setShowConnectEffect(false);
      }, 1000);
    } finally {
      setIsConnecting(false);
    }
  };

  if (!isOpen) return null;

  // Define tabs based on available data
  const tabs = [
    { 
      id: 'about', 
      label: 'About', 
      icon: ScrollText, 
      show: true 
    },
    { 
      id: 'details', 
      label: 'Details', 
      icon: User,
      show: user?.bio?.personalities?.length > 0 || user?.bio?.relationshipGoal || user?.languages?.length > 0
    },
    { 
      id: 'photos', 
      label: 'Photos', 
      icon: Camera, 
      show: user?.photos?.length > 0 
    }
  ].filter(tab => tab.show);

  const nextStep = () => setStep(prev => Math.min(prev + 1, tabs.length - 1));
  const prevStep = () => setStep(prev => Math.max(prev - 1, 0));

  return (
    <div 
      className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-[60] backdrop-blur-sm"
      onClick={handleOutsideClick}
    >
      {/* Connect effect overlay */}
      {showConnectEffect && (
        <div className="absolute inset-0 z-70 flex items-center justify-center bg-black/30">
          <Handshake className="w-32 h-32 text-pink-500 animate-ping" />
        </div>
      )}
      
      <div className="bg-white rounded-3xl w-full max-w-lg max-h-[85vh] flex flex-col relative overflow-hidden shadow-lg">
        {/* Profile Image Header */}
        <div className="relative w-full h-64">
          {user.photos?.[0] ? (
            <img
              src={user.photos[0]}
              alt={user.basicInfo?.name}
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="w-full h-full bg-gray-200 flex items-center justify-center">
              <User className="w-12 h-12 text-gray-400" />
            </div>
          )}
          
          {/* Close Button */}
          <button 
            onClick={onClose}
            className="absolute top-4 right-4 p-2 rounded-full bg-black/20 hover:bg-black/30 text-white transition-colors z-10"
          >
            <X className="w-5 h-5" />
          </button>
          
          {/* Gradient Overlay */}
          <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 via-black/50 to-transparent p-4">
            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <h2 className="text-white text-2xl font-semibold">
                  {user.basicInfo?.name}
                </h2>
                <div className="absolute bottom-2 right-2">
                  {user.basicInfo?.username && (
                    <span className="inline-flex items-center gap-1 text-gray-400 text-sm 
                                 bg-black/20 backdrop-blur-sm px-3 py-1 rounded-full
                                 transition-all duration-300 hover:bg-gray-500/20
                                 hover:text-gray-200 cursor-pointer">
                      <AtSign className="w-4 h-4 transition-transform group-hover:rotate-12" />
                      <span className="font-sans tracking-wide">{user.basicInfo.username}</span>
                    </span>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap items-center gap-2">
                {user.academic?.degree && (
                  <div className="flex items-center text-gray-200 text-sm bg-black/30 px-2 py-1 rounded-full">
                    <GraduationCap className="w-4 h-4 mr-1" />
                    <span>{user.academic.yearOfStudy} {user.academic.degree}</span>
                  </div>
                )}
                {user.bio?.personalities?.slice(0, 2).map((personality, index) => (
                  <span key={index} className="text-xs bg-green-500/80 text-white px-2 py-1 rounded-full">
                    {personality}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Connect Button - Desktop */}
        <div className="absolute top-4 right-16">
          {hasRequestBeenSent ? (
            <div className="bg-green-400/20 backdrop-blur-sm text-white rounded-lg py-1.5 px-3 flex items-center space-x-2">
              <Send className="w-4 h-4" />
              <span className="text-sm font-medium">Request sent</span>
            </div>
          ) : (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setShowMeetupOptions(prev => !prev);
              }}
              className="bg-white/20 hover:bg-white/30 backdrop-blur-sm text-white rounded-lg py-1.5 px-3 flex items-center space-x-2 transition-all"
            >
              <Handshake className="w-4 h-4" />
              <span className="text-sm font-medium">Connect</span>
            </button>
          )}
        </div>

        {/* Navigation Pills */}
        <div className="flex-shrink-0 px-6 pt-4">
          <div className="flex justify-between bg-gray-50 rounded-xl p-1">
            {tabs.map((tab, index) => (
              <button
                key={tab.id}
                onClick={() => setStep(index)}
                className={`flex-1 flex items-center justify-center p-2 rounded-lg transition-all
                  ${step === index ? 'bg-white text-pink-500 shadow-sm' : 'text-gray-500 hover:text-gray-700'}`}
              >
                <tab.icon className="w-5 h-5" />
                <span className="hidden sm:ml-2 sm:inline">{tab.label}</span>
              </button>
            ))}
          </div>
        </div>

        {/* Meetup Options Dropdown */}
        {showMeetupOptions && !hasRequestBeenSent && (
          <div className="absolute right-6 top-24 z-10 w-64 bg-white rounded-xl shadow-lg border border-gray-100 overflow-hidden">
            <div className="p-3 border-b border-gray-100">
              <div className="flex gap-2 overflow-x-auto pb-2 hide-scrollbar">
                {categories.map((category) => (
                  <button
                    key={category.id}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedCategory(category.id);
                    }}
                    className={`px-2 py-1 rounded-lg text-xs font-medium whitespace-nowrap flex items-center gap-1
                      ${selectedCategory === category.id
                        ? 'bg-pink-500 text-white'
                        : 'bg-gray-50 text-gray-600 hover:bg-gray-100'
                      } transition-all duration-200`}
                  >
                    <category.icon className="w-3 h-3" />
                    {category.label}
                  </button>
                ))}
              </div>
            </div>
            
            <div className="p-3 max-h-64 overflow-y-auto">
              <div className="grid gap-2">
                {filteredOptions.map((option) => (
                  <MeetupOption
                    key={option.label}
                    icon={option.icon}
                    label={option.label}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSendRequest(user.id, option.label);
                      setShowMeetupOptions(false);
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Scrollable Content Area */}
        <div className="flex-1 overflow-y-auto px-6 py-4">
          {step === 0 && (
            <div className="space-y-4">
              {user.academic?.university && (
                <div className="flex items-center space-x-2">
                  <Building2 className="w-5 h-5 text-gray-500" />
                  <span className="text-sm text-gray-600">
                    {user.academic.university}
                  </span>
                </div>
              )}

              <div className="space-y-3">
                {(user.bio?.shortBio || user.bio?.longBio) && (
                  <p className="text-gray-600 leading-relaxed">
                    {user.bio.longBio || user.bio.shortBio}
                  </p>
                )}
                
                {user.academic?.residence && (
                  <div className="flex items-center text-green-600 text-sm bg-green-50 p-2 rounded-lg">
                    <MapPin className="w-4 h-4 mr-1" />
                    <span>Currently at: {user.academic.residence}</span>
                  </div>
                )}
              </div>

              {user.bio?.personalities?.length > 0 && (
                <div className="space-y-2 pt-2">
                  <h3 className="font-medium text-gray-700 flex items-center gap-2">
                    <Heart className="w-4 h-4 text-pink-500" /> Personality
                  </h3>
                  <div className="flex flex-wrap gap-2">
                    {user.bio.personalities.map((personality, index) => (
                      <span key={index} className="px-3 py-1 bg-green-50 text-grey-600 rounded-full text-sm hover:bg-green-100 transition-colors">
                        {personality}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}

          {step === 1 && (
            <div className="space-y-4">
              {user.bio?.personalities?.length > 0 && (
                <div className="space-y-2">
                  <h3 className="font-medium text-gray-700 flex items-center gap-2">
                    <Heart className="w-4 h-4 text-pink-500" /> Personality
                  </h3>
                  <div className="flex flex-wrap gap-2">
                    {user.bio.personalities.map((personality, index) => (
                      <span key={index} className="px-3 py-1 bg-gray-50 rounded-full text-gray-600 text-sm hover:bg-gray-100 transition-colors">
                        {personality}
                      </span>
                    ))}
                  </div>
                </div>
              )}

              {user.languages?.length > 0 && (
                <div className="space-y-2 pt-2">
                  <h3 className="font-medium text-gray-700 flex items-center gap-2">
                    <Languages className="w-4 h-4 text-pink-500" /> Languages
                  </h3>
                  <div className="flex items-center space-x-2 p-3 bg-gray-50 rounded-xl text-gray-600 hover:bg-gray-100 transition-colors">
                    <span className="text-sm">{user.languages.join(', ')}</span>
                  </div>
                </div>
              )}

              {user.bio?.relationshipGoal && (
                <div className="space-y-2 pt-2">
                  <h3 className="font-medium text-gray-700 flex items-center gap-2">
                    <Goal className="w-4 h-4 text-pink-500" /> Looking For
                  </h3>
                  <div className="flex items-center space-x-2 p-3 bg-gray-50 rounded-xl text-gray-600 hover:bg-gray-100 transition-colors">
                    <span className="text-sm">{user.bio.relationshipGoal}</span>
                  </div>
                </div>
              )}
              
              {(user.basicInfo?.gender || user.basicInfo?.interestedIn?.length > 0) && (
                <div className="space-y-2 pt-2">
                  <h3 className="font-medium text-gray-700 flex items-center gap-2">
                    <User className="w-4 h-4 text-pink-500" /> Basic Info
                  </h3>
                  <div className="bg-gray-50 p-3 rounded-xl space-y-2 text-sm">
                    {user.basicInfo.gender && (
                      <p className="text-gray-600">Gender: {user.basicInfo.gender}</p>
                    )}
                    {user.basicInfo.interestedIn?.length > 0 && (
                      <p className="text-gray-600">Interested in: {user.basicInfo.interestedIn.join(', ')}</p>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          {step === 2 && user.photos?.length > 0 && (
            <div className="grid grid-cols-2 gap-4">
              {user.photos.map((photo, index) => (
                <div key={index} className="relative group">
                  <img
                    src={photo}
                    alt={`${user.basicInfo?.name || 'User'}'s photo ${index + 1}`}
                    className="w-full aspect-square object-cover rounded-xl cursor-pointer hover:opacity-95 transition-opacity"
                    onClick={() => setExpandedImage(photo)}
                  />
                  <div className="absolute inset-0 bg-black/0 group-hover:bg-black/10 rounded-xl transition-colors flex items-center justify-center">
                    <div className="opacity-0 group-hover:opacity-100 transition-opacity">
                      <button 
                        onClick={(e) => {
                          e.stopPropagation();
                          setExpandedImage(photo);
                        }}
                        className="bg-white/80 p-2 rounded-full text-pink-500"
                      >
                        <Camera className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        
        {/* Action Buttons / Connect Button - Mobile */}
        <div className="flex-shrink-0 p-4 border-t">
          {hasRequestBeenSent ? (
            <div className="w-full bg-green-100 text-green-600 font-medium py-3 px-4 rounded-xl flex items-center justify-center gap-2">
              <Send className="w-4 h-4" />
              Request Sent
            </div>
          ) : (
            <button
              onClick={() => setShowMeetupOptions(prev => !prev)}
              className={`w-full bg-green-500 hover:bg-pink-600 text-white font-medium py-3 px-4 rounded-xl 
                         flex items-center justify-center gap-2 transition-colors
                         ${isConnecting ? 'opacity-80' : ''}`}
              disabled={isConnecting}
            >
              <Handshake className="w-4 h-4" />
              Connect
            </button>
          )}
        </div>

        {/* Meetup Options for Mobile */}
        {showMeetupOptions && !hasRequestBeenSent && (
          <div className="fixed inset-0 bg-black/50 z-[70] sm:hidden flex items-end">
            <div className="bg-white rounded-t-2xl w-full max-h-[70vh] overflow-hidden flex flex-col">
              <div className="p-4 border-b border-gray-100 flex justify-between items-center">
                <h3 className="font-semibold text-gray-900">Connect with {user.basicInfo?.name}</h3>
                <button
                  onClick={() => setShowMeetupOptions(false)}
                  className="p-1"
                >
                  <X className="w-5 h-5 text-gray-500" />
                </button>
              </div>
              
              <div className="p-4 border-b border-gray-100">
                <div className="flex gap-2 overflow-x-auto pb-2 hide-scrollbar">
                  {categories.map((category) => (
                    <button
                      key={category.id}
                      onClick={() => setSelectedCategory(category.id)}
                      className={`px-3 py-2 rounded-lg text-xs font-medium whitespace-nowrap flex items-center gap-1
                        ${selectedCategory === category.id
                          ? 'bg-pink-500 text-white'
                          : 'bg-gray-50 text-gray-600 hover:bg-gray-100'
                        } transition-all duration-200`}
                    >
                      <category.icon className="w-3 h-3" />
                      {category.label}
                    </button>
                  ))}
                </div>
              </div>
              
              <div className="p-4 overflow-y-auto flex-1">
                <div className="grid grid-cols-2 gap-2">
                  {filteredOptions.map((option) => (
                    <MeetupOption
                      key={option.label}
                      icon={option.icon}
                      label={option.label}
                      onClick={() => {
                        handleSendRequest(user.id, option.label);
                        setShowMeetupOptions(false);
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Mobile Navigation Dots */}
        {tabs.length > 1 && (
          <div className="flex-shrink-0 pb-4 sm:hidden">
            <div className="flex justify-center space-x-1">
              {tabs.map((_, index) => (
                <div
                  key={index}
                  className={`w-2 h-2 rounded-full ${
                    index === step ? 'bg-pink-500' : 'bg-gray-300'
                  }`}
                />
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Expanded Image Modal */}
      {expandedImage && (
        <div 
          className="fixed inset-0 bg-black/90 z-[70] flex items-center justify-center p-4"
          onClick={() => setExpandedImage(null)}
        >
          <button 
            onClick={(e) => {
              e.stopPropagation();
              setExpandedImage(null);
            }}
            className="absolute top-4 right-4 p-2 rounded-full bg-white/20 hover:bg-white/30 text-white transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
          <img
            src={expandedImage}
            alt="Expanded"
            className="max-w-full max-h-[90vh] object-contain"
          />
        </div>
      )}
    </div>
  );
};

export default UserProfileModal;