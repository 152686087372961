import { 
  collection, 
  addDoc, 
  updateDoc,
  deleteDoc,
  doc, 
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  serverTimestamp,
  arrayUnion,
  arrayRemove,
  increment,
  onSnapshot, 
  setDoc,
  startAfter,
  limit  
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { db, storage, auth } from './firebase';
import { sendNotification } from './notificationService';

const POSTS_COLLECTION = 'posts';
const COMMENTS_COLLECTION = 'comments';
const NOTIFICATIONS_COLLECTION = 'notifications';
const PROFILES_COLLECTION = 'profiles';
const VIEWED_PROFILES_COLLECTION = 'viewedProfiles';

const POSTS_PER_PAGE = 5; 

export const addViewedProfile = async (userId, viewedProfileId) => {
  try {
    const viewedProfilesRef = doc(db, VIEWED_PROFILES_COLLECTION, userId);
    
    // Try to send notification but don't let it block the profile viewing
    try {
      const viewerProfile = await getDoc(doc(db, PROFILES_COLLECTION, userId));
      if (viewerProfile.exists()) {
        // Use await but wrap in try-catch to prevent blocking
        await sendNotification(viewedProfileId, {
          title: 'Profile View',
          body: `${viewerProfile.data().basicInfo.name}  appreciated your profile`,
          clickAction: '/profile',
          data: {
            type: 'profile_view',
            viewerId: userId
          }
        }).catch(() => null); // Catch and ignore notification errors
      }
    } catch (notificationError) {
      // Log but continue with profile viewing
      console.info('Failed to send profile view notification:', notificationError);
    }

    // Always update the viewed profiles collection
    await setDoc(viewedProfilesRef, {
      profileIds: arrayUnion(viewedProfileId),
      lastUpdated: serverTimestamp()
    }, { merge: true });

    return true;
  } catch (error) {
    console.error('Error adding viewed profile:', error);
    throw error;
  }
};

export const getViewedProfiles = async (userId) => {
  try {
    const viewedProfilesRef = doc(db, VIEWED_PROFILES_COLLECTION, userId);
    const docSnap = await getDoc(viewedProfilesRef);
    return docSnap.exists() ? docSnap.data().profileIds : [];
  } catch (error) {
    console.error('Error getting viewed profiles:', error);
    throw error;
  }
};

export const socialService = {
  
  // Change the parameter name to avoid conflict with the imported function
subscribeToPosts: (filters, callback, postLimit = POSTS_PER_PAGE) => {
  let postsQuery = query(
    collection(db, 'posts'),
    orderBy('createdAt', 'desc'),
    limit(postLimit) // Now using the imported limit function with postLimit parameter
  );

  if (filters?.tags?.length) {
    postsQuery = query(postsQuery, where('study_tags', 'array-contains-any', filters.tags));
  }

  if (filters?.authorId) {
    postsQuery = query(postsQuery, where('authorId', '==', filters.authorId));
  }

  // Set up realtime listener
  return onSnapshot(postsQuery, async (snapshot) => {
    const posts = [];
    
    for (const doc of snapshot.docs) {
      const postData = doc.data();
      
      // Get comments for each post
      const commentsQuery = query(
        collection(db, 'comments'),
        where('postId', '==', doc.id),
        where('parentCommentId', '==', null),
        orderBy('createdAt', 'desc')
      );
      
      const commentsSnap = await getDocs(commentsQuery);
      const comments = commentsSnap.docs.map(commentDoc => ({
        id: commentDoc.id,
        ...commentDoc.data(),
        createdAt: commentDoc.data().createdAt?.toDate()
      }));

      posts.push({
        id: doc.id,
        ...postData,
        comments,
        createdAt: postData.createdAt?.toDate(),
        updatedAt: postData.updatedAt?.toDate()
      });
    }
    
    callback(posts);
  });
},

  getUserProfile: async (userId) => {
    try {
      const profileRef = doc(db, PROFILES_COLLECTION, userId);
      const profileDoc = await getDoc(profileRef);
      
      if (!profileDoc.exists()) {
        throw new Error('Profile not found');
      }

      return {
        id: profileDoc.id,
        ...profileDoc.data(),
        createdAt: profileDoc.data().createdAt?.toDate(),
        updatedAt: profileDoc.data().updatedAt?.toDate()
      };
    } catch (error) {
      console.error('Error getting user profile:', error);
      throw error;
    }
  },

  createPost : async (userId, data) => {
    try {
      let mediaUrl = null;
      if (data.media?.file) {
        const mediaRef = ref(storage, `posts/${userId}/${Date.now()}-${data.media.type}`);
        const snapshot = await uploadBytes(mediaRef, data.media.file);
        mediaUrl = await getDownloadURL(snapshot.ref);
      }
  
      const authorProfile = await getDoc(doc(db, PROFILES_COLLECTION, userId));
      if (!authorProfile.exists()) {
        throw new Error('User profile not found');
      }
  
      const postData = {
        authorId: userId,
        content: data.content,
        location: data.location || null,
        university: data.university || null,
        study_tags: data.study_tags || [],
        media: mediaUrl ? { url: mediaUrl, type: data.media.type } : null,
        likes: [],
        likesCount: 0,
        commentsCount: 0,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        author: {
          name: authorProfile.data().basicInfo.name,
          username: authorProfile.data().basicInfo.username,
          avatar: authorProfile.data().photos[0] || null
        }
      };
  
      const docRef = await addDoc(collection(db, POSTS_COLLECTION), postData);
  
      // Notify followers if implemented
      const followersQuery = query(collection(db, 'followers'), where('followedId', '==', userId));
      const followersSnapshot = await getDocs(followersQuery);
      
      const notificationPromises = followersSnapshot.docs.map(followerDoc => 
        sendNotification(followerDoc.id, {
          title: 'New Post',
          body: `${postData.author.name} shared a new post`,
          clickAction: `/post/${docRef.id}`,
          data: {
            type: 'new_post',
            postId: docRef.id
          }
        })
      );
  
      await Promise.all(notificationPromises);
  
      return docRef.id;
    } catch (error) {
      console.error('Error creating post:', error);
      throw error;
    }
  },

  addComment: async (postId, userId, content, parentCommentId = null) => {
    try {
      const authorProfile = await getDoc(doc(db, PROFILES_COLLECTION, userId));
      if (!authorProfile.exists()) {
        throw new Error('User profile not found');
      }

      const postDoc = await getDoc(doc(db, POSTS_COLLECTION, postId));
      if (!postDoc.exists()) {
        throw new Error('Post not found');
      }

      const postAuthorId = postDoc.data().authorId;

      const commentData = {
        postId,
        parentCommentId,
        authorId: userId,
        author: {
          name: authorProfile.data().basicInfo.name,
          username: authorProfile.data().basicInfo.username,
          avatar: authorProfile.data().photos[0] || null
        },
        content,
        likes: [],
        likesCount: 0,
        createdAt: serverTimestamp()
      };

      const commentRef = await addDoc(collection(db, COMMENTS_COLLECTION), commentData);

      // Send notification to post author
      if (postAuthorId !== userId) {
        await sendNotification(postAuthorId, {
          title: 'New Comment',
          body: `${commentData.author.name} commented on your post: "${content.substring(0, 50)}${content.length > 50 ? '...' : ''}"`,
          clickAction: `/post/${postId}`,
          data: {
            type: 'new_comment',
            postId,
            commentId: commentRef.id
          }
        });
      }

      // If this is a reply, notify the parent comment author
      if (parentCommentId) {
        const parentComment = await getDoc(doc(db, COMMENTS_COLLECTION, parentCommentId));
        if (parentComment.exists() && parentComment.data().authorId !== userId) {
          await sendNotification(parentComment.data().authorId, {
            title: 'New Reply',
            body: `${commentData.author.name} replied to your comment: "${content.substring(0, 50)}${content.length > 50 ? '...' : ''}"`,
            clickAction: `/post/${postId}`,
            data: {
              type: 'comment_reply',
              postId,
              commentId: parentCommentId,
              replyId: commentRef.id
            }
          });
        }
      }

      // Update counts
      if (!parentCommentId) {
        await updateDoc(doc(db, POSTS_COLLECTION, postId), {
          commentsCount: increment(1)
        });
      } else {
        const parentComment = await getDoc(doc(db, COMMENTS_COLLECTION, parentCommentId));
        if (parentComment.exists()) {
          const replies = parentComment.data().replies || [];
          replies.push({
            id: commentRef.id,
            ...commentData,
            createdAt: new Date()
          });
          await updateDoc(doc(db, COMMENTS_COLLECTION, parentCommentId), {
            replies,
            repliesCount: increment(1)
          });
        }
      }

      return {
        id: commentRef.id,
        ...commentData,
        createdAt: new Date()
      };
    } catch (error) {
      console.error('Error adding comment:', error);
      throw error;
    }
  },

  toggleLike: async (itemId, userId, type = 'post') => {
    try {
      const itemRef = doc(db, type === 'post' ? POSTS_COLLECTION : COMMENTS_COLLECTION, itemId);
      const itemDoc = await getDoc(itemRef);
      
      if (!itemDoc.exists()) {
        throw new Error(`${type} not found`);
      }

      const likes = itemDoc.data().likes || [];
      const isLiked = likes.includes(userId);
      const authorId = itemDoc.data().authorId;

      // Send notification only when adding a like and not liking own content
      if (!isLiked && authorId !== userId) {
        const userProfile = await getDoc(doc(db, PROFILES_COLLECTION, userId));
        const contentType = type === 'post' ? 'post' : 'comment';
        
        await sendNotification(authorId, {
          title: 'New Like',
          body: `${userProfile.data().basicInfo.name} liked your ${contentType}`,
          clickAction: `/post/${type === 'post' ? itemId : itemDoc.data().postId}`,
          data: {
            type: 'new_like',
            contentType,
            itemId,
            postId: type === 'post' ? itemId : itemDoc.data().postId
          }
        });
      }

      // Update the likes
      await updateDoc(itemRef, {
        likes: isLiked ? arrayRemove(userId) : arrayUnion(userId),
        likesCount: increment(isLiked ? -1 : 1),
        updatedAt: serverTimestamp()
      });

      return !isLiked;
    } catch (error) {
      console.error('Error toggling like:', error);
      throw error;
    }
  },
  getNotifications: async (userId) => {
    try {
      const notificationsQuery = query(
        collection(db, NOTIFICATIONS_COLLECTION),
        where('receiverId', '==', userId),
        orderBy('createdAt', 'desc')
      );
      
      const snapshot = await getDocs(notificationsQuery);
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate()
      }));
    } catch (error) {
      console.error('Error getting notifications:', error);
      throw error;
    }
  },

  
  subscribeToNotifications: (userId, callback) => {
    try {
      const notificationsQuery = query(
        collection(db, 'users', userId, 'notifications'), // Updated to subcollection
        where('read', '==', false),
        orderBy('createdAt', 'desc')
      );

      return onSnapshot(notificationsQuery, (snapshot) => {
        const notifications = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate()
        }));
        callback(notifications);
      }, (error) => {
        console.error('Error in notification subscription:', error);
      });
    } catch (error) {
      console.error('Error subscribing to notifications:', error);
      throw error;
    }
  },

  markNotificationsAsRead: async (notificationIds) => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) throw new Error('No authenticated user');

      const updatePromises = notificationIds.map(id =>
        updateDoc(doc(db, 'users', userId, 'notifications', id), { // Updated to subcollection
          read: true,
          updatedAt: serverTimestamp()
        })
      );
      await Promise.all(updatePromises);
      return { success: true };
    } catch (error) {
      console.error('Error marking notifications as read:', error);
      throw error;
    }
  },

  getNotifications: async (userId) => {
    try {
      const notificationsQuery = query(
        collection(db, 'users', userId, 'notifications'), // Updated to subcollection
        orderBy('createdAt', 'desc')
      );

      const snapshot = await getDocs(notificationsQuery);
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate()
      }));
    } catch (error) {
      console.error('Error getting notifications:', error);
      throw error;
    }
  },

  getPosts: async (filters = {}) => {
    try {
      let postsQuery = query(
        collection(db, POSTS_COLLECTION),
        orderBy('createdAt', 'desc')
      );

      if (filters.university) {
        postsQuery = query(
          postsQuery,
          where('university', '==', filters.university)
        );
      }

      if (filters.tags?.length > 0) {
        postsQuery = query(
          postsQuery,
          where('study_tags', 'array-contains-any', filters.tags)
        );
      }

      const snapshot = await getDocs(postsQuery);
      const posts = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate(),
        updatedAt: doc.data().updatedAt?.toDate()
      }));

      const postsWithComments = await Promise.all(posts.map(async (post) => {
        const commentsQuery = query(
          collection(db, COMMENTS_COLLECTION),
          where('postId', '==', post.id),
          where('parentCommentId', '==', null),
          orderBy('createdAt', 'desc')
        );
        
        const commentsSnapshot = await getDocs(commentsQuery);
        const comments = commentsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate(),
          replies: (doc.data().replies || []).map(reply => ({
            ...reply,
            createdAt: reply.createdAt?.toDate()
          }))
        }));

        return {
          ...post,
          comments
        };
      }));

      return postsWithComments;
    } catch (error) {
      console.error('Error getting posts:', error);
      throw error;
    }
  },

  deletePost: async (postId, userId) => {
    try {
      const postRef = doc(db, POSTS_COLLECTION, postId);
      const post = await getDoc(postRef);
      
      if (!post.exists()) {
        throw new Error('Post not found');
      }
      
      if (post.data().authorId !== userId) {
        throw new Error('Unauthorized to delete this post');
      }

      // Delete associated images from storage
      const images = post.data().images || [];
      const deleteImagePromises = images.map(async (imageUrl) => {
        const imageRef = ref(storage, imageUrl);
        try {
          await deleteObject(imageRef);
        } catch (error) {
          console.warn('Error deleting image:', error);
          // Continue with post deletion even if image deletion fails
        }
      });

      // Delete all comments for the post
      const commentsQuery = query(
        collection(db, COMMENTS_COLLECTION),
        where('postId', '==', postId)
      );
      const commentsSnapshot = await getDocs(commentsQuery);
      const deleteCommentPromises = commentsSnapshot.docs.map(commentDoc => 
        deleteDoc(doc(db, COMMENTS_COLLECTION, commentDoc.id))
      );

      // Delete related notifications
      const notificationsQuery = query(
        collection(db, NOTIFICATIONS_COLLECTION),
        where('data.postId', '==', postId)
      );
      const notificationsSnapshot = await getDocs(notificationsQuery);
      const deleteNotificationPromises = notificationsSnapshot.docs.map(notifDoc => 
        deleteDoc(doc(db, NOTIFICATIONS_COLLECTION, notifDoc.id))
      );

      // Execute all deletions in parallel
      await Promise.all([
        ...deleteImagePromises,
        ...deleteCommentPromises,
        ...deleteNotificationPromises,
        deleteDoc(postRef)
      ]);

      return { success: true, message: 'Post and related content deleted successfully' };
    } catch (error) {
      console.error('Error deleting post:', error);
      throw error;
    }
  },

  deleteComment: async (commentId, userId) => {
    try {
      const commentRef = doc(db, COMMENTS_COLLECTION, commentId);
      const comment = await getDoc(commentRef);
      
      if (!comment.exists()) {
        throw new Error('Comment not found');
      }
      
      if (comment.data().authorId !== userId) {
        throw new Error('Unauthorized to delete this comment');
      }

      const commentData = comment.data();
      const postRef = doc(db, POSTS_COLLECTION, commentData.postId);

      // Delete related notifications
      const notificationsQuery = query(
        collection(db, NOTIFICATIONS_COLLECTION),
        where('data.commentId', '==', commentId)
      );
      const notificationsSnapshot = await getDocs(notificationsQuery);
      const deleteNotificationPromises = notificationsSnapshot.docs.map(notifDoc => 
        deleteDoc(doc(db, NOTIFICATIONS_COLLECTION, notifDoc.id))
      );

      // If this is a parent comment, update post comment count
      if (!commentData.parentCommentId) {
        await updateDoc(postRef, {
          commentsCount: increment(-1)
        });
      } else {
        // If this is a reply, update parent comment's replies
        const parentCommentRef = doc(db, COMMENTS_COLLECTION, commentData.parentCommentId);
        const parentComment = await getDoc(parentCommentRef);
        if (parentComment.exists()) {
          const replies = parentComment.data().replies || [];
          const updatedReplies = replies.filter(reply => reply.id !== commentId);
          await updateDoc(parentCommentRef, {
            replies: updatedReplies,
            repliesCount: increment(-1)
          });
        }
      }

      await Promise.all([
        ...deleteNotificationPromises,
        deleteDoc(commentRef)
      ]);

      return { success: true, message: 'Comment deleted successfully' };
    } catch (error) {
      console.error('Error deleting comment:', error);
      throw error;
    }
  },

  updatePost: async (postId, userId, updateData) => {
    try {
      const postRef = doc(db, POSTS_COLLECTION, postId);
      const post = await getDoc(postRef);
      
      if (!post.exists()) {
        throw new Error('Post not found');
      }
      
      if (post.data().authorId !== userId) {
        throw new Error('Unauthorized to update this post');
      }

      // Handle image updates if needed
      let updatedImages = post.data().images || [];
      if (updateData.images) {
        // Delete removed images from storage
        const removedImages = post.data().images.filter(img => !updateData.images.includes(img));
        for (const imageUrl of removedImages) {
          try {
            const imageRef = ref(storage, imageUrl);
            await deleteObject(imageRef);
          } catch (error) {
            console.warn('Error deleting image:', error);
          }
        }

        // Upload new images
        const newImages = await Promise.all(
          updateData.images
            .filter(img => img instanceof File)
            .map(async (img) => {
              const imageRef = ref(storage, `posts/${userId}/${Date.now()}-${img.name}`);
              const snapshot = await uploadBytes(imageRef, img);
              return getDownloadURL(snapshot.ref);
            })
        );

        // Combine existing and new images
        updatedImages = [
          ...updateData.images.filter(img => typeof img === 'string'),
          ...newImages
        ];
      }

      const updatePayload = {
        ...updateData,
        images: updatedImages,
        updatedAt: serverTimestamp()
      };

      await updateDoc(postRef, updatePayload);

      return {
        success: true,
        message: 'Post updated successfully',
        images: updatedImages
      };
    } catch (error) {
      console.error('Error updating post:', error);
      throw error;
    }
  },

  updateComment: async (commentId, userId, content) => {
    try {
      const commentRef = doc(db, COMMENTS_COLLECTION, commentId);
      const comment = await getDoc(commentRef);
      
      if (!comment.exists()) {
        throw new Error('Comment not found');
      }
      
      if (comment.data().authorId !== userId) {
        throw new Error('Unauthorized to update this comment');
      }

      await updateDoc(commentRef, {
        content,
        updatedAt: serverTimestamp(),
        edited: true
      });

      return {
        success: true,
        message: 'Comment updated successfully'
      };
    } catch (error) {
      console.error('Error updating comment:', error);
      throw error;
    }
  },

  // Helper method to clean up user data when account is deleted
  cleanupUserData: async (userId) => {
    try {
      // Delete user's posts and related content
      const postsQuery = query(
        collection(db, POSTS_COLLECTION),
        where('authorId', '==', userId)
      );
      const postsSnapshot = await getDocs(postsQuery);
      
      for (const postDoc of postsSnapshot.docs) {
        await socialService.deletePost(postDoc.id, userId);
      }

      // Delete user's comments
      const commentsQuery = query(
        collection(db, COMMENTS_COLLECTION),
        where('authorId', '==', userId)
      );
      const commentsSnapshot = await getDocs(commentsQuery);
      
      for (const commentDoc of commentsSnapshot.docs) {
        await socialService.deleteComment(commentDoc.id, userId);
      }

      // Delete user's notifications
      const notificationsQuery = query(
        collection(db, NOTIFICATIONS_COLLECTION),
        where('receiverId', '==', userId)
      );
      const notificationsSnapshot = await getDocs(notificationsQuery);
      const deleteNotificationPromises = notificationsSnapshot.docs.map(notifDoc => 
        deleteDoc(doc(db, NOTIFICATIONS_COLLECTION, notifDoc.id))
      );

      // Delete viewed profiles data
      const viewedProfilesRef = doc(db, VIEWED_PROFILES_COLLECTION, userId);
      const deleteViewedPromise = deleteDoc(viewedProfilesRef);

      await Promise.all([
        ...deleteNotificationPromises,
        deleteViewedPromise
      ]);

      return {
        success: true,
        message: 'User data cleaned up successfully'
      };
    } catch (error) {
      console.error('Error cleaning up user data:', error);
      throw error;
    }
  },
 // Add this improved getMorePosts function to your socialService.js

getMorePosts : async (lastVisible, filters = {}, pageLimit = POSTS_PER_PAGE) => {
  try {
    if (!lastVisible) {
      console.warn('No lastVisible document provided for pagination');
      return { posts: [], lastVisible: null, hasMore: false };
    }

    // Create the base query with pagination parameters
    let postsQuery = query(
      collection(db, POSTS_COLLECTION),
      orderBy('createdAt', 'desc'),
      startAfter(lastVisible),
      limit(pageLimit)
    );

    // Apply filters if provided
    if (filters?.tags?.length) {
      postsQuery = query(postsQuery, where('study_tags', 'array-contains-any', filters.tags));
    }

    if (filters?.authorId) {
      postsQuery = query(postsQuery, where('authorId', '==', filters.authorId));
    }

    if (filters?.university) {
      postsQuery = query(postsQuery, where('university', '==', filters.university));
    }

    // Get the documents
    const snapshot = await getDocs(postsQuery);
    
    // If no documents found, return empty result
    if (snapshot.empty) {
      return { posts: [], lastVisible: null, hasMore: false };
    }

    // Process each post with parallel fetching of comments
    const postPromises = snapshot.docs.map(async (doc) => {
      const postData = doc.data();
      
      // Create query for comments
      const commentsQuery = query(
        collection(db, COMMENTS_COLLECTION),
        where('postId', '==', doc.id),
        where('parentCommentId', '==', null),
        orderBy('createdAt', 'desc')
      );
      
      // Fetch comments for this post
      const commentsSnap = await getDocs(commentsQuery);
      const comments = commentsSnap.docs.map(commentDoc => ({
        id: commentDoc.id,
        ...commentDoc.data(),
        createdAt: commentDoc.data().createdAt?.toDate()
      }));

      // Return the complete post with comments
      return {
        id: doc.id,
        ...postData,
        comments,
        createdAt: postData.createdAt?.toDate(),
        updatedAt: postData.updatedAt?.toDate()
      };
    });

    // Wait for all post processing to complete
    const newPosts = await Promise.all(postPromises);
    
    // Return the processed posts with pagination metadata
    return {
      posts: newPosts,
      lastVisible: snapshot.docs[snapshot.docs.length - 1],
      hasMore: snapshot.docs.length === pageLimit
    };
  } catch (error) {
    console.error('Error getting more posts:', error);
    throw error;
  }
}}

export default socialService;