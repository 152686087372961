import React, { useState, useEffect } from 'react';

const InstallPrompt = () => {
  const [showPrompt, setShowPrompt] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      
      const isInstalled = localStorage.getItem('appInstalled');
      
      if (!isInstalled) {
        setShowPrompt(true);
      }
    };

    window.addEventListener('beforeinstallprompt', handler);
    
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        const isInstalled = localStorage.getItem('appInstalled');
        if (!isInstalled && deferredPrompt) {
          setShowPrompt(true);
        }
      }
    };
    
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [deferredPrompt]);

  const handleInstall = async () => {
    if (!deferredPrompt) return;
    
    deferredPrompt.prompt();
    
    const { outcome } = await deferredPrompt.userChoice;
    
    setDeferredPrompt(null);
    setShowPrompt(false);
    
    if (outcome === 'accepted') {
      localStorage.setItem('appInstalled', 'true');
    }
  };

  const handleDismiss = () => {
    setShowPrompt(false);
  };

  if (!showPrompt) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl p-6 max-w-sm mx-4 animate-fade-in">
        <div className="text-center">
          <h3 className="text-xl font-semibold text-gray-900">Install VarsityLink</h3>
          <p className="mt-2 text-base text-gray-600">
            Install our app for a better experience and quick access!
          </p>
          <div className="mt-6 flex gap-3 justify-center">
            <button
              onClick={handleInstall}
              className="px-6 py-2.5 bg-green-500 text-white font-medium rounded-lg hover:bg-green-600 transition-colors duration-200"
            >
              Install Now
            </button>
            <button
              onClick={handleDismiss}
              className="px-6 py-2.5 text-gray-600 font-medium hover:text-gray-800 transition-colors duration-200"
            >
              Maybe Later
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstallPrompt;