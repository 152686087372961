export const PERSONALITY_TRAITS = [
    'Outgoing', 'Talkative', 'Reserved', 'Creative', 'Analytical',
    'Adventurous', 'Empathetic', 'Organized', 'Spontaneous', 'Ambitious',
    'Introverted', 'Extroverted', 'Friendly', 'Energetic', 'Calm',
    'Artistic', 'Athletic', 'Intellectual', 'Humorous', 'Practical'
  ];
  
  export const RELATIONSHIP_GOALS = [
    'Networking', 'Dating', 'Love', 'Friendship', 'Study Partners',
    'Mentorship', 'Coffee Buddies', 'Gym Partners', 'Project Collaborators'
  ];
  
  export const LANGUAGES = [
    'English', 'Afrikaans', 'Zulu', 'Xhosa', 'Sotho',
    'Tswana', 'Venda', 'Tsonga', 'Swati', 'Ndebele',
    'French', 'Portuguese', 'Spanish', 'Mandarin', 'Hindi'
  ];
  
  export const STAR_SIGNS = [
    'Aries', 'Taurus', 'Gemini', 'Cancer', 'Leo', 'Virgo',
    'Libra', 'Scorpio', 'Sagittarius', 'Capricorn', 'Aquarius', 'Pisces'
  ];
  
  export const GENDERS = ['Male', 'Female', 'Non-binary', 'Other', 'Prefer not to say'];
  
  export const AGE_RANGES = [
    '18-20', '21-23', '24-26', '27-30', '30+'
  ];
  
  export const ACTIVITY_STATUSES = [
    'Available', 'Busy', 'Away', 'Do Not Disturb', 'Offline'
  ];
  
  export const UNIVERSITIES = [
    'Cape Peninsula University of Technology',
    'Central University of Technology',
    'Durban University of Technology',
    'Mangosuthu University of Technology',
    'Nelson Mandela University',
    'North-West University',
    'Rhodes University',
    'Sefako Makgatho Health Sciences University',
    'Sol Plaatje University',
    'Stellenbosch University',
    'Tshwane University of Technology',
    'University of Cape Town',
    'University of Fort Hare',
    'University of Johannesburg',
    'University of KwaZulu-Natal',
    'University of Limpopo',
    'University of Mpumalanga',
    'University of Pretoria',
    'University of South Africa',
    'University of the Free State',
    'University of the Western Cape',
    'University of the Witwatersrand',
    'Vaal University of Technology',
    'Walter Sisulu University'
  ].sort();
  
  export const STUDY_YEARS = ['1st Year', '2nd Year', '3rd Year', '4th Year', 'Honors', 'Masters', 'PhD'];