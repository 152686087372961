import React from 'react';

const ProfileCardSkeleton = () => {
  return (
    <div className="max-w-md mx-auto bg-white rounded-3xl shadow-lg animate-pulse overflow-hidden">
      {/* Image carousel skeleton */}
      <div className="w-full aspect-[3/4] bg-gray-200" />
      
      {/* Content skeleton */}
      <div className="p-6 space-y-4">
        {/* Name and age skeleton */}
        <div className="flex items-center space-x-2">
          <div className="h-7 bg-gray-200 rounded-full w-48" />
          <div className="h-7 bg-gray-200 rounded-full w-12" />
        </div>
        
        {/* University and program skeleton */}
        <div className="space-y-2">
          <div className="h-5 bg-gray-200 rounded-full w-3/4" />
          <div className="h-5 bg-gray-200 rounded-full w-1/2" />
        </div>
        
        {/* Bio skeleton */}
        <div className="space-y-2">
          <div className="h-4 bg-gray-200 rounded-full w-full" />
          <div className="h-4 bg-gray-200 rounded-full w-5/6" />
          <div className="h-4 bg-gray-200 rounded-full w-4/6" />
        </div>
      </div>

      {/* Action buttons skeleton */}
      <div className="px-6 pb-6 flex justify-center space-x-4">
        <div className="w-14 h-14 rounded-full bg-gray-200" />
        <div className="w-14 h-14 rounded-full bg-gray-200" />
      </div>
    </div>
  );
};

export default ProfileCardSkeleton;