import React, { useState, useRef, useEffect } from 'react';
import { Tag, ImageIcon, VideoIcon, MicIcon, X, Loader2, MessageSquare, Plus, ChevronDown } from 'lucide-react';

const CreatePost = ({ userProfile, onCreatePost, loading }) => {
  const [content, setContent] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [mediaFile, setMediaFile] = useState(null);
  const [mediaPreview, setMediaPreview] = useState(null);
  const [mediaType, setMediaType] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showTagInput, setShowTagInput] = useState(false);
  const [newTag, setNewTag] = useState('');
  const [characterCount, setCharacterCount] = useState(0);
  const [showMediaOptions, setShowMediaOptions] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const timerRef = useRef(null);
  const textareaRef = useRef(null);
  const tagInputRef = useRef(null);

  // Auto-resize textarea
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
    }
  }, [content]);

  // Focus tag input when shown
  useEffect(() => {
    if (showTagInput && tagInputRef.current) {
      tagInputRef.current.focus();
    }
  }, [showTagInput]);

  // Recording timer
  useEffect(() => {
    if (isRecording) {
      timerRef.current = setInterval(() => {
        setRecordingTime(prev => prev + 1);
      }, 1000);
    } else {
      clearInterval(timerRef.current);
      setRecordingTime(0);
    }
    return () => clearInterval(timerRef.current);
  }, [isRecording]);

  // Update character count
  useEffect(() => {
    setCharacterCount(content.length);
  }, [content]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        mediaRecorderRef.current = new MediaRecorder(stream);
        audioChunksRef.current = [];

        mediaRecorderRef.current.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data);
        };

        mediaRecorderRef.current.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
          setMediaFile(audioBlob);
          setMediaType('audio');
          setMediaPreview(URL.createObjectURL(audioBlob));
          stream.getTracks().forEach(track => track.stop());
        };

        mediaRecorderRef.current.start();
        setIsRecording(true);
        setIsExpanded(true);
      })
      .catch(err => {
        console.error('Error accessing microphone:', err);
        alert('Unable to access microphone. Please check your permissions.');
      });
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleAddTag = () => {
    if (newTag.trim() && !selectedTags.includes(newTag.trim())) {
      setSelectedTags([...selectedTags, newTag.trim()]);
      setNewTag('');
    }
    setShowTagInput(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && showTagInput) {
      e.preventDefault();
      handleAddTag();
    }
  };

  const handleSubmit = () => {
    if (!content.trim() && !mediaFile) return;
    
    // Animate post submission
    const formElement = document.getElementById('create-post-form');
    if (formElement) {
      formElement.classList.add('animate-pulse', 'opacity-50');
      setTimeout(() => {
        formElement.classList.remove('animate-pulse', 'opacity-50');
      }, 500);
    }
    
    onCreatePost({
      content,
      media: mediaFile ? { file: mediaFile, type: mediaType } : null,
      study_tags: selectedTags
    });
    
    setContent('');
    setMediaFile(null);
    setMediaPreview(null);
    setSelectedTags([]);
    setIsExpanded(false);
  };

  return (
    <div 
      id="create-post-form"
      className="bg-white rounded-lg shadow-sm overflow-hidden transition-all duration-300 hover:shadow-md"
    >
      <div className="p-4">
        {/* Header */}
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-3">
            <img
              src={userProfile?.photos?.[0] || "/api/placeholder/40/40"}
              alt="Profile"
              className="w-10 h-10 rounded-full object-cover transition-transform hover:scale-105"
            />
            <div>
              <h3 className="font-medium text-gray-900">Create Post</h3>
              <p className="text-sm text-gray-500">{userProfile?.academic?.university || 'University'}</p>
            </div>
          </div>
          <div className="flex items-center gap-2">
            {isExpanded && characterCount > 0 && (
              <span className={`text-xs ${characterCount > 280 ? 'text-red-500' : 'text-gray-400'}`}>
                {characterCount}/300
              </span>
            )}
            <button
              onClick={handleSubmit}
              disabled={(!content.trim() && !mediaFile) || loading || characterCount > 300}
              className={`px-4 py-1.5 rounded-full text-sm font-medium transition-all duration-200 ${
                (content.trim() || mediaFile) && !loading && characterCount <= 300
                  ? 'bg-blue-600 text-white hover:bg-blue-700 hover:shadow-md'
                  : 'bg-gray-100 text-gray-400'
              }`}
            >
              {loading ? <Loader2 className="w-4 h-4 animate-spin" /> : 'Post'}
            </button>
          </div>
        </div>

        {/* Content Area */}
        <textarea
          ref={textareaRef}
          value={content}
          onChange={(e) => setContent(e.target.value)}
          onFocus={() => setIsExpanded(true)}
          placeholder="Share your thoughts..."
          className="w-full min-h-[60px] resize-none border-none focus:ring-0 text-gray-800 placeholder:text-gray-400 transition-all duration-300"
          style={{ height: isExpanded ? (content ? 'auto' : '120px') : '60px' }}
        />
        
        {mediaPreview && (
          <div className="relative mt-2 group">
            {mediaType === 'image' && (
              <div className="overflow-hidden rounded-lg">
                <img 
                  src={mediaPreview} 
                  alt="Preview" 
                  className="w-full rounded-lg object-cover max-h-96 transition-transform duration-500 group-hover:scale-105"
                />
              </div>
            )}
            {mediaType === 'video' && (
              <video 
                src={mediaPreview} 
                controls 
                className="w-full rounded-lg"
              />
            )}
            {mediaType === 'audio' && (
              <div className="bg-gray-50 p-3 rounded-lg flex items-center gap-3 hover:bg-gray-100 transition-colors duration-200">
                <div className="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center">
                  <MicIcon className="w-5 h-5 text-blue-600" />
                </div>
                <div className="flex-1">
                  <p className="text-sm font-medium text-gray-700">Voice Recording</p>
                  <audio src={mediaPreview} controls className="w-full mt-1" />
                </div>
                <button 
                  onClick={() => {
                    setMediaFile(null);
                    setMediaPreview(null);
                    setMediaType(null);
                  }}
                  className="p-1.5 hover:bg-gray-200 rounded-full transition-colors duration-200"
                >
                  <X className="w-4 h-4 text-gray-500" />
                </button>
              </div>
            )}
            {mediaType !== 'audio' && (
              <button 
                onClick={() => {
                  setMediaFile(null);
                  setMediaPreview(null);
                  setMediaType(null);
                }}
                className="absolute top-2 right-2 p-1.5 bg-black/50 hover:bg-black/70 rounded-full text-white transition-colors duration-200"
              >
                <X className="w-4 h-4" />
              </button>
            )}
          </div>
        )}

        {isRecording && (
          <div className="mt-2 bg-red-50 p-3 rounded-lg flex items-center gap-3 animate-pulse">
            <div className="relative">
              <div className="w-10 h-10 bg-red-100 rounded-full flex items-center justify-center">
                <MicIcon className="w-5 h-5 text-red-600" />
              </div>
              <div className="absolute -top-1 -right-1 w-3 h-3 bg-red-500 rounded-full animate-ping" />
            </div>
            <div className="flex-1">
              <p className="text-sm font-medium text-red-700">Recording...</p>
              <p className="text-xs text-red-600">{formatTime(recordingTime)}</p>
            </div>
            <button 
              onClick={stopRecording}
              className="px-3 py-1.5 bg-red-100 hover:bg-red-200 rounded-full text-sm font-medium text-red-600 transition-colors duration-200"
            >
              Stop
            </button>
          </div>
        )}

        {selectedTags.length > 0 && (
          <div className="flex flex-wrap gap-2 mt-4">
            {selectedTags.map(tag => (
              <span 
                key={tag} 
                className="px-2 py-1 bg-gray-100 text-gray-600 rounded-full text-sm group hover:bg-blue-50 hover:text-blue-600 transition-colors duration-200"
              >
                #{tag}
                <button 
                  onClick={() => setSelectedTags(tags => tags.filter(t => t !== tag))}
                  className="ml-1.5 text-gray-400 group-hover:text-blue-400"
                >
                  <X className="w-3 h-3 inline" />
                </button>
              </span>
            ))}
          </div>
        )}

        {showTagInput && (
          <div className="mt-4 flex items-center gap-2">
            <span className="text-gray-500 text-sm">#</span>
            <input
              ref={tagInputRef}
              type="text"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              onKeyDown={handleKeyDown}
              onBlur={handleAddTag}
              placeholder="Add a tag..."
              className="flex-1 text-sm border-none focus:ring-0 text-gray-800"
            />
            <button 
              onClick={handleAddTag}
              className="p-1 bg-gray-100 hover:bg-gray-200 rounded-full transition-colors"
            >
              <Plus className="w-4 h-4 text-gray-500" />
            </button>
          </div>
        )}

        {/* Action Bar - Collapsible on small devices */}
        <div 
          className={`flex items-center gap-6 mt-4 pt-3 border-t border-gray-100 transition-opacity duration-300 ${
            isExpanded ? 'opacity-100' : 'opacity-70 hover:opacity-100'
          }`}
        >
          <button 
            onClick={() => setShowMediaOptions(!showMediaOptions)}
            className="md:hidden flex items-center gap-2 text-gray-500 hover:text-blue-600 transition-colors duration-200 group"
          >
            <ChevronDown className="w-5 h-5 transition-transform group-hover:scale-110 duration-200" />
            <span className="text-sm">Media</span>
          </button>

          <div className={`${showMediaOptions ? 'block' : 'hidden'} md:flex items-center gap-6`}>
            <label className="flex items-center gap-2 cursor-pointer text-gray-500 hover:text-blue-600 transition-colors duration-200 group">
              <input 
                type="file" 
                accept="image/*" 
                className="hidden" 
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    setMediaFile(file);
                    setMediaType('image');
                    setMediaPreview(URL.createObjectURL(file));
                    setIsExpanded(true);
                  }
                }}
              />
              <ImageIcon className="w-5 h-5 transition-transform group-hover:scale-110 duration-200" />
              <span className="text-sm">Image</span>
            </label>
            
            <label className="flex items-center gap-2 cursor-pointer text-gray-500 hover:text-blue-600 transition-colors duration-200 group">
              <input 
                type="file" 
                accept="video/*" 
                className="hidden" 
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    setMediaFile(file);
                    setMediaType('video');
                    setMediaPreview(URL.createObjectURL(file));
                    setIsExpanded(true);
                  }
                }}
              />
              <VideoIcon className="w-5 h-5 transition-transform group-hover:scale-110 duration-200" />
              <span className="text-sm">Video</span>
            </label>
            
            <button 
              onClick={() => setShowTagInput(true)}
              className="flex items-center gap-2 text-gray-500 hover:text-blue-600 transition-colors duration-200 group"
            >
              <Tag className="w-5 h-5 transition-transform group-hover:scale-110 duration-200" />
              <span className="text-sm">Tag</span>
            </button>

            <button 
              onClick={isRecording ? stopRecording : startRecording}
              disabled={mediaFile && mediaType !== 'audio'}
              className={`flex items-center gap-2 transition-colors duration-200 group ${
                isRecording 
                  ? 'text-red-500 hover:text-red-600' 
                  : 'text-gray-500 hover:text-blue-600'
              }`}
            >
              <MicIcon className={`w-5 h-5 transition-transform group-hover:scale-110 duration-200 ${isRecording ? 'animate-pulse' : ''}`} />
              <span className="text-sm">{isRecording ? 'Recording...' : 'Audio'}</span>
            </button>
          </div>
          
          {isExpanded && (
            <button 
              onClick={() => setIsExpanded(false)}
              className="ml-auto text-sm text-gray-400 hover:text-gray-600 transition-colors duration-200"
            >
              Cancel
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreatePost;