// CommentsList.js
import React from 'react';
import { CommentItem } from './CommentItem';

export const CommentsList = ({ comments, onReply, formatTimestamp }) => {
  return (
    <div className="space-y-4">
      {comments?.map(comment => (
        <CommentItem 
          key={comment.id}
          comment={comment}
          onReply={onReply}
          formatTimestamp={formatTimestamp}
        />
      ))}
      {!comments?.length && (
        <p className="text-center text-gray-500 py-4">No comments yet. Be the first to comment!</p>
      )}
    </div>
  );
};