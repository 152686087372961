import React, { useState } from 'react';
import { GENDERS } from '../../constants/profileConstants';

const BasicInformation = ({ 
  profile, 
  isEditing, 
  setProfile 
}) => {
  const [errors, setErrors] = useState({
    username: '',
    name: ''
  });

  const validateField = (field, value) => {
    if (!value || value.trim() === '') {
      return `${field.charAt(0).toUpperCase() + field.slice(1)} cannot be empty`;
    }
    return '';
  };

  const handleUsernameChange = (value) => {
    // Basic username validation
    const sanitizedUsername = value.toLowerCase().replace(/[^a-z0-9_]/g, '');
    const usernameError = validateField('username', sanitizedUsername);
    
    setErrors(prev => ({
      ...prev,
      username: usernameError
    }));
    
    setProfile(prev => ({
      ...prev,
      basicInfo: { ...prev.basicInfo, username: sanitizedUsername }
    }));
  };

  const handleNameChange = (value) => {
    const nameError = validateField('name', value);
    
    setErrors(prev => ({
      ...prev,
      name: nameError
    }));

    setProfile(prev => ({
      ...prev,
      basicInfo: { ...prev.basicInfo, name: value }
    }));
  };

  const handleInterestedInToggle = (gender) => {
    if (isEditing) {
      setProfile(prev => {
        const currentInterestedIn = prev.basicInfo.interestedIn || [];
        const updatedInterestedIn = currentInterestedIn.includes(gender)
          ? currentInterestedIn.filter(g => g !== gender)
          : [...currentInterestedIn, gender];
        
        return {
          ...prev,
          basicInfo: { 
            ...prev.basicInfo, 
            interestedIn: updatedInterestedIn 
          }
        };
      });
    }
  };

  return (
    <section className="bg-white rounded-2xl p-6 shadow-sm">
      <h2 className="text-xl font-semibold mb-6">Basic Information</h2>
      <div className="space-y-6">
        {/* Name, Username, Email */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Name */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Name</label>
            {isEditing ? (
              <div>
                <input
                  type="text"
                  value={profile.basicInfo.name}
                  onChange={(e) => handleNameChange(e.target.value)}
                  className={`w-full p-2 border rounded-lg ${errors.name ? 'border-red-500' : ''}`}
                />
                {errors.name && (
                  <p className="text-red-500 text-xs mt-1">{errors.name}</p>
                )}
              </div>
            ) : (
              <p className="text-gray-900">{profile.basicInfo.name}</p>
            )}
          </div>

          {/* Username */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Username</label>
            {isEditing ? (
              <div>
                <input
                  type="text"
                  value={profile.basicInfo.username}
                  onChange={(e) => handleUsernameChange(e.target.value)}
                  className={`w-full p-2 border rounded-lg ${errors.username ? 'border-red-500' : ''}`}
                />
                {errors.username && (
                  <p className="text-red-500 text-xs mt-1">{errors.username}</p>
                )}
              </div>
            ) : (
              <p className="text-gray-900">{profile.basicInfo.username}</p>
            )}
          </div>

          {/* Email */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
            <p className="text-gray-900 p-2">{profile.basicInfo.email}</p>
          </div>
        </div>

        {/* Gender */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Gender</label>
          <div className="flex flex-wrap gap-2">
            {GENDERS.map((gender) => (
              <button
                key={gender}
                onClick={() => isEditing && setProfile(prev => ({
                  ...prev,
                  basicInfo: { ...prev.basicInfo, gender }
                }))}
                className={`px-4 py-2 rounded-full text-sm ${
                  profile.basicInfo.gender === gender
                    ? 'bg-green-500 text-white'
                    : 'bg-gray-100 text-gray-600'
                } ${!isEditing && 'pointer-events-none'}`}
              >
                {gender}
              </button>
            ))}
          </div>
        </div>

        {/* Interested In */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Interested In</label>
          <div className="flex flex-wrap gap-2">
            {GENDERS.map((gender) => (
              <button
                key={`interested-${gender}`}
                onClick={() => handleInterestedInToggle(gender)}
                className={`px-4 py-2 rounded-full text-sm ${
                  (profile.basicInfo.interestedIn || []).includes(gender)
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-100 text-gray-600'
                } ${!isEditing && 'pointer-events-none'}`}
              >
                {gender}
              </button>
            ))}
          </div>
          {!isEditing && profile.basicInfo.interestedIn && profile.basicInfo.interestedIn.length > 0 && (
            <p className="text-gray-900 mt-2">
              Interested in: {profile.basicInfo.interestedIn.join(', ')}
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default BasicInformation;