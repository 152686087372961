import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Send, ArrowLeft, Users, Info, Image as ImageIcon, X, ChevronRight, Smile, Heart
} from 'lucide-react';
import { collection, addDoc, query, orderBy, onSnapshot, doc, getDoc, getDocs } from 'firebase/firestore';
import { auth, db } from '../firebase/firebase';
import EmojiPicker from 'emoji-picker-react';
import RecommendationCard from './RecommendationCard';

import { voteOnRecommendation } from '../firebase/groupVotingService';
import toast from 'react-hot-toast';



const GroupChat = () => {

  const navigate = useNavigate();
  const { groupId } = useParams();
  const [messages, setMessages] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [groupMembers, setGroupMembers] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [groupDetails, setGroupDetails] = useState(null);
  const [selectedRecommendation, setSelectedRecommendation] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const messagesEndRef = useRef(null);

  // Near the top of your GroupChat component, add some debug logging:
  // useEffect(() => {
  //   console.log('Current groupId from params:', groupId);
  //   console.log('Selected recommendation:', selectedRecommendation);
  // }, [groupId, selectedRecommendation]);

  useEffect(() => {
    const fetchGroupDetails = async () => {
      try {
        // Fetch basic group details
        const groupDoc = await getDoc(doc(db, 'groups', groupId));
        if (groupDoc.exists()) {
          const groupData = groupDoc.data();
          setGroupDetails(groupData);

          // First get the member IDs from the group document
          const memberIds = groupData.members || [];

          // Then fetch the full profile details for each member
          const membersRef = collection(db, 'profiles');
          const memberPromises = memberIds.map(async (memberId) => {
            const memberDoc = await getDoc(doc(membersRef, memberId));
            if (memberDoc.exists()) {
              return {
                id: memberDoc.id,
                ...memberDoc.data(),
                name: memberDoc.data().basicInfo?.name || 'Anonymous'  // Ensure name is available
              };
            }
            return null;
          });

          const memberProfiles = (await Promise.all(memberPromises)).filter(Boolean);
          // console.log('Fetched member profiles:', memberProfiles); // Debug log
          setGroupMembers(memberProfiles);
        }
      } catch (error) {
        console.error('Error fetching group details:', error);
      }
    };

    const messagesRef = collection(db, 'groups', groupId, 'messages');
    const messagesQuery = query(messagesRef, orderBy('createdAt', 'asc'));

    const recommendationsRef = collection(db, 'groups', groupId, 'recommendations');
    const recommendationsQuery = query(recommendationsRef, orderBy('createdAt', 'desc'));

    const unsubscribeMessages = onSnapshot(messagesQuery, (snapshot) => {
      setMessages(snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })));
    });

    const unsubscribeRecommendations = onSnapshot(recommendationsQuery, (snapshot) => {
      setRecommendations(snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })));
    });

    fetchGroupDetails();
    return () => {
      unsubscribeMessages();
      unsubscribeRecommendations();
    };
  }, [groupId]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleRecommendationVote = async (recommendationId, voteData) => {
    try {
      // console.log('Handling vote:', {
      //   groupId,
      //   recommendationId,
      //   voteData
      // });

      // Add the current user's ID
    const currentUserId = auth.currentUser.uid;
  
      const result = await voteOnRecommendation(
        groupId,
        recommendationId,
        voteData,
        currentUserId 
      );
  
      // Handle success toast with vote summary
      const VoteSummary = () => (
        <div className="mt-4 p-3 bg-gray-100 rounded-lg">
          <div className="font-medium">Vote Status:</div>
          <div>Votes received: {result.votesReceived}</div>
          <div>Total needed: {result.totalExpectedVotes}</div>
          {result.voteDistribution && (
            <div className="mt-2">
              <div className="font-medium">Votes per member:</div>
              {Object.entries(result.voteDistribution).map(([id, count]) => (
                <div key={id}>
                  {groupMembers.find(m => m.id === id)?.name || id}: {count}
                </div>
              ))}
            </div>
          )}
        </div>
      );
  
      if (result.complete) {
        toast.success(
          (t) => (
            <div>
              <div>Match created! Waiting for response...</div>
              <VoteSummary />
            </div>
          ),
          { duration: 5000 }
        );
        setTimeout(() => {
          setSelectedRecommendation(null);
        }, 5000);
      } else {
        toast.success(
          (t) => (
            <div>
              <div>Vote recorded! ({result.votesReceived}/{result.totalExpectedVotes})</div>
              <VoteSummary />
            </div>
          ),
          { duration: 3000 }
        );
      }
  
      console.log('Vote result:', result);
    } catch (error) {
      console.error('Error voting for recommendation:', error);
      toast.error('Failed to submit vote. Please try again.');
    }
  };

  const sendMessage = async () => {
    if (newMessage.trim() === '') return;

    try {
      const messagesRef = collection(db, 'groups', groupId, 'messages');
      await addDoc(messagesRef, {
        text: newMessage,
        senderId: auth.currentUser.uid,
        senderName: auth.currentUser.displayName || 'Anonymous',
        createdAt: new Date(),
      });

      setNewMessage('');
      setShowEmojiPicker(false);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const onEmojiClick = (emojiObject) => {
    setNewMessage(prev => prev + emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  return (
    <div className="h-screen bg-gray-50 relative flex">
      <div className={`flex-1 flex flex-col relative transition-all duration-300 ${isSidebarOpen ? 'mr-20' : ''}`}>
        <header className="fixed top-0 left-0 right-0 bg-white shadow-sm z-40 px-4 py-3 flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <button onClick={() => navigate(-1)} className="p-2 rounded-full hover:bg-gray-100">
              <ArrowLeft className="w-6 h-6 text-gray-600" />
            </button>
            <div className="flex items-center space-x-2">
              <div className="p-2 rounded-xl bg-gray-100 text-xl">
                {groupDetails?.emoji || '👥'}
              </div>
              <div>
                <h2 className="font-semibold">{groupDetails?.name}</h2>
                <p className="text-xs text-gray-500">{groupDetails?.memberCount || 0} members</p>
              </div>
            </div>
          </div>
          <div className="flex space-x-2">
            <button className="p-2 rounded-full hover:bg-gray-100">
              <Users className="w-5 h-5 text-gray-600" />
            </button>
            <button className="p-2 rounded-full hover:bg-gray-100">
              <Info className="w-5 h-5 text-gray-600" />
            </button>
          </div>
        </header>

        <main className="flex-1 overflow-y-auto px-4 py-6 pt-20 pb-20">
          {messages.map((message) => (
            <div
              key={message.id}
              className={`mb-4 flex ${message.senderId === auth.currentUser.uid ? 'justify-end' : 'justify-start'}`}
            >
              <div
                className={`max-w-[70%] p-3 rounded-xl ${message.senderId === auth.currentUser.uid
                    ? 'bg-green-500 text-white'
                    : 'bg-gray-200 text-black'
                  }`}
              >
                {message.senderId !== auth.currentUser.uid && (
                  <p className="text-xs font-semibold mb-1">{message.senderName}</p>
                )}
                <p>{message.text}</p>
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </main>

        <div className="fixed bottom-0 left-0 right-0 bg-white border-t px-4 py-3">
          <div className="flex items-center space-x-2">
            <button
              className="p-2 rounded-full hover:bg-gray-100"
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            >
              <Smile className="w-5 h-5 text-gray-600" />
            </button>
            <button className="p-2 rounded-full hover:bg-gray-100">
              <ImageIcon className="w-5 h-5 text-gray-600" />
            </button>
            <div className="flex-1 relative">
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                placeholder="Type a message..."
                className="w-full pl-4 pr-10 py-2 rounded-full bg-gray-100 focus:outline-none focus:ring-2 focus:ring-green-500"
              />
              <button
                onClick={sendMessage}
                className="absolute right-2 top-1/2 -translate-y-1/2 p-2 rounded-full hover:bg-green-100"
              >
                <Send className="w-5 h-5 text-green-600" />
              </button>
            </div>
          </div>

          {showEmojiPicker && (
            <div className="absolute bottom-full right-0 mb-2">
              <EmojiPicker onEmojiClick={onEmojiClick} />
            </div>
          )}
        </div>
      </div>

      <div
        className={`fixed right-0 top-20 bottom-0 w-20 bg-white/50 backdrop-blur-sm shadow-lg z-50 transition-transform duration-300 ease-in-out ${isSidebarOpen ? 'translate-x-0' : 'translate-x-full'
          }`}
      >
        <div className="h-full max-h-[calc(100vh-200px)] overflow-y-auto py-2 flex flex-col items-center space-y-2">
          {recommendations.map((recommendation) => (
            <div
              key={recommendation.id}
              className="relative"
              onClick={() => setSelectedRecommendation(recommendation)}
            >
              <img
                src={recommendation.profileDetails?.photos?.[0] || '/default-avatar.png'}
                alt="Recommended Profile"
                className="w-10 h-10 rounded-full object-cover cursor-pointer hover:ring-2 hover:ring-green-500"
              />
            </div>
          ))}
        </div>
      </div>

      <button
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        className="fixed right-0 top-1/2 -translate-y-1/2 z-[60] bg-white shadow-md p-2 rounded-l-full"
      >
        <ChevronRight
          className={`w-6 h-6 transition-transform ${isSidebarOpen ? 'rotate-180' : ''}`}
        />
      </button>

      {selectedRecommendation && (
        <RecommendationCard
          recommendation={selectedRecommendation}
          groupMembers={groupMembers}
          onVote={handleRecommendationVote}
          onClose={() => setSelectedRecommendation(null)}
          groupId={groupId}
        />
      )}
    </div>
  );
};

export default GroupChat;