// src/pages/ChatRoom.js
import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
import { Send, ArrowLeft, Square, Mic, Smile, Heart } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import { auth } from '../firebase/firebase';
import { 
  subscribeToChatRoom, 
  sendMessage, 
  sendVoiceMessage, 
  setTypingStatus, 
  subscribeToTypingStatus, 
  addMessageReaction, 
  markChatAsRead 
} from '../firebase/messagingService';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import toast from 'react-hot-toast';
import EmojiPicker from 'emoji-picker-react';
import { motion, AnimatePresence } from 'framer-motion';
import debounce from 'lodash/debounce';

// Performance optimizations
const BATCH_SIZE = 20;
const TYPING_DEBOUNCE_MS = 500;

// Optimized Skeleton Components
const MessageSkeleton = memo(() => (
  <div className="flex animate-pulse space-x-3 p-3">
    <div className="h-10 w-10 rounded-full bg-gray-200" />
    <div className="flex-1 space-y-2">
      <div className="h-4 w-3/4 rounded bg-gray-200" />
      <div className="h-4 w-1/2 rounded bg-gray-200" />
    </div>
  </div>
));

const ChatHeaderSkeleton = memo(() => (
  <div className="flex animate-pulse items-center space-x-3 p-3">
    <div className="h-12 w-12 rounded-full bg-gray-200" />
    <div className="flex-1 space-y-2">
      <div className="h-5 w-1/2 rounded bg-gray-200" />
      <div className="h-3 w-1/4 rounded bg-gray-200" />
    </div>
  </div>
));

// Optimized Voice Recorder
const VoiceRecorder = memo(({ onRecordingComplete }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const mediaRecorderRef = useRef(null);
  const timerRef = useRef(null);

  const startRecording = useCallback(async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: 'audio/webm' });
      const chunks = [];
      mediaRecorderRef.current.ondataavailable = (e) => chunks.push(e.data);
      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(chunks, { type: 'audio/webm' });
        onRecordingComplete(audioBlob);
        setRecordingTime(0);
        stream.getTracks().forEach(track => track.stop());
      };
      mediaRecorderRef.current.start(1000);
      setIsRecording(true);
      timerRef.current = setInterval(() => setRecordingTime(prev => prev + 1), 1000);
    } catch (error) {
      toast.error('Microphone access denied');
    }
  }, [onRecordingComplete]);

  const stopRecording = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (mediaRecorderRef.current?.state === 'recording') {
      mediaRecorderRef.current.stop();
      clearInterval(timerRef.current);
      setIsRecording(false);
    }
  }, []);

  useEffect(() => () => {
    clearInterval(timerRef.current);
    if (mediaRecorderRef.current?.state === 'recording') {
      mediaRecorderRef.current.stop();
    }
  }, []);

  return (
    <div className="flex items-center">
      {isRecording ? (
        <motion.button
          whileHover={{ scale: 1.1 }}
          onClick={stopRecording}
          className="p-2 bg-red-500 text-white rounded-full hover:bg-red-600"
        >
          <Square className="w-5 h-5" />
          <span className="ml-1 text-xs">{recordingTime}s</span>
        </motion.button>
      ) : (
        <motion.button
          whileHover={{ scale: 1.1 }}
          onClick={startRecording}
          className="p-2 bg-gray-200 text-gray-600 rounded-full hover:bg-gray-300"
        >
          <Mic className="w-5 h-5" />
        </motion.button>
      )}
    </div>
  );
});

// Optimized Message Components
const VoiceMessage = memo(({ url, isOwn }) => (
  <div className="flex items-center space-x-2 p-2 bg-gray-50 rounded-lg">
    <audio src={url} controls preload="metadata" className="h-8 w-48" />
  </div>
));

const ChatMessage = memo(({ message, isOwn, onReact }) => (
  <motion.div
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.2 }}
    className={`flex ${isOwn ? 'justify-end' : 'justify-start'} mb-2`}
  >
    <div
      className={`max-w-[75%] rounded-2xl px-3 py-2 relative group ${
        isOwn ? 'bg-green-500 text-white' : 'bg-gray-100 text-gray-800'
      }`}
    >
      {message.type === 'voice' ? (
        <VoiceMessage url={message.content} isOwn={isOwn} />
      ) : (
        <p className="text-sm whitespace-pre-wrap break-words">{message.content}</p>
      )}
      <div className="flex items-center justify-between mt-1">
        <p className="text-xs opacity-70">
          {message.timestamp?.toDate().toLocaleTimeString([], { 
            hour: '2-digit', 
            minute: '2-digit' 
          })}
        </p>
        {message.read && isOwn && (
          <span className="text-green-200 text-xs ml-1">✓✓</span>
        )}
      </div>
      {message.reactions && (
        <div className="absolute -bottom-3 right-2 flex space-x-1">
          {Object.entries(message.reactions).map(([userId, reaction]) => (
            <span key={userId} className="text-xs bg-gray-200 rounded-full px-1">
              {reaction}
            </span>
          ))}
        </div>
      )}
      <motion.button
        whileHover={{ scale: 1.2 }}
        className="absolute -top-2 -right-2 opacity-0 group-hover:opacity-100 bg-white rounded-full p-1 shadow-md"
        onClick={() => onReact(message.id, '❤️')}
      >
        <Heart className="w-4 h-4 text-red-500" />
      </motion.button>
    </div>
  </motion.div>
));

const TypingIndicator = memo(({ typingUsers, currentUserId }) => {
  const typingNames = Object.keys(typingUsers)
    .filter(userId => typingUsers[userId] && userId !== currentUserId);
  
  return (
    <AnimatePresence>
      {typingNames.length > 0 && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          className="text-sm text-green-600 italic mb-2 flex items-center"
        >
          {typingNames.length > 1 ? 'Multiple users are typing...' : 'Typing...'}
          <span className="inline-flex ml-1">
            {[0, 1, 2].map(i => (
              <motion.span
                key={i}
                animate={{ y: [0, -4, 0] }}
                transition={{ repeat: Infinity, duration: 0.6, delay: i * 0.2 }}
                className="w-1 h-1 bg-green-500 rounded-full mr-1"
              />
            ))}
          </span>
        </motion.div>
      )}
    </AnimatePresence>
  );
});

const ChatRoom = () => {
  const { chatId } = useParams();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [otherUser, setOtherUser] = useState(null);
  const [chatDetails, setChatDetails] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [typingUsers, setTypingUsers] = useState({});
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const typingTimeoutRef = useRef(null);
  const lastMessageIdRef = useRef(null);
  const unreadMessageRef = useRef(null);
  const inputRef = useRef(null);

  const debouncedSetTyping = useCallback(
    debounce((chatId, userId, status) => {
      setTypingStatus(chatId, userId, status);
    }, TYPING_DEBOUNCE_MS),
    []
  );

  const scrollToElement = useCallback((ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, []);

  const scrollToBottom = useCallback(() => {
    scrollToElement(messagesEndRef);
  }, [scrollToElement]);

  const scrollToFirstUnread = useCallback((messages) => {
    const firstUnread = messages.find(msg => !msg.read && msg.senderId !== auth.currentUser.uid);
    if (firstUnread && unreadMessageRef.current) {
      unreadMessageRef.current.dataset.messageId = firstUnread.id;
      scrollToElement(unreadMessageRef);
    } else {
      scrollToBottom();
    }
  }, [scrollToBottom, scrollToElement]);

  const loadInitialData = useCallback(async () => {
    let unsubscribeMessages, unsubscribeTyping;
    try {
      setIsLoading(true);
      const chatDoc = await getDoc(doc(db, 'chatRooms', chatId));
      if (!chatDoc.exists()) throw new Error('Chat room not found');
      const chatData = chatDoc.data();
      setChatDetails(chatData);

      const otherUserId = chatData.participants.find(id => id !== auth.currentUser.uid);
      const userDoc = await getDoc(doc(db, 'profiles', otherUserId));
      if (!userDoc.exists()) throw new Error('User profile not found');
      setOtherUser(userDoc.data());

      unsubscribeMessages = subscribeToChatRoom(chatId, (newMessages) => {
        const updatedMessages = [...newMessages].reverse();
        setMessages(updatedMessages);
        
        if (!lastMessageIdRef.current) {
          scrollToFirstUnread(updatedMessages);
        } else if (updatedMessages.length > 0 && 
                  updatedMessages[updatedMessages.length - 1].id !== lastMessageIdRef.current) {
          scrollToBottom();
        }
        lastMessageIdRef.current = updatedMessages[updatedMessages.length - 1]?.id;
      });

      unsubscribeTyping = subscribeToTypingStatus(chatId, setTypingUsers);
      await markChatAsRead(chatId, auth.currentUser.uid);
      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
      toast.error('Failed to load chat');
      navigate('/messages');
    }

    return () => {
      unsubscribeMessages?.();
      unsubscribeTyping?.();
      debouncedSetTyping.cancel();
      clearTimeout(typingTimeoutRef.current);
    };
  }, [chatId, navigate, scrollToBottom, scrollToFirstUnread]);

  useEffect(() => {
    let cleanupFunc;
    loadInitialData().then(cleanup => {
      cleanupFunc = cleanup;
    });

    return () => {
      if (cleanupFunc) cleanupFunc();
    };
  }, [loadInitialData]);

  const handleSendMessage = useCallback(async (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;
    
    try {
      const messageContent = newMessage.trim();
      setNewMessage('');
      setShowEmojiPicker(false);
      debouncedSetTyping(chatId, auth.currentUser.uid, false);
      
      await sendMessage(chatId, auth.currentUser.uid, messageContent);
      scrollToBottom();
    } catch (error) {
      toast.error('Failed to send message');
      setNewMessage(newMessage);
    }
  }, [chatId, newMessage, debouncedSetTyping, scrollToBottom]);

  const handleVoiceMessage = useCallback(async (audioBlob) => {
    try {
      await sendVoiceMessage(chatId, auth.currentUser.uid, audioBlob);
      scrollToBottom();
    } catch (error) {
      toast.error('Failed to send voice message');
    }
  }, [chatId, scrollToBottom]);

  const handleTyping = useCallback((e) => {
    setNewMessage(e.target.value);
    debouncedSetTyping(chatId, auth.currentUser.uid, true);
    clearTimeout(typingTimeoutRef.current);
    typingTimeoutRef.current = setTimeout(() => {
      debouncedSetTyping(chatId, auth.currentUser.uid, false);
    }, 2000);
  }, [chatId, debouncedSetTyping]);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent default Enter behavior (like new line)
      handleSendMessage(e); // Trigger send message
    }
  }, [handleSendMessage]);

  const handleReact = useCallback(async (messageId, reaction) => {
    try {
      await addMessageReaction(chatId, messageId, auth.currentUser.uid, reaction);
    } catch (error) {
      toast.error('Failed to add reaction');
    }
  }, [chatId]);

  const onEmojiClick = useCallback((emojiObject) => {
    setNewMessage(prev => prev + emojiObject.emoji);
    setShowEmojiPicker(false);
  }, []);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col">
        <ChatHeaderSkeleton />
        <div className="flex-1 overflow-y-auto pt-20 pb-24 px-4 space-y-4">
          {Array.from({ length: 5 }).map((_, i) => (
            <MessageSkeleton key={i} />
          ))}
        </div>
      </div>
    );
  }

  if (error) return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center">
      <div className="text-red-500 text-center p-4 bg-white rounded-xl shadow-md">{error}</div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <motion.div
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        className="fixed top-0 w-full bg-white shadow-md rounded-b-xl px-4 py-3 flex items-center space-x-4 z-50 max-w-3xl mx-auto"
      >
        <motion.button
          whileHover={{ scale: 1.1 }}
          onClick={() => navigate('/messages')}
          className="p-2 hover:bg-gray-100 rounded-full text-gray-600"
        >
          <ArrowLeft className="w-6 h-6" />
        </motion.button>
        <div className="flex items-center space-x-3">
          <div className="w-12 h-12 rounded-full overflow-hidden bg-gray-100 border-2 border-gray-100">
            <img
              src={otherUser?.photos?.[0] || '/default-avatar.png'}
              alt={otherUser?.basicInfo?.name || 'User'}
              loading="lazy"
              className="h-full w-full object-cover"
            />
          </div>
          <div>
            <h2 className="font-semibold text-gray-900">{otherUser?.basicInfo?.name || 'User'}</h2>
            <p className="text-xs text-gray-500">
              {chatDetails?.activity || 'Chat'} Chatroom
            </p>
          </div>
        </div>
      </motion.div>

      <div 
        ref={messagesContainerRef}
        className="flex-1 overflow-y-auto pt-20 pb-24 px-4 max-w-3xl mx-auto"
      >
        {messages.map((message) => (
          <div
            key={message.id}
            ref={message.id === unreadMessageRef.current?.dataset.messageId ? unreadMessageRef : null}
          >
            <ChatMessage
              message={message}
              isOwn={message.senderId === auth.currentUser.uid}
              onReact={handleReact}
            />
          </div>
        ))}
        <TypingIndicator 
          typingUsers={typingUsers} 
          currentUserId={auth.currentUser.uid} 
        />
        <div ref={messagesEndRef} />
      </div>

      <form
        onSubmit={handleSendMessage}
        className="fixed bottom-0 w-full bg-white border-t p-4 shadow-md max-w-3xl mx-auto"
      >
        <div className="relative flex items-center gap-2">
          <motion.button
            whileHover={{ scale: 1.1 }}
            type="button"
            onClick={() => setShowEmojiPicker(prev => !prev)}
            className="flex-shrink-0 p-2 text-gray-500 hover:bg-gray-100 rounded-full"
          >
            <Smile className="w-5 h-5" />
          </motion.button>
          <input
            ref={inputRef}
            type="text"
            value={newMessage}
            onChange={handleTyping}
            onKeyDown={handleKeyDown}
            placeholder="Type a message..."
            className="flex-1 bg-gray-100 rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-green-500 text-sm"
          />
          <VoiceRecorder onRecordingComplete={handleVoiceMessage} />
          <motion.button
            whileHover={{ scale: 1.1 }}
            type="submit"
            disabled={!newMessage.trim()}
            className="flex-shrink-0 p-2 bg-green-500 text-white rounded-full hover:bg-green-600 disabled:opacity-50"
          >
            <Send className="w-5 h-5" />
          </motion.button>
        </div>
        <AnimatePresence>
          {showEmojiPicker && (
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              className="absolute bottom-16 right-4 z-50"
            >
              <EmojiPicker onEmojiClick={onEmojiClick} lazyLoadEmojis />
            </motion.div>
          )}
        </AnimatePresence>
      </form>
    </div>
  );
};

export default ChatRoom;