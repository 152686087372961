import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AcademicNavBar from '../../components/AcademicNavBar';
import { HomeHeader } from '../../components/homepage/HomeHeader';
import GraduateProgramApplicationFlow from './GraduateProgramApplicationFlow';
import TutorSignup from './TutorSignup';
import { db, auth } from '../../firebase/firebase';
import { 
  collection, 
  addDoc, 
  serverTimestamp, 
  doc, 
  getDoc, 
  query, 
  orderBy, 
  limit, 
  getDocs, 
  where, 
  Timestamp 
} from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import SuggestionCardFlow from './SuggestionCardFlow';
import IncompleteProfileModal from '../../components/homepage/IncompleteProfileModal';
import { motion } from 'framer-motion';

// Import remaining images
import assignments from '../../images/assignments.jpg';
import gradPrograms from '../../images/grad-programs.jpg';
import studyPartners from '../../images/study-partners.jpg';
import thumbUp from '../../images/thumb-up.png';
import tutors from '../../images/tutors.jpg';
import defaultAvatar from '../../images/default-avatar.png';
import eventBg from '../../images/event-bg.jpg';
import searchIcon from '../../images/search-icon.png';
import arrowRight from '../../images/arrow-right.png';
import defaultAction from '../../images/default-action.png';

// Event Card Component
const EventCard = ({ event }) => {
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { 
      weekday: 'short', 
      month: 'short', 
      day: 'numeric' 
    });
  };

  return (
    <motion.div 
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      className="relative rounded-2xl overflow-hidden bg-gradient-to-br from-white to-gray-50 shadow-lg hover:shadow-xl transition-all duration-300"
      style={{ 
        backgroundImage: `url(${eventBg})`, 
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <div className="absolute inset-0 bg-black/40"></div>
      <div className="relative p-6 text-white">
        <div className="flex justify-between items-start">
          <div className="bg-white/20 backdrop-blur-sm px-3 py-1 rounded-full text-sm">
            {event.category || 'Uncategorized'}
          </div>
          <div className="bg-white/90 text-gray-800 px-3 py-1 rounded-full font-bold">
            {formatDate(event.date).split(' ')[2]} {formatDate(event.date).split(' ')[1]}
          </div>
        </div>
        <h3 className="mt-4 text-xl font-bold tracking-tight">{event.title || 'Untitled Event'}</h3>
        <div className="mt-3 space-y-2 text-sm opacity-90">
          <p>⏰ {event.time || 'TBD'}</p>
          {event.location && <p>📍 {event.location}</p>}
          <p>👥 {event.attendees?.length || 0}/{event.maxAttendees || 'Unlimited'}</p>
        </div>
        <div className="mt-4 flex items-center gap-2">
          <img
            src={event.organizer?.avatar || defaultAvatar}
            alt={event.organizer?.name || 'Organizer'}
            className="w-8 h-8 rounded-full border-2 border-white object-cover"
            onError={(e) => (e.target.src = defaultAvatar)}
          />
          <span className="text-sm">{event.organizer?.name || 'Unknown'}</span>
        </div>
      </div>
    </motion.div>
  );
};

// Quick Action Button Component
const QuickActionButton = ({ image, title, onClick }) => {
  const getImageSrc = (imageName) => {
    switch(imageName) {
      case 'assignments.jpg': return assignments;
      case 'tutors.jpg': return tutors;
      case 'study-partners.jpg': return studyPartners;
      case 'grad-programs.jpg': return gradPrograms;
      default: return defaultAction;
    }
  };

  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={onClick}
      className="relative rounded-xl overflow-hidden shadow-md hover:shadow-lg transition-all duration-300 cursor-pointer"
    >
      <img 
        src={getImageSrc(image)} 
        alt={title} 
        className="w-full h-28 object-cover"
        onError={(e) => (e.target.src = defaultAction)}
      />
      <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>
      <span className="absolute bottom-3 left-3 text-white font-semibold">{title}</span>
    </motion.div>
  );
};

// Greeting Component
const UserGreeting = ({ userName, loading, userPhoto }) => {
  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return "Good Morning";
    if (hour < 18) return "Good Afternoon";
    return "Good Evening";
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="relative rounded-2xl overflow-hidden h-40 bg-gradient-to-r from-green-500 to-teal-600 text-white p-6"
      style={{ 
        backgroundImage: userPhoto ? `url(${userPhoto})` : 'none', 
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <div className="absolute inset-0 bg-black/30"></div>
      <div className="relative">
        {loading ? (
          <div className="h-10 w-72 bg-white/20 rounded animate-pulse"></div>
        ) : (
          <>
            <h1 className="text-3xl font-bold">{getGreeting()}, {userName || 'Explorer'}!</h1>
            <p className="mt-2 text-lg opacity-90">Your academic journey awaits</p>
          </>
        )}
      </div>
    </motion.div>
  );
};

export default function Academic() {
  const [currentActivity, setCurrentActivity] = useState('academic');
  const [showSuggestionModal, setShowSuggestionModal] = useState(false);
  const [showTutorSignupModal, setShowTutorSignupModal] = useState(false);
  const [showIncompleteProfile, setShowIncompleteProfile] = useState(false);
  const [profileComplete, setProfileComplete] = useState(true);
  const [missingFields, setMissingFields] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [showGraduateProgramModal, setShowGraduateProgramModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isTutor, setIsTutor] = useState(false);
  const [userName, setUserName] = useState('');
  const [userPhoto, setUserPhoto] = useState(null);

  const checkProfileCompleteness = (profile) => {
    const requiredFields = [];
    if (!profile?.basicInfo?.gender) requiredFields.push('Gender');
    if (!profile?.basicInfo?.interestedIn || profile.basicInfo.interestedIn.length === 0) requiredFields.push('Interested In');
    if (!profile?.academic?.degree) requiredFields.push('Degree Program');
    if (!profile?.academic?.yearOfStudy) requiredFields.push('Year of Study');
    if (!profile?.bio?.shortBio) requiredFields.push('Short Bio');
    if (!profile?.photos || profile.photos.length === 0) requiredFields.push('Profile Photo');
    return requiredFields;
  };

  const fetchEvents = async () => {
    try {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const eventsRef = collection(db, 'events');
      const eventsQuery = query(
        eventsRef,
        where('status', '==', 'approved'),
        where('date', '>=', today.toISOString().split('T')[0]),
        orderBy('date', 'asc'),
        limit(3)
      );
      const eventsSnapshot = await getDocs(eventsQuery);
      
      const eventsData = eventsSnapshot.docs.map(doc => {
        const data = doc.data();
        const eventDate = data.date instanceof Timestamp 
          ? data.date.toDate() 
          : new Date(data.date);
        return {
          id: doc.id,
          ...data,
          date: eventDate
        };
      });
      
      const eventsWithProfiles = await Promise.all(
        eventsData.map(async (event) => {
          if (event.organizer && event.organizer.id) {
            try {
              const organizerDoc = await getDoc(doc(db, 'profiles', event.organizer.id));
              if (organizerDoc.exists()) {
                const profileData = organizerDoc.data();
                return {
                  ...event,
                  organizer: {
                    ...event.organizer,
                    avatar: profileData.photos && profileData.photos.length > 0 
                      ? profileData.photos[0] 
                      : defaultAvatar,
                    name: profileData.basicInfo?.name || 'Unknown User'
                  }
                };
              }
            } catch (e) {
              console.error('Error fetching organizer profile:', e);
            }
          }
          return event;
        })
      );
      
      const filteredEvents = eventsWithProfiles.filter(event => {
        const eventDate = new Date(event.date);
        return eventDate >= today;
      });
      
      setEvents(filteredEvents);
    } catch (error) {
      console.error('Error fetching events:', error);
      toast.error('Failed to fetch events');
    }
  };

  const checkIfUserIsTutor = async (userId) => {
    try {
      const tutorApplicationsRef = collection(db, 'tutorApplications');
      const tutorQuery = query(tutorApplicationsRef, where('userId', '==', userId));
      const tutorSnapshot = await getDocs(tutorQuery);
      if (!tutorSnapshot.empty) setIsTutor(true);
    } catch (error) {
      console.error('Error checking tutor status:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const user = auth.currentUser;
        if (user) {
          const userDoc = await getDoc(doc(db, 'profiles', user.uid));
          if (userDoc.exists()) {
            const userProfile = userDoc.data();
            setUserName(userProfile.basicInfo?.name || '');
            setUserPhoto(userProfile.photos && userProfile.photos.length > 0 ? userProfile.photos[0] : null);
            
            const incomplete = checkProfileCompleteness(userProfile);
            setProfileComplete(incomplete.length === 0);
            setMissingFields(incomplete);
            
            await checkIfUserIsTutor(user.uid);

            // Only show incomplete profile warning if trying to become a tutor later
            // Removed automatic popup here
          }
        }

        await fetchEvents();
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search-results?q=${encodeURIComponent(searchQuery)}`);
    }
  };
  
  const handleSuggestionSubmit = async (formData) => {
    toast.loading('Submitting your suggestion...', { id: 'suggestionSubmit' });
    try {
      await addDoc(collection(db, 'resources'), {
        ...formData,
        likes: 0,
        createdAt: serverTimestamp(),
        status: 'active',
        submittedBy: auth.currentUser?.uid || 'anonymous',
      });
      toast.success('Thank you for your suggestion!', { id: 'suggestionSubmit' });
      navigate('/resources');
    } catch (error) {
      console.error('Error submitting resource:', error);
      toast.error('Failed to submit suggestion. Please try again.', { id: 'suggestionSubmit' });
    }
  };

  const checkProfileBeforeTutorAction = () => {
    if (!profileComplete) {
      setShowIncompleteProfile(true);
      return false;
    }
    return true;
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      <HomeHeader 
        currentActivity={currentActivity}
        setCurrentActivity={setCurrentActivity}
      />

      <main className="px-6 py-8 pt-24 pb-28 max-w-6xl mx-auto">
        <UserGreeting userName={userName} loading={loading} userPhoto={userPhoto} />
        
        {/* Search Bar */}
        <div className="mt-8 relative max-w-2xl mx-auto">
          <form onSubmit={handleSearch}>
            <input 
              type="text" 
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Explore tutors, events, and more..." 
              className="w-full h-14 px-6 rounded-full bg-white shadow-lg border-none focus:ring-4 focus:ring-green-200 focus:outline-none text-gray-700 placeholder-gray-400 transition-all duration-300"
            />
            <button type="submit" className="absolute right-2 top-1/2 -translate-y-1/2 bg-green-500 p-2 rounded-full">
              <img 
                src={searchIcon} 
                alt="Search" 
                className="w-6 h-6" 
                onError={(e) => (e.target.src = searchIcon)}
              />
            </button>
          </form>
        </div>

        {/* Dashboard Grid Layout */}
        <div className="mt-10 grid grid-cols-1 lg:grid-cols-12 gap-8">
          {/* Main Content */}
          <div className="lg:col-span-8 space-y-8">
            {/* Primary Actions */}
            <section>
              <h2 className="text-2xl font-bold text-gray-800 mb-6">Your Academic Toolkit</h2>
              <div className="grid grid-cols-2 gap-6">
                <QuickActionButton 
                  image="assignments.jpg" 
                  title="Assignment Hub" 
                  onClick={() => navigate('/assignment-hub')}
                />
                <QuickActionButton 
                  image="tutors.jpg" 
                  title="Find a Tutor" 
                  onClick={() => navigate('/find-tutors')}
                />
                <QuickActionButton 
                  image="study-partners.jpg" 
                  title="Study Partners" 
                  onClick={() => navigate('/meetups')}
                />
                <QuickActionButton 
                  image="grad-programs.jpg" 
                  title="Graduate Programs" 
                  onClick={() => setShowGraduateProgramModal(true)}
                />
              </div>
            </section>

            {/* Upcoming Events */}
            <section>
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold text-gray-800">What's Happening</h2>
                <button 
                  onClick={() => navigate('/academic-events')}
                  className="text-green-600 font-semibold flex items-center gap-2 hover:text-green-700 transition-colors"
                >
                  See All 
                  <img 
                    src={arrowRight} 
                    alt="Arrow" 
                    className="w-5 h-5" 
                    onError={(e) => (e.target.src = arrowRight)}
                  />
                </button>
              </div>
              <div className="grid gap-6">
                {loading ? (
                  [...Array(2)].map((_, i) => (
                    <div key={i} className="h-64 bg-gray-200 rounded-2xl animate-pulse"></div>
                  ))
                ) : events.length > 0 ? (
                  events.filter(event => event.status === 'approved').map(event => (
                    <EventCard key={event.id} event={event} />
                  ))
                ) : (
                  <div className="text-center py-12 bg-white rounded-2xl shadow-md">
                    <p className="text-gray-600 font-semibold">No upcoming events yet</p>
                    <button 
                      onClick={() => navigate('/academic-events')}
                      className="mt-4 px-6 py-2 bg-green-600 text-white rounded-full hover:bg-green-700 transition-colors"
                    >
                      Create One
                    </button>
                  </div>
                )}
              </div>
            </section>
          </div>

          {/* Sidebar */}
          <div className="lg:col-span-4 space-y-8">
            {/* Quick Actions */}
            <div className="bg-white rounded-2xl shadow-md p-6">
              <h3 className="text-xl font-bold text-gray-800 mb-4">Take Action</h3>
              <div className="space-y-4">
                <motion.button 
                  whileHover={{ x: 5 }}
                  onClick={() => checkProfileBeforeTutorAction() && setShowTutorSignupModal(true)}
                  className="w-full flex items-center gap-4 p-3 bg-gradient-to-r from-green-50 to-teal-50 rounded-xl hover:bg-green-100 transition-all"
                >
                  <div className="w-10 h-10 bg-green-500 rounded-full flex items-center justify-center text-white">
                    <span className="text-lg">👨‍🏫</span>
                  </div>
                  <span className="font-semibold text-gray-700">
                    {isTutor ? 'Manage Tutoring' : 'Become a Tutor'}
                  </span>
                </motion.button>
                <motion.button 
                  whileHover={{ x: 5 }}
                  onClick={() => setShowSuggestionModal(true)}
                  className="w-full flex items-center gap-4 p-3 bg-gradient-to-r from-green-50 to-teal-50 rounded-xl hover:bg-green-100 transition-all"
                >
                  <div className="w-10 h-10 bg-green-500 rounded-full flex items-center justify-center text-white">
                    <span className="text-lg">📚</span>
                  </div>
                  <span className="font-semibold text-gray-700">Share a Resource</span>
                </motion.button>
              </div>
            </div>

            {/* Academic Game Section */}
            <div className="bg-white rounded-2xl shadow-md p-6">
              <h3 className="text-xl font-bold text-gray-800 mb-4">Daily Academic Challenge</h3>
              <div className="text-center py-12">
                <p className="text-gray-600 mb-4">Test your knowledge with today's question!</p>
                <button 
                  onClick={() => navigate('/academic-game')}
                  className="px-6 py-2 bg-green-600 text-white rounded-full hover:bg-green-700 transition-colors"
                >
                  Start Challenge
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* Modals */}
      {showSuggestionModal && (
        <SuggestionCardFlow 
          isOpen={showSuggestionModal}
          onClose={() => setShowSuggestionModal(false)}
          onSubmit={handleSuggestionSubmit}
        />
      )}

      <TutorSignup
        open={showTutorSignupModal}
        onClose={() => setShowTutorSignupModal(false)}
      />

      <IncompleteProfileModal
        missingFields={missingFields}
        onComplete={() => navigate('/edit-profile')}
        open={showIncompleteProfile}
        onClose={() => setShowIncompleteProfile(false)}
      />

      <GraduateProgramApplicationFlow
        isOpen={showGraduateProgramModal}
        onClose={() => setShowGraduateProgramModal(false)}
      />

      <AcademicNavBar />
    </div>
  );
}