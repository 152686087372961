import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import AcademicNavBar from '../../components/AcademicNavBar';
import { HomeHeader } from '../../components/homepage/HomeHeader';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'react-hot-toast';
import { db, auth } from '../../firebase/firebase';
import { 
  collection, 
  doc, 
  getDocs, 
  setDoc, 
  query, 
  orderBy, 
  limit, 
  where, 
  Timestamp, getDoc 
} from 'firebase/firestore';

const QUIZ_TIME_LIMIT = 30; // 30 seconds per question
const QUESTIONS_PER_DAY = 10; // 10 questions daily

// Daily Quiz Component
const DailyQuiz = ({ user, onComplete }) => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [timeLeft, setTimeLeft] = useState(QUIZ_TIME_LIMIT);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hasCompletedToday, setHasCompletedToday] = useState(false);

  // Fetch academic/social questions from Open Trivia Database API
  const fetchQuestions = useCallback(async () => {
    try {
      const today = new Date().toISOString().split('T')[0];
      const localStorageKey = `quiz_${today}`;
      const cachedQuestions = localStorage.getItem(localStorageKey);

      if (cachedQuestions) {
        setQuestions(JSON.parse(cachedQuestions));
        setLoading(false);
        return;
      }

      // Categories: Science (17), History (23), Geography (22), General Knowledge (9), Entertainment (11, 15)
      const categories = [17, 23, 22, 9, 11, 15];
      const category = categories[Math.floor(Math.random() * categories.length)];
      const response = await fetch(
        `https://opentdb.com/api.php?amount=${QUESTIONS_PER_DAY}&category=${category}&type=multiple&encode=base64`
      );
      const data = await response.json();
      if (data.response_code !== 0) throw new Error('API failed to return questions');

      const decodedQuestions = data.results.map((q) => ({
        question: atob(q.question),
        options: [...q.incorrect_answers, q.correct_answer].map(atob).sort(() => Math.random() - 0.5),
        correctAnswer: atob(q.correct_answer),
      }));

      setQuestions(decodedQuestions);
      localStorage.setItem(localStorageKey, JSON.stringify(decodedQuestions));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching questions:', error);
      toast.error('Failed to load quiz questions. Please try again.');
      setLoading(false);
    }
  }, []);

  // Check if user has completed today’s quiz
  const checkQuizCompletion = useCallback(async () => {
    if (!user) return;
    const today = new Date().toISOString().split('T')[0];
    const q = query(
      collection(db, 'quizScores'),
      where('userId', '==', user.uid),
      where('date', '==', today)
    );
    const snapshot = await getDocs(q);
    setHasCompletedToday(!snapshot.empty);
  }, [user]);

  useEffect(() => {
    fetchQuestions();
    checkQuizCompletion();
  }, [fetchQuestions, checkQuizCompletion]);

  // Timer logic with color transition
  useEffect(() => {
    if (timeLeft > 0 && !isSubmitted && !hasCompletedToday && questions.length > 0) {
      const timer = setInterval(() => setTimeLeft((prev) => prev - 1), 1000);
      return () => clearInterval(timer);
    } else if (timeLeft === 0 && !isSubmitted) {
      handleNextQuestion();
    }
  }, [timeLeft, isSubmitted, hasCompletedToday, questions]);

  const handleAnswerSelect = (answer) => {
    if (!isSubmitted && !hasCompletedToday) {
      setUserAnswers((prev) => ({ ...prev, [currentQuestionIndex]: answer }));
    }
  };

  const handleNextQuestion = async () => {
    if (hasCompletedToday) return;

    setIsSubmitted(true);
    const currentQuestion = questions[currentQuestionIndex];
    const selectedAnswer = userAnswers[currentQuestionIndex];
    const isCorrect = selectedAnswer === currentQuestion.correctAnswer;
    if (isCorrect) toast.success('Correct! +10 points', { duration: 1000 });
    else if (selectedAnswer) toast.error(`Wrong! Correct: ${currentQuestion.correctAnswer}`, { duration: 1000 });

    if (currentQuestionIndex === questions.length - 1) {
      const totalScore = calculateTotalScore();
      if (user) {
        const today = new Date().toISOString().split('T')[0];
        await setDoc(doc(db, 'quizScores', `${user.uid}_${today}`), {
          userId: user.uid,
          score: totalScore,
          date: today,
          timestamp: Timestamp.now(),
          answers: userAnswers,
        });
      }
      onComplete(totalScore);
      setHasCompletedToday(true);
    } else {
      setTimeout(() => {
        setCurrentQuestionIndex((prev) => prev + 1);
        setTimeLeft(QUIZ_TIME_LIMIT);
        setIsSubmitted(false);
      }, 1500); // Longer delay for feedback visibility
    }
  };

  const calculateTotalScore = () => {
    return questions.reduce((score, q, idx) => {
      return score + (userAnswers[idx] === q.correctAnswer ? 10 : 0);
    }, 0);
  };

  if (loading) return <div className="text-center py-8 text-gray-600">Loading your challenge...</div>;
  if (!questions.length) return <div className="text-center py-8 text-red-500">Failed to load questions!</div>;

  const currentQuestion = questions[currentQuestionIndex];
  const timerColor = timeLeft > 20 ? 'text-green-500' : timeLeft > 10 ? 'text-yellow-500' : 'text-red-500';

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      className="bg-gradient-to-br from-white to-gray-100 rounded-2xl shadow-xl p-6 relative overflow-hidden"
    >
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_rgba(76,175,80,0.1)_0,_transparent_70%)]"></div>
      <h2 className="text-2xl font-bold text-gray-800 mb-4 z-10 relative">
        Daily Challenge ({currentQuestionIndex + 1}/{QUESTIONS_PER_DAY})
      </h2>
      {hasCompletedToday ? (
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-gray-600 text-center py-4 z-10 relative"
        >
          You’ve conquered today’s challenge! Return tomorrow for more.
        </motion.p>
      ) : (
        <AnimatePresence mode="wait">
          <motion.div
            key={currentQuestionIndex}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.4, ease: 'easeInOut' }}
            className="relative z-10"
          >
            <div className="flex justify-between items-center mb-6">
              <p className="text-lg font-medium text-gray-700 max-w-md">{currentQuestion.question}</p>
              <motion.span
                animate={{ scale: [1, 1.1, 1] }}
                transition={{ repeat: timeLeft <= 10 ? Infinity : 0, duration: 0.5 }}
                className={`text-xl font-bold ${timerColor}`}
              >
                {timeLeft}s
              </motion.span>
            </div>
            <div className="grid grid-cols-2 gap-4">
              {currentQuestion.options.map((option, index) => (
                <motion.button
                  key={index}
                  whileHover={{ scale: 1.05, boxShadow: '0 4px 15px rgba(0,0,0,0.1)' }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleAnswerSelect(option)}
                  disabled={isSubmitted}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className={`p-4 rounded-xl border text-gray-700 bg-white shadow-md transition-all duration-300 ${
                    isSubmitted
                      ? option === currentQuestion.correctAnswer
                        ? 'bg-green-200 border-green-500 scale-105'
                        : userAnswers[currentQuestionIndex] === option
                        ? 'bg-red-200 border-red-500'
                        : 'bg-gray-100 border-gray-200'
                      : userAnswers[currentQuestionIndex] === option
                      ? 'bg-green-100 border-green-500'
                      : 'bg-white border-gray-300 hover:bg-gray-50'
                  }`}
                >
                  {option}
                </motion.button>
              ))}
            </div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleNextQuestion}
              className="mt-6 w-full bg-gradient-to-r from-green-500 to-teal-500 text-white py-3 rounded-full shadow-lg hover:from-green-600 hover:to-teal-600 transition-all disabled:bg-gray-400 disabled:cursor-not-allowed z-10 relative"
              disabled={isSubmitted && currentQuestionIndex < questions.length - 1}
            >
              {currentQuestionIndex === questions.length - 1 ? 'Finish Challenge' : 'Next Question'}
            </motion.button>
          </motion.div>
        </AnimatePresence>
      )}
      <div className="mt-6 flex justify-center gap-2 z-10 relative">
        {questions.map((_, idx) => (
          <motion.span
            key={idx}
            animate={{ scale: idx === currentQuestionIndex ? 1.2 : 1 }}
            className={`w-3 h-3 rounded-full ${
              idx < currentQuestionIndex
                ? 'bg-green-500'
                : idx === currentQuestionIndex
                ? 'bg-teal-500'
                : 'bg-gray-300'
            }`}
          />
        ))}
      </div>
    </motion.div>
  );
};

// Leaderboard Component
const Leaderboard = ({ entries }) => {
    const defaultEmoji = '👻'; // You could use any emoji like 🧑‍🎓, 📚, etc.
  
    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        className="bg-gradient-to-br from-white to-gray-100 rounded-2xl shadow-xl p-6"
      >
        <h2 className="text-2xl font-bold text-gray-800 mb-4">Top Scholars</h2>
        <div className="space-y-4">
          {entries.length > 0 ? (
            entries.map((entry) => (
              <motion.div
                key={entry.rank}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: entry.rank * 0.1 }}
                className="flex items-center justify-between p-3 bg-white rounded-lg shadow-md hover:bg-gray-50 transition-all"
              >
                <div className="flex items-center gap-3">
                  <span className="w-8 h-8 flex items-center justify-center bg-gradient-to-r from-green-500 to-teal-500 text-white rounded-full font-semibold">
                    {entry.rank}
                  </span>
                  {entry.avatar ? (
                    <img
                      src={entry.avatar}
                      alt={entry.name}
                      className="w-10 h-10 rounded-full object-cover border-2 border-green-500"
                      onError={(e) => (e.target.src = 'https://via.placeholder.com/40')}
                    />
                  ) : (
                    <span className="w-10 h-10 flex items-center justify-center bg-gray-200 rounded-full border-2 border-green-500 text-2xl">
                      {defaultEmoji}
                    </span>
                  )}
                  <span className="text-gray-700 font-medium">{entry.name}</span>
                </div>
                <span className="text-teal-600 font-semibold">{entry.totalScore} pts</span>
              </motion.div>
            ))
          ) : (
            <p className="text-gray-600 text-center">Be the first to score!</p>
          )}
        </div>
      </motion.div>
    );
  };
export default function AcademicGame() {
  const [currentActivity, setCurrentActivity] = useState('academic');
  const [userScore, setUserScore] = useState(0);
  const [leaderboard, setLeaderboard] = useState([]);
  const navigate = useNavigate();
  const user = auth.currentUser;

  const fetchLeaderboard = useCallback(async () => {
    try {
      const scoresQuery = query(collection(db, 'quizScores'));
      const scoresSnapshot = await getDocs(scoresQuery);
      
      // Aggregate scores by user
      const scoreMap = new Map();
      scoresSnapshot.forEach((doc) => {
        const data = doc.data();
        const currentScore = scoreMap.get(data.userId) || 0;
        scoreMap.set(data.userId, currentScore + data.score);
      });
  
      // Convert to array and fetch user profiles
      const leaderboardData = await Promise.all(
        Array.from(scoreMap.entries()).map(async ([userId, totalScore]) => {
          const profileDoc = await getDoc(doc(db, 'profiles', userId));
          const profileData = profileDoc.exists() ? profileDoc.data() : {};
          return {
            name: profileData.basicInfo?.name || 'Anonymous',
            avatar: profileData.photos?.[0] || null,
            totalScore,
            userId // Adding userId to help with ranking ties
          };
        })
      );
  
      // Sort by score (descending) and assign ranks
      const sortedLeaderboard = leaderboardData
        .sort((a, b) => b.totalScore - a.totalScore)
        .map((entry, index, array) => {
          // Handle ties by checking previous entry's score
          const previousScore = index > 0 ? array[index - 1].totalScore : null;
          const rank = index === 0 || entry.totalScore !== previousScore 
            ? index + 1 
            : array[index - 1].rank;
            
          return {
            ...entry,
            rank
          };
        })
        .slice(0, 5); // Top 5 only
      
      setLeaderboard(sortedLeaderboard);
    } catch (error) {
      console.error('Error fetching leaderboard:', error);
      toast.error('Failed to load leaderboard');
    }
  }, []);
  const fetchUserScore = useCallback(async () => {
    if (!user) return;
    const today = new Date().toISOString().split('T')[0];
    const q = query(
      collection(db, 'quizScores'),
      where('userId', '==', user.uid),
      where('date', '==', today)
    );
    const snapshot = await getDocs(q);
    if (!snapshot.empty) {
      const doc = snapshot.docs[0];
      setUserScore(doc.data().score);
    }
  }, [user]);

  useEffect(() => {
    fetchLeaderboard();
    fetchUserScore();
  }, [fetchLeaderboard, fetchUserScore]);

  const handleQuizComplete = (totalScore) => {
    setUserScore(totalScore);
    fetchLeaderboard();
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      <HomeHeader currentActivity={currentActivity} setCurrentActivity={setCurrentActivity} />

      <main className="px-6 py-8 pt-24 pb-28 max-w-6xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="relative rounded-2xl overflow-hidden h-40 bg-gradient-to-r from-green-500 to-teal-600 text-white p-6 mb-8 shadow-lg"
        >
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,_rgba(255,255,255,0.2)_0,_transparent_70%)]"></div>
          <div className="relative z-10">
            <h1 className="text-3xl font-bold">Daily Academic Challenge</h1>
            <p className="mt-2 text-lg opacity-90">
              Conquer {QUESTIONS_PER_DAY} questions and rise to the top!
            </p>
          </div>
        </motion.div>

        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
          <div className="lg:col-span-8">
            <DailyQuiz user={user} onComplete={handleQuizComplete} />
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="mt-6 text-center"
            >
              <p className="text-gray-600 text-lg">
                Your Score Today:{' '}
                <motion.span
                  animate={{ scale: [1, 1.1, 1] }}
                  transition={{ duration: 0.5 }}
                  className="font-semibold text-teal-600"
                >
                  {userScore} points
                </motion.span>
              </p>
            </motion.div>
          </div>

          <div className="lg:col-span-4">
            <Leaderboard entries={leaderboard} />
          </div>
        </div>

        <div className="mt-8 text-center">
          <motion.button
            whileHover={{ scale: 1.05, boxShadow: '0 4px 15px rgba(0,0,0,0.2)' }}
            whileTap={{ scale: 0.95 }}
            onClick={() => navigate('/academic')}
            className="px-6 py-3 bg-gradient-to-r from-gray-600 to-gray-700 text-white rounded-full shadow-md hover:from-gray-700 hover:to-gray-800 transition-all"
          >
            Back to Dashboard
          </motion.button>
        </div>
      </main>

      <AcademicNavBar />
    </div>
  );
}