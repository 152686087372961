import React, { useState, useRef, useEffect } from 'react';
import { Heart, MessageCircle, MoreHorizontal, Trash2, Flag, Play, Pause, Bell, Share, Bookmark, Copy } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import Comments from './Comments';

const reportReasons = [
  "Inappropriate content",
  "Spam or misleading",
  "Harassment or bullying",
  "Hate speech",
  "Violence or threats",
  "Other"
];

const Post = ({ 
  post, 
  userProfile,
  activeCommentId,
  onToggleComment,
  onToggleLike,
  onAddComment,
  onDeletePost,
  formatTimestamp,
  isOwnPost = false
}) => {
  const [showActions, setShowActions] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [selectedReason, setSelectedReason] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [reportSubmitted, setReportSubmitted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioProgress, setAudioProgress] = useState(0);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [copied, setCopied] = useState(false);
  const [likeAnimation, setLikeAnimation] = useState(false);
  const audioRef = useRef(null);
  const progressIntervalRef = useRef(null);
  const actionsRef = useRef(null);
  const shareOptionsRef = useRef(null);
  
  // Handle clicks outside the menus
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionsRef.current && !actionsRef.current.contains(event.target)) {
        setShowActions(false);
      }
      if (shareOptionsRef.current && !shareOptionsRef.current.contains(event.target)) {
        setShowShareOptions(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle ESC key to close modals
  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === 'Escape') {
        setShowActions(false);
        setShowReportModal(false);
        setShowShareOptions(false);
      }
    };
    
    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, []);

  // Clean up audio resources on unmount
  useEffect(() => {
    return () => {
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
      if (audioRef.current) {
        audioRef.current.pause();
      }
    };
  }, []);

  // Update audio progress while playing
  useEffect(() => {
    if (isPlaying && post.media?.type === 'audio') {
      progressIntervalRef.current = setInterval(() => {
        if (audioRef.current) {
          const progress = (audioRef.current.currentTime / audioRef.current.duration) * 100;
          setAudioProgress(progress || 0);
          
          if (audioRef.current.ended) {
            setIsPlaying(false);
            clearInterval(progressIntervalRef.current);
          }
        }
      }, 100);
    } else {
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
    }
    
    return () => {
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
    };
  }, [isPlaying, post.media?.type]);

  const handleDelete = (e) => {
    e.stopPropagation(); // Stop event propagation
    if (window.confirm('Are you sure you want to delete this post?')) {
      onDeletePost(post.id);
    }
    setShowActions(false);
  };

  const handleReport = (e) => {
    e.stopPropagation(); // Stop event propagation
    setShowReportModal(true);
    setShowActions(false);
  };

  const submitReport = () => {
    console.log('Report submitted:', {
      postId: post.id,
      reason: selectedReason,
      additionalInfo,
      reportedAt: new Date(),
      reportedBy: userProfile?.id
    });
    
    setReportSubmitted(true);
    setTimeout(() => {
      setShowReportModal(false);
      setReportSubmitted(false);
      setSelectedReason('');
      setAdditionalInfo('');
    }, 2000);
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const handleMediaToggle = () => {
    if (post.media?.type === 'audio' && audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play().catch(error => {
          console.error("Error playing audio:", error);
        });
      }
      setIsPlaying(!isPlaying);
    } else {
      const mediaElement = document.getElementById(`media-${post.id}`);
      if (mediaElement) {
        if (isPlaying) {
          mediaElement.pause();
        } else {
          mediaElement.play().catch(error => {
            console.error("Error playing media:", error);
          });
        }
        setIsPlaying(!isPlaying);
      }
    }
  };

  const handleBookmark = (e) => {
    if (e) e.stopPropagation();
    setIsBookmarked(!isBookmarked);
    // Show a toast notification
    const element = document.getElementById(`bookmark-toast-${post.id}`);
    if (element) {
      element.classList.remove('opacity-0');
      element.classList.add('opacity-100');
      setTimeout(() => {
        element.classList.remove('opacity-100');
        element.classList.add('opacity-0');
      }, 2000);
    }
  };

  const handleShare = (e) => {
    e.stopPropagation();
    setShowShareOptions(!showShareOptions);
    setShowActions(false);
  };

  const copyToClipboard = (e) => {
    e.stopPropagation();
    const postUrl = `https://varsitylink.com/posts/${post.id}`;
    navigator.clipboard.writeText(postUrl).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
    setShowShareOptions(false);
  };

  const handleLike = () => {
    onToggleLike(post.id);
    if (!post.isLiked) {
      setLikeAnimation(true);
      setTimeout(() => setLikeAnimation(false), 1000);
    }
  };

  const toggleActions = (e) => {
    e.stopPropagation();
    setShowActions(!showActions);
    setShowShareOptions(false);
  };

  // Get time ago format (e.g. "3m" for 3 minutes ago)
  const getTimeAgo = (date) => {
    const now = new Date();
    const postDate = new Date(date);
    const diffInSeconds = Math.floor((now - postDate) / 1000);
    
    if (diffInSeconds < 60) return `${diffInSeconds}s`;
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h`;
    return `${Math.floor(diffInSeconds / 86400)}d`;
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="bg-white rounded-xl shadow-md hover:shadow-lg transition-all duration-300 mb-4 overflow-hidden border border-gray-100 relative"
    >
      {/* Bookmark toast notification */}
      <div 
        id={`bookmark-toast-${post.id}`}
        className="absolute top-4 right-4 bg-gray-800 text-white py-2 px-4 rounded-lg z-50 opacity-0 transition-opacity duration-300 text-sm"
      >
        {isBookmarked ? 'Post saved to bookmarks' : 'Removed from bookmarks'}
      </div>
      
      {/* Like animation */}
      <AnimatePresence>
        {likeAnimation && (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 1.5 }}
            className="absolute inset-0 flex items-center justify-center pointer-events-none z-10"
          >
            <Heart className="w-24 h-24 text-red-500 fill-current" />
          </motion.div>
        )}
      </AnimatePresence>
      
      <div className="p-6">
        {/* Header */}
        <div className="flex justify-between items-start mb-4">
          <div className="flex items-center gap-4">
            <div className="relative group cursor-pointer">
              <img
                src={post.author?.avatar || "/api/placeholder/48/48"}
                alt={post.author?.name}
                className="w-12 h-12 rounded-full object-cover ring-2 ring-blue-50 transition-all duration-300 group-hover:ring-blue-200"
              />
              {post.author?.isOnline && (
                <div className="absolute -bottom-1 -right-1 w-4 h-4 bg-green-400 rounded-full border-2 border-white" />
              )}
              <div className="absolute inset-0 bg-black/10 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
            </div>
            <div>
              <div className="flex items-baseline gap-3">
                <h3 className="font-semibold text-gray-900 hover:text-blue-600 transition-colors duration-200 cursor-pointer">{post.author?.name}</h3>
              </div>
              <p className="text-sm text-gray-500">@{post.author?.username} · <span className="text-xs">{getTimeAgo(post.createdAt)}</span></p>
            </div>
          </div>

          <div className="relative" ref={actionsRef}>
            <button 
              onClick={toggleActions}
              className="p-2 hover:bg-gray-50 rounded-full transition-colors"
            >
              <MoreHorizontal className="w-5 h-5 text-gray-400" />
            </button>

            {showActions && (
              <div
                className="absolute right-0 top-10 w-48 bg-white rounded-xl shadow-lg z-10 py-1 border border-gray-100"
              >
                <button
                  onClick={handleBookmark}
                  className="w-full px-4 py-2.5 text-left text-gray-600 hover:bg-gray-50 flex items-center gap-2 transition-colors"
                >
                  <Bookmark className={`w-4 h-4 ${isBookmarked ? 'fill-current text-blue-500' : ''}`} />
                  {isBookmarked ? 'Saved' : 'Save post'}
                </button>
                
                <button
                  onClick={handleShare}
                  className="w-full px-4 py-2.5 text-left text-gray-600 hover:bg-gray-50 flex items-center gap-2 transition-colors"
                >
                  <Share className="w-4 h-4" />
                  Share post
                </button>
                
                {isOwnPost ? (
                  <button
                    onClick={handleDelete}
                    className="w-full px-4 py-2.5 text-left text-red-600 hover:bg-red-50 flex items-center gap-2 transition-colors"
                  >
                    <Trash2 className="w-4 h-4" />
                    Delete post
                  </button>
                ) : (
                  <button
                    onClick={handleReport}
                    className="w-full px-4 py-2.5 text-left text-gray-600 hover:bg-gray-50 flex items-center gap-2 transition-colors"
                  >
                    <Flag className="w-4 h-4" />
                    Report post
                  </button>
                )}
              </div>
            )}
            
            <div className="relative" ref={shareOptionsRef}>
              {showShareOptions && (
                <div
                  className="absolute right-0 top-10 w-48 bg-white rounded-xl shadow-lg z-10 py-1 border border-gray-100"
                >
                  <button
                    onClick={copyToClipboard}
                    className="w-full px-4 py-2.5 text-left text-gray-600 hover:bg-gray-50 flex items-center gap-2 transition-colors"
                  >
                    <Copy className="w-4 h-4" />
                    {copied ? 'Copied!' : 'Copy link'}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Content */}
        <p className="text-gray-800 whitespace-pre-wrap mb-4 leading-relaxed">{post.content}</p>

        {/* Media Section */}
        {post.media && (
          <div className="mb-4 rounded-xl overflow-hidden bg-gray-50 group">
            {post.media.type === 'image' && (
              <div className="overflow-hidden">
                <img
                  src={post.media.url}
                  alt="Post content"
                  className="w-full object-cover max-h-96 transition-transform duration-500 group-hover:scale-105"
                />
              </div>
            )}
            {post.media.type === 'video' && (
              <div className="relative">
                <video
                  id={`media-${post.id}`}
                  src={post.media.url}
                  controls
                  className="w-full rounded-lg"
                  onPlay={() => setIsPlaying(true)}
                  onPause={() => setIsPlaying(false)}
                  onEnded={() => setIsPlaying(false)}
                />
              </div>
            )}
            {post.media.type === 'audio' && (
              <div className="p-4 bg-gradient-to-r from-blue-50 to-indigo-50 rounded-lg">
                <div className="flex items-center gap-4">
                  <button
                    onClick={handleMediaToggle}
                    className="w-12 h-12 flex items-center justify-center bg-white rounded-full shadow-sm hover:shadow-md transition-all duration-300"
                  >
                    {isPlaying ? (
                      <Pause className="w-6 h-6 text-blue-600" />
                    ) : (
                      <Play className="w-6 h-6 text-blue-600" />
                    )}
                  </button>
                  <audio
                    ref={audioRef}
                    id={`media-${post.id}`}
                    src={post.media.url}
                    className="hidden"
                    onEnded={() => {
                      setIsPlaying(false);
                      setAudioProgress(0);
                    }}
                  />
                  <div className="flex-1">
                    <div className="h-2 bg-blue-100 rounded-full overflow-hidden">
                      <div 
                        className="h-full bg-blue-500 transition-all duration-300"
                        style={{ width: `${audioProgress}%` }}
                      />
                    </div>
                    <div className="flex justify-between mt-2 text-xs text-gray-500">
                      <span>{post.media.title || "Audio"}</span>
                      <span>{post.media.duration || "--:--"}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {/* Tags */}
        {post.study_tags?.length > 0 && (
          <div className="flex flex-wrap gap-2 mb-4">
            {post.study_tags.map(tag => (
              <span
                key={tag}
                className="px-3 py-1 bg-blue-50 text-blue-600 rounded-full text-sm font-medium hover:bg-blue-100 transition-colors cursor-pointer"
              >
                #{tag}
              </span>
            ))}
          </div>
        )}

        {/* Actions */}
        <div className="flex items-center gap-6 pt-4 border-t border-gray-100">
          <button 
            onClick={handleLike}
            className={`flex items-center gap-2 text-sm transition-colors ${
              post.isLiked 
                ? 'text-red-500 hover:text-red-600' 
                : 'text-gray-500 hover:text-gray-700'
            }`}
          >
            <Heart className={`w-5 h-5 ${post.isLiked ? 'fill-current' : ''} transition-transform hover:scale-110`} />
            <span>{post.likesCount || 0}</span>
          </button>
          
          <button 
            onClick={() => onToggleComment(post.id)}
            className="flex items-center gap-2 text-sm text-gray-500 hover:text-gray-700 transition-colors"
          >
            <MessageCircle className="w-5 h-5 transition-transform hover:scale-110" />
            <span>{post.commentsCount || 0}</span>
          </button>
          
          <button
            onClick={handleBookmark}
            className={`flex items-center gap-2 text-sm transition-colors ${
              isBookmarked
                ? 'text-blue-500 hover:text-blue-600'
                : 'text-gray-500 hover:text-gray-700'
            }`}
          >
            <Bookmark className={`w-5 h-5 ${isBookmarked ? 'fill-current' : ''} transition-transform hover:scale-110`} />
          </button>
          
          <button
            onClick={handleShare}
            className="flex items-center gap-2 text-sm text-gray-500 hover:text-gray-700 transition-colors"
          >
            <Share className="w-5 h-5 transition-transform hover:scale-110" />
          </button>
          
          <span className="text-sm text-gray-400 ml-auto">
            {formatDate(post.lastSharedAt || post.createdAt)}
          </span>
        </div>
      </div>

      {/* Comments Section */}
      {activeCommentId === post.id && (
        <Comments 
          post={post}
          userProfile={userProfile}
          onAddComment={onAddComment}
          formatTimestamp={formatTimestamp}
        />
      )}

      {/* Report Modal */}
      {showReportModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50 backdrop-blur-sm">
          <div
            className="bg-white rounded-2xl w-full max-w-md shadow-xl"
            onClick={(e) => e.stopPropagation()}
          >
            {!reportSubmitted ? (
              <div className="p-6">
                <h3 className="text-lg font-semibold mb-4">Report Post</h3>
                <div className="space-y-3">
                  {reportReasons.map((reason) => (
                    <label key={reason} className="flex items-center p-2 hover:bg-gray-50 rounded-lg cursor-pointer transition-colors">
                      <input
                        type="radio"
                        name="report-reason"
                        value={reason}
                        checked={selectedReason === reason}
                        onChange={(e) => setSelectedReason(e.target.value)}
                        className="w-4 h-4 text-blue-600"
                      />
                      <span className="ml-3">{reason}</span>
                    </label>
                  ))}
                </div>
                <div className="mt-4 relative">
                  <textarea
                    value={additionalInfo}
                    onChange={(e) => setAdditionalInfo(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg resize-none"
                    rows="4"
                    placeholder="Additional details (optional)"
                  />
                  <Bell className="absolute right-3 bottom-3 w-5 h-5 text-gray-400" />
                </div>
                <div className="mt-6 flex gap-3">
                  <button
                    onClick={() => setShowReportModal(false)}
                    className="flex-1 px-4 py-2 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={submitReport}
                    disabled={!selectedReason}
                    className={`flex-1 px-4 py-2 rounded-lg transition-colors ${
                      selectedReason
                        ? 'bg-blue-600 text-white hover:bg-blue-700'
                        : 'bg-gray-100 text-gray-400'
                    }`}
                  >
                    Submit
                  </button>
                </div>
              </div>
            ) : (
              <div className="p-6 text-center">
                <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
                  <div className="text-2xl text-green-500">
                    ✓
                  </div>
                </div>
                <h3 className="text-lg font-semibold mb-2">Report Submitted</h3>
                <p className="text-gray-500 mb-4">
                  Thank you for helping keep our community safe.
                </p>
                <button
                  onClick={() => {
                    setShowReportModal(false);
                    setReportSubmitted(false);
                    setSelectedReason('');
                  }}
                  className="px-4 py-2 bg-gray-100 rounded-lg text-gray-600 hover:bg-gray-200 transition-colors"
                >
                  Close
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default Post;