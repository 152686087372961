import React, { useState, useEffect } from 'react';
import { collection, addDoc, doc, getDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { Calendar, Clock, CheckCircle, XCircle, Loader2, X } from 'lucide-react';

const TutorBooking = ({ tutorId, onClose }) => {
  const { currentUser } = useAuth();
  const [availability, setAvailability] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [durationHours, setDurationHours] = useState(1); // Default to 1 hour
  const [maxDuration, setMaxDuration] = useState(1); // Calculated based on slot
  const [showDurationModal, setShowDurationModal] = useState(false); // Modal state
  const [loading, setLoading] = useState(true);
  const [bookingLoading, setBookingLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const fetchAvailability = async () => {
      try {
        const availabilityRef = doc(db, 'tutorAvailability', tutorId);
        const availabilityDoc = await getDoc(availabilityRef);
        if (availabilityDoc.exists()) {
          setAvailability(availabilityDoc.data().slots || []);
        }
      } catch (error) {
        console.error('Error fetching availability:', error);
        setError('Failed to load availability');
      } finally {
        setLoading(false);
      }
    };

    fetchAvailability();
  }, [tutorId]);

  // Calculate max duration when a slot is selected
  useEffect(() => {
    if (selectedSlot) {
      const startTimeParts = selectedSlot.startTime.split(':');
      const endTimeParts = selectedSlot.endTime.split(':');
      const startMinutes = parseInt(startTimeParts[0]) * 60 + parseInt(startTimeParts[1]);
      const endMinutes = parseInt(endTimeParts[0]) * 60 + parseInt(endTimeParts[1]);
      const maxHours = Math.floor((endMinutes - startMinutes) / 60);
      setMaxDuration(maxHours);
      setDurationHours(Math.min(durationHours, maxHours)); // Adjust if previously set higher
    }
  }, [selectedSlot]);

  const handleSlotClick = (slot) => {
    setSelectedSlot(slot);
    setShowDurationModal(true); // Show modal when slot is clicked
  };

  const handleBookSession = async () => {
    if (!selectedSlot || !currentUser) return;

    setBookingLoading(true);
    setError('');
    setSuccess('');

    try {
      // Calculate end time based on selected duration
      const startTimeParts = selectedSlot.startTime.split(':');
      const startMinutes = parseInt(startTimeParts[0]) * 60 + parseInt(startTimeParts[1]);
      const endMinutes = startMinutes + (durationHours * 60);
      const endHours = Math.floor(endMinutes / 60);
      const endMins = endMinutes % 60;
      const endTime = `${endHours.toString().padStart(2, '0')}:${endMins.toString().padStart(2, '0')}`;

      const bookingData = {
        tutorId,
        studentId: currentUser.uid,
        date: selectedSlot.day,
        startTime: selectedSlot.startTime,
        endTime: endTime,
        durationHours: durationHours,
        status: 'pending',
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };

      await addDoc(collection(db, 'tutorBookings'), bookingData);
      setSuccess('Booking request sent successfully! A confirmation email has been sent to your inbox.');
      setShowDurationModal(false);
      setTimeout(() => onClose(), 3000);
    } catch (error) {
      console.error('Error booking session:', error);
      setError('Failed to create booking request');
    } finally {
      setBookingLoading(false);
    }
  };

  const DurationModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 transition-opacity duration-300">
      <div className="bg-white rounded-xl w-full max-w-sm shadow-lg transform transition-all duration-300 scale-100 p-6 relative">
        <button
          onClick={() => setShowDurationModal(false)}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 transition-colors"
        >
          <X className="w-5 h-5" />
        </button>
        
        <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
          <Clock className="w-5 h-5 text-green-500 mr-2" />
          Choose Your Session Duration
        </h3>
        
        <p className="text-sm text-gray-600 mb-4">
          Available slot: {selectedSlot.startTime} - {selectedSlot.endTime}
        </p>

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            How many hours?
          </label>
          <select
            value={durationHours}
            onChange={(e) => setDurationHours(parseInt(e.target.value))}
            className="w-full p-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-green-500 transition-all"
          >
            {Array.from({ length: maxDuration }, (_, i) => i + 1).map((hours) => (
              <option key={hours} value={hours}>
                {hours} hour{hours > 1 ? 's' : ''}
              </option>
            ))}
          </select>
        </div>

        <button
          onClick={handleBookSession}
          disabled={bookingLoading}
          className={`w-full p-3 rounded-lg text-white flex items-center justify-center transition-all duration-200 ${
            bookingLoading ? 'bg-green-400 cursor-not-allowed' : 'bg-green-500 hover:bg-green-600'
          }`}
        >
          {bookingLoading ? (
            <>
              <Loader2 className="w-5 h-5 mr-2 animate-spin" />
              Booking...
            </>
          ) : (
            'Book Session'
          )}
        </button>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <h2 className="text-xl font-bold mb-4">Book a Session</h2>
      {error && <div className="bg-red-100 p-3 rounded-lg text-red-700 mb-4">{error}</div>}
      {success && <div className="bg-green-100 p-3 rounded-lg text-green-700 mb-4">{success}</div>}
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {availability.map((slot, index) => (
          <div
            key={index}
            onClick={() => handleSlotClick(slot)}
            className={`p-4 border rounded-lg cursor-pointer transition-all duration-200 ${
              selectedSlot === slot ? 'bg-blue-50 border-blue-500 shadow-md' : 'bg-gray-50 hover:bg-gray-100'
            }`}
          >
            <div className="flex items-center space-x-2">
              <Calendar className="w-5 h-5 text-blue-500" />
              <span className="font-medium">{slot.day}</span>
            </div>
            <div className="flex items-center space-x-2 mt-2">
              <Clock className="w-5 h-5 text-blue-500" />
              <span>{slot.startTime} - {slot.endTime}</span>
            </div>
          </div>
        ))}
      </div>

      {showDurationModal && selectedSlot && <DurationModal />}
    </div>
  );
};

export default TutorBooking;