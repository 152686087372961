import React from 'react';
import { LANGUAGES, STAR_SIGNS } from '../../constants/profileConstants';

const AdditionalInformation = ({ 
  profile, 
  isEditing, 
  setProfile 
}) => {
  return (
    <section className="bg-white rounded-2xl p-6 shadow-sm">
      <h2 className="text-xl font-semibold mb-6">Additional Information</h2>
      <div className="space-y-6">
        {/* Languages */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Languages</label>
          <div className="flex flex-wrap gap-2">
            {LANGUAGES.map((language) => (
              <button
                key={language}
                onClick={() => {
                  if (isEditing) {
                    setProfile(prev => ({
                      ...prev,
                      languages: prev.languages.includes(language)
                        ? prev.languages.filter(l => l !== language)
                        : [...prev.languages, language]
                    }));
                  }
                }}
                className={`px-4 py-2 rounded-full text-sm ${
                  profile.languages.includes(language)
                    ? 'bg-green-500 text-white'
                    : 'bg-gray-100 text-gray-600'
                } ${!isEditing && 'pointer-events-none'}`}
              >
                {language}
              </button>
            ))}
          </div>
        </div>

        {/* Star Sign */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Star Sign</label>
          <div className="flex flex-wrap gap-2">
            {STAR_SIGNS.map((sign) => (
              <button
                key={sign}
                onClick={() => {
                  if (isEditing) {
                    setProfile(prev => ({
                      ...prev,
                      basicInfo: { ...prev.basicInfo, starSign: sign }
                    }));
                  }
                }}
                className={`px-4 py-2 rounded-full text-sm ${
                  profile.basicInfo.starSign === sign
                    ? 'bg-green-500 text-white'
                    : 'bg-gray-100 text-gray-600'
                } ${!isEditing && 'pointer-events-none'}`}
              >
                {sign}
              </button>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdditionalInformation;