import React, { useState, useEffect } from 'react';
import { resourceService } from '../../firebase/resourceService';
import AcademicNavBar from '../../components/AcademicNavBar';
import AcademicHeader from '../academics/AcademicHeader';
import { ThumbsUp, ThumbsDown, FileText, Video, Link as LinkIcon, Loader, FileUp, Book, Wrench, Search, Filter, X, Eye } from 'lucide-react';

const ResourcesPage = () => {
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('all');
  const [showFilters, setShowFilters] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState('all');
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [thumbnailErrors, setThumbnailErrors] = useState({});

  useEffect(() => {
    fetchResources();
  }, []);

  const fetchResources = async () => {
    try {
      const fetchedResources = await resourceService.getResources();
      setResources(fetchedResources.map(resource => ({
        ...resource,
        likes: resource.likes || 0,
        dislikes: resource.dislikes || 0,
        userInteraction: 'none',
        views: resource.views || Math.floor(Math.random() * 1000) + 100,
        thumbnailUrl: resource.link ? getThumbnailUrl(resource.link) : null
      })));
    } catch (error) {
      console.error('Error fetching resources:', error);
    } finally {
      setLoading(false);
    }
  };

  const getThumbnailUrl = (url) => {
    try {
      const urlObj = new URL(url);
      if (urlObj.hostname.includes('youtube.com') || urlObj.hostname.includes('youtu.be')) {
        const videoId = urlObj.searchParams.get('v') || urlObj.pathname.split('/').pop();
        return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
      }
      return null;
    } catch {
      return null;
    }
  };

  const handleThumbnailError = (resourceId) => {
    setThumbnailErrors(prev => ({ ...prev, [resourceId]: true }));
  };

  const handleInteraction = async (id, type, event) => {
    event.preventDefault();
    const resource = resources.find(r => r.id === id);
    if (!resource) return;

    try {
      const newResources = resources.map(resource => {
        if (resource.id !== id) return resource;

        let newLikes = resource.likes;
        let newDislikes = resource.dislikes;
        let newInteraction = resource.userInteraction;

        if (resource.userInteraction === 'liked') newLikes--;
        if (resource.userInteraction === 'disliked') newDislikes--;

        if (type !== resource.userInteraction) {
          if (type === 'liked') {
            newLikes++;
            newInteraction = 'liked';
          } else if (type === 'disliked') {
            newDislikes++;
            newInteraction = 'disliked';
          }
        } else {
          newInteraction = 'none';
        }

        return {
          ...resource,
          likes: newLikes,
          dislikes: newDislikes,
          userInteraction: newInteraction
        };
      });

      setResources(newResources);
      
      const updatedResource = newResources.find(r => r.id === id);
      await resourceService.updateResource(id, {
        ...updatedResource,
        likes: updatedResource.likes,
        dislikes: updatedResource.dislikes
      });
    } catch (error) {
      console.error('Error updating resource interaction:', error);
    }
  };

  const getResourceIcon = (type) => {
    const iconClass = "w-6 h-6";
    switch (type.toLowerCase()) {
      case 'video': return <Video className={iconClass} />;
      case 'website': return <LinkIcon className={iconClass} />;
      case 'past papers': return <FileUp className={iconClass} />;
      case 'book': return <Book className={iconClass} />;
      case 'tool': return <Wrench className={iconClass} />;
      default: return <FileText className={iconClass} />;
    }
  };

  const formatViews = (views) => {
    if (views >= 1000000) {
      return `${(views / 1000000).toFixed(1)}M views`;
    } else if (views >= 1000) {
      return `${(views / 1000).toFixed(1)}K views`;
    }
    return `${views} views`;
  };

  const filteredResources = resources.filter(resource => {
    const isTypeMatch = filterType === 'all' || resource.resourceType.toLowerCase() === filterType.toLowerCase();
    const isSubjectMatch = selectedSubject === 'all' || resource.subject.toLowerCase() === selectedSubject.toLowerCase();
    const isSearchMatch = resource.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         resource.description.toLowerCase().includes(searchTerm.toLowerCase());
    return isTypeMatch && isSubjectMatch && isSearchMatch;
  });

  const subjects = ['all', ...new Set(resources.map(r => r.subject))];
  const types = ['all', 'video', 'website', 'past papers', 'book', 'tool', 'article'];

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <div className="fixed top-0 left-0 right-0 z-50 bg-white shadow-md">
        <AcademicHeader 
          title="Academic Resources"
          subtitle="Discover and share helpful academic resources"
        />
      </div>
      
      <main className="flex-grow mt-24 mb-20">
        <div className="container mx-auto px-4 lg:px-8 py-8">
          <div className="bg-white rounded-2xl shadow-lg p-4 mb-8">
            <div className="flex flex-col md:flex-row gap-4">
              <div className={`relative flex-grow ${isSearchFocused ? 'scale-102' : ''}`}>
                <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onFocus={() => setIsSearchFocused(true)}
                  onBlur={() => setIsSearchFocused(false)}
                  placeholder="Search resources..."
                  className="w-full pl-12 pr-4 py-3 rounded-xl bg-gray-50 border-2 border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all duration-200"
                />
                {searchTerm && (
                  <button
                    onClick={() => setSearchTerm('')}
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                  >
                    <X className="w-4 h-4" />
                  </button>
                )}
              </div>
              <button
                onClick={() => setShowFilters(!showFilters)}
                className="md:w-auto w-full px-6 py-3 bg-gray-50 hover:bg-gray-100 rounded-xl flex items-center justify-center gap-2 border-2 border-gray-200 transition-all duration-200"
              >
                <Filter className="w-4 h-4" />
                Filters
              </button>
            </div>

            {showFilters && (
              <div className="mt-6 p-6 bg-gray-50 rounded-xl space-y-6 animate-in slide-in-from-top">
                <div>
                  <label className="text-sm font-medium text-gray-700 mb-3 block">Resource Type</label>
                  <div className="flex flex-wrap gap-2">
                    {types.map(type => (
                      <button
                        key={type}
                        onClick={() => setFilterType(type)}
                        className={`px-4 py-2 rounded-xl text-sm font-medium capitalize transition-all duration-200 ${
                          filterType === type
                            ? 'bg-blue-100 text-blue-800 border-2 border-blue-200'
                            : 'bg-white border-2 border-gray-200 text-gray-600 hover:bg-gray-50'
                        }`}
                      >
                        {type}
                      </button>
                    ))}
                  </div>
                </div>
                <div>
                  <label className="text-sm font-medium text-gray-700 mb-3 block">Subject</label>
                  <div className="flex flex-wrap gap-2">
                    {subjects.map(subject => (
                      <button
                        key={subject}
                        onClick={() => setSelectedSubject(subject)}
                        className={`px-4 py-2 rounded-xl text-sm font-medium capitalize transition-all duration-200 ${
                          selectedSubject === subject
                            ? 'bg-blue-100 text-blue-800 border-2 border-blue-200'
                            : 'bg-white border-2 border-gray-200 text-gray-600 hover:bg-gray-50'
                        }`}
                      >
                        {subject}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>

          {loading ? (
            <div className="flex justify-center items-center py-16">
              <div className="relative">
                <Loader className="w-12 h-12 animate-spin text-blue-500" />
                <div className="absolute inset-0 animate-ping bg-blue-500 rounded-full opacity-20"></div>
              </div>
            </div>
          ) : (
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 max-w-7xl mx-auto">
              {filteredResources.map(resource => (
                <a
                  key={resource.id}
                  href={resource.link || resource.fileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group"
                >
                  <div className="bg-white rounded-2xl shadow-lg overflow-hidden transition-all duration-300 group-hover:shadow-xl group-hover:-translate-y-1">
                    <div className="aspect-video bg-gray-100 relative overflow-hidden">
                      {resource.thumbnailUrl && !thumbnailErrors[resource.id] ? (
                        <img
                          src={resource.thumbnailUrl}
                          alt={resource.title}
                          className="w-full h-full object-cover"
                          onError={() => handleThumbnailError(resource.id)}
                          loading="lazy"
                        />
                      ) : (
                        <div className="absolute inset-0 flex items-center justify-center bg-gray-200">
                          {getResourceIcon(resource.resourceType)}
                        </div>
                      )}
                      <div className="absolute bottom-2 right-2 bg-black/75 text-white px-2 py-1 rounded-lg text-xs font-medium">
                        {resource.resourceType}
                      </div>
                    </div>

                    <div className="p-4">
                      <h3 className="text-lg font-semibold text-gray-900 line-clamp-2 mb-2 group-hover:text-blue-600">
                        {resource.title}
                      </h3>
                      
                      <div className="flex items-center gap-2 text-sm text-gray-500 mb-3">
                        <Eye className="w-4 h-4" />
                        <span>{formatViews(resource.views)}</span>
                        <span className="text-gray-300">•</span>
                        <span className="capitalize">{resource.subject}</span>
                      </div>
                      
                      <p className="text-gray-600 text-sm line-clamp-2 mb-4">
                        {resource.description}
                      </p>
                      
                      <div className="flex items-center gap-4">
                        <button
                          onClick={(e) => handleInteraction(resource.id, 'liked', e)}
                          className={`flex items-center gap-1 transition-all duration-200 ${
                            resource.userInteraction === 'liked'
                              ? 'text-blue-600'
                              : 'text-gray-600 hover:text-blue-600'
                          }`}
                        >
                          <ThumbsUp className="w-5 h-5" />
                          <span className="font-medium">{resource.likes}</span>
                        </button>
                        
                        <button
                          onClick={(e) => handleInteraction(resource.id, 'disliked', e)}
                          className={`flex items-center gap-1 transition-all duration-200 ${
                            resource.userInteraction === 'disliked'
                              ? 'text-red-600'
                              : 'text-gray-600 hover:text-red-600'
                          }`}
                        >
                          <ThumbsDown className="w-5 h-5" />
                          <span className="font-medium">{resource.dislikes}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          )}
          
          {filteredResources.length === 0 && !loading && (
            <div className="max-w-2xl mx-auto bg-white rounded-2xl shadow-lg p-8 text-center">
              <div className="mb-4">
                <Search className="w-16 h-16 text-gray-300 mx-auto" />
              </div>
              <p className="text-xl font-medium mb-2">No resources found</p>
              <p className="text-gray-600">Try adjusting your search terms or filters to find what you're looking for.</p>
            </div>
          )}
        </div>
      </main>

      <div className="fixed bottom-0 left-0 right-0 z-50">
        <AcademicNavBar className="bg-white shadow-lg" />
      </div>
    </div>
  );
};

export default ResourcesPage;