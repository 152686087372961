import React from 'react';
import { XCircle, Upload, BookOpenCheck, Tag, Calendar, DollarSign, Phone, FileText } from 'lucide-react';

const UploadAssignmentModal = ({ isOpen, onClose, assignmentData, setAssignmentData, onSubmit, selectedAssignment, fileInputRef, setAttachedPdf }) => {
  if (!isOpen) return null;

  const universities = [
    'Cape Peninsula University of Technology',
    'Central University of Technology',
    'Durban University of Technology',
    'Mangosuthu University of Technology',
    'Nelson Mandela University',
    'North-West University',
    'Rhodes University',
    'Sefako Makgatho Health Sciences University',
    'Sol Plaatje University',
    'Stellenbosch University',
    'Tshwane University of Technology',
    'University of Cape Town',
    'University of Fort Hare',
    'University of Johannesburg',
    'University of KwaZulu-Natal',
    'University of Limpopo',
    'University of Mpumalanga',
    'University of Pretoria',
    'University of South Africa',
    'University of the Free State',
    'University of the Western Cape',
    'University of the Witwatersrand',
    'Vaal University of Technology',
    'Walter Sisulu University'
  ].sort();

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-xl w-full max-w-lg relative flex flex-col max-h-[90vh]">
        {/* Close button */}
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 p-2 rounded-full hover:bg-gray-100"
        >
          <XCircle className="w-5 h-5" />
        </button>

        {/* Header */}
        <div className="p-6 flex-none">
          <div className="text-center mb-8">
            <div className="bg-blue-50 p-3 rounded-xl inline-block mb-4">
              <Upload className="w-8 h-8 text-blue-500" />
            </div>
            <h2 className="text-xl font-bold text-gray-800">
              {selectedAssignment ? 'Edit Assignment' : 'Upload New Assignment'}
            </h2>
            <p className="text-sm text-gray-600 mt-1">
              Share your assignment with qualified tutors
            </p>
          </div>
        </div>

        {/* Form Content */}
        <div className="flex-1 overflow-y-auto px-6">
          <form onSubmit={onSubmit} className="space-y-4 mb-8">
            {/* Course Info Section */}
            <div className="space-y-4 p-4 bg-gray-50 rounded-xl">
              <div className="flex items-center gap-2 text-gray-700 mb-2">
                <BookOpenCheck className="w-5 h-5" />
                <h3 className="font-medium">Course Information</h3>
              </div>
              
              <div>
                <label className="block text-sm font-medium mb-1">Course Name</label>
                <input
                  type="text"
                  placeholder="e.g., Introduction to Programming"
                  value={assignmentData.courseName}
                  onChange={(e) => setAssignmentData({ ...assignmentData, courseName: e.target.value })}
                  className="w-full p-3 border rounded-xl"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Course Code *</label>
                <input
                  type="text"
                  placeholder="e.g., CSC1015F"
                  value={assignmentData.courseCode}
                  onChange={(e) => setAssignmentData({ ...assignmentData, courseCode: e.target.value })}
                  className="w-full p-3 border rounded-xl"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">University *</label>
                <select
                  value={assignmentData.university}
                  onChange={(e) => setAssignmentData({ ...assignmentData, university: e.target.value })}
                  className="w-full p-3 border rounded-xl"
                  required
                >
                  <option value="" disabled>Select a University</option>
                  {universities.map((uni) => (
                    <option key={uni} value={uni}>{uni}</option>
                  ))}
                </select>
              </div>
            </div>

            {/* Assignment Details Section */}
            <div className="space-y-4 p-4 bg-gray-50 rounded-xl">
              <div className="flex items-center gap-2 text-gray-700 mb-2">
                <FileText className="w-5 h-5" />
                <h3 className="font-medium">Assignment Details</h3>
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Description</label>
                <textarea
                  placeholder="Describe your assignment requirements..."
                  value={assignmentData.description}
                  onChange={(e) => setAssignmentData({ ...assignmentData, description: e.target.value })}
                  className="w-full p-3 border rounded-xl"
                  rows="3"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Due Date *</label>
                <input
                  type="date"
                  value={assignmentData.dueDate}
                  onChange={(e) => setAssignmentData({ ...assignmentData, dueDate: e.target.value })}
                  className="w-full p-3 border rounded-xl"
                  required
                />
              </div>
            </div>

            {/* Contact & Price Section */}
            <div className="space-y-4 p-4 bg-gray-50 rounded-xl">
              <div className="flex items-center gap-2 text-gray-700 mb-2">
                <Phone className="w-5 h-5" />
                <h3 className="font-medium">Contact & Price</h3>
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Phone Number (10 digits) *</label>
                <input
                  type="tel"
                  placeholder="e.g., 0123456789"
                  value={assignmentData.phoneNumber}
                  onChange={(e) => setAssignmentData({ ...assignmentData, phoneNumber: e.target.value })}
                  className="w-full p-3 border rounded-xl"
                  pattern="[0-9]{10}"
                  title="Please enter a 10-digit phone number"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Price Offer (R)</label>
                <input
                  type="number"
                  placeholder="Enter amount"
                  value={assignmentData.priceOffer}
                  onChange={(e) => setAssignmentData({ ...assignmentData, priceOffer: e.target.value })}
                  className="w-full p-3 border rounded-xl"
                  min="0"
                />
              </div>
            </div>

            {/* File Upload Section */}
            <div className="space-y-4 p-4 bg-gray-50 rounded-xl">
              <div className="flex items-center gap-2 text-gray-700 mb-2">
                <Upload className="w-5 h-5" />
                <h3 className="font-medium">Assignment File</h3>
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Attach Assignment PDF *</label>
                <input
                  type="file"
                  accept=".pdf"
                  ref={fileInputRef}
                  onChange={(e) => setAttachedPdf(e.target.files[0])}
                  className="w-full p-3 border rounded-xl bg-white"
                  required
                />
              </div>
            </div>
          </form>
        </div>

        {/* Footer Buttons */}
        <div className="p-6 flex-none border-t">
          <div className="flex gap-3 justify-end">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:bg-gray-50 rounded-xl"
            >
              Cancel
            </button>
            <button
              onClick={onSubmit}
              className="px-6 py-2 bg-blue-500 text-white rounded-xl hover:bg-blue-600 transition-colors flex items-center gap-2"
            >
              {selectedAssignment ? 'Update' : 'Upload'}
              <Upload className="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadAssignmentModal;