import React, { useState } from 'react';
import { ArrowLeft, Filter } from 'lucide-react';
import { FilterModal } from '../../components/homepage/FilterModal';

const AcademicHeader = ({ 
  title, 
  onBack, 
  userPreferences,
  onFilterChange = () => {} 
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [selectedUniversity, setSelectedUniversity] = useState('');

  const handleFilterApply = () => {
    onFilterChange(selectedUniversity);
    setShowFilters(false);
  };

  const handleFilterReset = () => {
    setSelectedUniversity('');
    onFilterChange('');
    setShowFilters(false);
  };

  return (
    <>
      <header className="fixed top-0 w-full bg-white shadow-sm z-40">
        <div className="max-w-7xl mx-auto px-4 py-3">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-3">
              <button 
                onClick={onBack}
                className="p-2 rounded-full hover:bg-gray-100 transition-colors"
                aria-label="Go back"
              >
                <ArrowLeft className="w-5 h-5 text-gray-500" />
              </button>
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <div className="w-8 h-8 bg-gradient-to-br from-green-500 to-green-600 rounded-xl flex items-center justify-center transform rotate-12 shadow-lg">
                    <span className="text-white font-bold text-xl transform -rotate-12">V</span>
                  </div>
                  <div className="absolute -top-1 -right-1 w-2 h-2 bg-green-400 rounded-full animate-pulse" />
                </div>
              </div>
              <h1 className="text-xl font-semibold text-gray-700">{title}</h1>
            </div>

            <div>
              <button
                onClick={() => setShowFilters(true)}
                className="p-2 rounded-full hover:bg-gray-100 transition-colors"
                aria-label="Show filters"
              >
                <Filter className="w-5 h-5 text-gray-500" />
              </button>
            </div>
          </div>
        </div>
      </header>

      <FilterModal
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        selectedUniversity={selectedUniversity}
        setSelectedUniversity={setSelectedUniversity}
        userPreferences={userPreferences}
        onApply={handleFilterApply}
        onReset={handleFilterReset}
      />
    </>
  );
};

export default AcademicHeader;