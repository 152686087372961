import React, { useEffect, useState } from 'react';
import { db } from '../../firebase/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend, PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { Users, Heart, Coffee, Loader } from 'lucide-react';

const DashboardSummary = () => {
    const [applicationsData, setApplicationsData] = useState({ pending: 0, approved: 0, rejected: 0 });
    const [totalTutors, setTotalTutors] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [profileStats, setProfileStats] = useState({
        complete: 0,
        incomplete: 0,
        onMeetups: 0,
        onDating: 0
    });
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            setLoading(true);
            const applicationsRef = collection(db, 'tutorApplications');
            const usersRef = collection(db, 'profiles');

            const applicationsSnapshot = await getDocs(applicationsRef);
            const usersSnapshot = await getDocs(usersRef);
            
            const apps = applicationsSnapshot.docs.map(doc => doc.data());
            const users = usersSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            // Calculate application stats
            setApplicationsData({
                pending: apps.filter(app => app.status === 'pending').length,
                approved: apps.filter(app => app.status === 'approved').length,
                rejected: apps.filter(app => app.status === 'rejected').length,
            });
            setTotalTutors(apps.length);
            setTotalUsers(users.length);

            // Calculate profile completeness
            const completeProfiles = users.filter(user => isProfileComplete(user));
            const incompleteProfiles = users.length - completeProfiles.length;
            
            // Calculate users on meetups and dating
            const onMeetups = users.filter(user => 
                user.preferences?.showInMeetups === true
            ).length;
            
            const onDating = users.filter(user => 
                user.preferences?.showInDating === true || 
                user.preferences?.relationshipGoal === 'Dating'
            ).length;

            setProfileStats({
                complete: completeProfiles.length,
                incomplete: incompleteProfiles,
                onMeetups,
                onDating
            });
        } catch (error) {
            console.error('Error fetching summary data:', error);
        } finally {
            setLoading(false);
        }
    };

    // Helper function to check profile completeness
    const isProfileComplete = (profile) => {
        if (!profile) return false;
        
        // Check all required fields
        const hasBasicInfo = profile.basicInfo?.name && 
                            profile.basicInfo?.gender;
        
        const hasAcademic = profile.academic?.university && 
                           profile.academic?.degree && 
                           profile.academic?.yearOfStudy;
        
        const hasBio = profile.bio?.shortBio;
        
        const hasPhoto = profile.photos && profile.photos.length > 0;
        
        return hasBasicInfo && hasAcademic && hasBio && hasPhoto;
    };

    useEffect(() => {
        fetchData();
    }, []);

    const applicationData = [
        { name: 'Pending', count: applicationsData.pending },
        { name: 'Approved', count: applicationsData.approved },
        { name: 'Rejected', count: applicationsData.rejected },
    ];

    const profileCompletionData = [
        { name: 'Complete', value: profileStats.complete, color: '#4ade80' },
        { name: 'Incomplete', value: profileStats.incomplete, color: '#f87171' }
    ];

    const platformUsageData = [
        { name: 'On Meetups', value: profileStats.onMeetups, color: '#60a5fa' },
        { name: 'On Dating', value: profileStats.onDating, color: '#f472b6' }
    ];

    if (loading) {
        return (
            <div className="p-8 bg-white rounded-xl shadow-sm text-center">
                <Loader className="w-8 h-8 text-blue-500 animate-spin mx-auto" />
                <p className="mt-4 text-gray-600">Loading dashboard data...</p>
            </div>
        );
    }

    return (
        <div className="p-4 md:p-6 bg-white rounded-xl shadow-sm">
            <h3 className="text-lg md:text-xl font-semibold mb-4">Dashboard Summary</h3>
            
            {/* Top Stats Cards */}
            <div className="grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-4 mb-6">
                <div className="border p-3 md:p-4 rounded-md bg-blue-50">
                    <h4 className="text-sm font-semibold flex items-center">
                        <Users className="w-4 h-4 mr-1 text-blue-500" />
                        Total Users
                    </h4>
                    <p className="text-base md:text-lg font-bold">{totalUsers}</p>
                </div>
                <div className="border p-3 md:p-4 rounded-md bg-purple-50">
                    <h4 className="text-sm font-semibold flex items-center">
                        <Users className="w-4 h-4 mr-1 text-purple-500" />
                        Total Tutors
                    </h4>
                    <p className="text-base md:text-lg font-bold">{totalTutors}</p>
                </div>
                <div className="border p-3 md:p-4 rounded-md bg-emerald-50">
                    <h4 className="text-sm font-semibold flex items-center">
                        <Coffee className="w-4 h-4 mr-1 text-emerald-500" />
                        Meetup Users
                    </h4>
                    <p className="text-base md:text-lg font-bold">{profileStats.onMeetups}</p>
                </div>
                <div className="border p-3 md:p-4 rounded-md bg-pink-50">
                    <h4 className="text-sm font-semibold flex items-center">
                        <Heart className="w-4 h-4 mr-1 text-pink-500" />
                        Dating Users
                    </h4>
                    <p className="text-base md:text-lg font-bold">{profileStats.onDating}</p>
                </div>
            </div>
            
            {/* Charts Row - Responsive with vertical stacking on mobile */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
                {/* Applications Chart */}
                <div className="border rounded-lg p-4">
                    <h4 className="font-semibold mb-2 text-sm md:text-base">Tutor Applications</h4>
                    <div className="h-64 md:h-72">
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart data={applicationData} margin={{ top: 5, right: 10, left: 0, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="count" fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                
                {/* Profile Completion Donut Chart */}
                <div className="border rounded-lg p-4">
                    <h4 className="font-semibold mb-2 text-sm md:text-base">Profile Completion</h4>
                    <div className="h-64 md:h-72">
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart>
                                <Pie
                                    data={profileCompletionData}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={60}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    paddingAngle={5}
                                    dataKey="value"
                                    label={({name, percent}) => `${name}: ${(percent * 100).toFixed(0)}%`}
                                >
                                    {profileCompletionData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={entry.color} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
            
            {/* Platform Usage Horizontal Bar Chart */}
            <div className="border rounded-lg p-4">
                <h4 className="font-semibold mb-2 text-sm md:text-base">Platform Usage</h4>
                <div className="h-48 md:h-64">
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            layout="vertical"
                            data={platformUsageData}
                            margin={{ top: 5, right: 10, left: 20, bottom: 5 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis type="number" />
                            <YAxis dataKey="name" type="category" />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="value" radius={[0, 4, 4, 0]}>
                                {platformUsageData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={entry.color} />
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
};

export default DashboardSummary;