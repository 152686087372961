import React from 'react';
import { X, Check, UserPlus ,Gamepad,Mountain,Volleyball,Beer} from 'lucide-react';

const GroupConnectionNotificationsModal = ({ 
  notifications, 
  onClose, 
  onAccept, 
  onReject 
}) => {
  const getActivityIcon = (activityType) => {
    const icons = {
      games: <Gamepad className="w-6 h-6 text-purple-500" />,
      hiking: <Mountain className="w-6 h-6 text-green-500" />,
      sports: <Volleyball className="w-6 h-6 text-blue-500" />,
      chillers: <Beer className="w-6 h-6 text-amber-500" />
    };
    return icons[activityType] || <UserPlus className="w-6 h-6 text-gray-500" />;
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg max-w-md w-full max-h-[70vh] overflow-y-auto">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-semibold">Group Connection Invitations</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-6 h-6" />
          </button>
        </div>

        {notifications.length === 0 ? (
          <div className="p-4 text-center text-gray-500">
            No group connection invitations
          </div>
        ) : (
          <div>
            {notifications.map((notification) => (
              <div 
                key={notification.id} 
                className="p-4 border-b flex items-center justify-between hover:bg-gray-50"
              >
                <div className="flex items-center space-x-4">
                  {getActivityIcon(notification.activityType)}
                  <div>
                    <p className="font-medium">
                      Group Connection Invitation
                    </p>
                    <p className="text-sm text-gray-600">
                      {notification.activityType.charAt(0).toUpperCase() + notification.activityType.slice(1)} Activity
                    </p>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <button 
                    onClick={() => onAccept(notification.id)}
                    className="p-2 bg-green-500 text-white rounded-full hover:bg-green-600"
                  >
                    <Check className="w-5 h-5" />
                  </button>
                  <button 
                    onClick={() => onReject(notification.id)}
                    className="p-2 bg-red-500 text-white rounded-full hover:bg-red-600"
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupConnectionNotificationsModal;