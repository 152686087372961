// Comments.js
import React, { useState } from 'react';
import {X, Send, Heart } from 'lucide-react';
import { CommentsList } from './CommentsList';

const Comments = ({ post, userProfile, onAddComment, formatTimestamp }) => {
  const [newComment, setNewComment] = useState('');
  const [replyToComment, setReplyToComment] = useState(null);

  const handleSubmit = () => {
    if (!newComment.trim()) return;
    onAddComment(post.id, newComment, replyToComment?.id);
    setNewComment('');
    setReplyToComment(null);
  };

  return (
    <div className="bg-gray-50 p-4 rounded-b-xl">
      <div className="flex gap-2 mb-4">
        <img
          src={userProfile?.photos?.[0] || "/api/placeholder/32/32"}
          alt="Your avatar"
          className="w-8 h-8 rounded-full object-cover border-2 border-gray-100"
        />
        <div className="flex-1 flex gap-2">
          <input
            type="text"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder={replyToComment ? `Reply to ${replyToComment.author.name}...` : "Write a comment..."}
            className="flex-1 bg-white rounded-full px-4 py-2 border border-gray-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all"
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSubmit();
              }
            }}
          />
          <button
            onClick={handleSubmit}
            disabled={!newComment.trim()}
            className={`p-2 rounded-full transition-colors ${
              newComment.trim()
                ? 'bg-green-500 text-white hover:bg-green-600 active:bg-green-700'
                : 'bg-gray-200 text-gray-400 cursor-not-allowed'
            }`}
          >
            <Send className="w-5 h-5" />
          </button>
        </div>
      </div>

      {replyToComment && (
        <div className="mb-4 px-4 py-2 bg-gray-100 rounded-lg flex items-center justify-between">
          <span className="text-sm text-gray-600">
            Replying to {replyToComment.author.name}
          </span>
          <button 
            onClick={() => setReplyToComment(null)}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-4 h-4" />
          </button>
        </div>
      )}

      <CommentsList 
        comments={post.comments} 
        onReply={setReplyToComment}
        formatTimestamp={formatTimestamp}
      />
    </div>
  );
};

export default Comments