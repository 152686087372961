import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { AlertCircle, Search, User, Mail, Calendar, Clock, GraduationCap } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { format } from 'date-fns';

const UserSearch = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [allUsers, setAllUsers] = useState([]); // Cached users
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchAllUsers = async () => {
    setLoading(true);
    try {
      const profilesRef = collection(db, 'profiles');
      const snapshot = await getDocs(profilesRef);
      const users = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAllUsers(users); // Cache all users
      setSearchResults(users); // Initialize search results with all users
    } catch (error) {
      console.error('Error fetching users:', error);
      toast.error('Error fetching users');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllUsers(); // Load all users on mount
  }, []);

  useEffect(() => {
    // Update searchResults whenever searchQuery changes
    if (searchQuery.trim() === '') {
      setSearchResults(allUsers);
    } else {
      const query = searchQuery.toLowerCase();
      const filteredResults = allUsers.filter(user =>
        user.basicInfo?.name?.toLowerCase().includes(query) ||
        user.basicInfo?.username?.toLowerCase().includes(query) ||
        user.academic?.degree?.toLowerCase().includes(query) ||
        user.email?.toLowerCase().includes(query)
      );
      setSearchResults(filteredResults);
    }
  }, [searchQuery, allUsers]);

  const UserCard = ({ user }) => {
    const createdAt = user.createdAt?.toDate 
      ? format(user.createdAt.toDate(), 'MMMM d, yyyy')
      : 'Date not available';

    const statusColors = {
      active: 'bg-green-100 text-green-800',
      suspended: 'bg-red-100 text-red-800',
      pending: 'bg-yellow-100 text-yellow-800'
    };

    return (
      <div className="bg-white rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 overflow-hidden border border-gray-100">
        <div className="p-6 space-y-4">
          <div className="flex justify-between items-start">
            <div className="space-y-1">
              <div className="flex items-center space-x-2">
                <User className="w-5 h-5 text-blue-500" />
                <h3 className="font-semibold text-lg">
                  {user.basicInfo?.name || 'No name'}
                </h3>
              </div>
              <div className="flex items-center space-x-2 text-gray-600">
                <Mail className="w-4 h-4" />
                <span className="text-sm">{user.email}</span>
              </div>
            </div>
            <span className={`px-3 py-1 rounded-full text-sm font-medium ${
              statusColors[user.status || 'active']
            }`}>
              {(user.status || 'active').charAt(0).toUpperCase() + (user.status || 'active').slice(1)}
            </span>
          </div>

          <div className="grid grid-cols-2 gap-4 text-sm text-gray-600">
            <div className="flex items-center space-x-2">
              <Clock className="w-4 h-4 text-blue-500" />
              <span>Joined: {createdAt}</span>
            </div>
            <div className="flex items-center space-x-2">
              <GraduationCap className="w-4 h-4 text-blue-500" />
              <span>Year: {user.academic?.yearOfStudy || 'Not specified'}</span>
            </div>
          </div>

          {user.academic?.courses && (
            <div className="space-y-2">
              <h4 className="font-medium text-gray-700">Enrolled Courses:</h4>
              <div className="grid grid-cols-2 gap-2">
                {user.academic.courses.map((course, idx) => (
                  <div key={idx} className="bg-gray-50 p-2 rounded text-sm">
                    {course.courseCode}
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="pt-2 text-sm text-gray-500">
            <p>User ID: {user.id}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-6">
      <div className="flex gap-4">
        <div className="flex-1 relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="text"
            placeholder="Search by email, name, username, or degree..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {searchResults.map(user => (
          <UserCard key={user.id} user={user} />
        ))}
      </div>

      {searchResults.length === 0 && searchQuery && !loading && (
        <div className="text-center py-8">
          <AlertCircle className="w-12 h-12 text-gray-400 mx-auto mb-2" />
          <p className="text-gray-500">No users found matching your search</p>
        </div>
      )}
      
      {loading && (
        <div className="flex justify-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
        </div>
      )}
    </div>
  );
};

export default UserSearch;