import React, { useState } from 'react';
import { X, Sparkles, ArrowRight, ArrowLeft, Check, Link, BookText, Tag, FileUp } from 'lucide-react';
import { storage, db } from "../../firebase/firebase"; // Ensure correct Firebase imports
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";
const SuggestionCardFlow = ({ onClose, onSubmit, isOpen }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    resourceType: 'article',
    subject: '',
    link: '',
    pdfFile: null
  });

  const steps = [
    {
      id: 'title',
      icon: BookText,
      title: "What's this resource called?",
      subtitle: "Give it a clear, descriptive title",
      input: (
        <input
          type="text"
          value={formData.title}
          onChange={(e) => setFormData({...formData, title: e.target.value})}
          placeholder="e.g., 2023 Mathematics Final Exam"
          className="w-full text-lg p-3 rounded-xl border border-gray-200 focus:border-green-500 focus:ring-2 focus:ring-green-200 transition-all"
        />
      )
    },
    {
      id: 'type',
      icon: Tag,
      title: "What type of resource is it?",
      subtitle: "Select the category that best fits",
      input: (
        <div className="grid grid-cols-2 gap-3">
          {['Article', 'Video', 'Book', 'Website', 'Past Papers', 'Tool', 'Other'].map((type) => (
            <button
              key={type}
              onClick={() => {
                setFormData({
                  ...formData, 
                  resourceType: type.toLowerCase(),
                  // Clear pdfFile and link when switching types
                  pdfFile: null,
                  link: ''
                });
              }}
              className={`p-4 rounded-xl border-2 transition-all ${
                formData.resourceType === type.toLowerCase()
                  ? 'border-green-500 bg-green-50 text-green-700'
                  : 'border-gray-200 hover:border-green-200 hover:bg-green-50'
              }`}
            >
              {type}
            </button>
          ))}
        </div>
      )
    },
    {
      id: 'subject',
      icon: Tag,
      title: "What subject is this for?",
      subtitle: "Help others find this resource",
      input: (
        <input
          type="text"
          value={formData.subject}
          onChange={(e) => setFormData({...formData, subject: e.target.value})}
          placeholder="e.g., Mathematics"
          className="w-full text-lg p-3 rounded-xl border border-gray-200 focus:border-green-500 focus:ring-2 focus:ring-green-200 transition-all"
        />
      )
    },
    {
      id: 'file-or-link',
      icon: formData.resourceType === 'past papers' ? FileUp : Link,
      title: formData.resourceType === 'past papers' ? "Upload the past paper" : "Got a link to share?",
      subtitle: formData.resourceType === 'past papers' ? "Upload the PDF file" : "Optional - Add the resource URL",
      input: formData.resourceType === 'past papers' ? (
        <div className="space-y-4">
          <div 
            className="border-2 border-dashed border-gray-200 rounded-xl p-6 text-center hover:border-green-200 transition-all"
            onClick={() => document.getElementById('pdf-upload').click()}
          >
            <input
              type="file"
              accept=".pdf"
              onChange={(e) => {
                const file = e.target.files[0];
                if (file && file.type === 'application/pdf') {
                  setFormData({...formData, pdfFile: file, link: ''});
                }
              }}
              className="hidden"
              id="pdf-upload"
            />
            <div className="cursor-pointer">
              <FileUp className="w-8 h-8 text-gray-400 mx-auto mb-2" />
              <p className="text-sm text-gray-600">
                {formData.pdfFile ? formData.pdfFile.name : "Click to upload PDF"}
              </p>
            </div>
          </div>
          {formData.pdfFile && (
            <p className="text-sm text-green-600 flex items-center gap-2">
              <Check className="w-4 h-4" />
              File selected
            </p>
          )}
        </div>
      ) : (
        <input
          type="url"
          value={formData.link}
          onChange={(e) => setFormData({...formData, link: e.target.value, pdfFile: null})}
          placeholder="https://..."
          className="w-full text-lg p-3 rounded-xl border border-gray-200 focus:border-green-500 focus:ring-2 focus:ring-green-200 transition-all"
        />
      )
    },
    {
      id: 'description',
      icon: BookText,
      title: "Why is this helpful?",
      subtitle: "Tell others what makes this resource great",
      input: (
        <textarea
          value={formData.description}
          onChange={(e) => setFormData({...formData, description: e.target.value})}
          placeholder="This resource is helpful because..."
          className="w-full text-lg p-3 rounded-xl border border-gray-200 focus:border-green-500 focus:ring-2 focus:ring-green-200 transition-all h-32 resize-none"
        />
      )
    }
  ];

  const canProgress = () => {
    if (currentStep === 0 && !formData.title) return false;
    if (currentStep === 2 && !formData.subject) return false;
    if (currentStep === 3 && formData.resourceType === 'past papers' && !formData.pdfFile) return false;
    if (currentStep === 4 && !formData.description) return false;
    return true;
  };
  const handleSubmit = async () => {
    try {
      let pdfUrl = null;
  
      // If the user uploaded a PDF file, upload it to Firebase Storage
      if (formData.pdfFile) {
        const fileRef = ref(storage, `resources/${formData.pdfFile.name}`);
        await uploadBytes(fileRef, formData.pdfFile);
        pdfUrl = await getDownloadURL(fileRef);
      }
  
      // Save resource data in Firestore
      await addDoc(collection(db, "resources"), {
        title: formData.title,
        description: formData.description,
        resourceType: formData.resourceType,
        subject: formData.subject,
        link: formData.link || pdfUrl, // Store the PDF URL if no link is provided
        createdAt: new Date()
      });
  
      onClose(); // Close the modal after submission
    } catch (error) {
      console.error("Error submitting resource:", error);
    }
  };

  if (!isOpen) return null;

  const CurrentIcon = steps[currentStep].icon;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[60] p-4">
      <div className="bg-white rounded-2xl w-full max-w-lg relative overflow-hidden">
        <div className="absolute top-0 left-0 right-0 h-1 bg-gray-100">
          <div 
            className="h-full bg-green-500 transition-all duration-300"
            style={{ width: `${((currentStep + 1) / steps.length) * 100}%` }}
          />
        </div>

        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 p-2 rounded-full hover:bg-gray-100 z-10"
          aria-label="Close modal"
        >
          <X className="w-5 h-5" />
        </button>

        <div className="p-6">
          <div className="text-center mb-8">
            <div className="bg-green-50 p-3 rounded-xl inline-block mb-4">
              <CurrentIcon className="w-8 h-8 text-green-500" />
            </div>
            <h2 className="text-xl font-bold text-gray-800">
              {steps[currentStep].title}
            </h2>
            <p className="text-sm text-gray-600 mt-1">
              {steps[currentStep].subtitle}
            </p>
          </div>

          <div className="mb-8">
            {steps[currentStep].input}
          </div>

          <div className="flex gap-3 justify-between">
            <button
              onClick={() => setCurrentStep(prev => prev - 1)}
              disabled={currentStep === 0}
              className={`px-4 py-2 rounded-xl flex items-center gap-2 ${
                currentStep === 0
                  ? 'opacity-0 pointer-events-none'
                  : 'text-gray-600 hover:bg-gray-50'
              }`}
            >
              <ArrowLeft className="w-4 h-4" />
              Back
            </button>
            
            {currentStep === steps.length - 1 ? (
              <button
                onClick={handleSubmit}
                disabled={!canProgress()}
                className="px-6 py-2 bg-green-500 text-white rounded-xl hover:bg-green-600 transition-colors flex items-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Submit
                <Check className="w-4 h-4" />
              </button>
            ) : (
              <button
                onClick={() => setCurrentStep(prev => prev + 1)}
                disabled={!canProgress()}
                className="px-6 py-2 bg-green-500 text-white rounded-xl hover:bg-green-600 transition-colors flex items-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Next
                <ArrowRight className="w-4 h-4" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuggestionCardFlow;