import React, { useEffect, useState } from 'react';
import { 
  Activity, 
  Users, 
  Moon, 
  Sun,
  LogOut,
  ChevronRight,
  AlertCircle,
  Heart,
  Bell
} from 'lucide-react';
import { ACTIVITY_STATUSES } from '../../constants/profileConstants';
import { isProfileComplete } from '../../components/profile/profileValidation';

const SettingsPreferences = ({ 
  profile, 
  isEditing, 
  setProfile,
  handleLogout,
  setShowDeleteConfirm
}) => {
  const profileComplete = isProfileComplete(profile);

  // Effect to sync notification permission with profile preferences
  useEffect(() => {
    const syncNotificationStatus = () => {
      if ('Notification' in window) {
        // Update profile preferences based on current browser permission
        setProfile(prev => ({
          ...prev,
          preferences: {
            ...prev.preferences,
            notifications: Notification.permission === 'granted'
          }
        }));
      }
    };

    // Initial sync
    syncNotificationStatus();

    // Listen for permission changes (useful for when user changes browser settings)
    if ('permissions' in navigator) {
      navigator.permissions.query({ name: 'notifications' })
        .then(permissionStatus => {
          permissionStatus.onchange = syncNotificationStatus;
        });
    }
  }, [setProfile]);

  // Effect to automatically disable meetups and dating when profile becomes incomplete
  useEffect(() => {
    if (!profileComplete && (profile.preferences.showInMeetups || profile.preferences.showInDating)) {
      setProfile(prev => ({
        ...prev,
        preferences: {
          ...prev.preferences,
          showInMeetups: false,
          showInDating: false
        }
      }));
    }
  }, [profileComplete, profile.preferences.showInMeetups, profile.preferences.showInDating, setProfile]);

  const handleVisibilityToggle = (field) => {
    if (!isEditing) return;
    
    // Only allow enabling if profile is complete
    if (!profile.preferences[field] && !profileComplete) {
      return;
    }

    setProfile(prev => ({
      ...prev,
      preferences: {
        ...prev.preferences,
        [field]: !prev.preferences[field]
      }
    }));
  };

  const handleNotificationToggle = async () => {
    if (!isEditing) return;

    if (!('Notification' in window)) {
      alert('This browser does not support desktop notifications');
      return;
    }

    try {
      if (Notification.permission === 'denied') {
        alert('Please enable notifications in your browser settings to receive updates.');
        return;
      }

      if (profile.preferences.notifications) {
        // If notifications are currently enabled, just update the preference
        setProfile(prev => ({
          ...prev,
          preferences: {
            ...prev.preferences,
            notifications: false
          }
        }));
      } else {
        // Request permission when trying to enable
        const permission = await Notification.requestPermission();
        
        // Profile will be automatically updated by the useEffect hook above
        if (permission === 'granted') {
          // Show a test notification
          new Notification('Notifications Enabled', {
            body: 'You will now receive notifications from Wingman',
            icon: '/logo192.png'
          });
        }
      }
    } catch (error) {
      console.error('Error handling notification permission:', error);
      alert('There was an error managing notifications. Please try again.');
    }
  };

  return (
    <>
      <section className="bg-white rounded-2xl p-6 shadow-sm">
        <h2 className="text-xl font-semibold mb-6">Settings & Preferences</h2>
        <div className="space-y-4">
          {/* Notifications */}
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <Bell className="w-5 h-5 text-gray-500" />
                <div>
                  <p className="font-medium">Notifications</p>
                  <p className="text-sm text-gray-500">Enable push notifications</p>
                </div>
              </div>
              <button
                onClick={handleNotificationToggle}
                className={`w-12 h-6 rounded-full transition-colors ${
                  profile.preferences.notifications ? 'bg-green-500' : 'bg-gray-200'
                } ${!isEditing && 'pointer-events-none opacity-50'}`}
              >
                <div
                  className={`w-5 h-5 rounded-full bg-white transform transition-transform ${
                    profile.preferences.notifications ? 'translate-x-6' : 'translate-x-1'
                  }`}
                />
              </button>
            </div>
            {Notification.permission === 'denied' && (
              <div className="flex items-center gap-2 text-amber-600 text-sm">
                <AlertCircle className="w-4 h-4" />
                <span>Please enable notifications in your browser settings to receive updates</span>
              </div>
            )}
          </div>
          {/* Activity Status */}
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <Activity className="w-5 h-5 text-gray-500" />
              <div>
                <p className="font-medium">Activity Status</p>
                <p className="text-sm text-gray-500">Show your current status to others</p>
              </div>
            </div>
            {isEditing ? (
              <select
                value={profile.preferences.activityStatus}
                onChange={(e) => setProfile(prev => ({
                  ...prev,
                  preferences: { ...prev.preferences, activityStatus: e.target.value }
                }))}
                className="p-2 border rounded-lg"
              >
                {ACTIVITY_STATUSES.map(status => (
                  <option key={status} value={status}>{status}</option>
                ))}
              </select>
            ) : (
              <span className="text-gray-900">{profile.preferences.activityStatus}</span>
            )}
          </div>

          {/* Dating Visibility */}
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <Heart className="w-5 h-5 text-gray-500" />
                <div>
                  <p className="font-medium">Show in Connect</p>
                  <p className="text-sm text-gray-500">Allow others to find you for connecting</p>
                </div>
              </div>
              <button
                onClick={() => handleVisibilityToggle('showInDating')}
                className={`w-12 h-6 rounded-full transition-colors ${
                  profile.preferences.showInDating ? 'bg-green-500' : 'bg-gray-200'
                } ${(!isEditing || (!profileComplete && !profile.preferences.showInDating)) ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                disabled={!isEditing || (!profileComplete && !profile.preferences.showInDating)}
              >
                <div
                  className={`w-5 h-5 rounded-full bg-white transform transition-transform ${
                    profile.preferences.showInDating ? 'translate-x-6' : 'translate-x-1'
                  }`}
                />
              </button>
            </div>
            {!profileComplete && (
              <div className="flex items-center gap-2 text-amber-600 text-sm">
                <AlertCircle className="w-4 h-4" />
                <span>Complete your profile to enable connecting visibility</span>
              </div>
            )}
          </div>

          {/* Meetup Visibility */}
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <Users className="w-5 h-5 text-gray-500" />
                <div>
                  <p className="font-medium">Show in Meetups</p>
                  <p className="text-sm text-gray-500">Allow others to find you for meetups</p>
                </div>
              </div>
              <button
                onClick={() => handleVisibilityToggle('showInMeetups')}
                className={`w-12 h-6 rounded-full transition-colors ${
                  profile.preferences.showInMeetups ? 'bg-green-500' : 'bg-gray-200'
                } ${(!isEditing || (!profileComplete && !profile.preferences.showInMeetups)) ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                disabled={!isEditing || (!profileComplete && !profile.preferences.showInMeetups)}
              >
                <div
                  className={`w-5 h-5 rounded-full bg-white transform transition-transform ${
                    profile.preferences.showInMeetups ? 'translate-x-6' : 'translate-x-1'
                  }`}
                />
              </button>
            </div>
            {!profileComplete && (
              <div className="flex items-center gap-2 text-amber-600 text-sm">
                <AlertCircle className="w-4 h-4" />
                <span>Complete your profile to enable meetup visibility</span>
              </div>
            )}
          </div>

          {/* Dark Mode */}
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              {profile.preferences.darkMode ? (
                <Moon className="w-5 h-5 text-gray-500" />
              ) : (
                <Sun className="w-5 h-5 text-gray-500" />
              )}
              <div>
                <p className="font-medium">Dark Mode</p>
                <p className="text-sm text-gray-500">Switch between light and dark themes</p>
              </div>
            </div>
            <button
              onClick={() => {
                if (isEditing) {
                  setProfile(prev => ({
                    ...prev,
                    preferences: {
                      ...prev.preferences,
                      darkMode: !prev.preferences.darkMode
                    }
                  }));
                }
              }}
              className={`w-12 h-6 rounded-full transition-colors ${
                profile.preferences.darkMode ? 'bg-green-500' : 'bg-gray-200'
              } ${!isEditing && 'pointer-events-none opacity-50'}`}
            >
              <div
                className={`w-5 h-5 rounded-full bg-white transform transition-transform ${
                  profile.preferences.darkMode ? 'translate-x-6' : 'translate-x-1'
                }`}
              />
            </button>
          </div>
        </div>
      </section>

      {/* Account Section */}
      <section className="bg-white rounded-2xl p-6 shadow-sm mt-4">
        <h2 className="text-xl font-semibold mb-6">Account</h2>
        <div className="space-y-4">
          {/* Logout Button */}
          <button
            onClick={handleLogout}
            className="w-full flex items-center justify-between p-4 bg-gray-50 rounded-xl text-gray-600 hover:bg-gray-100 transition-colors"
          >
            <div className="flex items-center gap-3">
              <LogOut className="w-5 h-5" />
              <span>Log Out</span>
            </div>
            <ChevronRight className="w-5 h-5" />
          </button>

          {/* Deactivate Account Button */}
          <button
            onClick={() => setShowDeleteConfirm(true)}
            className="w-full flex items-center justify-between p-4 bg-red-50 rounded-xl text-red-500 hover:bg-red-100 transition-colors"
          >
            <div className="flex items-center gap-3">
              <LogOut className="w-5 h-5" />
              <span>Deactivate Account</span>
            </div>
            <ChevronRight className="w-5 h-5" />
          </button>
        </div>
      </section>
    </>
  );
};

export default SettingsPreferences;