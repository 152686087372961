// resourceService.js
import { db, storage } from './firebase';
import { 
  collection, 
  addDoc, 
  getDocs, 
  query, 
  orderBy,
  updateDoc,
  doc,
  increment,
  serverTimestamp 
} from 'firebase/firestore';
import { 
  ref, 
  uploadBytes, 
  getDownloadURL,
  deleteObject 
} from 'firebase/storage';

export const resourceService = {
  // Add a new resource suggestion
  async addResourceSuggestion(resourceData) {
    try {
      let fileUrl = null;
      
      // Handle PDF file upload if present
      if (resourceData.pdfFile) {
        // Create a reference to store the file
        const fileRef = ref(storage, `past-papers/${Date.now()}-${resourceData.pdfFile.name}`);
        
        // Upload the file
        const uploadResult = await uploadBytes(fileRef, resourceData.pdfFile);
        
        // Get the download URL
        fileUrl = await getDownloadURL(uploadResult.ref);
      }

      // Prepare the resource data
      const resourceToAdd = {
        ...resourceData,
        likes: 0,
        createdAt: serverTimestamp(),
        status: 'pending'
      };

      // Add the file URL if we uploaded a file
      if (fileUrl) {
        resourceToAdd.fileUrl = fileUrl;
      }

      // Remove the File object as it can't be stored in Firestore
      delete resourceToAdd.pdfFile;

      // Add to Firestore
      const docRef = await addDoc(collection(db, 'resources'), resourceToAdd);
      return docRef.id;
    } catch (error) {
      console.error('Error adding resource:', error);
      throw error;
    }
  },

  // Get all resources
  async getResources() {
    try {
      const q = query(collection(db, 'resources'), orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error('Error getting resources:', error);
      throw error;
    }
  },

// resourceService.js
async updateResource(resourceId, updateData) {
  try {
    const resourceRef = doc(db, 'resources', resourceId);
    await updateDoc(resourceRef, updateData);
  } catch (error) {
    console.error('Error updating resource:', error);
    throw error;
  }
},

  // Delete file from storage (optional, for cleanup)
  async deleteFile(fileUrl) {
    try {
      const fileRef = ref(storage, fileUrl);
      await deleteObject(fileRef);
    } catch (error) {
      console.error('Error deleting file:', error);
      throw error;
    }
  }
};