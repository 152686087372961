import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { auth, initializePushNotifications } from './firebase/firebase';
import Academic from "./pages/academics/Academic";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import SocialFeed from "./pages/SocialFeed";
import MeetUps from "./pages/Meetups";
import Messages from "./pages/Messages";
import ChatRoom from "./pages/ChatRoom";
import AuthContainer from "./pages/auth/AuthContainer";
import GroupsPage from "./pages/Groups";
import ProtectedRoute from "./components/ProtectedRoute";
import { Toaster } from "react-hot-toast";
import { NotificationsProvider } from "./contexts/NotificationsContext";
import GroupChat from "./pages/GroupChat";
import StudyGroupsPage from "./pages/academics/StudyGroupsPage";
import StudyPartners from "./pages/academics/StudyPartners";
import AssignmentHub from "./pages/academics/AssignmentHub";
import { AuthProvider } from "./contexts/AuthContext";
import EventsPage from "./pages/academics/EventsPage";
import ResourcesPage from "./pages/academics/ResourcesPage";
import Network from "./pages/academics/Network";
import FindTutors from "./pages/academics/FindTutors";
import MyPosts from "./components/social/MyPosts";
import AdminDashBoard from "./pages/admin/AdminDashboard"
import InstallPrompt from './components/InstallPrompt';
import GraduateProgramApplicationFlow from "./pages/academics/GraduateProgramApplicationFlow";
import SearchResults from "./pages/SearchResults";
import AcademicGame from "./pages/academics/AcademicGame";

function App() {
  useEffect(() => {
    // Initialize notifications when user logs in
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        await initializePushNotifications();
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <AuthProvider>
      <NotificationsProvider>
        <InstallPrompt />
        <Toaster
          position="bottom-center"
          toastOptions={{
            duration: 3000,
            style: {
              background: "#333",
              color: "#fff",
            },
            success: {
              style: {
                background: "#22c55e",
              },
            },
            error: {
              style: {
                background: "#ef4444",
              },
            },
          }}
        />
        <Router>
          <Routes>
            {/* Public route */}
            <Route path="/auth" element={<AuthContainer />} />

            {/* Protected routes */}
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Academic />
                </ProtectedRoute>
              }
            />

            <Route
              path="/academic-network"
              element={
                <ProtectedRoute>
                  <Network />
                </ProtectedRoute>
              }
            />
            
            <Route
              path="/connect"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />

            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />

            <Route
              path="/social-feed"
              element={
                <ProtectedRoute>
                  <SocialFeed />
                </ProtectedRoute>
              }
            />

            <Route
              path="/meetups"
              element={
                <ProtectedRoute>
                  <MeetUps />
                </ProtectedRoute>
              }
            />

            <Route
              path="/messages"
              element={
                <ProtectedRoute>
                  <Messages />
                </ProtectedRoute>
              }
            />

            <Route
              path="/groups"
              element={
                <ProtectedRoute>
                  <GroupsPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/chat/:chatId"
              element={
                <ProtectedRoute>
                  <ChatRoom />
                </ProtectedRoute>
              }
            />

            <Route
              path="/group-chat/:groupId"
              element={
                <ProtectedRoute>
                  <GroupChat />
                </ProtectedRoute>
              }
            />

            <Route
              path="/find-tutors"
              element={
                <ProtectedRoute>
                  <FindTutors/>
                </ProtectedRoute>
              }
            />

            <Route
              path="/study-partners"
              element={
                <ProtectedRoute>
                  <StudyPartners />
                </ProtectedRoute>
              }
            />

            <Route
              path="/assignment-hub"
              element={
                <ProtectedRoute>
                  <AssignmentHub />
                </ProtectedRoute>
              }
            />

            <Route
              path="/academic-events"
              element={
                <ProtectedRoute>
                  <EventsPage />
                </ProtectedRoute>
              }
            />
                   <Route
              path="/search-results"
              element={
                <ProtectedRoute>
                  <SearchResults/>
                </ProtectedRoute>
              }
            />

            <Route
              path="/my-social-profile"
              element={
                <ProtectedRoute>
                 <MyPosts/>
                </ProtectedRoute>
              }
            />
            {/* <Route
              path="/graduate-opportunities"
              element={
                <ProtectedRoute>
                 <GraduateProgramApplicationFlow/>
                </ProtectedRoute>
              }
            /> */}
            <Route
              path="/academic-resources"
              element={
                <ProtectedRoute>
                  <ResourcesPage />
                </ProtectedRoute>
              }
            />

<Route
              path="/control"
              element={
                <ProtectedRoute>
                  <AdminDashBoard/>
                </ProtectedRoute>
              }
            />
            
<Route
              path="/academic-game"
              element={
                <ProtectedRoute>
                  <AcademicGame/>
                </ProtectedRoute>
              }
            />

            {/* Redirect unknown routes to Academic home */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </NotificationsProvider>
    </AuthProvider>
  );
}

export default App;