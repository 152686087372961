import React, { useState, useEffect } from 'react';
import { auth, db } from '../../firebase/firebase';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { sendMeetupRequest } from '../../firebase/meetupService';
import { toast } from 'react-hot-toast';
import NavBar from '../../components/NavBar';
import MeetupHeader from '../../components/meetups/MeetupHeader';
import FilterModal from '../../components/meetups/FilterModal';
import UserCard from '../../components/meetups/UserCard';
import UserCardSkeleton from '../../components/meetups/UserCardSkeleton';

const MeetupsPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userPreferences, setUserPreferences] = useState(null);
  const [sentRequests, setSentRequests] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [selectedUniversity, setSelectedUniversity] = useState('');

  useEffect(() => {
    const fetchUserPreferencesAndRequests = async () => {
      setLoading(true);
      try {
        const user = auth.currentUser;
        if (!user) throw new Error('No authenticated user');

        // Fetch user preferences
        const userDoc = await getDoc(doc(db, 'profiles', user.uid));
        if (!userDoc.exists()) throw new Error('User profile not found');
        
        const userProfile = userDoc.data();
        setUserPreferences(userProfile);

        // Set initial university filter to user's university if not already selected
        if (!selectedUniversity) {
          setSelectedUniversity(userProfile.academic.university);
        }

        // Fetch sent meetup requests
        const sentRequestsQuery = query(
          collection(db, 'meetupRequests'),
          where('senderId', '==', user.uid)
        );
        const sentRequestsSnapshot = await getDocs(sentRequestsQuery);
        const sentRequestIds = sentRequestsSnapshot.docs.map(doc => doc.data().receiverId);
        setSentRequests(sentRequestIds);

        // Fetch all profiles with university filter
        let baseQuery;
        if (selectedUniversity) {
          baseQuery = query(
            collection(db, 'profiles'),
            where('academic.university', '==', selectedUniversity)
          );
        } else {
          baseQuery = query(collection(db, 'profiles'));
        }
        
        const querySnapshot = await getDocs(baseQuery);
        const profiles = querySnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(profile => 
            profile.id !== user.uid && 
            profile.preferences?.showInMeetups === true
          );

        setUsers(profiles);
        setFilteredUsers(profiles);
      } catch (err) {
        console.error('Error fetching profiles:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserPreferencesAndRequests();
  }, [selectedUniversity]);

  useEffect(() => {
    if (searchQuery.trim() === '') {
      setFilteredUsers(users);
    } else {
      const query = searchQuery.toLowerCase();
      const filtered = users.filter(user => 
        user.basicInfo.name.toLowerCase().includes(query) ||
        user.basicInfo.username.toLowerCase().includes(query)
      );
      setFilteredUsers(filtered);
    }
  }, [searchQuery, users]);

  const handleMeetupRequest = async (userId, activity) => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) throw new Error('You must be logged in');
      
      if (sentRequests.includes(userId)) {
        toast.error('You have already sent a request to this user');
        return;
      }

      await sendMeetupRequest(
        currentUser.uid, 
        userId, 
        activity,
        userPreferences?.basicInfo?.name,
        users.find(u => u.id === userId)?.basicInfo?.name
      );
      
      setSentRequests(prev => [...prev, userId]);
      
      toast.success('Meetup request sent!', {
        duration: 3000,
        position: 'bottom-center',
      });
    } catch (err) {
      console.error('Error sending meetup request:', err);
      toast.error(err.message || 'Failed to send request', {
        duration: 3000,
        position: 'bottom-center',
      });
    }
  };

  const handleFilterChange = (university) => {
    setSelectedUniversity(university);
  };

  if (error) {
    return (
      <div className="h-screen flex items-center justify-center bg-gray-50">
        <div className="text-red-500">{error}</div>
      </div>
    );
  }

  return (
    <div className="h-screen bg-gray-50 flex flex-col">
      <MeetupHeader 
        title="Meetups"
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        userPreferences={userPreferences}
        onFilterChange={handleFilterChange}
      />
      
      <main className="flex-1 mt-28 mb-20 px-4 py-6 overflow-y-auto">
        {error && (
          <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-4">
            Error: {error}
          </div>
        )}
        <div className="space-y-4">
          {loading ? (
            Array(5).fill(null).map((_, index) => (
              <UserCardSkeleton key={`skeleton-${index}`} />
            ))
          ) : filteredUsers.length > 0 ? (
            filteredUsers.map((user) => (
              <UserCard
                key={user.id}
                user={user}
                onSendRequest={handleMeetupRequest}
                sentRequests={sentRequests}
              />
            ))
          ) : (
            <div className="text-center py-8">
              <p className="text-gray-500">No users found matching your criteria.</p>
              <p className="text-gray-400 text-sm mt-2">
                This could be because:
                <br />- No users have enabled meetups
                {selectedUniversity && (
                  <>
                    <br />- No users from {selectedUniversity}
                  </>
                )}
                <br />- Your search filters are too restrictive
              </p>
            </div>
          )}
        </div>
      </main>

      <NavBar />
    </div>
  );
};

export default MeetupsPage;