import React from 'react';

const PrivacyPolicyModal = ({ isOpen, onClose, onAccept }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg max-w-2xl w-full mx-4">
        <div className="p-6">
          <h2 className="text-xl font-bold mb-4">Terms of Use & Privacy Policy</h2>
          
          <div className="h-[60vh] overflow-y-auto pr-4">
            <div className="space-y-4 text-sm">
              <p className="font-semibold">Last Updated: February 7, 2025</p>

              <div className="space-y-2">
                <h3 className="font-semibold text-base">1. Acceptance of Terms</h3>
                <p>By accessing and using VarsityLink ("the Service"), you acknowledge that you have read, understood, and agree to be bound by these Terms of Use and Privacy Policy. If you do not agree to these terms, you must not use the Service.</p>
              </div>

              <div className="space-y-2">
                <h3 className="font-semibold text-base">2. User Eligibility</h3>
                <p>You hereby declare that:</p>
                <ul className="list-disc pl-5 space-y-1">
                  <li>You are a currently enrolled student at a recognized South African university</li>
                  <li>You are 18 years or older</li>
                  <li>You will use a valid university email address for verification</li>
                  <li>You have legal capacity to enter into this agreement</li>
                </ul>
              </div>

              <div className="space-y-2">
                <h3 className="font-semibold text-base">3. Limitation of Liability</h3>
                <p>VarsityLink, its directors, employees, partners, and agents shall not be liable for:</p>
                <ul className="list-disc pl-5 space-y-1">
                  <li>Any direct, indirect, incidental, special, or consequential damages</li>
                  <li>Any actions, conduct, or content of other users</li>
                  <li>Any misuse of personal information by other users</li>
                  <li>Any loss or damage arising from your use of the Service</li>
                  <li>Any offline meetings or interactions between users</li>
                </ul>
              </div>

              <div className="space-y-2">
                <h3 className="font-semibold text-base">4. User Conduct</h3>
                <p>You agree not to:</p>
                <ul className="list-disc pl-5 space-y-1">
                  <li>Harass, abuse, or harm other users</li>
                  <li>Share false or misleading information</li>
                  <li>Impersonate others or provide false information</li>
                  <li>Use the Service for any illegal purposes</li>
                  <li>Share content that violates South African law</li>
                  <li>Attempt to manipulate or exploit the Service</li>
                </ul>
              </div>

              <div className="space-y-2">
                <h3 className="font-semibold text-base">5. Privacy & Data Protection</h3>
                <p>In accordance with the Protection of Personal Information Act (POPIA):</p>
                <ul className="list-disc pl-5 space-y-1">
                  <li>We collect and process your personal information with your consent</li>
                  <li>Your data may be stored on secure servers outside South Africa</li>
                  <li>We implement reasonable security measures to protect your data</li>
                  <li>You have the right to access, correct, or delete your personal information</li>
                  <li>We may share your information with law enforcement if required by law</li>
                </ul>
              </div>

              <div className="space-y-2">
                <h3 className="font-semibold text-base">6. Indemnification</h3>
                <p>You agree to indemnify and hold harmless VarsityLink, its affiliates, officers, directors, employees, and agents from and against any claims, disputes, demands, liabilities, damages, losses, and expenses.</p>
              </div>

              <div className="space-y-2">
                <h3 className="font-semibold text-base">7. Termination</h3>
                <p>We reserve the right to:</p>
                <ul className="list-disc pl-5 space-y-1">
                  <li>Suspend or terminate your account at any time without notice</li>
                  <li>Remove any content that violates these terms</li>
                  <li>Modify or discontinue the Service without notice</li>
                </ul>
              </div>

              <div className="space-y-2">
                <h3 className="font-semibold text-base">8. Governing Law</h3>
                <p>These terms shall be governed by and construed in accordance with the laws of the Republic of South Africa. Any disputes shall be subject to the exclusive jurisdiction of the South African courts.</p>
              </div>

              <p className="mt-6 text-xs text-gray-500">By clicking "Accept & Continue", you acknowledge that you have read, understood, and agree to be bound by these Terms of Use and Privacy Policy.</p>
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-2 p-6 border-t">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
          >
            Decline
          </button>
          <button
            onClick={onAccept}
            className="px-4 py-2 text-sm font-medium text-white bg-green-500 rounded-lg hover:bg-green-600"
          >
            Accept & Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyModal;