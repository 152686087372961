import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Heart, MapPin, Building2, GraduationCap, AtSign, Clock,
  Users, Send, Medal, X, Coffee, Utensils, Book, Lightbulb,
  ClipboardList, FlaskConical, Briefcase, Dumbbell, Activity,
  Music, PartyPopper, Camera, Bike, Palette, Film, Handshake,
  Globe, IceCream, Sparkles
} from 'lucide-react';
import MeetupOption from './MeetupOption';
import UserProfileModal from './UserProfileModal ';

const meetupOptions = [
  { icon: Coffee, label: 'Coffee Chat', category: 'social' },
  { icon: Utensils, label: 'Foodie Adventure', category: 'social' },
  { icon: Book, label: 'Study Session', category: 'academic' },
  { icon: Users, label: 'Group Tutoring', category: 'academic' },
  { icon: Lightbulb, label: 'Brainstorming Session', category: 'academic' },
  { icon: ClipboardList, label: 'Assignment Help', category: 'academic' },
  { icon: FlaskConical, label: 'Lab Partners', category: 'academic' },
  { icon: Briefcase, label: 'Career Workshop', category: 'professional' },
  { icon: Dumbbell, label: 'Gym Buddy', category: 'fitness' },
  { icon: Activity, label: 'Morning Run', category: 'fitness' },
  { icon: Music, label: 'Music & Vibes', category: 'entertainment' },
  { icon: Gamepad, label: 'Gaming Session', category: 'entertainment' },
  { icon: PartyPopper, label: 'Party Time', category: 'social' },
  { icon: Camera, label: 'Photo Walk', category: 'hobby' },
  { icon: Bike, label: 'Cycling Group', category: 'fitness' },
  { icon: Palette, label: 'Art Gallery Visit', category: 'culture' },
  { icon: Film, label: 'Movie Night', category: 'entertainment' },
  { icon: Handshake, label: 'Networking Meetup', category: 'professional' },
  { icon: Globe, label: 'Language Exchange', category: 'academic' },
  { icon: IceCream, label: 'Ice Cream Date', category: 'social' },
];

const UserCard = ({ 
  user, 
  onSendRequest, 
  sentRequests,
  onLike,
  isLiked
}) => {
  const [showActions, setShowActions] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [showLikeEffect, setShowLikeEffect] = useState(false);
  const [isLiking, setIsLiking] = useState(false);
  
  const hasRequestBeenSent = sentRequests.includes(user.id);

  const filteredOptions = selectedCategory === 'all' 
    ? meetupOptions 
    : meetupOptions.filter(option => option.category === selectedCategory);

  const categories = [
    { id: 'all', label: 'All', icon: Sparkles },
    { id: 'social', label: 'Social', icon: Users },
    { id: 'academic', label: 'Academic', icon: GraduationCap },
    { id: 'professional', label: 'Career', icon: Building2 },
    { id: 'entertainment', label: 'Fun', icon: Heart },
    { id: 'culture', label: 'Culture', icon: MapPin },
    { id: 'fitness', label: 'Fitness', icon: Clock },
    { id: 'hobby', label: 'Hobbies', icon: AtSign }
  ];

  const handleLike = async () => {
    if (isLiking) return;
    
    try {
      setIsLiking(true);
      setShowLikeEffect(true);
      await onLike(user.id);
      
      // Reset like effect after animation
      setTimeout(() => {
        setShowLikeEffect(false);
      }, 1000);
    } finally {
      setIsLiking(false);
    }
  };

  return (
    <>
      <motion.div 
        layout
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -10 }}
        className="bg-white rounded-xl shadow-sm overflow-hidden border border-gray-100 hover:shadow-md transition-all duration-300 mb-3"
        onClick={() => setShowProfile(true)}
      >
        <div className="p-3 flex items-center">
          {/* Profile Photo - Round */}
          <div className="relative mr-3">
            {showLikeEffect && (
              <div className="absolute inset-0 z-10 flex items-center justify-center bg-black/30 rounded-full">
                <Heart className="w-10 h-10 text-pink-500 animate-ping" />
              </div>
            )}
            
            <div className="w-14 h-14 rounded-full overflow-hidden border-2 border-indigo-100">
              {user.photos?.[0] ? (
                <img
                  src={user.photos[0]}
                  alt={user.basicInfo.name}
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="w-full h-full bg-gradient-to-br from-gray-100 to-gray-200 flex items-center justify-center">
                  <Users className="w-6 h-6 text-gray-400" />
                </div>
              )}
            </div>
            
            {user.preferences?.verified && (
              <div className="absolute bottom-0 right-0 bg-indigo-500 rounded-full p-1 shadow-md border border-white">
                <Medal className="w-3 h-3 text-white" />
              </div>
            )}
          </div>
          
          {/* User Info - Compact */}
          <div className="flex-1">
            <div className="flex items-center justify-between">
              <h2 className="text-base font-semibold text-gray-800 flex items-center">
                {user.basicInfo.name}
                <span className="text-xs text-gray-500 ml-2 font-normal">@{user.basicInfo.username}</span>
              </h2>
            </div>
            
            <div className="flex flex-wrap gap-1 mt-1">
              <div className="flex items-center text-xs text-gray-500 bg-gray-50 px-2 py-1 rounded-md">
                <GraduationCap className="w-3 h-3 mr-1 text-indigo-500" />
                <span>{user.academic.yearOfStudy} {user.academic.degree}</span>
              </div>
              
              <div className="flex items-center text-xs text-gray-500 bg-gray-50 px-2 py-1 rounded-md">
                <MapPin className="w-3 h-3 mr-1 text-indigo-500" />
                <span>{user.academic.residence}</span>
              </div>
            </div>
          </div>
          
          {/* Status Indicator (replacing the Connect button) */}
          <div>
            {hasRequestBeenSent && (
              <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="text-xs text-green-600 flex items-center bg-green-50 py-1 px-2 rounded-md"
              >
                <Send className="w-3 h-3 mr-1" />
                Sent
              </motion.div>
            )}
          </div>
        </div>
      </motion.div>

      {/* Bottom sheet modal for meetup options */}
      <AnimatePresence>
        {showActions && !hasRequestBeenSent && (
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 z-50 flex items-end justify-center"
            onClick={() => setShowActions(false)}
          >
            <motion.div
              initial={{ y: "100%" }}
              animate={{ y: 0 }}
              exit={{ y: "100%" }}
              transition={{ type: "spring", damping: 25, stiffness: 300 }}
              onClick={(e) => e.stopPropagation()}
              className="w-full max-w-md bg-white rounded-t-3xl overflow-hidden"
            >
              <div className="flex justify-between items-center border-b border-gray-100 p-4">
                <h3 className="font-medium text-lg text-gray-800">Connect with {user.basicInfo.name}</h3>
                <button 
                  onClick={() => setShowActions(false)}
                  className="p-1 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors"
                >
                  <X className="w-5 h-5 text-gray-600" />
                </button>
              </div>
              
              <div className="p-4">
                <div className="flex gap-2 overflow-x-auto pb-3 hide-scrollbar">
                  {categories.map((category) => (
                    <button
                      key={category.id}
                      onClick={() => setSelectedCategory(category.id)}
                      className={`px-3 py-2 rounded-lg text-xs font-medium whitespace-nowrap flex items-center gap-1
                        ${selectedCategory === category.id
                          ? 'bg-indigo-500 text-white'
                          : 'bg-white text-gray-600 hover:bg-gray-100 border border-gray-200'
                        } transition-all duration-200`}
                    >
                      <category.icon className="w-3 h-3" />
                      {category.label}
                    </button>
                  ))}
                </div>
                
                <div className="grid grid-cols-2 gap-2 mt-2 max-h-64 overflow-y-auto">
                  {filteredOptions.map((option) => (
                    <MeetupOption
                      key={option.label}
                      icon={option.icon}
                      label={option.label}
                      onClick={() => {
                        onSendRequest(user.id, option.label);
                        setShowActions(false);
                      }}
                    />
                  ))}
                </div>
              </div>
              
              <div className="p-4 border-t border-gray-100 bg-gray-50">
                <button
                  onClick={() => setShowActions(false)}
                  className="w-full bg-indigo-500 text-white py-3 px-4 rounded-xl font-medium"
                >
                  Cancel
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* User Profile Modal (when clicking the card) */}
      <UserProfileModal
        isOpen={showProfile}
        onClose={() => setShowProfile(false)}
        user={user}
        onSendRequest={onSendRequest}
        hasRequestBeenSent={hasRequestBeenSent}
        onLike={handleLike}
        isLiked={isLiked}
      />
    </>
  );
};

export default UserCard;