// components/groups/MemberSelectionModal.js
export const MemberSelectionModal = ({ users, selectedMembers, onToggleMember, onClose, onSave }) => (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-2xl p-6 w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4">Select Group Members</h2>
        <div className="max-h-96 overflow-y-auto">
          {users.map(user => (
            <div key={user.id} className="flex items-center justify-between py-2 border-b">
              <div className="flex items-center space-x-3">
                <img 
                  src={user.photos?.[0] || '/default-avatar.png'} 
                  alt={user.basicInfo?.name} 
                  className="w-10 h-10 rounded-full"
                />
                <span>{user.basicInfo?.name || 'Anonymous'}</span>
              </div>
              <input 
                type="checkbox" 
                checked={selectedMembers.includes(user.id)}
                onChange={() => onToggleMember(user.id)}
                className="form-checkbox"
              />
            </div>
          ))}
        </div>
        <div className="flex space-x-3 mt-4">
          <button onClick={onClose} className="flex-1 py-2 bg-gray-100 rounded-lg">
            Cancel
          </button>
          <button onClick={onSave} className="flex-1 py-2 bg-green-500 text-white rounded-lg">
            Save
          </button>
        </div>
      </div>
    </div>
  );
  