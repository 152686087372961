import React from 'react';
import { UNIVERSITIES, STUDY_YEARS } from '../../constants/profileConstants';

const AcademicInformation = ({ 
  profile, 
  isEditing, 
  setProfile 
}) => {
  return (
    <section className="bg-white rounded-2xl p-6 shadow-sm">
      <h2 className="text-xl font-semibold mb-6">Academic Information</h2>
      <div className="space-y-6">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">University</label>
            {isEditing ? (
              <select
                value={profile.academic.university}
                onChange={(e) => setProfile(prev => ({
                  ...prev,
                  academic: { ...prev.academic, university: e.target.value }
                }))}
                className="w-full p-2 border rounded-lg"
              >
                <option value="">Select University</option>
                {UNIVERSITIES.map(uni => (
                  <option key={uni} value={uni}>{uni}</option>
                ))}
              </select>
            ) : (
              <p className="text-gray-900">{profile.academic.university}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Year of Study</label>
            {isEditing ? (
              <select
                value={profile.academic.yearOfStudy}
                onChange={(e) => setProfile(prev => ({
                  ...prev,
                  academic: { ...prev.academic, yearOfStudy: e.target.value }
                }))}
                className="w-full p-2 border rounded-lg"
              >
                <option value="">Select Year</option>
                {STUDY_YEARS.map(year => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
            ) : (
              <p className="text-gray-900">
                {profile.academic.yearOfStudy || "Not specified"}
              </p>
            )}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Degree</label>
          {isEditing ? (
            <input
              type="text"
              value={profile.academic.degree}
              onChange={(e) => setProfile(prev => ({
                ...prev,
                academic: { ...prev.academic, degree: e.target.value }
              }))}
              className="w-full p-2 border rounded-lg"
              placeholder="Enter your degree"
            />
          ) : (
            <p className="text-gray-900">{profile.academic.degree}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Residence</label>
          {isEditing ? (
            <input
              type="text"
              value={profile.academic.residence}
              onChange={(e) => setProfile(prev => ({
                ...prev,
                academic: { ...prev.academic, residence: e.target.value }
              }))}
              className="w-full p-2 border rounded-lg"
              placeholder="Enter your residence"
            />
          ) : (
            <p className="text-gray-900">{profile.academic.residence}</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default AcademicInformation;