import React from 'react';
import {
    BookOpenCheck,
    Calendar,
    FileText,
    Edit,
    ExternalLink,
    Tag,
    Clock,
    DollarSign,
    ChevronRight,   MapPin
} from 'lucide-react';

const AssignmentCard = ({ assignment, currentUser, onEdit }) => {
    const canEdit = currentUser && assignment.userId === currentUser.uid;
    const expired = isExpired(assignment.dueDate);
    
    // Function to check if assignment is expired
    function isExpired(dueDate) {
        if (!dueDate) return false;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        // Parse the due date (assuming format YYYY-MM-DD)
        const assignmentDueDate = new Date(dueDate);
        return assignmentDueDate < today;
    }

    // Format date to be more readable
    const formatDate = (dateString) => {
        if (!dateString) return 'No date';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { 
            month: 'short', 
            day: 'numeric', 
            year: 'numeric' 
        });
    };
    
    return (
        <div
            className="bg-white rounded-xl shadow-md mb-4 overflow-hidden border border-gray-100 
                      transition-all duration-300 hover:shadow-lg hover:border-blue-100 transform hover:-translate-y-1"
        >
            <div className="p-5">
                {/* Status Badge - Expired or Active */}
                <div className="flex justify-between items-start mb-3">
                    <div 
                        className={`text-xs font-medium px-2.5 py-1 rounded-full ${
                            expired 
                                ? 'bg-orange-100 text-orange-600' 
                                : 'bg-green-100 text-green-600'
                        }`}
                    >
                        {expired ? 'Expired' : 'Active'}
                    </div>
                    
                    {canEdit && (
                        <button
                            onClick={() => onEdit(assignment)}
                            className="flex items-center text-blue-500 hover:text-blue-700 transition-colors bg-blue-50 hover:bg-blue-100 rounded-full p-1.5"
                            aria-label="Edit assignment"
                        >
                            <Edit className="w-4 h-4" />
                        </button>
                    )}
                </div>
                
                {/* Assignment Header */}
                <div className="flex items-start space-x-3">
                    <div className="bg-blue-50 p-2 rounded-lg">
                        <BookOpenCheck className="w-6 h-6 text-blue-500" />
                    </div>
                    <div className="flex-1">
                        <h3 className="font-bold text-xl text-gray-800 line-clamp-1">
                            {assignment.courseName || 'Untitled Assignment'}
                        </h3>
                        <div className="flex items-center mt-1 text-gray-500">
                            <Tag className="w-3.5 h-3.5 mr-1" />
                            <p className="text-sm">
                                {assignment.courseCode || 'No code'} 
                            </p>
                        </div>
                    </div>
                </div>
                
                {/* Key Details */}
                <div className="mt-4 grid grid-cols-2 gap-3">
                    <div className="bg-gray-50 rounded-lg p-2.5">
                        <div className="flex items-center space-x-2">
                            <Calendar className="w-4 h-4 text-gray-500" />
                            <span className="text-xs text-gray-500 font-medium">Due Date</span>
                        </div>
                        <p className={`text-sm mt-1 font-medium ${expired ? 'text-orange-600' : 'text-gray-700'}`}>
                            {formatDate(assignment.dueDate)}
                        </p>
                    </div>
                    
                    <div className="bg-gray-50 rounded-lg p-2.5">
                        <div className="flex items-center space-x-2">
                     
                            <span className="text-xs text-gray-500 font-medium">Price Offer</span>
                        </div>
                        <p className="text-sm mt-1 font-medium text-green-600">
                            R {assignment.priceOffer || '0'}
                        </p>
                    </div>
                </div>

                {/* University Info */}
                <div className="mt-3 flex items-center space-x-2 bg-blue-50 p-2 rounded-lg">
                    <MapPin className="w-4 h-4 text-blue-500" />
                    <span className="text-sm text-blue-700">{assignment.university || 'No university specified'}</span>
                </div>
                
                {/* Assignment Description */}
                {assignment.description && (
                    <div className="mt-3">
                        <h4 className="font-medium text-sm text-gray-700 mb-1">Description:</h4>
                        <p className="text-sm text-gray-600 line-clamp-3">{assignment.description}</p>
                    </div>
                )}
                
                {/* PDF Link */}
                {assignment.pdfUrl && (
                    <div className="mt-4">
                        <a
                            href={assignment.pdfUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center justify-center space-x-2 text-blue-600 hover:text-blue-800 transition-colors
                                     bg-blue-50 hover:bg-blue-100 rounded-lg py-2 px-4 w-full"
                        >
                            <FileText className="w-4 h-4" />
                            <span className="font-medium text-sm">View Assignment PDF</span>
                            <ExternalLink className="w-3.5 h-3.5 ml-1" />
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AssignmentCard;