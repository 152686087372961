import React, { useState, useEffect } from 'react';
import { 
  X, MapPin, Building2, GraduationCap, Heart, Camera, ChevronLeft, ChevronRight,
  Languages, User, Goal, Medal, AtSign, ScrollText
} from 'lucide-react';

const UserProfileModal = ({ isOpen, onClose, profile, onLike, isLiked }) => {
  const [step, setStep] = useState(0);
  const [expandedImage, setExpandedImage] = useState(null);
  const [isLiking, setIsLiking] = useState(false);
  const [showLikeEffect, setShowLikeEffect] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      setStep(0);
    } else {
      document.body.style.overflow = '';
    }

    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscape);
    return () => {
      window.removeEventListener('keydown', handleEscape);
      document.body.style.overflow = '';
    };
  }, [isOpen, onClose]);

  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleLike = async () => {
    if (isLiking) return;
    
    try {
      setIsLiking(true);
      setShowLikeEffect(true);
      await onLike();
      
      // Reset like effect after animation
      setTimeout(() => {
        setShowLikeEffect(false);
      }, 1000);
    } finally {
      setIsLiking(false);
    }
  };

  if (!isOpen) return null;

  // Define tabs based on available data
  const tabs = [
    { 
      id: 'about', 
      label: 'About', 
      icon: ScrollText, 
      show: true 
    },
    { 
      id: 'details', 
      label: 'Details', 
      icon: User,
      show: profile?.bio?.personalities?.length > 0 || profile?.bio?.relationshipGoal || profile?.languages?.length > 0
    },
    { 
      id: 'photos', 
      label: 'Photos', 
      icon: Camera, 
      show: profile?.photos?.length > 0 
    }
  ].filter(tab => tab.show);

  const nextStep = () => setStep(prev => Math.min(prev + 1, tabs.length - 1));
  const prevStep = () => setStep(prev => Math.max(prev - 1, 0));

  return (
    <div 
      className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-[60] backdrop-blur-sm"
      onClick={handleOutsideClick}
    >
      {/* Like effect overlay */}
      {showLikeEffect && (
        <div className="absolute inset-0 z-70 flex items-center justify-center bg-black/30">
          <Heart className="w-32 h-32 text-pink-500 animate-ping" />
        </div>
      )}
      
      <div className="bg-white rounded-3xl w-full max-w-lg max-h-[85vh] flex flex-col relative overflow-hidden shadow-lg">
        {/* Profile Image Header */}
        <div className="relative w-full h-64">
          {profile.photos?.[0] ? (
            <img
              src={profile.photos[0]}
              alt={profile.basicInfo?.name}
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="w-full h-full bg-gray-200 flex items-center justify-center">
              <User className="w-12 h-12 text-gray-400" />
            </div>
          )}
          
          {/* Close Button */}
          <button 
            onClick={onClose}
            className="absolute top-4 right-4 p-2 rounded-full bg-black/20 hover:bg-black/30 text-white transition-colors z-10"
          >
            <X className="w-5 h-5" />
          </button>
          
          {/* Gradient Overlay */}
          <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 via-black/50 to-transparent p-4">
            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <h2 className="text-white text-2xl font-semibold">
                  {profile.basicInfo?.name}
                </h2>
                <div className="absolute bottom-2 right-2">
                  {profile.basicInfo?.username && (
                    <span className="inline-flex items-center gap-1 text-gray-400 text-sm 
                                 bg-black/20 backdrop-blur-sm px-3 py-1 rounded-full
                                 transition-all duration-300 hover:bg-gray-500/20
                                 hover:text-gray-200 cursor-pointer">
                      <AtSign className="w-4 h-4 transition-transform group-hover:rotate-12" />
                      <span className="font-sans tracking-wide">{profile.basicInfo.username}</span>
                    </span>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap items-center gap-2">
                {profile.academic?.degree && (
                  <div className="flex items-center text-gray-200 text-sm bg-black/30 px-2 py-1 rounded-full">
                    <GraduationCap className="w-4 h-4 mr-1" />
                    <span>{profile.academic.yearOfStudy} {profile.academic.degree}</span>
                  </div>
                )}
                {profile.interests?.slice(0, 2).map((interest, index) => (
                  <span key={index} className="text-xs bg-green-500/80 text-white px-2 py-1 rounded-full">
                    {interest}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Navigation Pills */}
        <div className="flex-shrink-0 px-6 pt-4">
          <div className="flex justify-between bg-gray-50 rounded-xl p-1">
            {tabs.map((tab, index) => (
              <button
                key={tab.id}
                onClick={() => setStep(index)}
                className={`flex-1 flex items-center justify-center p-2 rounded-lg transition-all
                  ${step === index ? 'bg-white text-pink-500 shadow-sm' : 'text-gray-500 hover:text-gray-700'}`}
              >
                <tab.icon className="w-5 h-5" />
                <span className="hidden sm:ml-2 sm:inline">{tab.label}</span>
              </button>
            ))}
          </div>
        </div>

        {/* Scrollable Content Area */}
        <div className="flex-1 overflow-y-auto px-6 py-4">
          {step === 0 && (
            <div className="space-y-4">
              {profile.academic?.university && (
                <div className="flex items-center space-x-2">
                  <Building2 className="w-5 h-5 text-gray-500" />
                  <span className="text-sm text-gray-600">
                    {profile.academic.university}
                  </span>
                </div>
              )}

              <div className="space-y-3">
                {(profile.bio?.shortBio || profile.bio?.longBio) && (
                  <p className="text-gray-600 leading-relaxed">
                    {profile.bio.longBio || profile.bio.shortBio}
                  </p>
                )}
                
                {profile.academic?.residence && (
                  <div className="flex items-center text-green-600 text-sm bg-green-50 p-2 rounded-lg">
                    <MapPin className="w-4 h-4 mr-1" />
                    <span>Currently at: {profile.academic.residence}</span>
                  </div>
                )}
              </div>

              {profile.interests?.length > 0 && (
                <div className="space-y-2 pt-2">
                  <h3 className="font-medium text-gray-700 flex items-center gap-2">
                    <Heart className="w-4 h-4 text-pink-500" /> Interests
                  </h3>
                  <div className="flex flex-wrap gap-2">
                    {profile.interests.map((interest, index) => (
                      <span key={index} className="px-3 py-1 bg-green-50 text-green-600 rounded-full text-sm hover:bg-green-100 transition-colors">
                        {interest}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}

          {step === 1 && (
            <div className="space-y-4">
              {profile.bio?.personalities?.length > 0 && (
                <div className="space-y-2">
                  <h3 className="font-medium text-gray-700 flex items-center gap-2">
                    <Heart className="w-4 h-4 text-pink-500" /> Personality
                  </h3>
                  <div className="flex flex-wrap gap-2">
                    {profile.bio.personalities.map((personality, index) => (
                      <span key={index} className="px-3 py-1 bg-gray-50 rounded-full text-gray-600 text-sm hover:bg-gray-100 transition-colors">
                        {personality}
                      </span>
                    ))}
                  </div>
                </div>
              )}

              {profile.languages?.length > 0 && (
                <div className="space-y-2 pt-2">
                  <h3 className="font-medium text-gray-700 flex items-center gap-2">
                    <Languages className="w-4 h-4 text-pink-500" /> Languages
                  </h3>
                  <div className="flex items-center space-x-2 p-3 bg-gray-50 rounded-xl text-gray-600 hover:bg-gray-100 transition-colors">
                    <span className="text-sm">{profile.languages.join(', ')}</span>
                  </div>
                </div>
              )}

              {profile.bio?.relationshipGoal && (
                <div className="space-y-2 pt-2">
                  <h3 className="font-medium text-gray-700 flex items-center gap-2">
                    <Goal className="w-4 h-4 text-pink-500" /> Looking For
                  </h3>
                  <div className="flex items-center space-x-2 p-3 bg-gray-50 rounded-xl text-gray-600 hover:bg-gray-100 transition-colors">
                    <span className="text-sm">{profile.bio.relationshipGoal}</span>
                  </div>
                </div>
              )}
              
              {(profile.basicInfo?.gender || profile.basicInfo?.interestedIn?.length > 0) && (
                <div className="space-y-2 pt-2">
                  <h3 className="font-medium text-gray-700 flex items-center gap-2">
                    <User className="w-4 h-4 text-pink-500" /> Basic Info
                  </h3>
                  <div className="bg-gray-50 p-3 rounded-xl space-y-2 text-sm">
                    {profile.basicInfo.gender && (
                      <p className="text-gray-600">Gender: {profile.basicInfo.gender}</p>
                    )}
                    {profile.basicInfo.interestedIn?.length > 0 && (
                      <p className="text-gray-600">Interested in: {profile.basicInfo.interestedIn.join(', ')}</p>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          {step === 2 && profile.photos?.length > 0 && (
            <div className="grid grid-cols-2 gap-4">
              {profile.photos.map((photo, index) => (
                <div key={index} className="relative group">
                  <img
                    src={photo}
                    alt={`${profile.basicInfo?.name || 'User'}'s photo ${index + 1}`}
                    className="w-full aspect-square object-cover rounded-xl cursor-pointer hover:opacity-95 transition-opacity"
                    onClick={() => setExpandedImage(photo)}
                  />
                  <div className="absolute inset-0 bg-black/0 group-hover:bg-black/10 rounded-xl transition-colors flex items-center justify-center">
                    <div className="opacity-0 group-hover:opacity-100 transition-opacity">
                      <button 
                        onClick={(e) => {
                          e.stopPropagation();
                          setExpandedImage(photo);
                        }}
                        className="bg-white/80 p-2 rounded-full text-pink-500"
                      >
                        <Camera className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        
        {/* Action Buttons */}
        <div className="flex-shrink-0 p-4 border-t flex justify-around">
          <button 
            className="p-4 rounded-full bg-gray-100 hover:bg-gray-200 transition-all hover:scale-110"
            onClick={onClose}
            aria-label="Pass"
          >
            {/* Upside-down heart for "pass" to match ProfileCard */}
            <Heart 
              className="w-8 h-8 text-gray-400 transform rotate-180" 
              fill="none"
            />
          </button>
          <button 
            className={`p-4 rounded-full transition-all hover:scale-110 
                      ${isLiked ? 'bg-pink-100' : 'bg-gray-100 hover:bg-gray-200'}
                      ${isLiking ? 'scale-95' : ''}`}
            onClick={handleLike}
            disabled={isLiking}
            aria-label="Like"
          >
            <Heart 
              className={`w-8 h-8 transform transition-transform
                        ${isLiked ? 'text-pink-500 scale-110' : 'text-pink-400'}
                        ${isLiking ? 'scale-90' : ''}`} 
              fill={isLiked ? "currentColor" : "none"}
            />
          </button>
        </div>

        {/* Mobile Navigation Dots */}
        {tabs.length > 1 && (
          <div className="flex-shrink-0 pb-4 sm:hidden">
            <div className="flex justify-center space-x-1">
              {tabs.map((_, index) => (
                <div
                  key={index}
                  className={`w-2 h-2 rounded-full ${
                    index === step ? 'bg-pink-500' : 'bg-gray-300'
                  }`}
                />
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Expanded Image Modal */}
      {expandedImage && (
        <div 
          className="fixed inset-0 bg-black/90 z-[70] flex items-center justify-center p-4"
          onClick={() => setExpandedImage(null)}
        >
          <button 
            onClick={(e) => {
              e.stopPropagation();
              setExpandedImage(null);
            }}
            className="absolute top-4 right-4 p-2 rounded-full bg-white/20 hover:bg-white/30 text-white transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
          <img
            src={expandedImage}
            alt="Expanded"
            className="max-w-full max-h-[90vh] object-contain"
          />
        </div>
      )}
    </div>
  );
};

export default UserProfileModal;