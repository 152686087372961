import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase/firebase';
import { 
  collection, 
  query, 
  where, 
  onSnapshot,
  doc,
  updateDoc
} from 'firebase/firestore';
import { getDocs } from 'firebase/firestore';
import { socialService } from '../firebase/socialService';

const NotificationsContext = createContext();

export const NotificationsProvider = ({ children }) => {
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [unreadActivities, setUnreadActivities] = useState(0);
  const [unreadSocial, setUnreadSocial] = useState(0);
  const [unreadGroupConnections, setUnreadGroupConnections] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [groupConnectionNotifications, setGroupConnectionNotifications] = useState([]);

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) return;
    // Subscribe to meetup requests in realtime
    const meetupRequestsQuery = query(
      collection(db, 'meetupRequests'),
      where('receiverId', '==', user.uid),
      where('status', '==', 'pending'),
      where('seen', '==', false)
    );
// Add subscription to social notifications
const unsubscribeSocial = socialService.subscribeToNotifications(
  user.uid,
  (notifications) => {
    setNotifications(notifications);
    setUnreadSocial(notifications.length);
  }
);

    const unsubscribeMeetupRequests = onSnapshot(meetupRequestsQuery, (snapshot) => {
      setUnreadActivities(snapshot.size);
    });

    // Subscribe to all user's chat rooms in realtime
    const chatsQuery = query(
      collection(db, 'chats'),
      where('participants', 'array-contains', user.uid)
    );

    const unsubscribeChats = onSnapshot(chatsQuery, (snapshot) => {
      let totalUnread = 0;
      snapshot.docs.forEach(chatDoc => {
        const chatData = chatDoc.data();
        if (chatData.unreadCounts && chatData.unreadCounts[user.uid]) {
          totalUnread += chatData.unreadCounts[user.uid];
        }
      });
      setUnreadMessages(totalUnread);
    });
 // Subscribe to group connection invitations
 const groupConnectionQuery = query(
  collection(db, 'groupConnectionInvitations'),
  where('targetGroupMembers', 'array-contains', user.uid),
  where('status', '==', 'pending')
);

const unsubscribeGroupConnections = onSnapshot(groupConnectionQuery, (snapshot) => {
  const notifications = snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
  setGroupConnectionNotifications(notifications);
  setUnreadGroupConnections(notifications.length);
});
    // Cleanup subscriptions
    return () => {
      unsubscribeGroupConnections();
      unsubscribeSocial();
      unsubscribeMeetupRequests();
      unsubscribeChats();
    };
  }, []);
  const acceptGroupConnectionInvitation = async (invitationId) => {
    const invitationRef = doc(db, 'groupConnectionInvitations', invitationId);
    await updateDoc(invitationRef, { status: 'accepted' });
  };

  const rejectGroupConnectionInvitation = async (invitationId) => {
    const invitationRef = doc(db, 'groupConnectionInvitations', invitationId);
    await updateDoc(invitationRef, { status: 'rejected' });
  };

  const markMessagesAsRead = async (chatId) => {
    const user = auth.currentUser;
    if (!user || !chatId) return;

    const chatRef = doc(db, 'chats', chatId);
    await updateDoc(chatRef, {
      [`unreadCounts.${user.uid}`]: 0
    });
  };

  const markActivitiesAsRead = async () => {
    const user = auth.currentUser;
    if (!user) return;

    const meetupRequestsQuery = query(
      collection(db, 'meetupRequests'),
      where('receiverId', '==', user.uid),
      where('seen', '==', false)
    );

    const snapshot = await getDocs(meetupRequestsQuery);
    const updatePromises = snapshot.docs.map(doc => 
      updateDoc(doc.ref, { seen: true })
    );
    await Promise.all(updatePromises);
  };

  const markSocialNotificationsAsRead = async () => {
    const unreadIds = notifications
      .filter(notif => !notif.read)
      .map(notif => notif.id);
    
    if (unreadIds.length > 0) {
      await socialService.markNotificationsAsRead(unreadIds);
    }
  };

  const value = {
    messageCount: unreadMessages,
    activityCount: unreadActivities,
    socialCount: unreadSocial,
    groupConnectionCount: unreadGroupConnections,
    notifications,
    groupConnectionNotifications,
    acceptGroupConnectionInvitation,
    rejectGroupConnectionInvitation,
    markMessagesAsRead,
    markActivitiesAsRead,
    markSocialNotificationsAsRead
  };

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};


export const useNotifications = () => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error('useNotifications must be used within a NotificationsProvider');
  }
  return context;
};