// components/groups/GroupList.js
import { GroupCard } from './GroupCard';
import { GroupCardSkeleton } from './GroupCardSkeleton';
import { Users } from 'lucide-react';
import { useEffect, useRef } from 'react';

export const GroupList = ({
  groups,
  loading,
  isUserMember,
  onInviteToGames,
  emptyStateTitle,
  emptyStateMessage
}) => {
  const listRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!listRef.current) return;
      
      const dividers = listRef.current.querySelectorAll('.group-divider');
      
      dividers.forEach((divider) => {
        const rect = divider.getBoundingClientRect();
        const parentRect = listRef.current.getBoundingClientRect();
        const scrollProgress = (parentRect.bottom - rect.top) / parentRect.height;
        
        // Calculate opacity based on scroll position
        const opacity = Math.min(Math.max(scrollProgress, 0.1), 0.8);
        divider.style.opacity = opacity;
        
        // Add subtle parallax effect
        const translateY = Math.min(Math.max(scrollProgress * 5, 0), 10);
        divider.style.transform = `translateY(${translateY}px)`;
      });
    };

    const listElement = listRef.current;
    if (listElement) {
      listElement.addEventListener('scroll', handleScroll);
      // Initial call to set initial states
      handleScroll();
    }

    return () => {
      if (listElement) {
        listElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loading, groups.length]);

  return (
    <div 
      ref={listRef}
      className="space-y-6 max-w-3xl mx-auto overflow-y-auto"
      style={{ height: 'calc(100vh - 250px)' }} // Adjust based on your header/footer heights
    >
      {loading ? (
        <div className="space-y-6">
          {Array(3).fill(null).map((_, index) => (
            <div key={`skeleton-${index}`} className="relative">
              <GroupCardSkeleton />
              {index < 2 && (
                <div className="group-divider absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full max-w-[90%] h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent transition-all duration-300" />
              )}
            </div>
          ))}
        </div>
      ) : groups.length > 0 ? (
        <div className="space-y-6">
          {groups.map((group, index) => (
            <div key={group.id} className="relative">
              <GroupCard
                group={group}
                isUserMember={isUserMember}
                onInviteToGames={onInviteToGames}
              />
              {index < groups.length - 1 && (
                <div className="group-divider absolute -bottom-3 left-1/2 transform -translate-x-1/2 w-full max-w-[90%] h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent transition-all duration-300" />
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-12 bg-gray-50 rounded-xl">
          <Users className="w-12 h-12 text-gray-400 mx-auto mb-3" />
          <p className="text-gray-600 font-medium text-lg">{emptyStateTitle}</p>
          <p className="text-sm text-gray-400 mt-1">{emptyStateMessage}</p>
        </div>
      )}
    </div>
  );
};  