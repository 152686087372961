import React, { useState } from 'react';
import { GraduationCap, Users, Calendar, BookOpen, ArrowRight, Menu, X, Instagram, Mail, Phone, HelpCircle, ShoppingBag } from 'lucide-react';

const LandingPage = ({ navigateToLogin, navigateToRegister }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const features = [
    {
      icon: <Users className="w-6 h-6 text-green-500" />,
      title: "Connect with Students",
      description: "Build meaningful connections with fellow students from your university",
    },
    {
      icon: <BookOpen className="w-6 h-6 text-green-500" />,
      title: "Study Groups",
      description: "Join or create study groups to collaborate on assignments and exam prep",
    },
    {
      icon: <Calendar className="w-6 h-6 text-green-500" />,
      title: "Campus Events",
      description: "Stay updated with academic and social events happening around campus",
    }
  ];

  const navLinks = [
    { name: 'About', href: '#about' },
    { name: 'Events', href: '#events' },
    { name: 'Merchandise', href: '#merchandise' },
    { name: 'Support', href: '#support' },
  ];

  // Custom TikTok Icon Component
  const TikTokIcon = ({ className }) => (
    <svg 
      className={className} 
      viewBox="0 0 24 24" 
      fill="currentColor"
    >
      <path d="M19.59 6.69a4.83 4.83 0 0 1-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 0 1-5.2 1.74 2.89 2.89 0 0 1 2.31-4.64 2.93 2.93 0 0 1 .88.13V9.4a6.84 6.84 0 0 0-1-.05A6.33 6.33 0 0 0 5 20.1a6.34 6.34 0 0 0 10.86-4.43v-7a8.16 8.16 0 0 0 4.77 1.52v-3.4a4.85 4.85 0 0 1-1-.1z"/>
    </svg>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation */}
      <nav className="bg-white shadow-sm sticky top-0 z-50">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            {/* Logo */}
            <div className="flex items-center space-x-2">
              <div className="relative">
                <div className="w-10 h-10 bg-gradient-to-br from-green-500 to-green-600 rounded-xl flex items-center justify-center transform rotate-12 shadow-lg">
                  <span className="text-white font-bold text-xl transform -rotate-12">V</span>
                </div>
                <div className="absolute -top-1 -right-1 w-2 h-2 bg-green-400 rounded-full animate-pulse" />
              </div>
              <span className="text-xl font-bold text-gray-800">
                <span className="text-green-500">Varsity</span>Link
              </span>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-8">
              {navLinks.map((link) => (
                <a
                  key={link.name}
                  href={link.href}
                  className="text-gray-600 hover:text-green-500 transition-colors"
                >
                  {link.name}
                </a>
              ))}
              <a
                href="https://www.instagram.com/varsitylink_za/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-600 hover:text-green-500"
              >
                <Instagram className="w-5 h-5" />
              </a>
              <a
                href="https://www.tiktok.com/@varsitylink"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-600 hover:text-green-500"
              >
                <TikTokIcon className="w-5 h-5" />
              </a>
              <button
                onClick={navigateToLogin}
                className="text-gray-600 hover:text-green-500"
              >
                Log in
              </button>
              <button
                onClick={navigateToRegister}
                className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-xl"
              >
                Get Started
              </button>
            </div>

            {/* Mobile menu button */}
            <div className="md:hidden">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="text-gray-600 hover:text-green-500"
              >
                {isMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
              </button>
            </div>
          </div>

          {/* Mobile Navigation */}
          {isMenuOpen && (
            <div className="md:hidden py-4">
              {navLinks.map((link) => (
                <a
                  key={link.name}
                  href={link.href}
                  className="block py-2 text-gray-600 hover:text-green-500"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {link.name}
                </a>
              ))}
              <a
                href="https://www.instagram.com/varsitylink_za/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-2 py-2 text-gray-600 hover:text-green-500"
              >
                <Instagram className="w-5 h-5" />
                <span>Follow us on Instagram</span>
              </a>
              <a
                href="https://www.tiktok.com/@varsitylink"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-2 py-2 text-gray-600 hover:text-green-500"
              >
                <TikTokIcon className="w-5 h-5" />
                <span>Follow us on TikTok</span>
              </a>
              <div className="space-y-2 pt-4">
                <button
                  onClick={navigateToLogin}
                  className="block w-full text-left py-2 text-gray-600 hover:text-green-500"
                >
                  Log in
                </button>
                <button
                  onClick={navigateToRegister}
                  className="block w-full bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-xl"
                >
                  Get Started
                </button>
              </div>
            </div>
          )}
        </div>
      </nav>

      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-green-500/20 to-green-600/20 z-0" />
        <div className="relative z-10 container mx-auto px-4 pt-20 pb-32">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div className="max-w-2xl">
              <div className="flex items-center space-x-2 mb-6">
                <span className="bg-green-100 text-green-600 px-3 py-1 rounded-full text-sm flex items-center">
                  <GraduationCap className="w-4 h-4 mr-2" />
                  The Ultimate Student Connection Hub
                </span>
              </div>
              <h1 className="text-5xl font-bold text-gray-800 mb-6">
                Connect, Study, and Thrive with Your Campus Community
              </h1>
              <p className="text-xl text-gray-600 mb-8">
                Join VarsityLink to connect with fellow students, form study groups, 
                access academic resources, and make the most of your university experience.
              </p>
              <div className="flex items-center space-x-4">
                <button
                  onClick={navigateToRegister}
                  className="bg-green-500 hover:bg-green-600 text-white px-6 py-3 rounded-xl font-medium flex items-center space-x-2"
                >
                  <span>Join VarsityLink</span>
                  <ArrowRight className="w-5 h-5" />
                </button>
                <button
                  onClick={navigateToLogin}
                  className="border border-gray-300 hover:border-green-500 hover:text-green-500 px-6 py-3 rounded-xl font-medium"
                >
                  Sign In
                </button>
              </div>
            </div>
            <div className="block">
              <img 
                src="/images/5.png" 
                alt="VarsityLink App Preview" 
                className="rounded-2xl shadow-2xl transform rotate-2 hover:rotate-0 transition-transform duration-300 w-full max-w-md mx-auto"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div id="about" className="bg-white py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Why Choose VarsityLink?</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="group bg-gray-50 p-6 rounded-2xl hover:shadow-lg transition-all duration-300">
                <div className="w-12 h-12 bg-white rounded-xl flex items-center justify-center mb-4 shadow-sm">
                  {feature.icon}
                </div>
                <h3 className="text-xl font-semibold text-gray-800 mb-2">
                  {feature.title}
                </h3>
                <p className="text-gray-600">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Community Showcase Section */}
      <div className="bg-gray-50 py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Join Our Growing Community</h2>
          <div className="relative h-80 rounded-2xl overflow-hidden bg-gradient-to-br from-green-500/20 to-green-600/20">
            <div className="absolute inset-0 flex items-end">
              <div className="p-8 text-gray-800">
                <h3 className="text-2xl font-bold mb-2">Connect with 10,000+ Students</h3>
                <p className="text-lg opacity-90">Be part of something bigger</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Events Section */}
      <div id="events" className="bg-gray-50 py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Upcoming Events</h2>
          <div className="max-w-3xl mx-auto bg-white rounded-2xl p-8 shadow-sm">
            <div className="space-y-6">
              <div className="flex items-start space-x-4">
                <Calendar className="w-8 h-8 text-green-500 flex-shrink-0" />
                <div>
                  <h3 className="text-xl font-semibold">Campus Launch Event</h3>
                  <p className="text-gray-600 mt-2">
                    Join us for the official campus launch of VarsityLink! Meet fellow students,
                    learn about our features, and get exclusive merchandise.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Merchandise Section */}
      <div id="merchandise" className="bg-white py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">VarsityLink Merchandise</h2>
          <div className="max-w-3xl mx-auto text-center">
            <ShoppingBag className="w-16 h-16 text-green-500 mx-auto mb-6" />
            <p className="text-xl text-gray-600">
              Coming Soon! Stay tuned for our exclusive VarsityLink merchandise collection.
            </p>
          </div>
        </div>
      </div>

      {/* Support Section */}

      <div id="support" className="bg-gray-50 py-20">
  <div className="container mx-auto px-4">
    <h2 className="text-3xl font-bold text-center mb-12">Need Help?</h2>
    <div className="max-w-3xl mx-auto">
      <div className="grid md:grid-cols-4 gap-8">
        <div className="bg-white p-6 rounded-2xl text-center flex flex-col items-center h-full">
          <Mail className="w-8 h-8 text-green-500 mb-4" />
          <h3 className="font-semibold mb-2">Email Support</h3>
          <p className="text-gray-500 text-sm">admin@varsitylink.co.za</p>
        </div>
        <div className="bg-white p-6 rounded-2xl text-center flex flex-col items-center h-full">
          <Instagram className="w-8 h-8 text-green-500 mb-4" />
          <h3 className="font-semibold mb-2">Instagram</h3>
          <a 
            href="https://www.instagram.com/varsitylink_za/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-green-500 hover:text-green-600 text-sm"
          >
            @varsitylink_za
          </a>
        </div>
        <div className="bg-white p-6 rounded-2xl text-center flex flex-col items-center h-full">
          <TikTokIcon className="w-8 h-8 text-green-500 mb-4" />
          <h3 className="font-semibold mb-2">TikTok</h3>
          <a 
            href="https://www.tiktok.com/@varsitylink"
            target="_blank"
            rel="noopener noreferrer"
            className="text-green-500 hover:text-green-600 text-sm"
          >
            @varsitylink
          </a>
        </div>
        <div className="bg-white p-6 rounded-2xl text-center flex flex-col items-center h-full">
          <HelpCircle className="w-8 h-8 text-green-500 mb-4" />
          <h3 className="font-semibold mb-2">Help Center</h3>
          <p className="text-gray-600 text-sm">FAQs and Guides</p>
        </div>
      </div>
    </div>
  </div>
</div>

      {/* Footer */}
      <footer className="bg-white border-t">
        <div className="container mx-auto px-4 py-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="flex items-center space-x-2 mb-4 md:mb-0">
              <div className="relative">
                <div className="w-8 h-8 bg-gradient-to-br from-green-500 to-green-600 rounded-xl flex items-center justify-center transform rotate-12 shadow-lg">
                  <span className="text-white font-bold text-sm transform -rotate-12">V</span>
                </div>
              </div>
              <span className="text-sm text-gray-600">
                © 2025 VarsityLink. All rights reserved.
              </span>
            </div>
            <div className="flex items-center space-x-4">
              <a
                href="https://www.instagram.com/varsitylink_za/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-600 hover:text-green-500"
              >
                <Instagram className="w-5 h-5" />
              </a>
              <a
                href="https://www.tiktok.com/@varsitylink"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-600 hover:text-green-500"
              >
                <TikTokIcon className="w-5 h-5" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;