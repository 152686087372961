import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../../firebase/firebase';
import { 
  GraduationCap, Star, Book, Mail, User, Clock, Search, 
  Languages, Filter, CheckCircle, XCircle, SlidersHorizontal,
  AlertTriangle, EyeOff, Eye
} from 'lucide-react';
import { toast } from 'react-hot-toast';
import { format } from 'date-fns';

const TutorManagement = () => {
  const [applications, setApplications] = useState({
    pending: [],
    approved: [],
    rejected: [],
    suspended: []
  });
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [showFilters, setShowFilters] = useState(false);
  const [universitiesFilter, setUniversitiesFilter] = useState([]);
  const [selectedUniversity, setSelectedUniversity] = useState('');

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const snapshot = await getDocs(collection(db, 'tutorApplications'));
        const apps = await Promise.all(snapshot.docs.map(async (docSnapshot) => {
          const appData = {
            id: docSnapshot.id,
            ...docSnapshot.data(),
            createdAt: docSnapshot.data().createdAt?.toDate() || null,
          };

          // Fetch additional profile data
          const profileRef = doc(db, 'profiles', appData.userId);
          const profileSnap = await getDoc(profileRef);
          
          if (profileSnap.exists()) {
            const profileData = profileSnap.data();
            appData.languages = profileData.languages || [];
            appData.yearOfStudy = profileData.academic?.yearOfStudy || '';
            appData.name = profileData.basicInfo?.name || 'Unknown';
            
            // Fetch profile picture
            if (profileData.photos && profileData.photos.length > 0) {
              appData.photoURL = profileData.photos[0];
            } else if (profileData.photoURL) {
              appData.photoURL = profileData.photoURL;
            } else {
              appData.photoURL = '/api/placeholder/80/80';
            }
          }

          return appData;
        }));

        // Extract unique universities for filtering
        const universities = [...new Set(apps.map(app => app.university).filter(Boolean))];
        setUniversitiesFilter(universities);

        setApplications({
          pending: apps.filter(app => app.status === 'pending'),
          approved: apps.filter(app => app.status === 'approved'),
          rejected: apps.filter(app => app.status === 'rejected'),
          suspended: apps.filter(app => app.status === 'suspended')
        });
      } catch (error) {
        console.error('Error fetching tutor applications:', error);
        toast.error('Failed to load tutor applications');
      } finally {
        setLoading(false);
      }
    };

    fetchApplications();
  }, []);

  const handleApplicationAction = async (applicationId, action, reason = '') => {
    try {
      const applicationRef = doc(db, 'tutorApplications', applicationId);
      const updateData = {
        status: action,
        reviewedAt: serverTimestamp()
      };
      
      // If suspending, add suspension reason and timestamp
      if (action === 'suspended') {
        updateData.suspensionReason = reason;
        updateData.suspendedAt = serverTimestamp();
      }
      
      // If unsuspending (reactivating), remove suspension data
      if (action === 'approved' && applications.suspended.find(app => app.id === applicationId)) {
        updateData.suspensionReason = null;
        updateData.suspendedAt = null;
      }
      
      await updateDoc(applicationRef, updateData);

      const updatedApps = { ...applications };
      let movedApp;

      Object.keys(updatedApps).forEach(status => {
        const appIndex = updatedApps[status].findIndex(app => app.id === applicationId);
        if (appIndex !== -1) {
          [movedApp] = updatedApps[status].splice(appIndex, 1);
        }
      });

      if (movedApp) {
        movedApp.status = action;
        if (action === 'suspended') {
          movedApp.suspensionReason = reason;
          movedApp.suspendedAt = new Date();
        }
        updatedApps[action].push(movedApp);
        setApplications(updatedApps);
        
        // If application is approved, send the approval email
        if (action === 'approved') {
          try {
            // Call the function with tutor data
            const sendTutorApprovalEmail = httpsCallable(functions, 'sendTutorApprovalEmail');
            await sendTutorApprovalEmail({
              tutorEmail: movedApp.email,
              tutorName: movedApp.name || 'Tutor'
            });
            
            toast.success('Approval email sent to the tutor');
          } catch (emailError) {
            console.error('Failed to send approval email:', emailError);
            toast.error('Application approved, but failed to send email notification');
          }
        }
      }
      
      let successMessage;
      switch(action) {
        case 'approved':
          successMessage = 'Application approved successfully';
          break;
        case 'rejected':
          successMessage = 'Application rejected successfully';
          break;
        case 'suspended':
          successMessage = 'Tutor suspended successfully';
          break;
        default:
          successMessage = 'Application status updated successfully';
      }
      
      toast.success(successMessage);
    } catch (error) {
      console.error('Failed to update application:', error);
      toast.error('Failed to update application status');
    }
  };
  
  const [suspensionReason, setSuspensionReason] = useState('');
  const [showSuspendModal, setShowSuspendModal] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);

  const handleSuspendClick = (application) => {
    setSelectedApplication(application);
    setShowSuspendModal(true);
    setSuspensionReason('');
  };

  const handleSuspendConfirm = () => {
    if (selectedApplication) {
      handleApplicationAction(selectedApplication.id, 'suspended', suspensionReason);
      setShowSuspendModal(false);
    }
  };

  const SuspensionModal = () => (
    <div className={`fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center transition-opacity ${showSuspendModal ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <h3 className="text-lg font-bold text-gray-900 mb-4 flex items-center">
          <AlertTriangle className="w-5 h-5 text-yellow-500 mr-2" />
          Suspend Tutor
        </h3>
        <p className="text-gray-600 mb-4">
          Suspending will hide this tutor from the platform. They won't appear in search results or be able to receive new tutoring requests.
        </p>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Reason for suspension (optional)
          </label>
          <textarea
            value={suspensionReason}
            onChange={(e) => setSuspensionReason(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter reason for suspension"
            rows="3"
          />
        </div>
        <div className="flex justify-end space-x-3">
          <button
            onClick={() => setShowSuspendModal(false)}
            className="px-4 py-2 text-gray-700 border border-gray-300 rounded-lg hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={handleSuspendConfirm}
            className="px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600"
          >
            Suspend Tutor
          </button>
        </div>
      </div>
    </div>
  );

  const filterApplications = () => {
    const allApps = [...applications.pending, ...applications.approved, ...applications.rejected, ...applications.suspended];
    return allApps.filter(app => {
      const matchesSearch = 
        app.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        app.university?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        app.courses?.some(course => 
          course.courseCode.toLowerCase().includes(searchTerm.toLowerCase())
        );
      
      const matchesUniversity = !selectedUniversity || app.university === selectedUniversity;

      if (filterStatus === 'all') return matchesSearch && matchesUniversity;
      return matchesSearch && app.status === filterStatus && matchesUniversity;
    });
  };

  const ApplicationCard = ({ application }) => {
    const formattedDate = application.createdAt
      ? format(application.createdAt, 'MMMM d, yyyy h:mm a')
      : 'Date not available';

    const statusColors = {
      approved: 'bg-green-100 text-green-800',
      pending: 'bg-yellow-100 text-yellow-800',
      rejected: 'bg-red-100 text-red-800',
      suspended: 'bg-gray-100 text-gray-800'
    };

    const statusIcons = {
      approved: <CheckCircle className="w-4 h-4 mr-1" />,
      pending: <Clock className="w-4 h-4 mr-1" />,
      rejected: <XCircle className="w-4 h-4 mr-1" />,
      suspended: <EyeOff className="w-4 h-4 mr-1" />
    };

    return (
      <div className="bg-white rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 overflow-hidden border border-gray-100">
        <div className="p-4 md:p-6">
          <div className="flex justify-between items-center mb-4">
            <div className={`px-3 py-1 rounded-full text-sm font-medium flex items-center ${statusColors[application.status]}`}>
              {statusIcons[application.status]}
              <span>{application.status.charAt(0).toUpperCase() + application.status.slice(1)}</span>
            </div>
            <div className="text-xs md:text-sm text-gray-500">{formattedDate}</div>
          </div>

          <div className="space-y-4">
            <div className="flex items-start gap-3">
              <div className="relative flex-shrink-0">
                <img 
                  src={application.photoURL || '/api/placeholder/80/80'}
                  alt={application.name} 
                  className={`w-12 h-12 md:w-16 md:h-16 rounded-full object-cover border-2 ${application.status === 'suspended' ? 'border-gray-300 opacity-70' : 'border-blue-100'}`}
                  onError={(e) => {
                    e.target.src = '/api/placeholder/80/80';
                    e.target.onerror = null;
                  }}
                />
                {application.status === 'suspended' && (
                  <div className="absolute -bottom-1 -right-1 bg-gray-100 rounded-full p-1 border border-gray-300">
                    <EyeOff className="w-4 h-4 text-gray-500" />
                  </div>
                )}
              </div>
              <div className="flex-1 min-w-0">
                <div className="space-y-1">
                  <h3 className="font-bold text-lg md:text-xl text-gray-800 truncate">{application.name}</h3>
                  <div className="flex items-center space-x-2 text-gray-600">
                    <GraduationCap className="w-4 h-4 flex-shrink-0" />
                    <p className="text-sm truncate">{application.university}</p>
                  </div>
                </div>
              </div>
            </div>

            {application.status === 'suspended' && application.suspensionReason && (
              <div className="bg-gray-50 p-3 rounded-lg border-l-4 border-gray-400">
                <p className="text-sm text-gray-600">
                  <span className="font-medium">Suspension reason:</span> {application.suspensionReason}
                </p>
                {application.suspendedAt && (
                  <p className="text-xs text-gray-500 mt-1">
                    Suspended on: {format(application.suspendedAt instanceof Date ? application.suspendedAt : application.suspendedAt.toDate(), 'MMMM d, yyyy')}
                  </p>
                )}
              </div>
            )}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 text-sm text-gray-600">
              <div className="flex items-center space-x-2">
                <Mail className="w-4 h-4 text-blue-500 flex-shrink-0" />
                <span className="truncate">{application.email}</span>
              </div>
              <div className="flex items-center space-x-2">
                <Clock className="w-4 h-4 text-blue-500 flex-shrink-0" />
                <span>{application.yearOfStudy || 'Year not specified'}</span>
              </div>
              <div className="flex items-center space-x-2 md:col-span-2">
                <Languages className="w-4 h-4 text-blue-500 flex-shrink-0" />
                <span className="truncate">
                  {application.languages?.length > 0 
                    ? application.languages.join(', ') 
                    : 'Languages not specified'}
                </span>
              </div>
            </div>

            <div className="space-y-2">
              <h4 className="font-semibold text-gray-700 flex items-center">
                <Book className="w-4 h-4 text-blue-500 mr-2" />
                Courses:
              </h4>
              <div className="grid gap-2">
                {application.courses?.slice(0, 3).map((course, index) => (
                  <div 
                    key={index} 
                    className="flex items-center justify-between bg-gray-50 p-2 rounded"
                  >
                    <span className="font-medium truncate">{course.courseCode}</span>
                    <div className="flex items-center space-x-1 flex-shrink-0">
                      <Star className="w-4 h-4 text-yellow-500" />
                      <span>{course.finalMark}%</span>
                    </div>
                  </div>
                ))}
                {(application.courses?.length > 3) && (
                  <div className="text-center text-sm text-blue-500 font-medium">
                    +{application.courses.length - 3} more courses
                  </div>
                )}
              </div>
            </div>

            {application.status === 'pending' && (
              <div className="flex space-x-2 pt-4">
                <button
                  onClick={() => handleApplicationAction(application.id, 'approved')}
                  className="flex-1 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors flex items-center justify-center"
                >
                  <CheckCircle className="w-4 h-4 mr-2" />
                  Approve
                </button>
                <button
                  onClick={() => handleApplicationAction(application.id, 'rejected')}
                  className="flex-1 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors flex items-center justify-center"
                >
                  <XCircle className="w-4 h-4 mr-2" />
                  Reject
                </button>
              </div>
            )}

            {application.status === 'approved' && (
              <div className="flex space-x-2 pt-4">
                <button
                  onClick={() => handleSuspendClick(application)}
                  className="flex-1 px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition-colors flex items-center justify-center"
                >
                  <EyeOff className="w-4 h-4 mr-2" />
                  Suspend
                </button>
              </div>
            )}

            {application.status === 'suspended' && (
              <div className="flex space-x-2 pt-4">
                <button
                  onClick={() => handleApplicationAction(application.id, 'approved')}
                  className="flex-1 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors flex items-center justify-center"
                >
                  <Eye className="w-4 h-4 mr-2" />
                  Reactivate
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const FilterDropdown = () => (
    <div className={`transition-all duration-300 overflow-hidden ${showFilters ? 'max-h-96' : 'max-h-0'}`}>
      <div className="bg-white rounded-lg shadow-md p-4 mb-4 space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">University</label>
          <select
            value={selectedUniversity}
            onChange={(e) => setSelectedUniversity(e.target.value)}
            className="w-full p-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">All Universities</option>
            {universitiesFilter.map((university) => (
              <option key={university} value={university}>
                {university}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mb-4" />
        <p className="text-gray-600">Loading tutor applications...</p>
      </div>
    );
  }

  const filteredApps = filterApplications();

  return (
    <div className="space-y-4">
      <SuspensionModal />
      
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl md:text-2xl font-bold text-gray-800">Tutor Applications</h2>
        <div className="flex flex-wrap gap-2">
          <div className="flex items-center text-sm bg-yellow-50 p-2 rounded-lg">
            <Clock className="w-4 h-4 text-yellow-500 mr-2" />
            <span className="text-yellow-700">{applications.pending.length} pending</span>
          </div>
          <div className="flex items-center text-sm bg-gray-50 p-2 rounded-lg">
            <EyeOff className="w-4 h-4 text-gray-500 mr-2" />
            <span className="text-gray-700">{applications.suspended.length} suspended</span>
          </div>
        </div>
      </div>

      <div className="flex flex-col sm:flex-row gap-4">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="text"
            placeholder="Search by name, university or course..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex gap-2">
          <select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            className="px-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="all">All Status</option>
            <option value="pending">Pending</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
            <option value="suspended">Suspended</option>
          </select>
          <button 
            onClick={() => setShowFilters(!showFilters)}
            className={`p-3 border ${showFilters ? 'bg-blue-50 border-blue-300' : 'border-gray-200'} rounded-lg hover:bg-gray-50 transition-colors`}
          >
            <SlidersHorizontal className="w-5 h-5 text-gray-600" />
          </button>
        </div>
      </div>

      <FilterDropdown />

      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 md:gap-6">
        {filteredApps.map(application => (
          <ApplicationCard key={application.id} application={application} />
        ))}
      </div>

      {filteredApps.length === 0 && (
        <div className="text-center py-12 bg-white rounded-2xl shadow-sm">
          <GraduationCap className="w-16 h-16 text-gray-300 mx-auto mb-4" />
          <p className="text-xl font-medium text-gray-700 mb-2">
            No applications found
          </p>
          <p className="text-gray-500 max-w-md mx-auto">
            {searchTerm || selectedUniversity || filterStatus !== 'all'
              ? 'Try adjusting your search criteria or filters'
              : 'There are no tutor applications available at this time'}
          </p>
        </div>
      )}
    </div>
  );
};

export default TutorManagement;