// components/groups/EmojiPicker.js
export const EmojiPicker = ({ onSelect }) => {
    const emojis = ['⚽️', '🎬', '❤️', '💑', '💍', '🌹', '💌', '💕', '💖', '🌟', '🍷', '🥂', '🌈', '🌸', '🎶', '🕯️', '🎁', '🍫', '💐', '👩‍❤️‍👨', '👩‍❤️‍👩', '👨‍❤️‍👨', '😍', '😘', '🥰', '🤗', '💞', '✨', '🌷', '🥀'];
  
    return (
      <div className="grid grid-cols-6 gap-2">
        {emojis.map((emoji) => (
          <button
            key={emoji}
            onClick={() => onSelect(emoji)}
            className="p-2 text-2xl hover:bg-gray-100 rounded-lg"
          >
            {emoji}
          </button>
        ))}
      </div>
    );
  };
  