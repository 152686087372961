// src/components/meetups/MeetupOption.js
import React from 'react';
import { motion } from 'framer-motion';

const MeetupOption = ({ icon: Icon, label, onClick, active }) => (
  <motion.button
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    onClick={onClick}
    className={`px-4 py-2 rounded-xl text-sm flex items-center space-x-2 transition-all duration-200
      ${active 
        ? 'bg-gradient-to-r from-green-500 to-green-400 text-white shadow-lg shadow-green-500/20' 
        : 'bg-white border border-gray-100 text-gray-600 hover:border-green-500/20 hover:shadow-md'}`}
  >
    <Icon className="w-4 h-4" />
    <span className="font-medium">{label}</span>
  </motion.button>
);

export default MeetupOption; 