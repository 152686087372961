import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Mail, Lock, Loader2, GraduationCap } from 'lucide-react';
import { auth } from '../../firebase/firebase';
import ForgotPassword from './ForgotPassword';

const Login = ({ switchToRegister }) => {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const navigate = useNavigate();
  const location = useLocation();
  
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) {
      newErrors.email = 'University email is required';
    } else if (!formData.email.toLowerCase().endsWith('.ac.za')) {
      newErrors.email = 'Please use your university email address';
    }
    if (!formData.password) {
      newErrors.password = 'Password is required';
    }
    return newErrors;
  };

  const getErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 'auth/user-not-found':
        return 'No account found with this email address';
      case 'auth/wrong-password':
        return 'Invalid password';
      case 'auth/invalid-email':
        return 'Invalid email address';
      case 'auth/too-many-requests':
        return 'Too many failed attempts. Please try again later';
      default:
        return 'Login failed. Please try again later';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          formData.email,
          formData.password
        );
        const token = await userCredential.user.getIdToken();
        localStorage.setItem('authToken', token);
        
        const from = location.state?.from?.pathname || '/';
        navigate(from);
      } catch (error) {
        console.error('Error during login:', error);
        setErrors({
          ...newErrors,
          backend: getErrorMessage(error.code),
        });
      } finally {
        setLoading(false);
      }
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center px-4">
      <div className="w-full max-w-md">
        {/* Updated Header with new logo */}
        <div className="text-center mb-8">
          <div className="flex items-center justify-center mb-4">
            <div className="relative">
              <div className="w-12 h-12 bg-gradient-to-br from-green-500 to-green-600 rounded-xl flex items-center justify-center transform rotate-12 shadow-lg">
                <span className="text-white font-bold text-2xl transform -rotate-12">V</span>
              </div>
              <div className="absolute -top-1 -right-1 w-2 h-2 bg-green-400 rounded-full animate-pulse" />
            </div>
          </div>
          <h1 className="text-3xl font-bold text-gray-800">
            <span className="text-green-500">Varsity</span>Link
          </h1>
          <div className="flex items-center justify-center space-x-2 mt-2">
            <span className="text-xs bg-green-100 text-green-600 px-2 py-1 rounded-full flex items-center">
              <GraduationCap className="w-3 h-3 mr-1" />
              The Ultimate Student Connection Hub
            </span>
          </div>
        </div>

        {showForgotPassword ? (
          <ForgotPassword onBack={() => setShowForgotPassword(false)} />
        ) : (
          <div className="bg-white rounded-3xl shadow-lg p-6 space-y-6">
            <h2 className="text-2xl font-semibold text-gray-800 text-center">Welcome Back</h2>
            
            <form onSubmit={handleSubmit} className="space-y-4">
              {/* Email Input */}
              <div className="space-y-2">
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Mail className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="email"
                    name="email"
                    placeholder="University Email"
                    value={formData.email}
                    onChange={handleChange}
                    className={`pl-10 pr-16 py-3 w-full bg-gray-50 border ${
                      errors.email ? 'border-red-500' : 'border-gray-200'
                    } rounded-xl focus:ring-2 focus:ring-green-500 focus:border-transparent`}
                  />
                
                </div>
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>

              {/* Password Input */}
              <div className="space-y-2">
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Lock className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleChange}
                    className={`pl-10 pr-4 py-3 w-full bg-gray-50 border ${
                      errors.password ? 'border-red-500' : 'border-gray-200'
                    } rounded-xl focus:ring-2 focus:ring-green-500 focus:border-transparent`}
                  />
                </div>
                {errors.password && (
                  <p className="text-red-500 text-sm">{errors.password}</p>
                )}
              </div>

              {/* Forgot Password Link */}
              <div className="text-right">
                <button
                  type="button"
                  onClick={() => setShowForgotPassword(true)}
                  className="text-sm text-green-500 hover:text-green-600"
                >
                  Forgot password?
                </button>
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                disabled={loading}
                className="w-full bg-green-500 hover:bg-green-600 text-white font-medium py-3 rounded-xl flex items-center justify-center space-x-2 transition-colors"
              >
                {loading ? (
                  <>
                    <Loader2 className="w-5 h-5 animate-spin" />
                    <span>Logging in...</span>
                  </>
                ) : (
                  <span>Log in</span>
                )}
              </button>
            </form>

            {errors.backend && (
              <div className="bg-red-50 text-red-500 p-3 rounded-xl text-sm">
                {errors.backend}
              </div>
            )}

            {/* Switch to Register */}
            <div className="text-center text-sm">
              <span className="text-gray-600">New to VarsityLink?</span>{' '}
              <button
                onClick={switchToRegister}
                className="text-green-500 hover:text-green-600 font-medium"
              >
                Create an account
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;