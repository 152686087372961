// components/social/TagsModal.js
import React from 'react';
import { motion } from 'framer-motion';

export const TagsModal = ({ 
  show, 
  onClose, 
  selectedTags, 
  onToggleTag, 
  onClearTags,
  studyTags 
}) => {
  if (!show) return null;

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50"
    >
      <motion.div 
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        className="bg-white rounded-2xl p-6 w-full max-w-sm"
      >
        <h3 className="text-lg font-semibold mb-4">Select Study Tags</h3>
        <div className="flex flex-wrap gap-2 mb-6">
          {studyTags.map((tag) => (
            <button
              key={tag}
              onClick={() => onToggleTag(tag)}
              className={`px-3 py-1.5 rounded-full text-sm transition-colors ${
                selectedTags.includes(tag)
                  ? 'bg-green-100 text-green-600'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              }`}
            >
              {tag}
            </button>
          ))}
        </div>
        <div className="flex gap-3">
          <button 
            className="flex-1 py-2.5 rounded-xl bg-green-500 text-white font-medium hover:bg-green-600 transition-colors"
            onClick={onClose}
          >
            Done ({selectedTags.length})
          </button>
          <button 
            className="flex-1 py-2.5 rounded-xl bg-gray-100 text-gray-600 font-medium hover:bg-gray-200 transition-colors"
            onClick={onClearTags}
          >
            Clear All
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default TagsModal;