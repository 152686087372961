import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { auth, db } from '../firebase/firebase';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { sendMeetupRequest } from '../firebase/meetupService';
import { toast } from 'react-hot-toast';
import NavBar from '../components/NavBar';
import MeetupHeader from '../components/meetups/MeetupHeader';
import FilterModal from '../components/meetups/FilterModal';
import UserCard from '../components/meetups/UserCard';
import UserCardSkeleton from '../components/meetups/UserCardSkeleton';
import IncompleteProfileModal from '../components/homepage/IncompleteProfileModal';
import { useNavigate } from 'react-router-dom';
import NoMeetupsState from '../components/meetups/NoMeetupsState';
import { Users, Filter, MapPin, School, RefreshCw } from 'lucide-react';

const MeetupsPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userPreferences, setUserPreferences] = useState(null);
  const [sentRequests, setSentRequests] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [selectedUniversity, setSelectedUniversity] = useState('');
  const [profileComplete, setProfileComplete] = useState(true);
  const [missingFields, setMissingFields] = useState([]);
  const [showIncompleteProfile, setShowIncompleteProfile] = useState(false);
  const [tempSelectedUniversity, setTempSelectedUniversity] = useState('');
  const [isRefreshing, setIsRefreshing] = useState(false);

  const checkProfileCompleteness = (profile) => {
    const requiredFields = [];

    if (!profile.basicInfo?.gender) {
      requiredFields.push("Gender");
    }
    if (!profile.academic?.degree) {
      requiredFields.push("Degree Program");
    }
    if (!profile.academic?.yearOfStudy) {
      requiredFields.push("Year of Study");
    }
    if (!profile.bio?.shortBio) {
      requiredFields.push("Short Bio");
    }
    if (!profile.photos || profile.photos.length === 0) {
      requiredFields.push("Profile Photo");
    }

    return requiredFields;
  };

  const isProfileComplete = (profile) => {
    return checkProfileCompleteness(profile).length === 0;
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const user = auth.currentUser;
      if (!user) throw new Error('No authenticated user');

      // Fetch user preferences
      const userDoc = await getDoc(doc(db, 'profiles', user.uid));
      if (!userDoc.exists()) throw new Error('User profile not found');
      
      const userProfile = userDoc.data();
      setUserPreferences(userProfile);

      // Check profile completeness
      const incomplete = checkProfileCompleteness(userProfile);
      setProfileComplete(incomplete.length === 0);
      setMissingFields(incomplete);

      // Initialize temporary university filter if not set
      if (!tempSelectedUniversity) {
        setTempSelectedUniversity(userProfile.academic.university);
      }

      // Fetch sent meetup requests
      const sentRequestsQuery = query(
        collection(db, 'meetupRequests'),
        where('senderId', '==', user.uid)
      );
      const sentRequestsSnapshot = await getDocs(sentRequestsQuery);
      const sentRequestIds = sentRequestsSnapshot.docs.map(doc => doc.data().receiverId);
      setSentRequests(sentRequestIds);

      // Build query based on selected university
      let baseQuery;
      if (selectedUniversity) {
        baseQuery = query(
          collection(db, 'profiles'),
          where('academic.university', '==', selectedUniversity)
        );
      } else {
        baseQuery = query(collection(db, 'profiles'));
      }
      
      const querySnapshot = await getDocs(baseQuery);
      const profiles = querySnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
        .filter(profile => 
          profile.id !== user.uid && 
          profile.preferences?.showInMeetups === true &&
          isProfileComplete(profile)
        );

      setUsers(profiles);
      setFilteredUsers(profiles);
    } catch (err) {
      console.error('Error fetching profiles:', err);
      setError(err.message);
      toast.error('Failed to load profiles');
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };

  const handleRefresh = () => {
    setIsRefreshing(true);
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [selectedUniversity]);

  // Search functionality
  useEffect(() => {
    if (searchQuery.trim() === '') {
      setFilteredUsers(users);
    } else {
      const query = searchQuery.toLowerCase();
      const filtered = users.filter(user => 
        user.basicInfo?.name?.toLowerCase().includes(query) ||
        user.basicInfo?.username?.toLowerCase().includes(query) ||
        user.academic?.degree?.toLowerCase().includes(query)
      );
      setFilteredUsers(filtered);
    }
  }, [searchQuery, users]);

  const handleFilterReset = () => {
    // Reset to user's own university from preferences
    const userUniversity = userPreferences?.academic?.university || '';
    setTempSelectedUniversity(userUniversity);
  };

  const handleFilterApply = () => {
    // Apply the temporary selection to the actual filter
    setSelectedUniversity(tempSelectedUniversity);
    setShowFilters(false);
    
    if (tempSelectedUniversity) {
      toast.success(`Showing profiles from ${tempSelectedUniversity}`, {
        icon: '🏫',
        duration: 3000,
      });
    } else {
      toast.success('Showing profiles from all universities', {
        icon: '🌎',
        duration: 3000,
      });
    }
  };

  const handleMeetupRequest = async (userId, activity) => {
    if (!profileComplete) {
      setShowIncompleteProfile(true);
      return;
    }

    try {
      const currentUser = auth.currentUser;
      if (!currentUser) throw new Error('You must be logged in');
      
      if (sentRequests.includes(userId)) {
        toast.error('You have already sent a request to this user');
        return;
      }

      await sendMeetupRequest(
        currentUser.uid, 
        userId, 
        activity,
        userPreferences?.basicInfo?.name,
        users.find(u => u.id === userId)?.basicInfo?.name
      );
      
      setSentRequests(prev => [...prev, userId]);
      
      toast.success('Meetup request sent!', {
        duration: 3000,
        position: 'bottom-center',
        icon: '🎉',
      });
    } catch (err) {
      console.error('Error sending meetup request:', err);
      toast.error(err.message || 'Failed to send request', {
        duration: 3000,
        position: 'bottom-center',
      });
    }
  };

  const handleFilterChange = (university) => {
    setSelectedUniversity(university);
  };

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-indigo-50 to-white p-4">
        <div className="bg-white rounded-xl shadow-lg p-6 max-w-md w-full">
          <div className="text-red-500 text-center mb-4">
            <div className="inline-block p-3 bg-red-100 rounded-full mb-3">
              <Users className="w-6 h-6" />
            </div>
            <h3 className="text-lg font-semibold text-red-700">Unable to Load Meetups</h3>
          </div>
          <p className="text-gray-600 mb-4">{error}</p>
          <button 
            onClick={() => window.location.reload()}
            className="w-full bg-indigo-500 text-white py-3 rounded-xl hover:bg-indigo-600 transition-colors"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white flex flex-col">
      <MeetupHeader 
        title="Connect Hub"
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        userPreferences={userPreferences}
        onFilterChange={handleFilterChange}
      />


      <main className="flex-1 mt-40 mb-20 px-4 py-2 overflow-y-auto">
        <div className="max-w-lg mx-auto space-y-4">
          <AnimatePresence mode="wait">
            {loading ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="space-y-4"
              >
                {Array(5).fill(null).map((_, index) => (
                  <UserCardSkeleton key={`skeleton-${index}`} />
                ))}
              </motion.div>
            ) : filteredUsers.length > 0 ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="space-y-4"
              >
                {filteredUsers.map((user) => (
                  <UserCard
                    key={user.id}
                    user={user}
                    onSendRequest={handleMeetupRequest}
                    sentRequests={sentRequests}
                  />
                ))}
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
              >
                <NoMeetupsState 
                  onOpenFilters={() => setShowFilters(true)}
                  searchQuery={searchQuery}
                  selectedUniversity={selectedUniversity}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </main>

      <FilterModal
        isOpen={showFilters}
        onClose={() => setShowFilters(false)}
        selectedUniversity={tempSelectedUniversity}
        setSelectedUniversity={setTempSelectedUniversity}
        onReset={handleFilterReset}
        onApply={handleFilterApply}
      />

      <IncompleteProfileModal
        missingFields={missingFields}
        onComplete={() => navigate("/edit-profile")}
        open={showIncompleteProfile}
        onClose={() => setShowIncompleteProfile(false)}
      />

      <NavBar />
    </div>
  );
};

export default MeetupsPage;