// components/social/NotificationsPopover.js
import React from 'react';
import { formatDistanceToNow } from 'date-fns';
import { useNotifications } from '../../contexts/NotificationsContext';

export const NotificationsPopover = ({ show, onClose }) => {
    const { notifications, markSocialNotificationsAsRead } = useNotifications();
  
    if (!show) return null;
  
    const handleClose = () => {
      markSocialNotificationsAsRead();
      onClose();
    };
  
    return (
      <div className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-lg border border-gray-200 z-50">
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold">Notifications</h3>
            <button 
              onClick={handleClose}
              className="text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
          </div>
          <div className="space-y-4 max-h-96 overflow-y-auto">
            {notifications.length === 0 ? (
              <p className="text-gray-500 text-center py-4">No new notifications</p>
            ) : (
              notifications.map(notification => (
                <div 
                  key={notification.id} 
                  className={`p-3 rounded-lg ${notification.read ? 'bg-gray-50' : 'bg-blue-50'}`}
                >
                  <div className="flex items-center gap-3">
                    {notification.actorAvatar && (
                      <img 
                        src={notification.actorAvatar} 
                        alt=""
                        className="w-10 h-10 rounded-full"
                      />
                    )}
                    <div>
                      <p className="text-sm">
                        <span className="font-semibold">{notification.actorName}</span>
                        {notification.type === 'like' ? ' liked your post' : ' commented on your post'}
                      </p>
                      <p className="text-xs text-gray-500 mt-1">
                        {formatDistanceToNow(notification.createdAt, { addSuffix: true })}
                      </p>
                    </div>
                  </div>
                  {notification.contentPreview && (
                    <p className="text-sm text-gray-600 mt-2 line-clamp-2">
                      "{notification.contentPreview}"
                    </p>
                  )}
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    );
  };
  
  export default NotificationsPopover;