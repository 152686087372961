import React, { useState, useEffect } from 'react';
import AcademicNavBar from '../../components/AcademicNavBar';
import AcademicHeader from './AcademicHeader';
import {
    Calendar,
    Users,
    Plus,
    Search,
    MessageCircle,
    Clock,
    Dumbbell,
    Briefcase,
    X,
    MapPin,
    Info,
    GraduationCap
} from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../../firebase/firebase';
import { 
    collection, 
    addDoc, 
    query, 
    getDocs, 
    updateDoc, 
    doc, 
    Timestamp, 
    where,
    getDoc 
} from 'firebase/firestore';
import { toast } from 'react-hot-toast';

const EventsPage = () => {
    const { currentUser } = useAuth();
    const [events, setEvents] = useState([]);
    const [activeCategory, setActiveCategory] = useState('all');
    const [activeStatus, setActiveStatus] = useState('upcoming');
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [userProfile, setUserProfile] = useState(null);
    const [newEvent, setNewEvent] = useState({
        title: '',
        description: '',
        date: '',
        time: '',
        location: '',
        category: '',
        maxAttendees: '',
        requirements: '',
        status: 'pending'
    });

    const isAdmin = currentUser?.email === 'mngnta011@myuct.ac.za' || currentUser?.email === 'lthmat005@myuct.ac.za';

    const categories = [
        { id: 'all', label: 'All', icon: Calendar },
        { id: 'academic', label: 'Study', icon: GraduationCap },
        { id: 'social', label: 'Social', icon: MessageCircle },
        { id: 'sports', label: 'Sports', icon: Dumbbell },
        { id: 'career', label: 'Career', icon: Briefcase }
    ];

    const statusTabs = [
        { id: 'upcoming', label: 'Upcoming' },
        { id: 'expired', label: 'Past Events' },
    ];

    useEffect(() => {
        fetchUserProfile();
        fetchEvents();
    }, []);

    const fetchUserProfile = async () => {
        try {
            if (!currentUser) return;
            
            const profileDoc = await getDoc(doc(db, 'profiles', currentUser.uid));
            if (profileDoc.exists()) {
                setUserProfile(profileDoc.data());
            }
        } catch (error) {
            console.error('Error fetching user profile:', error);
        }
    };

    const fetchEvents = async () => {
        try {
            setLoading(true);
            const eventsRef = collection(db, 'events');
            const eventsSnapshot = await getDocs(eventsRef);
            
            // Get all events first
            const eventsData = eventsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            
            // Fetch profile data for each organizer
            const eventsWithProfiles = await Promise.all(
                eventsData.map(async (event) => {
                    if (event.organizer && event.organizer.id) {
                        try {
                            const organizerDoc = await getDoc(doc(db, 'profiles', event.organizer.id));
                            if (organizerDoc.exists()) {
                                const profileData = organizerDoc.data();
                                return {
                                    ...event,
                                    organizer: {
                                        ...event.organizer,
                                        avatar: profileData.photos && profileData.photos.length > 0 
                                            ? profileData.photos[0] 
                                            : '/api/placeholder/32/32',
                                        name: profileData.basicInfo?.name || 'Unknown User'
                                    }
                                };
                            }
                        } catch (e) {
                            console.error('Error fetching organizer profile:', e);
                        }
                    }
                    return event;
                })
            );
            
            setEvents(eventsWithProfiles);
        } catch (error) {
            console.error('Error fetching events:', error);
            toast.error('Failed to load events');
        } finally {
            setLoading(false);
        }
    };

    const handleCreateEvent = async (e) => {
        e.preventDefault();
        try {
            // Make sure we have the user profile data
            if (!userProfile) {
                await fetchUserProfile();
            }
            
            const eventData = {
                ...newEvent,
                createdAt: Timestamp.now(),
                attendees: [currentUser.uid],
                organizer: {
                    id: currentUser.uid,
                    name: userProfile?.basicInfo?.name || currentUser.displayName || 'Anonymous',
                    avatar: userProfile?.photos && userProfile.photos.length > 0 
                        ? userProfile.photos[0] 
                        : '/api/placeholder/32/32'
                }
            };
            
            await addDoc(collection(db, 'events'), eventData);
            setIsCreateModalOpen(false);
            setNewEvent({
                title: '',
                description: '',
                date: '',
                time: '',
                location: '',
                category: '',
                maxAttendees: '',
                requirements: '',
                status: 'pending'
            });
            
            toast.success('Event created successfully!');
            fetchEvents();
        } catch (error) {
            console.error('Error creating event:', error);
            toast.error('Failed to create event');
        }
    };

    const handleRSVP = async (eventId, currentAttendees) => {
        try {
            const eventRef = doc(db, 'events', eventId);
            const isAttending = currentAttendees.includes(currentUser.uid);
            const updatedAttendees = isAttending
                ? currentAttendees.filter(id => id !== currentUser.uid)
                : [...currentAttendees, currentUser.uid];
            
            await updateDoc(eventRef, {
                attendees: updatedAttendees
            });
            
            toast.success(isAttending ? 'Your registration has been canceled' : 'You have joined the event!');
            fetchEvents();
        } catch (error) {
            console.error('Error updating RSVP:', error);
            toast.error('Failed to update registration');
        }
    };

    const handleApproveEvent = async (eventId) => {
        try {
            const eventRef = doc(db, 'events', eventId);
            await updateDoc(eventRef, {
                status: 'approved'
            });
            toast.success('Event approved');
            fetchEvents();
        } catch (error) {
            console.error('Error approving event:', error);
            toast.error('Failed to approve event');
        }
    };

    const handleRejectEvent = async (eventId) => {
        try {
            const eventRef = doc(db, 'events', eventId);
            await updateDoc(eventRef, {
                status: 'rejected'
            });
            toast.success('Event rejected');
            fetchEvents();
        } catch (error) {
            console.error('Error rejecting event:', error);
            toast.error('Failed to reject event');
        }
    };

    const isEventExpired = (eventDate) => {
        if (!eventDate) return false;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const date = new Date(eventDate);
        return date < today;
    };

    const filteredEvents = events.filter(event => {
        const matchesCategory = activeCategory === 'all' || event.category === activeCategory;
        const matchesSearch = event.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            event.description?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            event.location?.toLowerCase().includes(searchQuery.toLowerCase());
        const isExpired = isEventExpired(event.date);
        const matchesStatus = 
            (activeStatus === 'upcoming' && !isExpired) || 
            (activeStatus === 'expired' && isExpired);
        const isVisible = isAdmin ? true : event.status === 'approved';
        return matchesCategory && matchesSearch && matchesStatus && isVisible;
    });

    // Formatter for date display
    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { 
            weekday: 'short', 
            month: 'short', 
            day: 'numeric' 
        });
    };
    
    // Function to detect URLs in text and make them clickable
    const renderRequirementsWithLinks = (text) => {
        if (!text) return null;
        
        // Regular expression to find URLs
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        
        // Split the text by URLs
        const parts = text.split(urlRegex);
        
        // Find all URLs in the text
        const urls = text.match(urlRegex) || [];
        
        // Combine text parts with URL links
        return (
            <span className="truncate max-w-[200px]">
                Requirements: 
                {parts.map((part, i) => {
                    // Check if this part is a URL (by seeing if it matches a URL in our extracted list)
                    const isUrl = urls.includes(part);
                    
                    if (isUrl) {
                        return (
                            <a 
                                key={i} 
                                href={part} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="text-blue-500 hover:underline mx-1"
                                onClick={(e) => e.stopPropagation()}
                            >
                                {part.length > 30 ? part.substring(0, 30) + '...' : part}
                            </a>
                        );
                    }
                    
                    return <span key={i}>{part}</span>;
                })}
            </span>
        );
    };

    const handleGoBack = () => {
        window.history.back();
    };

    return (
        <div className="min-h-screen bg-gray-50">
            {/* Header Section */}
            <AcademicHeader 
                title="Campus Events" 
                onBack={handleGoBack}
                userPreferences={{}}
            />
            
            <div className="pt-16 px-4 pb-20 max-w-4xl mx-auto">
                {/* Search and Create Button */}
                <div className="flex items-center justify-between mb-4">
                    <div className="relative flex-1 mr-4">
                        <input
                            type="text"
                            placeholder="Search events..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="w-full pl-12 pr-4 py-3 rounded-full border-none shadow-md bg-white focus:ring-2 focus:ring-green-500 transition-all text-base"
                        />
                        <div className="absolute left-4 top-1/2 transform -translate-y-1/2">
                            <Search className="w-5 h-5 text-gray-400" />
                        </div>
                    </div>
                    <button
                        onClick={() => setIsCreateModalOpen(true)}
                        className="w-12 h-12 bg-green-500 rounded-full flex items-center justify-center shadow-lg hover:shadow-xl hover:bg-green-600 transition-all"
                        aria-label="Create new event"
                    >
                        <Plus className="w-6 h-6 text-white" />
                    </button>
                </div>

                {/* Status Tabs */}
                <div className="flex mb-4">
                    {statusTabs.map(tab => (
                        <button
                            key={tab.id}
                            onClick={() => setActiveStatus(tab.id)}
                            className={`py-2 px-4 font-medium text-sm transition-colors ${
                                activeStatus === tab.id
                                    ? 'text-green-600 border-b-2 border-green-500'
                                    : 'text-gray-500 hover:text-gray-800'
                            }`}
                        >
                            {tab.label}
                        </button>
                    ))}
                </div>

                {/* Categories */}
                <div className="flex gap-2 mb-6 overflow-x-auto pb-2 scrollbar-hide">
                    {categories.map(category => (
                        <button
                            key={category.id}
                            onClick={() => setActiveCategory(category.id)}
                            className={`flex items-center gap-1 px-3 py-1.5 rounded-full transition-all ${
                                activeCategory === category.id
                                    ? 'bg-green-500 text-white shadow-md'
                                    : 'bg-white border border-gray-200 text-gray-600'
                            }`}
                        >
                            <category.icon className="w-4 h-4" />
                            <span className="text-sm font-medium">{category.label}</span>
                        </button>
                    ))}
                </div>

                {/* Events List */}
                {loading ? (
                    <div className="flex justify-center items-center h-40">
                        <div className="w-12 h-12 border-4 border-green-200 border-t-green-500 rounded-full animate-spin" />
                    </div>
                ) : filteredEvents.length === 0 ? (
                    <div className="text-center py-16">
                        <div className="mx-auto w-20 h-20 rounded-full bg-green-50 flex items-center justify-center mb-4">
                            <Calendar className="w-10 h-10 text-green-500" />
                        </div>
                        <h3 className="text-xl font-medium text-gray-900 mb-2">No events found</h3>
                        <p className="text-base text-gray-500">
                            {searchQuery || activeCategory !== 'all'
                                ? 'Try adjusting your search or filters'
                                : activeStatus === 'upcoming'
                                    ? 'No upcoming events scheduled yet'
                                    : 'No past events to display'}
                        </p>
                        {(searchQuery || activeCategory !== 'all') && (
                            <button 
                                onClick={() => {
                                    setSearchQuery('');
                                    setActiveCategory('all');
                                }}
                                className="mt-4 text-green-500 text-sm font-medium"
                            >
                                Clear all filters
                            </button>
                        )}
                    </div>
                ) : (
                    <div className="grid gap-4">
                        {filteredEvents.map(event => (
                            <div key={event.id} className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow p-4">
                                {/* Event Header */}
                                <div className="flex justify-between items-start mb-3">
                                    <div>
                                        <div className="flex items-center">
                                            <h3 className="font-semibold text-lg">{event.title}</h3>
                                            {event.status === 'pending' && isAdmin && (
                                                <span className="ml-2 px-2 py-0.5 bg-yellow-100 text-yellow-800 text-xs rounded-full">
                                                    Pending Approval
                                                </span>
                                            )}
                                        </div>
                                        <div className="flex items-center text-sm text-gray-500 mt-1">
                                            <Calendar className="w-4 h-4 mr-1" />
                                            <span>{formatDate(event.date)} • {event.time}</span>
                                        </div>
                                        {event.location && (
                                            <div className="flex items-center text-sm text-gray-500 mt-1">
                                                <MapPin className="w-4 h-4 mr-1" />
                                                <span>{event.location}</span>
                                            </div>
                                        )}
                                    </div>
                                    <span className="px-3 py-1 rounded-full text-xs font-medium bg-green-100 text-green-600">
                                        {categories.find(c => c.id === event.category)?.label || event.category}
                                    </span>
                                </div>

                                {/* Event Description */}
                                <p className="text-gray-600 text-sm mb-3 line-clamp-2">{event.description}</p>

                                {/* Event Stats */}
                                <div className="flex flex-wrap gap-3 text-sm text-gray-500 mb-3">
                                    <div className="flex items-center">
                                        <Users className="w-4 h-4 mr-1 text-green-500" />
                                        <span>{event.attendees?.length || 0}/{event.maxAttendees}</span>
                                    </div>
                                    <div className="flex items-center">
                                        <Clock className="w-4 h-4 mr-1 text-green-500" />
                                        <span>{event.maxAttendees - (event.attendees?.length || 0)} spots left</span>
                                    </div>
                                    {event.requirements && (
                                        <div className="flex items-center">
                                            <Info className="w-4 h-4 mr-1 text-green-500" />
                                            {renderRequirementsWithLinks(event.requirements)}
                                        </div>
                                    )}
                                </div>

                                {/* Event Footer */}
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <img
                                            src={event.organizer.avatar || '/api/placeholder/32/32'}
                                            alt={event.organizer.name}
                                            className="w-6 h-6 rounded-full object-cover"
                                            onError={(e) => {
                                                e.target.src = '/api/placeholder/32/32';
                                            }}
                                        />
                                        <span className="text-sm text-gray-500 ml-2">{event.organizer.name}</span>
                                    </div>
                                    <div className="flex gap-2">
                                        {isAdmin && event.status === 'pending' && (
                                            <>
                                                <button
                                                    onClick={() => handleApproveEvent(event.id)}
                                                    className="px-3 py-1 rounded-full text-xs font-medium bg-green-500 text-white hover:bg-green-600 transition-colors"
                                                >
                                                    Approve
                                                </button>
                                                <button
                                                    onClick={() => handleRejectEvent(event.id)}
                                                    className="px-3 py-1 rounded-full text-xs font-medium bg-red-500 text-white hover:bg-red-600 transition-colors"
                                                >
                                                    Reject
                                                </button>
                                            </>
                                        )}
                                        {!isEventExpired(event.date) && (
                                            <button
                                                onClick={() => handleRSVP(event.id, event.attendees || [])}
                                                className={`px-4 py-1.5 rounded-full text-sm font-medium transition-all ${
                                                    event.attendees?.includes(currentUser.uid)
                                                        ? 'bg-red-500 text-white hover:bg-red-600'
                                                        : 'bg-green-500 text-white hover:bg-green-600'
                                                }`}
                                                disabled={!event.attendees?.includes(currentUser.uid) && 
                                                        (event.maxAttendees - (event.attendees?.length || 0)) === 0}
                                            >
                                                {event.attendees?.includes(currentUser.uid) ? 'Cancel' : 'Join'}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {/* Create Event Modal */}
            {isCreateModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
                    <div className="bg-white rounded-xl w-full max-w-md max-h-[90vh] overflow-y-auto">
                        <div className="p-4 border-b flex justify-between items-center">
                            <h2 className="text-lg font-semibold">Create New Event</h2>
                            <button 
                                onClick={() => setIsCreateModalOpen(false)}
                                className="rounded-full p-1 hover:bg-gray-100 transition-colors"
                                aria-label="Close modal"
                            >
                                <X className="w-5 h-5 text-gray-500" />
                            </button>
                        </div>
                        <form onSubmit={handleCreateEvent} className="p-4 space-y-4">
                            <div>
                                <label htmlFor="event-title" className="block text-sm font-medium text-gray-700 mb-1">Event Title</label>
                                <input
                                    id="event-title"
                                    type="text"
                                    placeholder="Enter a descriptive title"
                                    value={newEvent.title}
                                    onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
                                    className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-green-500 focus:border-transparent"
                                    required
                                />
                            </div>
                            
                            <div>
                                <label htmlFor="event-description" className="block text-sm font-medium text-gray-700 mb-1">Description</label>
                                <textarea
                                    id="event-description"
                                    placeholder="What will this event be about?"
                                    value={newEvent.description}
                                    onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })}
                                    rows={3}
                                    className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-green-500 focus:border-transparent"
                                    required
                                />
                            </div>
                            
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label htmlFor="event-date" className="block text-sm font-medium text-gray-700 mb-1">Date</label>
                                    <input
                                        id="event-date"
                                        type="date"
                                        value={newEvent.date}
                                        onChange={(e) => setNewEvent({ ...newEvent, date: e.target.value })}
                                        className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-green-500 focus:border-transparent"
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="event-time" className="block text-sm font-medium text-gray-700 mb-1">Time</label>
                                    <input
                                        id="event-time"
                                        type="time"
                                        value={newEvent.time}
                                        onChange={(e) => setNewEvent({ ...newEvent, time: e.target.value })}
                                        className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-green-500 focus:border-transparent"
                                        required
                                    />
                                </div>
                            </div>
                            
                            <div>
                                <label htmlFor="event-location" className="block text-sm font-medium text-gray-700 mb-1">Location</label>
                                <input
                                    id="event-location"
                                    type="text"
                                    placeholder="Where will this event take place?"
                                    value={newEvent.location}
                                    onChange={(e) => setNewEvent({ ...newEvent, location: e.target.value })}
                                    className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-green-500 focus:border-transparent"
                                    required
                                />
                            </div>
                            
                            <div>
                                <label htmlFor="event-category" className="block text-sm font-medium text-gray-700 mb-1">Category</label>
                                <select
                                    id="event-category"
                                    value={newEvent.category}
                                    onChange={(e) => setNewEvent({ ...newEvent, category: e.target.value })}
                                    className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-green-500 focus:border-transparent"
                                    required
                                >
                                    <option value="">Select Category</option>
                                    {categories.filter(cat => cat.id !== 'all').map(cat => (
                                        <option key={cat.id} value={cat.id}>{cat.label}</option>
                                    ))}
                                </select>
                            </div>
                            
                            <div>
                                <label htmlFor="event-max-attendees" className="block text-sm font-medium text-gray-700 mb-1">Max Attendees</label>
                                <input
                                    id="event-max-attendees"
                                    type="number"
                                    placeholder="Maximum number of participants"
                                    value={newEvent.maxAttendees}
                                    onChange={(e) => setNewEvent({ ...newEvent, maxAttendees: e.target.value })}
                                    className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-green-500 focus:border-transparent"
                                    required
                                    min={1}
                                />
                            </div>
                            
                            <div>
                                <label htmlFor="event-requirements" className="block text-sm font-medium text-gray-700 mb-1">
                                    Requirements (optional)
                                    <span className="ml-1 text-xs text-gray-500">
                                        (URLs will be clickable)
                                    </span>
                                </label>
                                <textarea
                                    id="event-requirements"
                                    placeholder="Any specific requirements or RSVP links for participants?"
                                    value={newEvent.requirements}
                                    onChange={(e) => setNewEvent({ ...newEvent, requirements: e.target.value })}
                                    rows={2}
                                    className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-green-500 focus:border-transparent"
                                />
                            </div>
                            
                            <button
                                type="submit"
                                className="w-full py-2 bg-green-500 text-white rounded-lg font-medium hover:bg-green-600 hover:shadow-lg transition-all"
                            >
                                Create Event
                            </button>
                        </form>
                    </div>
                </div>
            )}

            <AcademicNavBar />
        </div>
    );
};

export default EventsPage;