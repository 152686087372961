import React, { useState, useEffect } from 'react';
import { Heart, MapPin, Building2, GraduationCap, AtSign } from 'lucide-react';
import { ImageCarousel } from './ImageCarousel';
import UserProfileModal from './UserProfileModal';

export const ProfileCard = ({ 
  profile, 
  onDislike, 
  onLike,
  cardAction,
  isLiked 
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLiking, setIsLiking] = useState(false);
  const [showLikeEffect, setShowLikeEffect] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);

  useEffect(() => {
    setIsLoaded(false);
  }, [profile]);

  const getCardTransform = () => {
    if (!isLoaded) return 'translate-y-[20px] opacity-0';
    
    switch (cardAction) {
      case 'left':
        return 'translate-x-[-120%] rotate-[-20deg] opacity-0';
      case 'right':
        return 'translate-x-[120%] rotate-[20deg] opacity-0';
      case 'up':
        return 'translate-y-[-120%] opacity-0';
      default:
        return 'translate-x-0 translate-y-0 rotate-0 opacity-100';
    }
  };

  const handleLike = async () => {
    if (isLiking) return;
    
    try {
      setIsLiking(true);
      setShowLikeEffect(true);
      await onLike();
      
      // Reset like effect after animation
      setTimeout(() => {
        setShowLikeEffect(false);
      }, 1000);
    } catch (error) {
      console.error('Error liking profile:', error);
    } finally {
      setIsLiking(false);
    }
  };

  const handleCardClick = () => {
    setShowProfileModal(true);
  };

  return (
    <div className="relative">
      {/* Like effect overlay */}
      {showLikeEffect && (
        <div className="absolute inset-0 z-50 flex items-center justify-center bg-black/30 rounded-3xl">
          <Heart className="w-32 h-32 text-pink-500 animate-ping" />
        </div>
      )}
      
      <div 
        className={`max-w-md w-full mx-auto bg-white rounded-3xl shadow-lg overflow-hidden 
          transition-all duration-500 ease-out transform ${getCardTransform()} cursor-pointer`}
        onClick={handleCardClick}
      >
        <div className="relative">
          <ImageCarousel 
            images={profile.photos} 
            onAllImagesLoaded={() => setIsLoaded(true)}
          />
          <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 via-black/50 to-transparent p-4">
            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <h2 className="text-white text-2xl font-semibold">
                  {profile.basicInfo.name}
                </h2>
                <div className="absolute bottom-2 right-2">
                  <span className="inline-flex items-center gap-1 text-gray-400 text-sm 
                                 bg-black/20 backdrop-blur-sm px-3 py-1 rounded-full
                                 transition-all duration-300 hover:bg-gray-500/20
                                 hover:text-gray-200 cursor-pointer">
                    <AtSign className="w-4 h-4 transition-transform group-hover:rotate-12" />
                    <span className="font-sans tracking-wide">{profile.basicInfo.username}</span>
                  </span>
                </div>
              </div>
              <div className="flex flex-wrap items-center gap-2">
                <div className="flex items-center text-gray-200 text-sm bg-black/30 px-2 py-1 rounded-full">
                  <GraduationCap className="w-4 h-4 mr-1" />
                  <span>{profile.academic.yearOfStudy} {profile.academic.degree}</span>
                </div>
                {profile.interests?.slice(0, 2).map((interest, index) => (
                  <span key={index} className="text-xs bg-green-500/80 text-white px-2 py-1 rounded-full">
                    {interest}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="p-6 space-y-4">
          <div className="flex items-center space-x-2">
            <Building2 className="w-5 h-5 text-gray-500" />
            <span className="text-sm text-gray-600">
              {profile.academic.university}
            </span>
          </div>

          <div className="space-y-3">
            <p className="text-gray-600 leading-relaxed">{profile.bio.shortBio}</p>
            <div className="flex items-center text-green-600 text-sm bg-green-50 p-2 rounded-lg">
              <MapPin className="w-4 h-4 mr-1" />
              <span>Currently at: {profile.academic.residence}</span>
            </div>
          </div>

          <div className="flex justify-around pt-4" onClick={(e) => e.stopPropagation()}>
            <button 
              className="p-4 rounded-full bg-gray-100 hover:bg-gray-200 transition-all hover:scale-110"
              onClick={onDislike}
              aria-label="Pass"
            >
              {/* Upside-down heart for "pass" instead of ThumbsDown */}
              <Heart 
                className="w-8 h-8 text-gray-400 transform rotate-180" 
                fill="none"
              />
            </button>
            <button 
              className={`p-4 rounded-full transition-all hover:scale-110 
                        ${isLiked ? 'bg-pink-100' : 'bg-gray-100 hover:bg-gray-200'}
                        ${isLiking ? 'scale-95' : ''}`}
              onClick={handleLike}
              disabled={isLiking}
              aria-label="Like"
            >
              <Heart 
                className={`w-8 h-8 transform transition-transform
                          ${isLiked ? 'text-pink-500 scale-110' : 'text-pink-400'}
                          ${isLiking ? 'scale-90' : ''}`} 
                fill={isLiked ? "currentColor" : "none"}
              />
            </button>
          </div>
        </div>
      </div>

      {/* User profile modal */}
      <UserProfileModal
        isOpen={showProfileModal}
        onClose={() => setShowProfileModal(false)}
        profile={profile}
        onLike={handleLike}
        isLiked={isLiked}
      />
    </div>
  );
};

export default ProfileCard;