// src/pages/Messages.js
import React, { useState, useEffect, useCallback } from 'react';
import { MessageCircle, Bell, Search } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import toast from 'react-hot-toast';
import { auth } from '../firebase/firebase';
import { motion, AnimatePresence } from 'framer-motion';
import AcademicHeader from './academics/AcademicHeader';
import { ChatPreview } from '../components/messages/ChatPreview';
import {
  subscribeToUserChats,
  getMeetupRequests,
  updateMeetupRequest,
  markChatAsRead,
  markActivitiesAsRead
} from '../firebase/messagingService';

const Messages = () => {
  const [activeTab, setActiveTab] = useState('messages');
  const [chats, setChats] = useState([]);
  const [activities, setActivities] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [unreadCounts, setUnreadCounts] = useState({ messages: 0, activities: 0 });
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.currentUser) {
      setLoading(false);
      return;
    }

    const userId = auth.currentUser.uid;

    const unsubscribeChats = subscribeToUserChats(userId, (updatedChats) => {
      setChats(updatedChats);
      setUnreadCounts(prev => ({
        ...prev,
        messages: updatedChats.reduce((sum, chat) => sum + chat.unreadCount, 0)
      }));
      setLoading(false);
    });

    const fetchActivities = async () => {
      try {
        const requests = await getMeetupRequests(userId);
        setActivities(requests.map(req => ({
          id: req.id,
          type: 'meetup',
          name: req.senderName,
          content: `wants to ${req.activity.toLowerCase()}`,
          timestamp: req.createdAt?.toDate(),
          isNew: !req.seen,
          requestData: req
        })));
        setUnreadCounts(prev => ({
          ...prev,
          activities: requests.filter(req => !req.seen).length
        }));
      } catch (error) {
        console.error('Error fetching activities:', error);
      }
    };

    fetchActivities();
    return () => unsubscribeChats();
  }, []);

  const handleChatClick = useCallback(
    debounce(async (chatId) => {
      if (!auth.currentUser) return;
      try {
        await markChatAsRead(chatId, auth.currentUser.uid);
        navigate(`/chat/${chatId}`);
      } catch (error) {
        console.error('Error marking chat as read:', error);
        toast.error('Failed to open chat');
      }
    }, 300),
    [navigate]
  );

  const handleMeetupResponse = async (requestId, accept) => {
    try {
      setLoading(true);
      await updateMeetupRequest(requestId, accept ? 'accepted' : 'declined');
      setActivities(prev => prev.filter(activity => activity.id !== requestId));
      if (accept) setActiveTab('messages');
      toast.success(accept ? 'Meetup accepted!' : 'Request declined');
    } catch (error) {
      console.error('Error handling meetup:', error);
      toast.error('Failed to process request');
    } finally {
      setLoading(false);
    }
  };

  const filterContent = () => {
    const term = searchTerm.toLowerCase();
    if (activeTab === 'messages') {
      return chats.filter(chat =>
        chat.name.toLowerCase().includes(term) ||
        chat.lastMessage.toLowerCase().includes(term)
      );
    }
    return activities.filter(activity =>
      activity.name.toLowerCase().includes(term) ||
      activity.content.toLowerCase().includes(term)
    );
  };

  const formatTimestamp = (timestamp) => {
    const now = new Date();
    const diff = (now - timestamp) / 1000; // Difference in seconds
    if (diff < 60) return `${Math.floor(diff)}s ago`;
    if (diff < 3600) return `${Math.floor(diff / 60)}m ago`;
    if (diff < 86400) return `${Math.floor(diff / 3600)}h ago`;
    return timestamp.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' });
  };

  const ActivityCard = ({ activity }) => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="relative bg-white rounded-xl shadow-md hover:shadow-lg transition-all duration-300 overflow-hidden p-3"
    >
      <div className="flex justify-between items-start">
        <div className="flex-1">
          <h3 className="text-base font-semibold text-gray-900">{activity.name}</h3>
          <p className="text-sm text-gray-600 mt-1">{activity.content}</p>
          <p className="text-xs text-gray-500 mt-1">{formatTimestamp(activity.timestamp)}</p>
        </div>
        {activity.isNew && (
          <span className="w-3 h-3 bg-green-500 rounded-full flex-shrink-0 mt-1"></span>
        )}
      </div>
      <div className="mt-3 flex gap-2">
        <motion.button
          whileHover={{ scale: 1.05 }}
          onClick={() => handleMeetupResponse(activity.id, true)}
          className="flex-1 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
        >
          Accept
        </motion.button>
        <motion.button
          whileHover={{ scale: 1.05 }}
          onClick={() => handleMeetupResponse(activity.id, false)}
          className="flex-1 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 transition-colors"
        >
          Decline
        </motion.button>
      </div>
    </motion.div>
  );

  const TabButton = ({ icon: Icon, label, count, active, onClick }) => (
    <motion.button
      whileHover={{ scale: 1.05 }}
      onClick={onClick}
      className={`flex-1 py-3 px-4 flex items-center justify-center gap-2 rounded-lg transition-all ${
        active ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
      }`}
    >
      <Icon className="w-5 h-5" />
      <span className="text-base font-medium">{label}</span>
      {count > 0 && (
        <span className={`px-2 py-0.5 rounded-full text-xs font-medium ${
          active ? 'bg-white text-green-500' : 'bg-green-500 text-white'
        }`}>
          {count}
        </span>
      )}
    </motion.button>
  );

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="flex flex-col items-center space-y-4">
          <div className="w-12 h-12 border-4 border-green-200 border-t-green-500 rounded-full animate-spin" />
          <p className="text-base text-gray-500 animate-pulse">Loading messages...</p>
        </div>
      </div>
    );
  }

  if (!auth.currentUser) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center space-y-4 p-6 max-w-md">
          <MessageCircle className="w-16 h-16 text-green-500 mx-auto" />
          <h2 className="text-2xl font-bold text-gray-900">Messages</h2>
          <p className="text-base text-gray-500">Please log in to view your messages</p>
        </div>
      </div>
    );
  }

  const filteredContent = filterContent();

  return (
    <div className="min-h-screen bg-gray-50">
 <AcademicHeader
      title="Messages"
      onBack={() => navigate("/connect")}
      userPreferences={{}}
    />
      <div className="pt-16 px-4 pb-20 max-w-3xl mx-auto">
        <div className="relative my-4">
          <input
            type="text"
            placeholder="Search messages or contacts..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-12 pr-4 py-3 rounded-full border-none shadow-md bg-white focus:ring-2 focus:ring-green-500 transition-all text-base"
          />
          <div className="absolute left-4 top-1/2 transform -translate-y-1/2">
            <Search className="w-5 h-5 text-gray-400" />
          </div>
        </div>
        <div className="flex gap-3 mb-4">
          <TabButton
            icon={MessageCircle}
            label="Chats"
            count={unreadCounts.messages}
            active={activeTab === 'messages'}
            onClick={() => setActiveTab('messages')}
          />
          <TabButton
            icon={Bell}
            label="Activities"
            count={unreadCounts.activities}
            active={activeTab === 'activities'}
            onClick={() => {
              setActiveTab('activities');
              if (auth.currentUser) markActivitiesAsRead(auth.currentUser.uid);
            }}
          />
        </div>
        <AnimatePresence>
          {filteredContent.length > 0 ? (
            <div className="grid grid-cols-1 gap-3">
              {activeTab === 'messages' ? (
                filteredContent.map(chat => (
                  <ChatPreview
                    key={chat.userId}
                    chat={chat}
                    onChatClick={handleChatClick}
                    formatTimestamp={formatTimestamp}
                  />
                ))
              ) : (
                filteredContent.map(activity => (
                  <ActivityCard key={activity.id} activity={activity} />
                ))
              )}
            </div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="text-center py-16"
            >
              <div className="mx-auto w-20 h-20 rounded-full bg-green-50 flex items-center justify-center mb-4">
                {activeTab === 'messages' ? (
                  <MessageCircle className="w-10 h-10 text-green-500" />
                ) : (
                  <Bell className="w-10 h-10 text-green-500" />
                )}
              </div>
              <h3 className="text-xl font-medium text-gray-900 mb-2">
                {searchTerm ? 'No results found' : activeTab === 'messages' ? 'No chats yet' : 'No activities yet'}
              </h3>
              <p className="text-base text-gray-500">
                {searchTerm ? 'Try adjusting your search' : 'Start connecting with others!'}
              </p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Messages;