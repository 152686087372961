import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { AlertCircle, PieChart, Users, UserCheck, Calendar } from 'lucide-react';
import AdminNav from './AdminNav';
import TutorManagement from './TutorManagement';
import UserSearch from './UserSearch';
import { isUserAdmin } from '../../utils/adminUtils';
import DashboardSummary from './DashboardSummary';
import { collection, getCountFromServer, query, where } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import EmailManagement from './EmailManagement';

const AdminDashboard = () => {
  const [activeSection, setActiveSection] = useState('dashboard');
  const { currentUser } = useAuth();
  const isAdmin = currentUser && isUserAdmin(currentUser.email);
  const [stats, setStats] = useState({
    totalUsers: 0,
    activeUsers: 0,
    pendingApplications: 0,
    approvedTutors: 0
  });
  const [loading, setLoading] = useState(true);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  useEffect(() => {
    const fetchStats = async () => {
      if (!isAdmin) return;
      
      try {
        // Get total users count
        const usersRef = collection(db, 'profiles');
        const userSnapshot = await getCountFromServer(usersRef);
        
        // Get active users (recent logins)
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
        
        const activeUsersQuery = query(
          collection(db, 'profiles'),
          where('lastLogin', '>=', thirtyDaysAgo)
        );
        const activeUsersSnapshot = await getCountFromServer(activeUsersQuery);
        
        // Get pending applications
        const pendingQuery = query(
          collection(db, 'tutorApplications'),
          where('status', '==', 'pending')
        );
        const pendingSnapshot = await getCountFromServer(pendingQuery);
        
        // Get approved tutors
        const approvedQuery = query(
          collection(db, 'tutorApplications'),
          where('status', '==', 'approved')
        );
        const approvedSnapshot = await getCountFromServer(approvedQuery);
        
        setStats({
          totalUsers: userSnapshot.data().count,
          activeUsers: activeUsersSnapshot.data().count,
          pendingApplications: pendingSnapshot.data().count,
          approvedTutors: approvedSnapshot.data().count
        });
      } catch (error) {
        console.error('Error fetching admin stats:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchStats();
  }, [isAdmin]);

  if (!currentUser || !isAdmin) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <div className="text-center bg-white p-8 rounded-xl shadow-md max-w-md w-full">
          <AlertCircle className="w-12 h-12 text-red-500 mx-auto mb-4" />
          <h2 className="text-xl font-bold text-red-600 mb-2">Access Denied</h2>
          <p className="text-gray-600">You don't have permission to access the admin dashboard</p>
        </div>
      </div>
    );
  }

  const StatCard = ({ icon: Icon, title, value, color, subtext }) => (
    <div className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 overflow-hidden">
      <div className="p-4 md:p-6">
        <div className="flex items-center justify-between mb-3">
          <div className={`p-2 md:p-3 ${color} rounded-lg`}>
            <Icon className="h-5 w-5 md:h-6 md:w-6 text-white" />
          </div>
          <span className="text-xl md:text-3xl font-bold text-gray-800">{loading ? '-' : value}</span>
        </div>
        <h3 className="text-sm md:text-lg font-semibold text-gray-700">{title}</h3>
        {subtext && <p className="text-xs md:text-sm text-gray-500 mt-1">{subtext}</p>}
      </div>
    </div>
  );

  const handleToggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <AdminNav 
        activeSection={activeSection} 
        onSectionChange={setActiveSection} 
        showMobileMenu={showMobileMenu}
        toggleMobileMenu={handleToggleMobileMenu}
      />
      
      <div className="pt-16 pb-20"> {/* Add padding for fixed navbar and mobile nav */}
        <main className="max-w-7xl mx-auto px-4 py-4 md:py-8">
          {activeSection === 'dashboard' && (
            <div className="space-y-6">
              <div className="flex flex-col md:flex-row md:items-center justify-between gap-4 mb-4">
                <h1 className="text-xl md:text-2xl font-bold text-gray-800">Admin Dashboard</h1>
                <div className="flex items-center space-x-2 text-sm bg-blue-50 p-2 rounded-lg">
                  <UserCheck className="w-4 h-4 text-blue-500" />
                  <span className="text-blue-700">
                    {stats.pendingApplications} applications pending review
                  </span>
                </div>
              </div>
              
              <div className="grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-6">
                <StatCard
                  icon={Users}
                  title="Total Users"
                  value={stats.totalUsers}
                  color="bg-blue-500"
                  subtext="Registered accounts"
                />
                <StatCard
                  icon={PieChart}
                  title="Active Users"
                  value={stats.activeUsers}
                  color="bg-green-500"
                  subtext="Last 30 days"
                />
                <StatCard
                  icon={UserCheck}
                  title="Pending"
                  value={stats.pendingApplications}
                  color="bg-yellow-500"
                  subtext="Applications"
                />
                <StatCard
                  icon={Calendar}
                  title="Tutors"
                  value={stats.approvedTutors}
                  color="bg-purple-500"
                  subtext="Approved"
                />
              </div>
              
              <div className="mt-8">
                <DashboardSummary />
              </div>
            </div>
          )}
          
          {activeSection === 'tutors' && <TutorManagement />}
          {activeSection === 'users' && <UserSearch />}
          {activeSection === 'email' && <EmailManagement />}
        </main>
      </div>
    </div>
  );
};

export default AdminDashboard;