import React from 'react';
import { Users, Filter, Coffee, ChevronRight, Search } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const NoMeetupsState = ({ onOpenFilters, searchQuery, selectedUniversity }) => {
  const navigate = useNavigate();

  const getReasonsList = () => {
    const reasons = [
      'No users have enabled meetups',
      'No users have complete profiles'
    ];
    
    if (selectedUniversity) {
      reasons.push(`No users from ${selectedUniversity}`);
    }
    
    if (searchQuery) {
      reasons.push(`No users match your search "${searchQuery}"`);
    }
    
    return reasons;
  };

  return (
    <div className="max-w-md mx-auto text-center px-6 py-8 bg-white rounded-3xl shadow-lg">
      <div className="flex justify-center mb-6">
        <div className="p-4 bg-blue-50 rounded-full">
          <Users className="w-10 h-10 text-blue-500" />
        </div>
      </div>
      
      <h2 className="text-2xl font-semibold text-gray-900 mb-3">
        No Meetup Profiles Available
      </h2>
      
      <div className="text-gray-600 mb-8">
        <p className="mb-4">This could be because:</p>
        <ul className="space-y-2 text-left list-none">
          {getReasonsList().map((reason, index) => (
            <li key={index} className="flex items-start gap-2 text-sm">
              <span className="text-blue-500 mt-1">•</span>
              <span>{reason}</span>
            </li>
          ))}
        </ul>
      </div>

      <div className="space-y-3">
        {searchQuery && (
          <button
            onClick={() => navigate('/meetups')}
            className="w-full flex items-center justify-between p-4 bg-gray-50 rounded-xl hover:bg-gray-100 transition-colors"
          >
            <div className="flex items-center gap-3">
              <Search className="w-5 h-5 text-gray-600" />
              <span className="font-medium text-gray-900">Clear Search</span>
            </div>
            <ChevronRight className="w-5 h-5 text-gray-400" />
          </button>
        )}

        <button
          onClick={onOpenFilters}
          className="w-full flex items-center justify-between p-4 bg-blue-50 rounded-xl hover:bg-blue-100 transition-colors"
        >
          <div className="flex items-center gap-3">
            <Filter className="w-5 h-5 text-blue-600" />
            <span className="font-medium text-gray-900">Adjust University Filter</span>
          </div>
          <ChevronRight className="w-5 h-5 text-gray-400" />
        </button>

        <button
          onClick={() => navigate('/social-feed')}
          className="w-full flex items-center justify-between p-4 bg-violet-50 rounded-xl hover:bg-violet-100 transition-colors"
        >
          <div className="flex items-center gap-3">
            <Coffee className="w-5 h-5 text-violet-600" />
            <span className="font-medium text-gray-900">Explore Social Feed</span>
          </div>
          <ChevronRight className="w-5 h-5 text-gray-400" />
        </button>
      </div>
    </div>
  );
};

export default NoMeetupsState;