import React, { useState, useEffect } from 'react';
import { Bell, ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase/firebase';
import { socialService } from '../../firebase/socialService';
import { AnimatePresence, motion } from 'framer-motion';
import { NotificationsPopover } from '../../components/social/NotificationsPopover';
import { useNotifications } from '../../contexts/NotificationsContext';

const MyPosts = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showNotifications, setShowNotifications] = useState(false);
  const { socialCount } = useNotifications();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = socialService.subscribeToPosts(
      { authorId: auth.currentUser?.uid },
      (updatedPosts) => {
        setPosts(updatedPosts);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  const handleDeletePost = async (postId) => {
    try {
      await socialService.deletePost(postId, auth.currentUser.uid);
      setPosts(posts.filter(post => post.id !== postId));
      showToast('Post deleted successfully');
    } catch (err) {
      console.error('Error deleting post:', err);
      showToast('Failed to delete post', true);
    }
  };

  const showToast = (message, isError = false) => {
    const toast = document.createElement('div');
    toast.className = `fixed bottom-20 left-1/2 transform -translate-x-1/2 px-4 py-2 rounded-lg text-white ${
      isError ? 'bg-red-500' : 'bg-green-500'
    } z-50`;
    toast.textContent = message;
    document.body.appendChild(toast);
    setTimeout(() => {
      toast.style.opacity = '0';
      setTimeout(() => document.body.removeChild(toast), 300);
    }, 3000);
  };

  return (
    <div className="min-h-screen bg-gray-50 pb-20">
      {/* Header */}
      <div className="fixed top-0 left-0 right-0 bg-white border-b border-gray-200 z-40">
        <div className="max-w-2xl mx-auto px-4 py-3 flex items-center justify-between">
          <button
            onClick={() => navigate('/social-feed')}
            className="p-2 rounded-full hover:bg-gray-100"
          >
            <ArrowLeft className="w-6 h-6 text-gray-600" />
          </button>
          <h1 className="text-lg font-semibold text-gray-900">My Posts</h1>
          <div className="w-10" /> {/* Spacer for alignment */}
        </div>
      </div>

      {/* Content */}
      <div className="max-w-2xl mx-auto pt-16 px-4">
        <AnimatePresence>
          {loading ? (
            <div className="flex justify-center items-center h-32">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
            </div>
          ) : error ? (
            <div className="text-center py-8">
              <p className="text-red-600">{error}</p>
              <button
                onClick={() => window.location.reload()}
                className="mt-4 text-blue-600 hover:text-blue-700 font-medium"
              >
                Try again
              </button>
            </div>
          ) : posts.length === 0 ? (
            <div className="text-center py-8">
              <p className="text-gray-600 mb-4">You haven't created any posts yet</p>
              <button
                onClick={() => navigate('/')}
                className="text-blue-600 hover:text-blue-700 font-medium"
              >
                Create your first post
              </button>
            </div>
          ) : (
            posts.map((post) => (
              <motion.div
                key={post.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="bg-white rounded-lg shadow-sm mb-4 p-4"
              >
                <div className="flex justify-between items-start mb-4">
                  <div className="flex items-center space-x-3">
                    <img
                      src={post.author.avatar || '/default-avatar.png'}
                      alt={post.author.name}
                      className="w-10 h-10 rounded-full"
                    />
                    <div>
                      <h3 className="font-medium text-gray-900">{post.author.name}</h3>
                      <p className="text-sm text-gray-500">
                        {new Date(post.createdAt).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={() => handleDeletePost(post.id)}
                    className="text-red-600 hover:text-red-700 text-sm font-medium"
                  >
                    Delete
                  </button>
                </div>
                <p className="text-gray-800 mb-4">{post.content}</p>
                {post.images?.length > 0 && (
                  <img
                    src={post.images[0]}
                    alt="Post content"
                    className="rounded-lg w-full mb-4"
                  />
                )}
                <div className="flex items-center space-x-4 text-sm text-gray-500">
                  <span>{post.likesCount} likes</span>
                  <span>{post.commentsCount} comments</span>
                </div>
              </motion.div>
            ))
          )}
        </AnimatePresence>
      </div>

     
      {/* Notifications Panel */}
      <AnimatePresence>
        {showNotifications && (
          <NotificationsPopover
            show={showNotifications}
            onClose={() => setShowNotifications(false)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default MyPosts;