import React, { useState, useEffect } from 'react';
import { auth, db, storage } from '../firebase/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { isProfileComplete } from '../components/profile/profileValidation';
import ProfileSkeleton from '../components/profile/ProfileSkeleton';
import {
  Camera, Calendar, ChevronRight, Edit, Globe, Heart, Home, LinkIcon,
  LogOut, Mail, MapPin, Menu, MessageCircle, Moon, MoreHorizontal,
  PlusCircle, Save, Settings, Shield, Sun, ThumbsUp, Trash2, User,
  X, Coffee, Users, GraduationCap, Building2, Sparkles, Loader2,
  BookOpen, Languages, Star, Clock, Activity
} from 'lucide-react';

// Import subcomponents
import ProfileHeader from '../components/profile/ProfileHeader';
import ProfileSaveHandler from '../components/profile/ProfileSaveHandler';
import PhotoGallery from '../components/profile/PhotoGallery';
import BasicInformation from '../components/profile/BasicInformation';
import AcademicInformation from '../components/profile/AcademicInformation';
import AboutMe from '../components/profile/AboutMe';
import AdditionalInformation from '../components/profile/AdditionalInformation';
import SettingsPreferences from '../components/profile/SettingsPreferences';
import NavBar from '../components/NavBar';

const Profile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const saveHandlerRef = React.useRef();

  // Handler for save button click in header
  const handleSave = () => {
    if (saveHandlerRef.current) {
      saveHandlerRef.current.handleSaveProfile();
    }
  };

  // Initialize profile with a default structure
  const [profile, setProfile] = useState({
    photos: [],
    basicInfo: {
      name: '',
      username: '',
      gender: '',
      interestedIn: [],
      starSign: ''
    },
    academic: {
      university: '',
      yearOfStudy: '',
      degree: '',
      residence: ''
    },
    bio: {
      shortBio: '',
      longBio: '',
      personalities: [],
      relationshipGoal: ''
    },
    languages: [],
    preferences: {
      activityStatus: 'Available',
      showInMeetups: true,
      darkMode: false
    }
  });

  // Fetch profile data
  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      try {
        const user = auth.currentUser;
        if (!user) throw new Error('No authenticated user found');

        const profileRef = doc(db, 'profiles', user.uid);
        const profileSnap = await getDoc(profileRef);

        if (profileSnap.exists()) {
          setProfile(profileSnap.data());
        } else {
          throw new Error('Profile not found');
        }
      } catch (err) {
        setError(err.message);
        console.error('Error fetching profile:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handleImageUpload = async (file, index) => {
    if (!file) return;
    
    setLoading(true);
    setError(null);
  
    try {
      const user = auth.currentUser;
      if (!user) throw new Error('No authenticated user found');
  
      // Generate a unique filename
      const fileName = `${user.uid}_${Date.now()}.webp`;
      const storageRef = ref(storage, `profile_photos/${fileName}`);
      
      // Upload the processed image
      const snapshot = await uploadBytes(storageRef, file);
      const imageUrl = await getDownloadURL(snapshot.ref);
      
      // Update the profile with the new image
      const profileRef = doc(db, 'profiles', user.uid);
      
      // If we're updating an existing photo
      if (typeof index === 'number' && index < profile.photos.length) {
        const updatedPhotos = [...profile.photos];
        updatedPhotos[index] = imageUrl;
        
        await updateDoc(profileRef, {
          photos: updatedPhotos,
          lastUpdated: new Date()
        });
  
        setProfile(prev => ({
          ...prev,
          photos: updatedPhotos
        }));
      } else {
        // Adding a new photo
        const newPhotos = [...(profile.photos || []), imageUrl].slice(0, 6);
        
        await updateDoc(profileRef, {
          photos: newPhotos,
          lastUpdated: new Date()
        });
  
        setProfile(prev => ({
          ...prev,
          photos: newPhotos
        }));
      }
    } catch (err) {
      setError(err.message);
      console.error('Error uploading image:', err);
      throw err; // Rethrow to handle in the component
    } finally {
      setLoading(false);
    }
  };

  // Account deactivation handler
  const handleDeactivateAccount = async () => {
    setLoading(true);
    setError(null);

    try {
      const user = auth.currentUser;
      if (!user) throw new Error('No authenticated user found');

      const profileRef = doc(db, 'profiles', user.uid);
      await updateDoc(profileRef, {
        isDeactivated: true,
        deactivatedAt: new Date()
      });

      await user.delete();
      window.location.href = '/logout';
    } catch (err) {
      setError(err.message);
      console.error('Error deactivating account:', err);
    } finally {
      setLoading(false);
      setShowDeleteConfirm(false);
    }
  };

  // Logout handler
  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem('authToken');
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
      setError('Failed to log out. Please try again.');
    }
  };

  if (loading && !profile.basicInfo.name) {
    return <ProfileSkeleton />;
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="bg-red-50 text-red-500 p-4 rounded-xl">
          {error}
        </div>
      </div>
    );
  }

  
  return (
    <div className="min-h-screen bg-gray-50">
      <ProfileHeader 
        profile={profile}
        isEditing={isEditing}
        loading={loading}
        setIsEditing={setIsEditing}
        onSave={handleSave}  // Connect save button to handler
      />

      <ProfileSaveHandler
        ref={saveHandlerRef}
        profile={profile}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        onLoadingChange={setLoading}  // Pass loading state up to parent
      />

      <main className="max-w-2xl mx-auto px-4 py-6 mb-16">
        <PhotoGallery 
          isEditing={isEditing} 
          profile={profile} 
          setProfile={setProfile} 
          handleImageUpload={handleImageUpload}
        />

        <BasicInformation 
          isEditing={isEditing} 
          profile={profile} 
          setProfile={setProfile}
        />

        <AcademicInformation 
          isEditing={isEditing} 
          profile={profile} 
          setProfile={setProfile}
        />

        <AboutMe 
          isEditing={isEditing} 
          profile={profile} 
          setProfile={setProfile}
        />

        <AdditionalInformation 
          isEditing={isEditing} 
          profile={profile} 
          setProfile={setProfile}
        />

        <SettingsPreferences 
          isEditing={isEditing} 
          profile={profile} 
          setProfile={setProfile}
          handleLogout={handleLogout}
          setShowDeleteConfirm={setShowDeleteConfirm}
        />
      </main>

      <NavBar />

      {/* Delete Confirmation Modal */}
      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-2xl p-6 w-full max-w-sm">
            <h3 className="text-lg font-semibold mb-2">Deactivate Account</h3>
            <p className="text-gray-600 mb-6">Are you sure you want to deactivate your account? This action cannot be undone.</p>
            <div className="flex gap-3">
              <button
                onClick={() => setShowDeleteConfirm(false)}
                className="flex-1 py-2 px-4 bg-gray-100 text-gray-600 rounded-lg hover:bg-gray-200 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleDeactivateAccount}
                className="flex-1 py-2 px-4 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
              >
                Deactivate
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;