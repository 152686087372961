import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { AlertCircle, PlusCircle, XCircle, ArrowRight, ArrowLeft, Check, BookOpen, School, Sparkles, Calendar, Clock } from 'lucide-react';
import { collection, query, where, getDocs, getDoc, setDoc, addDoc, serverTimestamp, doc } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { isProfileComplete } from '../../components/profile/profileValidation';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebase/firebase';
const TutorSignup = ({ open, onClose }) => {
  const { userProfile, currentUser, loading } = useAuth();
  const [currentStep, setCurrentStep] = useState(0);
  const [error, setError] = useState('');
  const [existingApplication, setExistingApplication] = useState(null);
  const [formData, setFormData] = useState({
    courses: [{ courseCode: '', finalMark: '' }],
    availability: [],
    hourlyRate: ''
  });

  useEffect(() => {
    if (userProfile && currentUser) {
      checkExistingApplication();
    }
  }, [userProfile, currentUser]);

  const checkExistingApplication = async () => {
    if (!currentUser) return;
    try {
      const q = query(
        collection(db, 'tutorApplications'),
        where('userId', '==', currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const application = querySnapshot.docs[0].data();
        setExistingApplication(application);

        // Fetch availability and courses for all applications
        const availabilityRef = doc(db, 'tutorAvailability', currentUser.uid);
        const availabilityDoc = await getDoc(availabilityRef);
        if (availabilityDoc.exists()) {
          const availabilityData = availabilityDoc.data();
          setFormData(prev => ({
            ...prev,
            availability: availabilityData.slots || [],
            hourlyRate: availabilityData.hourlyRate || '',
            courses: application.courses || [{ courseCode: '', finalMark: '' }]
          }));
        }
      }
    } catch (error) {
      console.error('Error checking existing application:', error);
    }
  };

  const addCourse = () => {
    if (formData.courses.length < 4) {
      setFormData({
        ...formData,
        courses: [...formData.courses, { courseCode: '', finalMark: '' }]
      });
    }
  };

  const removeCourse = (index) => {
    if (formData.courses.length > 1) {
      setFormData({
        ...formData,
        courses: formData.courses.filter((_, i) => i !== index)
      });
    }
  };

  const updateCourse = (index, field, value) => {
    const newCourses = [...formData.courses];
    newCourses[index] = { ...newCourses[index], [field]: value };
    setFormData({ ...formData, courses: newCourses });
  };

  const addAvailabilitySlot = () => {
    setFormData({
      ...formData,
      availability: [
        ...formData.availability,
        { id: Date.now().toString(), day: 'Monday', startTime: '09:00', endTime: '10:00' }
      ]
    });
  };

  const removeAvailabilitySlot = (id) => {
    setFormData({
      ...formData,
      availability: formData.availability.filter(slot => slot.id !== id)
    });
  };

  const updateAvailabilitySlot = (id, field, value) => {
    const newSlots = formData.availability.map(slot =>
      slot.id === id ? { ...slot, [field]: value } : slot
    );
    setFormData({ ...formData, availability: newSlots });
  };

  const validateCourses = () => {
    const errors = [];
    if (formData.courses.some(course => !course.courseCode || !course.finalMark)) {
      errors.push('All course fields must be completed');
    }
    if (formData.courses.some(course => Number(course.finalMark) < 60)) {
      errors.push('Final mark must be at least 60% for all courses');
    }
    if (formData.courses.some(course => Number(course.finalMark) > 100)) {
      errors.push('Final mark cannot exceed 100%');
    }
    return errors;
  };

  const handleSubmit = async () => {
    setError('');
    
    // Validate hourly rate
    const hourlyRateNum = parseFloat(formData.hourlyRate);
    if (isNaN(hourlyRateNum) || hourlyRateNum <= 0) {
      setError('Please enter a valid hourly rate greater than 0');
      return;
    }
    
    try {
      const tutorApplicationRef = collection(db, 'tutorApplications');
      
      if (existingApplication) {
        // Update availability for all applications
        const availabilityRef = doc(db, 'tutorAvailability', currentUser.uid);
        
        // Verify availability slots have valid times
        const validSlots = formData.availability.map(slot => {
          // Ensure the endTime is after startTime
          if (slot.startTime >= slot.endTime) {
            throw new Error('End time must be after start time for all availability slots');
          }
          return {
            id: slot.id,
            day: slot.day,
            startTime: slot.startTime,
            endTime: slot.endTime
          };
        });
        
        // Use setDoc with merge option to update or create
        await setDoc(availabilityRef, {
          slots: validSlots,
          hourlyRate: hourlyRateNum,
          updatedAt: serverTimestamp()
        }, { merge: true });
        
        alert('Availability and rate updated successfully!');
      } else {
        // Submit new application
        const applicationData = {
          userId: currentUser.uid,
          email: currentUser.email,
          name: userProfile.basicInfo?.name,
          university: userProfile.academic?.university,
          courses: formData.courses.map(course => ({
            courseCode: course.courseCode.toUpperCase(),
            finalMark: Number(course.finalMark)
          })),
          photos: userProfile.photos || [],
          status: 'pending',
          submittedAt: serverTimestamp(),
          requiresProof: true
        };
        
        await addDoc(tutorApplicationRef, applicationData);
        
        // If the application is submitted successfully, also create an initial availability entry
        const availabilityRef = doc(db, 'tutorAvailability', currentUser.uid);
        await setDoc(availabilityRef, {
          slots: formData.availability,
          hourlyRate: hourlyRateNum,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        });
        
        // Send confirmation email to the tutor
        try {
          // Import at the top of the file: import { httpsCallable } from 'firebase/functions';
          // And also import the functions instance: import { functions } from '../../firebase/firebase';
          const sendTutorApplicationEmail = httpsCallable(functions, 'sendTutorApplicationConfirmation');
          await sendTutorApplicationEmail({
            tutorEmail: currentUser.email,
            tutorName: userProfile.basicInfo?.name
          });
          console.log('Tutor application confirmation email sent successfully');
        } catch (emailError) {
          console.error('Error sending tutor application confirmation email:', emailError);
          // Don't fail the entire submission if just the email fails
        }
        
        alert('Application submitted successfully! Note that proof of academic performance may be required. Awaiting admin approval.');
      }
      onClose();
    } catch (error) {
      console.error('Error submitting application:', error);
      setError('Failed to submit application: ' + error.message);
    }
  };
  
  // Determine which steps to show based on existing application
  const getSteps = () => {
    if (existingApplication) {
      // For existing applications, only show availability step
      return [
        {
          id: 'availability',
          icon: Calendar,
          title: "Update Your Availability",
          subtitle: "Adjust your weekly availability and rate",
          content: (
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-1">Hourly Rate (R)</label>
                <input
                  type="number"
                  value={formData.hourlyRate}
                  onChange={(e) => setFormData({ ...formData, hourlyRate: e.target.value })}
                  className="w-full p-3 border rounded-xl"
                  placeholder="e.g., 200"
                />
              </div>
              {formData.availability.map((slot, index) => (
                <div key={slot.id} className="p-4 border rounded-xl bg-gray-50">
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="font-medium">Slot {index + 1}</h3>
                    <button
                      type="button"
                      onClick={() => removeAvailabilitySlot(slot.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <XCircle className="h-5 w-5" />
                    </button>
                  </div>
                  <div className="space-y-2">
                    <div>
                      <label className="block text-sm font-medium mb-1">Day</label>
                      <select
                        value={slot.day}
                        onChange={(e) => updateAvailabilitySlot(slot.id, 'day', e.target.value)}
                        className="w-full p-3 border rounded-xl"
                      >
                        {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
                          <option key={day} value={day}>{day}</option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1">Start Time</label>
                      <input
                        type="time"
                        value={slot.startTime}
                        onChange={(e) => updateAvailabilitySlot(slot.id, 'startTime', e.target.value)}
                        className="w-full p-3 border rounded-xl"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1">End Time</label>
                      <input
                        type="time"
                        value={slot.endTime}
                        onChange={(e) => updateAvailabilitySlot(slot.id, 'endTime', e.target.value)}
                        className="w-full p-3 border rounded-xl"
                      />
                    </div>
                  </div>
                </div>
              ))}
              <button
                type="button"
                onClick={addAvailabilitySlot}
                className="flex items-center justify-center w-full p-3 border-2 border-dashed 
                  border-gray-300 rounded-xl text-gray-600 hover:border-green-200 hover:text-gray-700"
              >
                <PlusCircle className="h-5 w-5 mr-2" />
                Add Another Slot
              </button>
            </div>
          )
        }
      ];
    } else {
      // For new applications, show all steps
      return [
        {
          id: 'welcome',
          icon: Sparkles,
          title: "Become a Tutor",
          subtitle: "Help others succeed while earning",
          content: (
            <div className="space-y-4">
              <div className="bg-blue-50 p-4 rounded-lg text-sm text-blue-700">
                <strong>Important Notes:</strong>
                <ul className="list-disc ml-4 mt-2">
                  <li>You can add up to 4 courses you wish to tutor</li>
                  <li>Minimum required mark is 60% for each course</li>
                  <li>Proof of academic performance may be required</li>
                  <li>Only one application per student is allowed</li>
                </ul>
              </div>
            </div>
          )
        },
        {
          id: 'profile',
          icon: School,
          title: "Your Academic Profile",
          subtitle: "Confirm your details",
          content: (
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-1">Name</label>
                <input
                  type="text"
                  value={userProfile?.basicInfo?.name || ''}
                  disabled
                  className="w-full p-3 bg-gray-50 border rounded-xl"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">University</label>
                <input
                  type="text"
                  value={userProfile?.academic?.university || ''}
                  disabled
                  className="w-full p-3 bg-gray-50 border rounded-xl"
                />
              </div>
            </div>
          )
        },
        {
          id: 'courses',
          icon: BookOpen,
          title: "Add Your Courses",
          subtitle: "Enter the courses you'd like to tutor",
          content: (
            <div className="space-y-4">
              {formData.courses.map((course, index) => (
                <div key={index} className="p-4 border rounded-xl bg-gray-50">
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="font-medium">Course {index + 1}</h3>
                    {formData.courses.length > 1 && (
                      <button
                        type="button"
                        onClick={() => removeCourse(index)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <XCircle className="h-5 w-5" />
                      </button>
                    )}
                  </div>
                  <div className="space-y-2">
                    <div>
                      <label className="block text-sm font-medium mb-1">Course Code</label>
                      <input
                        type="text"
                        value={course.courseCode}
                        onChange={(e) => updateCourse(index, 'courseCode', e.target.value)}
                        className="w-full p-3 border rounded-xl"
                        placeholder="e.g., CSC1015F"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1">Final Mark (%)</label>
                      <input
                        type="number"
                        value={course.finalMark}
                        onChange={(e) => updateCourse(index, 'finalMark', e.target.value)}
                        className="w-full p-3 border rounded-xl"
                        min="60"
                        max="100"
                        placeholder="Enter your final mark"
                      />
                    </div>
                  </div>
                </div>
              ))}
              
              {formData.courses.length < 4 && (
                <button
                  type="button"
                  onClick={addCourse}
                  className="flex items-center justify-center w-full p-3 border-2 border-dashed 
                    border-gray-300 rounded-xl text-gray-600 hover:border-green-200 hover:text-gray-700"
                >
                  <PlusCircle className="h-5 w-5 mr-2" />
                  Add Another Course
                </button>
              )}
            </div>
          )
        },
        {
          id: 'availability',
          icon: Calendar,
          title: "Set Your Availability",
          subtitle: "Enter your weekly availability",
          content: (
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-1">Hourly Rate (R)</label>
                <input
                  type="number"
                  value={formData.hourlyRate}
                  onChange={(e) => setFormData({ ...formData, hourlyRate: e.target.value })}
                  className="w-full p-3 border rounded-xl"
                  placeholder="e.g., 200"
                />
              </div>
              {formData.availability.map((slot, index) => (
                <div key={slot.id} className="p-4 border rounded-xl bg-gray-50">
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="font-medium">Slot {index + 1}</h3>
                    <button
                      type="button"
                      onClick={() => removeAvailabilitySlot(slot.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <XCircle className="h-5 w-5" />
                    </button>
                  </div>
                  <div className="space-y-2">
                    <div>
                      <label className="block text-sm font-medium mb-1">Day</label>
                      <select
                        value={slot.day}
                        onChange={(e) => updateAvailabilitySlot(slot.id, 'day', e.target.value)}
                        className="w-full p-3 border rounded-xl"
                      >
                        {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
                          <option key={day} value={day}>{day}</option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1">Start Time</label>
                      <input
                        type="time"
                        value={slot.startTime}
                        onChange={(e) => updateAvailabilitySlot(slot.id, 'startTime', e.target.value)}
                        className="w-full p-3 border rounded-xl"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1">End Time</label>
                      <input
                        type="time"
                        value={slot.endTime}
                        onChange={(e) => updateAvailabilitySlot(slot.id, 'endTime', e.target.value)}
                        className="w-full p-3 border rounded-xl"
                      />
                    </div>
                  </div>
                </div>
              ))}
              <button
                type="button"
                onClick={addAvailabilitySlot}
                className="flex items-center justify-center w-full p-3 border-2 border-dashed 
                  border-gray-300 rounded-xl text-gray-600 hover:border-green-200 hover:text-gray-700"
              >
                <PlusCircle className="h-5 w-5 mr-2" />
                Add Another Slot
              </button>
            </div>
          )
        }
      ];
    }
  };
  
  const steps = getSteps();

  const canProgress = () => {
    if (existingApplication) {
      // For existing applications, only validate availability
      return formData.availability.length > 0 && formData.hourlyRate;
    } else {
      if (currentStep === 0) return true;
      if (currentStep === 1) return userProfile?.basicInfo?.name && userProfile?.academic?.university;
      if (currentStep === 2) return validateCourses().length === 0;
      if (currentStep === 3) return formData.availability.length > 0 && formData.hourlyRate;
    }
    return true;
  };

  if (!open) return null;

  if (loading) {
    return (
      <div className="fixed inset-0 bg-gray-900 bg-opacity-60 flex items-center justify-center z-[1000]">
        <div className="bg-white rounded-2xl p-6 w-full max-w-md shadow-2xl">
          <div className="flex justify-center items-center">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-blue-600"></div>
          </div>
        </div>
      </div>
    );
  }

  if (!existingApplication && !isProfileComplete(userProfile)) {
    return (
      <div className="fixed inset-0 bg-gray-900 bg-opacity-60 flex items-center justify-center z-[1000]">
        <div className="bg-white rounded-2xl p-8 w-full max-w-md shadow-2xl border-t-4 border-blue-600">
          <div className="text-center">
            <AlertCircle className="h-12 w-12 text-blue-600 mx-auto mb-4" />
            <h2 className="text-2xl font-serif font-bold text-gray-800 mb-3">Profile Incomplete</h2>
            <p className="text-gray-600 mb-6 font-sans">
              Please complete your academic profile before applying to become a tutor.
            </p>
            <button
              onClick={onClose}
              className="w-full bg-blue-600 text-white p-3 rounded-lg hover:bg-blue-700 transition-colors font-sans"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }

  const CurrentIcon = steps[currentStep].icon;
  const submitButtonText = existingApplication ? "Update" : "Submit Application";

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-60 flex items-center justify-center z-[1000] p-4">
      <div className="bg-white rounded-2xl w-full max-w-xl relative flex flex-col max-h-[90vh] shadow-2xl border-t-4 border-blue-600">
        {/* Progress Bar */}
        <div className="absolute top-0 left-0 right-0 h-1 bg-gray-200">
          <div 
            className="h-full bg-blue-600 transition-all duration-300"
            style={{ width: `${((currentStep + 1) / steps.length) * 100}%` }}
          />
        </div>

        {/* Close Button */}
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 p-2 rounded-full hover:bg-gray-100 transition-colors"
        >
          <XCircle className="w-6 h-6" />
        </button>

        {/* Header */}
        <div className="p-6 flex-none bg-gradient-to-r from-blue-50 to-indigo-50">
          <div className="text-center mb-6">
            <div className="bg-white p-3 rounded-full inline-block mb-4 shadow-md">
              <CurrentIcon className="w-8 h-8 text-blue-600" />
            </div>
            <h2 className="text-2xl font-serif font-bold text-gray-800">
              {steps[currentStep].title}
            </h2>
            <p className="text-sm text-gray-600 mt-2 font-sans italic">
              {steps[currentStep].subtitle}
            </p>
          </div>

          {error && (
            <div className="bg-red-50 p-4 rounded-lg mb-4 border-l-4 border-red-500">
              <p className="text-sm text-red-700 font-sans">{error}</p>
            </div>
          )}
        </div>

        {/* Content */}
        <div className="flex-1 overflow-y-auto px-6 py-4 bg-[url('https://www.transparenttextures.com/patterns/paper-fibers.png')] bg-opacity-10">
          <div className="mb-6 space-y-6">
            {steps[currentStep].content}
            {/* Academic-inspired styling for inputs */}
            {steps[currentStep].id === 'courses' && (
              <div className="text-sm text-gray-500 italic text-center">
                "Knowledge is the foundation of teaching" - Academic Proverb
              </div>
            )}
          </div>
        </div>

        {/* Footer */}
        <div className="p-6 flex-none border-t bg-gray-50">
          <div className="flex gap-4 justify-between items-center">
            {!existingApplication && currentStep > 0 && (
              <button
                onClick={() => setCurrentStep(prev => prev - 1)}
                className="px-5 py-2 rounded-lg flex items-center gap-2 text-blue-600 hover:bg-blue-50 transition-colors border border-blue-200 font-sans"
              >
                <ArrowLeft className="w-4 h-4" />
                Previous
              </button>
            )}
            
            <button
              onClick={existingApplication || currentStep === steps.length - 1 ? handleSubmit : () => setCurrentStep(prev => prev + 1)}
              disabled={!canProgress()}
              className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed font-sans shadow-md ml-auto"
            >
              {existingApplication || currentStep === steps.length - 1 ? (
                <>
                  {submitButtonText}
                  <Check className="w-4 h-4" />
                </>
              ) : (
                <>
                  Next
                  <ArrowRight className="w-4 h-4" />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorSignup;