import React from 'react';

const PostSkeleton = () => {
  return (
    <div className="bg-white rounded-lg shadow mb-4 p-4 animate-pulse">
      {/* Author info skeleton */}
      <div className="flex items-center mb-4">
        <div className="w-10 h-10 rounded-full bg-gray-200"></div>
        <div className="ml-3">
          <div className="h-4 bg-gray-200 rounded w-24"></div>
          <div className="h-3 bg-gray-200 rounded w-16 mt-1"></div>
        </div>
      </div>
      
      {/* Content skeleton */}
      <div className="space-y-2">
        <div className="h-4 bg-gray-200 rounded w-full"></div>
        <div className="h-4 bg-gray-200 rounded w-5/6"></div>
        <div className="h-4 bg-gray-200 rounded w-4/6"></div>
      </div>
      
      {/* Image placeholder */}
      <div className="w-full h-40 mt-4 bg-gray-200 rounded-lg"></div>
      
      {/* Action buttons skeleton */}
      <div className="flex justify-between mt-4">
        <div className="flex space-x-4">
          <div className="h-6 bg-gray-200 rounded w-16"></div>
          <div className="h-6 bg-gray-200 rounded w-16"></div>
        </div>
        <div className="h-6 bg-gray-200 rounded w-16"></div>
      </div>
    </div>
  );
};

export default PostSkeleton;