import React, { useState, useEffect, useRef } from 'react';
import { ArrowLeft, Filter, MessageCircle, Search, Sparkles } from 'lucide-react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import FilterModal from './FilterModal';  // Adjust path as needed
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import default styles

const MeetupHeader = ({ 
  title = 'Meetups',
  onBack,
  userPreferences,
  onFilterChange = () => {},
  searchQuery,
  setSearchQuery
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [selectedUniversity, setSelectedUniversity] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(true);
  const lastScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      
      if (currentScrollY > lastScrollY.current + 10) {
        // Scrolling down
        setShowSearchBar(false);
      } else if (currentScrollY < lastScrollY.current - 10) {
        // Scrolling up
        setShowSearchBar(true);
      }
      
      lastScrollY.current = currentScrollY;
    };
    
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleFilterApply = () => {
    onFilterChange(selectedUniversity);
    setShowFilters(false);
    
    const message = selectedUniversity 
        ? `Showing profiles from ${selectedUniversity}` 
        : 'Showing profiles from all universities';
        
    toast(message);
  };

  const handleFilterReset = () => {
    setSelectedUniversity('');
    onFilterChange('');
    setShowFilters(false);
  };

  return (
    <>
      <motion.header 
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        className="fixed top-0 w-full bg-white shadow-lg z-40 rounded-b-2xl border border-gray-100"
      >
        <div className="max-w-7xl mx-auto px-6 py-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-4">
              {onBack && (
                <motion.button 
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={onBack}
                  className="p-3 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors"
                  aria-label="Go back"
                >
                  <ArrowLeft className="w-5 h-5 text-green-500" />
                </motion.button>
              )}
              <div className="flex items-center space-x-3">
                <div className="relative">
                  <motion.div 
                    whileHover={{ rotate: 20, scale: 1.05 }}
                    className="w-10 h-10 bg-gradient-to-br from-green-500 to-green-600 rounded-xl flex items-center justify-center transform rotate-12 shadow-lg"
                  >
                    <span className="text-white font-bold text-xl transform -rotate-12">V</span>
                  </motion.div>
                  <div className="absolute -top-1 -right-1 w-3 h-3 bg-green-400 rounded-full animate-pulse" />
                </div>
                <motion.h1 
                  layout
                  className="text-2xl font-semibold text-gray-700"
                >
                  {title}
                </motion.h1>
              </div>
            </div>

            <div className="flex items-center space-x-3">
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => setShowFilters(true)}
                className="p-3 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors"
                aria-label="Show filters"
              >
                <Filter className="w-5 h-5 text-green-500" />
              </motion.button>
              <Link to="/messages">
                <motion.button 
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className="p-3 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors"
                >
                  <MessageCircle className="w-5 h-5 text-green-500" />
                </motion.button>
              </Link>
            </div>
          </div>

          <AnimatePresence>
            {showSearchBar && setSearchQuery && (
              <motion.div 
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.2 }}
                className="mt-5 relative overflow-hidden"
              >
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search by name or username..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full px-5 py-3 pr-12 rounded-xl border border-gray-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent shadow-sm"
                  />
                  <Search className="w-5 h-5 text-gray-400 absolute right-4 top-1/2 transform -translate-y-1/2" />
                </div>
                <div className="absolute right-12 top-1/2 transform -translate-y-1/2">
                  <Sparkles className="w-4 h-4 text-green-400 animate-pulse" />
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </motion.header>

      <FilterModal
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        selectedUniversity={selectedUniversity}
        setSelectedUniversity={setSelectedUniversity}
        userPreferences={userPreferences}
        onApply={handleFilterApply}
        onReset={handleFilterReset}
      />
    </>
  );
};

export default MeetupHeader;