// components/groups/CreateGroupModal.js
import { useState, useEffect } from 'react';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { EmojiPicker } from './EmojiPicker';
import { MemberSelectionModal } from './MemberSelectionModal';

export const CreateGroupModal = ({ onClose, currentUser }) => {
  const [groupName, setGroupName] = useState('');
  const [selectedEmoji, setSelectedEmoji] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [showMemberModal, setShowMemberModal] = useState(false);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const usersRef = collection(db, 'profiles');
        const snapshot = await getDocs(usersRef);
        const allUsers = snapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(user => user.id !== currentUser.uid);
        setUsers(allUsers);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    loadUsers();
  }, [currentUser]);

  const handleToggleMember = (userId) => {
    setSelectedMembers(prev => 
      prev.includes(userId) 
        ? prev.filter(id => id !== userId)
        : [...prev, userId]
    );
  };

  const handleCreateGroup = async () => {
    try {
      const groupData = {
        name: groupName,
        emoji: selectedEmoji,
        creatorId: currentUser.uid,
        tags: [],
        memberCount: selectedMembers.length + 1,
        members: [currentUser.uid, ...selectedMembers],
        createdAt: new Date()
      };

      await addDoc(collection(db, 'groups'), groupData);
      onClose();
    } catch (error) {
      console.error('Group creation failed:', error);
    }
  };

  return (
    <>
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
        <div className="bg-white rounded-2xl p-6 w-full max-w-lg">
          <h2 className="text-2xl font-bold mb-6">Create New Group</h2>
          
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Group Emoji
            </label>
            <EmojiPicker onSelect={setSelectedEmoji} />
            {selectedEmoji && (
              <div className="mt-2 p-3 bg-gray-100 rounded-lg text-center text-2xl">
                {selectedEmoji}
              </div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Group Name
            </label>
            <input
              type="text"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              className="w-full px-4 py-2 rounded-lg border border-gray-200"
              placeholder="Enter group name"
            />
          </div>

          <button
            onClick={() => setShowMemberModal(true)}
            className="w-full py-2 bg-green-100 text-green-600 rounded-lg mb-4"
          >
            Select Members ({selectedMembers.length})
          </button>

          <div className="flex space-x-3">
            <button
              onClick={onClose}
              className="flex-1 py-2 bg-gray-100 rounded-lg"
            >
              Cancel
            </button>
            <button
              onClick={handleCreateGroup}
              disabled={!groupName || !selectedEmoji}
              className="flex-1 py-2 bg-green-500 text-white rounded-lg disabled:opacity-50"
            >
              Create Group
            </button>
          </div>
        </div>
      </div>

      {showMemberModal && (
        <MemberSelectionModal 
          users={users}
          selectedMembers={selectedMembers}
          onToggleMember={handleToggleMember}
          onClose={() => setShowMemberModal(false)}
          onSave={() => setShowMemberModal(false)}
        />
      )}
    </>
  );
};
