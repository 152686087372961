import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { doc, setDoc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Mail, User, Lock, Loader2, GraduationCap, Building } from 'lucide-react';
import { auth, db } from '../../firebase/firebase';
import OTPVerification from './OTPVerification';
import PrivacyPolicyModal from './PrivacyPolicyModal';
import EmailInput from './EmailInput';
import { limit } from 'firebase/firestore';
import { debounce } from 'lodash';
// South African Universities list
const universities = [
  { name: 'Cape Peninsula University of Technology', domain: '@mycput.ac.za' },
  { name: 'Central University of Technology', domain: '@cut.ac.za' },
  { name: 'Durban University of Technology', domain: '@dut.ac.za' },
  { name: 'Mangosuthu University of Technology', domain: '@mut.ac.za' },
  { name: 'Nelson Mandela University', domain: '@mandela.ac.za' },
  { name: 'North-West University', domain: '@mynwu.ac.za' },
  { name: 'Rhodes University', domain: '@campus.ru.ac.za ' },
  { name: 'Sefako Makgatho Health Sciences University', domain: '@swave.smu.ac.za' },
  { name: 'Sol Plaatje University', domain: '@spu.ac.za' },
  { name: 'Stellenbosch University', domain: '@sun.ac.za' },
  { name: 'Tshwane University of Technology', domain: '@tut.ac.za' },
  { name: 'University of Cape Town', domain: '@myuct.ac.za' },
  { name: 'University of Fort Hare', domain: '@ufh.ac.za' },
  { name: 'University of Johannesburg', domain: '@student.uj.ac.za' },
  { name: 'University of KwaZulu-Natal', domain: '@stu.ukzn.ac.za' },
  { name: 'University of Limpopo', domain: '@keyaka.ul.ac.za' },
  { name: 'University of Mpumalanga', domain: '@ump.ac.za' },
  { name: 'University of Pretoria', domain: '@tuks.co.za' },
  { name: 'University of South Africa', domain: '@mylife.unisa.ac.za' },
  { name: 'University of the Free State', domain: '@ufs4life.ac.za' },
  { name: 'University of the Western Cape', domain: '@myuwc.ac.za' },
  { name: 'University of the Witwatersrand', domain: '@wits.ac.za' },
  { name: 'University of Zululand', domain: '@unizulu.ac.za' },
  { name: 'Vaal University of Technology', domain: '@edu.vut.ac.za' },
  { name: 'Walter Sisulu University', domain: '@mywsu.ac.za' }
].sort((a, b) => a.name.localeCompare(b.name));


const Register = ({ switchToLogin }) => {
  const [formData, setFormData] = useState({
    name: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    university: ''
  });

  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [verificationEmail, setVerificationEmail] = useState('');
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [isValid, setIsValid] = useState(false);
 // Update handleChange to include username check

const [checkingUsername, setCheckingUsername] = useState(false);

 // Updated username check function with proper Firestore query
 const checkUsernameExists = async (username) => {
  try {
    if (!username || username.length < 3) return false;
    
    const usersRef = collection(db, "profiles");
    const q = query(
      usersRef,
      where("basicInfo.username", "==", username.toLowerCase()),
      limit(1)
    );

    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  } catch (error) {
    console.error("Error checking username:", error);
    setErrors(prev => ({
      ...prev,
      username: 'Error checking username availability. Please try again.'
    }));
    return false;
  }
};

// Improved debounced username check with better error handling
const debouncedUsernameCheck = debounce(async (username) => {
  if (!username || username.length < 3) {
    setCheckingUsername(false);
    return;
  }

  setCheckingUsername(true);
  try {
    const exists = await checkUsernameExists(username);
    setErrors(prev => ({
      ...prev,
      username: exists ? 'Username already taken. Please choose a different one.' : undefined
    }));
    
    // Update form validity
    setIsValid(!exists && username.length >= 3);
  } catch (error) {
    console.error('Error in username check:', error);
  } finally {
    setCheckingUsername(false);
  }
}, 500);

// Enhanced handleChange function with username validation
const handleChange = (e) => {
  const { name, value } = e.target;
  
  setFormData(prev => ({
    ...prev,
    [name]: value,
  }));

  if (name === 'username') {
    // Basic validation before checking database
    if (!value) {
      setErrors(prev => ({
        ...prev,
        username: 'Username is required'
      }));
      setIsValid(false);
    } else if (value.length < 3) {
      setErrors(prev => ({
        ...prev,
        username: 'Username must be at least 3 characters long'
      }));
      setIsValid(false);
    } else if (!/^[a-zA-Z0-9_]+$/.test(value)) {
      setErrors(prev => ({
        ...prev,
        username: 'Username can only contain letters, numbers, and underscores'
      }));
      setIsValid(false);
    } else {
      debouncedUsernameCheck(value);
    }
  }
};

 // Update validateForm to include real-time validation
 const validateForm = () => {
  const newErrors = {};
  
  // Name validation
  if (!formData.name.trim()) {
    newErrors.name = 'Full name is required';
  }

  // Username validation
  if (!formData.username) {
    newErrors.username = 'Username is required';
  } else if (formData.username.length < 3) {
    newErrors.username = 'Username must be at least 3 characters long';
  } else if (formData.username.length > 20) {
    newErrors.username = 'Username must be less than 20 characters';
  } else if (!/^[a-zA-Z0-9_]+$/.test(formData.username)) {
    newErrors.username = 'Username can only contain letters, numbers, and underscores';
  }

  // University validation
  if (!formData.university) {
    newErrors.university = 'Please select your university';
  }

  // Email validation
  if (!formData.email) {
    newErrors.email = 'University email is required';
  } else {
    const selectedUniversity = universities.find(uni => uni.name === formData.university);
    if (selectedUniversity) {
      const emailDomain = formData.email.substring(formData.email.indexOf('@'));
      if (emailDomain !== selectedUniversity.domain) {
        newErrors.email = `Please use your ${selectedUniversity.name} email address (${selectedUniversity.domain})`;
      }
    }
  }

  // Password validation
  if (!formData.password) {
    newErrors.password = 'Password is required';
  } else if (formData.password.length < 6) {
    newErrors.password = 'Password must be at least 6 characters';
  }

  // Confirm password validation
  if (formData.password !== formData.confirmPassword) {
    newErrors.confirmPassword = 'Passwords do not match';
  }

  setErrors(newErrors);
  return newErrors;
};


  const sendOTP = async (email) => {
    const functions = getFunctions();
    const sendOTPFunction = httpsCallable(functions, 'sendOTP');
    
    try {
      await sendOTPFunction({ email });
      setVerificationEmail(email);
      setShowOTP(true);
    } catch (error) {
      console.error('Error sending OTP:', error);
      throw new Error('Failed to send verification code');
    }
  };

  const verifyOTP = async (otpCode) => {
    const functions = getFunctions();
    const verifyOTPFunction = httpsCallable(functions, 'verifyOTP');
    
    try {
      await verifyOTPFunction({ email: verificationEmail, otp: otpCode });
      await completeRegistration();
    } catch (error) {
      console.error('Error verifying OTP:', error);
      throw new Error(error.message || 'Invalid verification code');
    }
  };

  const initializeUserProfile = async (userId, userData) => {
    const profileData = {
      photos: [],
      basicInfo: {
        name: userData.name,
        username: userData.username,
        email: userData.email,
        phone: '',
        gender: '',
        interestedIn: [],
        ageRange: '',
        starSign: ''
      },
      academic: {
        university: userData.university,
        degree: '',
        yearOfStudy: '',
        residence: '',
        studentNumber: ''
      },
      location: {
        hometown: '',
        currentLocation: '',
        residenceType: ''
      },
      bio: {
        shortBio: '',
        longBio: '',
        personalities: [],
        interests: [],
        relationshipGoal: ''
      },
      languages: ['English'],
      preferences: {
        showInMeetups: true,
        showOtherUniversities: true,
        darkMode: false,
        activityStatus: 'Available',
        emailNotifications: true,
        pushNotifications: true
      },
      privacy: {
        profileVisibility: 'Public',
        showOnlineStatus: true,
        showLastSeen: true,
        showReadReceipts: true
      },
      social: {
        instagram: '',
        linkedin: '',
        github: ''
      },
      createdAt: new Date(),
      lastUpdated: new Date()
    };

    const profileRef = doc(db, 'profiles', userId);
    await setDoc(profileRef, profileData);
  };

  const getErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 'auth/email-already-in-use':
        return 'An account with this email already exists';
      case 'auth/invalid-email':
        return 'Invalid email address';
      case 'auth/operation-not-allowed':
        return 'Email/password accounts are not enabled. Please contact support';
      case 'auth/weak-password':
        return 'Password is too weak. Please use at least 6 characters';
      default:
        return 'Registration failed. Please try again later';
    }
  };

  const completeRegistration = async () => {
    setLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );

      await updateProfile(userCredential.user, {
        displayName: formData.name,
        photoURL: `./avi.png`
      });

      await initializeUserProfile(userCredential.user.uid, formData);

      const token = await userCredential.user.getIdToken();
      localStorage.setItem('authToken', token);

      navigate('/home');
    } catch (error) {
      console.error('Error during registration:', error);
      throw new Error(getErrorMessage(error.code));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
      setShowPrivacyPolicy(true);  // Show privacy policy instead of immediate submission
    } else {
      setErrors(newErrors);
    }
  };
  
  const handlePrivacyPolicyAccept = async () => {
    setShowPrivacyPolicy(false);
    setLoading(true);
    try {
      await sendOTP(formData.email);
    } catch (error) {
      setErrors({
        ...errors,
        backend: error.message,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center px-4 py-8 mt-6 sm:mt-0">
      <div className="w-full max-w-md">
        {/* Updated Header with proper spacing */}
        <div className="text-center mb-8 mt-8 sm:mt-0">
          <div className="flex items-center justify-center mb-4">
            <div className="relative">
              <div className="w-12 h-12 bg-gradient-to-br from-green-500 to-green-600 rounded-xl flex items-center justify-center transform rotate-12 shadow-lg">
                <span className="text-white font-bold text-2xl transform -rotate-12">V</span>
              </div>
              <div className="absolute -top-1 -right-1 w-2 h-2 bg-green-400 rounded-full animate-pulse" />
            </div>
          </div>
          <h1 className="text-3xl font-bold text-gray-800">
            <span className="text-green-500">Varsity</span>Link
          </h1>
          <div className="flex items-center justify-center space-x-2 mt-2">
            <span className="text-xs bg-green-100 text-green-600 px-2 py-1 rounded-full flex items-center">
              <GraduationCap className="w-3 h-3 mr-1" />
              The Ultimate Student Connection Hub
            </span>
          </div>
        </div>


        <div className="bg-white rounded-3xl shadow-lg p-6 space-y-6">
          <h2 className="text-2xl font-semibold text-gray-800 text-center">Create Account</h2>
          
          {!showOTP ? (
            <form onSubmit={handleSubmit} className="space-y-4">
              {/* Full Name Input */}
              <div className="space-y-2">
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <User className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="text"
                    name="name"
                    placeholder="Full Name"
                    value={formData.name}
                    onChange={handleChange}
                    className={`pl-10 pr-4 py-3 w-full bg-gray-50 border ${
                      errors.name ? 'border-red-500' : 'border-gray-200'
                    } rounded-xl focus:ring-2 focus:ring-green-500 focus:border-transparent`}
                  />
                </div>
                {errors.name && (
                  <p className="text-red-500 text-sm">{errors.name}</p>
                )}
              </div>

              {/* Username Input */}
              <div className="space-y-2">
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <User className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          name="username"
          placeholder="Username"
          value={formData.username}
          onChange={handleChange}
          className={`pl-10 pr-10 py-3 w-full bg-gray-50 border ${
            errors.username ? 'border-red-500' : 'border-gray-200'
          } rounded-xl focus:ring-2 focus:ring-green-500 focus:border-transparent`}
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
          {checkingUsername ? (
            <Loader2 className="h-5 w-5 text-gray-400 animate-spin" />
          ) : (
            <span className="text-gray-400">@</span>
          )}
        </div>
      </div>
      {errors.username && (
        <p className="text-red-500 text-sm">{errors.username}</p>
      )}
    </div>
{/* University Selection */}
<div className="space-y-2">
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Building className="h-5 w-5 text-gray-400" />
                  </div>
                  <select
                    name="university"
                    value={formData.university}
                    onChange={handleChange}
                    className={`pl-10 pr-4 py-3 w-full bg-gray-50 border ${
                      errors.university ? 'border-red-500' : 'border-gray-200'
                    } rounded-xl focus:ring-2 focus:ring-green-500 focus:border-transparent`}
                  >
                    <option value="">Select University</option>
                    {universities.map((uni) => (
                      <option key={uni.name} value={uni.name}>
                        {uni.name}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.university && (
                  <p className="text-red-500 text-sm">{errors.university}</p>
                )}
              </div>

              {/* Email Input */}
              <EmailInput 
  formData={formData}
  handleChange={handleChange}
  errors={errors}
  universities={universities}
/>
              {/* Password Input */}
              <div className="space-y-2">
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Lock className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleChange}
                    className={`pl-10 pr-4 py-3 w-full bg-gray-50 border ${
                      errors.password ? 'border-red-500' : 'border-gray-200'
                    } rounded-xl focus:ring-2 focus:ring-green-500 focus:border-transparent`}
                  />
                </div>
                {errors.password && (
                  <p className="text-red-500 text-sm">{errors.password}</p>
                )}
              </div>

              {/* Confirm Password Input */}
              <div className="space-y-2">
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Lock className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    className={`pl-10 pr-4 py-3 w-full bg-gray-50 border ${
                      errors.confirmPassword ? 'border-red-500' : 'border-gray-200'
                    } rounded-xl focus:ring-2 focus:ring-green-500 focus:border-transparent`}
                  />
                </div>
                {errors.confirmPassword && (
                  <p className="text-red-500 text-sm">{errors.confirmPassword}</p>
                )}
              </div>

              {/* Updated Submit Button */}
              <button
              type="submit"
              disabled={loading || !isValid}
              className={`w-full font-medium py-3 rounded-xl flex items-center justify-center space-x-2 transition-colors ${
                loading || !isValid
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-green-500 hover:bg-green-600 text-white'
              }`}
            >
              {loading ? (
                <>
                  <Loader2 className="w-5 h-5 animate-spin" />
                  <span>Creating Account...</span>
                </>
              ) : (
                <span>Create Account</span>
              )}
            </button>
          </form>
        ) : (
          <OTPVerification
            email={verificationEmail}
            onVerified={verifyOTP}
            resendOTP={() => sendOTP(verificationEmail)}
          />
        )}
          <PrivacyPolicyModal 
  isOpen={showPrivacyPolicy}
  onClose={() => setShowPrivacyPolicy(false)}
  onAccept={handlePrivacyPolicyAccept}
/>
          {errors.backend && (
            <div className="bg-red-50 text-red-500 p-3 rounded-xl text-sm">
              {errors.backend}
            </div>
          )}

          {/* Switch to Login */}
          <div className="text-center text-sm">
            <span className="text-gray-600">Already have an account?</span>{' '}
            <button
              onClick={switchToLogin}
              className="text-green-500 hover:text-green-600 font-medium"
            >
              Log in
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;