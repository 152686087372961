import React, { useState } from 'react';
import Login from './Login';
import Register from './Register';
import LandingPage from './LandingPage';

const AuthContainer = () => {
  const [view, setView] = useState('landing');

  const renderView = () => {
    switch (view) {
      case 'login':
        return <Login switchToRegister={() => setView('register')} />;
      case 'register':
        return <Register switchToLogin={() => setView('login')} />;
      default:
        return (
          <LandingPage 
            navigateToLogin={() => setView('login')} 
            navigateToRegister={() => setView('register')} 
          />
        );
    }
  };

  return renderView();
};

export default AuthContainer;